import {
  Alert,
  AlertTitle,
  ButtonGroup,
  Dialog,
  DialogContent,
  Grid,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { IppButton } from "./Buttons/IppButton";
import { useTypedTranslation } from "utils/customHooks";

export interface RenderDialogContentProps {
  setShowRejectInfo: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IppNeedsReviewBannerProps {
  objectTitle: string;
  acceptOnClick: () => void;
  renderDialogContent?: (props: RenderDialogContentProps) => React.ReactNode;
  showAccept?: boolean;
  acceptButtonText?: string;
  showReject?: boolean;
  rejectButtonText?: string;
  showMerge?: boolean; // Show merge text on reject button as "Merge / Reject"
  alertTitle?: string; // Title of the alert
  alertText?: string;
  severity?: "success" | "warning" | "error" | "info";
  buttonColor?: "success" | "primary";
  reviewOnClick?: () => void;
  reviewButtonText?: string;
  showEdit?: Boolean;
}

export const IppNeedsReviewBanner = ({
  objectTitle,
  acceptOnClick,
  renderDialogContent = () => null,
  showAccept = true,
  acceptButtonText,
  showReject = false,
  rejectButtonText,
  showMerge = false,
  alertTitle,
  alertText,
  severity = "warning",
  buttonColor = "success",
  reviewOnClick,
  reviewButtonText,
  showEdit,
}: IppNeedsReviewBannerProps) => {
  const [showRejectInfo, setShowRejectInfo] = useState(false);
  const t = useTypedTranslation(["strGen"]);

  const defaultAcceptButtonText = t(
    "strGen:components.needsreviewbanner.defaults.review"
  );
  const defaultAlertTitle = t(
    "strGen:components.needsreviewbanner.defaults.pendingreview"
  );
  const defaultAlertText = t(
    "strGen:components.needsreviewbanner.defaults.missingrequiredinfo"
  );

  return (
    <>
      <Grid xs={12}>
        <Alert
          severity={severity}
          action={
            showEdit && (
              <ButtonGroup
                variant="outlined"
                aria-label="outlined button group"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {showAccept && (
                    <IppButton
                      color={buttonColor}
                      variant="contained"
                      size="small"
                      id="alert"
                      onClick={acceptOnClick}
                      style={{ borderRadius: "4px" }}
                    >
                      {acceptButtonText || defaultAcceptButtonText}
                    </IppButton>
                  )}
                  {reviewOnClick && (
                    <IppButton
                      color="primary"
                      variant="contained"
                      size="small"
                      id="review-button"
                      onClick={reviewOnClick}
                      style={{ borderRadius: "4px" }}
                    >
                      {reviewButtonText || defaultAcceptButtonText}
                    </IppButton>
                  )}
                </div>
                {showReject && (
                  <Tooltip
                    title={
                      showMerge
                        ? t(
                            "strGen:components.needsreviewbanner.tooltips.permanentlydelete",
                            { objectname: objectTitle }
                          )
                        : t(
                            "strGen:components.needsreviewbanner.tooltips.checkandcompare",
                            { objectname: objectTitle }
                          )
                    }
                    arrow
                  >
                    <IppButton
                      color="error"
                      variant="contained"
                      size="small"
                      id="alert"
                      onClick={() => {
                        setShowRejectInfo(true);
                      }}
                    >
                      {rejectButtonText ||
                        (showMerge
                          ? t("strGen:buttons.mergerejected")
                          : t("strGen:buttons.reject"))}
                    </IppButton>
                  </Tooltip>
                )}
              </ButtonGroup>
            )
          }
        >
          <AlertTitle>{alertTitle || defaultAlertTitle}</AlertTitle>
          <p>{alertText || defaultAlertText}</p>
          <b>
            {showReject &&
              showRejectInfo &&
              t("strGen:components.needsreviewbanner.pressdeletetoremove")}
          </b>
        </Alert>
      </Grid>
      {showReject && renderDialogContent && (
        <Dialog open={showRejectInfo} maxWidth="lg">
          <DialogContent>
            {renderDialogContent({ setShowRejectInfo })}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
