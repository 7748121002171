import {
  Dialog,
  TextField,
  DialogContent,
  Typography,
  Chip,
  Autocomplete,
  Grid,
  Paper,
  Box,
  MenuList,
  MenuItem,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { ContactChildForm } from "features/platform/contacts/ContactChildForm";
import { IssueChildForm } from "features/stakeholder/issue/IssueChildForm";
import { StakeholderGroupChildForm } from "features/platform/groups/StakeholderGroupChildForm";
import { useEffect, useState } from "react";
import { IppContactAutoComplete } from "./IppContactAutoComplete";
import { InteractionCommentChildForm } from "features/stakeholder/interactionComment/InteractionCommentChildForm";
import { PaymentChildForm } from "features/benefits/payments/PaymentChildForm";
import { JobHistoryChildForm } from "features/benefits/jobHistory/JobHistoryChildForm";
import { RnDExpenseChildForm } from "features/benefits/RnDExpense/RnDExpenseChildForm";
import { formatStringForCompare } from "utils/functions";
import { EnTExpenseChildForm } from "features/benefits/entExpenses/EnTExpenseChildForm";
import { IppSaveButton } from "./Buttons/IppSaveButton";
import { IppCancelButton } from "./Buttons/IppCancelButton";
import { useTypedTranslation } from "utils/customHooks";
import { SuggestionBanner } from "./SuggestionBanner";
import IppCloseButton from "./Buttons/IppCloseButton";

interface ippChildEditProps {
  title: string; // title to show on sub page
  parentTitle: string; // title to show for container page
  parentID: number; // id value for parent record
  isOpen: boolean; // whether dialog is open or not
  closeAction: any; // action to close dialog
  tableName: string; // name of underlying table being edited
  id: string;
  label: string;
  options: any;
  selectedValues: any;
  setSelectedValues: any;
  setDidSaveInventory: any;
  parentValue: any;
  itemID: number;
  suggestions?: any;
  showSuggestions?: boolean;
  suggestionTitle?: any;
}

export const IppChildEdit = (props: ippChildEditProps) => {
  const dispatch = useDispatch();
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);
  const [addingItem, setAddingItem] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [showGroup, setShowGroup] = useState(false);
  const [showIssue, setShowIssue] = useState(false);
  const [newItem, setNewItem] = useState("");
  const [didSaveChild, setDidSaveChild] = useState(false);
  const [isTypingNewItem, setIsTypingNewItem] = useState(false);

  const {
    title,
    parentTitle,
    parentID,
    isOpen,
    closeAction,
    tableName,
    id,
    label,
    options,
    selectedValues,
    setSelectedValues,
    setDidSaveInventory,
    parentValue,
    itemID,
    suggestions,
    showSuggestions,
    suggestionTitle,
  } = props;

  const handleClose = (event: any, reason: any) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      dispatch(
        openSnackBar(t("strGen:components.snackbar.saveorcancel"), "info")
      );
    } else {
      // form was closed using either Save or Cancel
      closeAction();
    }
  };

  function handleClickAdd() {
    try {
      switch (id) {
        case "GroupList":
          setAddingItem(true);
          setIsTypingNewItem(false);
          setShowGroup(true);
          break;

        case "IssueList":
          setAddingItem(true);
          setIsTypingNewItem(false);
          setShowIssue(true);
          break;

        default:
          break;
      }
    } catch (e) {
      console.error(e);
    }
  }

  const handleSaveClicked = () => {
    setDidSaveInventory(true);
  };

  useEffect(() => {
    if (didSaveChild) {
      let newList = selectedValues;
      newList.push(newItem);

      setSelectedValues(newList);
      setDidSaveChild(false);
      setNewItem("");
    }
    return () => {
      // cleanup
    };
  }, [didSaveChild, newItem]);

  useEffect(() => {
    //When a new item is added successfully, we should add it to the selected values.
    if (newItem) {
      setSelectedValues([...selectedValues, newItem]);
    }
  }, [JSON.stringify(options)]);

  const handleInputChange = (event: any, value: any, reason: any) => {
    setNewItem(value);

    let exists = options.filter(
      (options: any) =>
        formatStringForCompare(options) === formatStringForCompare(value)
    );

    if (reason === "input" && value !== "" && exists.length === 0) {
      setIsTypingNewItem(true);
    } else {
      setIsTypingNewItem(false);
    }
  };

  const handleChange = (event: any, value: any, reason: any) => {
    setSelectedValues(value);

    // if new item added,show appropriate dialog
    // added item will always be the last in the list
    if (reason === "createOption") {
      let newString = value.pop().trim();

      let exists = options.filter(
        (options: any) =>
          formatStringForCompare(options) === formatStringForCompare(newString)
      );

      if (exists.length === 0) {
        setAddingItem(true);
        setIsTypingNewItem(false);

        switch (id) {
          case "ContactList":
            setNewItem(newString);
            setShowContact(true);
            break;

          case "GroupList":
            setNewItem(newString);
            setShowGroup(true);
            break;

          case "IssueList":
            setNewItem(newString);
            setShowIssue(true);
            break;

          default:
            break;
        }
      } else {
        let exists = selectedValues.filter(
          (options: any) =>
            formatStringForCompare(options) ===
            formatStringForCompare(newString)
        );

        if (exists.length === 0) {
          let test = [...selectedValues, newString];
          setSelectedValues(test);
        } else {
          let test = [...selectedValues];
          setSelectedValues(test);
        }
      }
    }
  };

  // Custom filter logic - normalize input and compare to options
  const customFilter = (
    options: any[],
    { inputValue }: { inputValue: string }
  ) => {
    const trimmedInput = formatStringForCompare(inputValue) || "";
    return options.filter((option) => {
      const optionLabel = formatStringForCompare(option) || "";
      return optionLabel.includes(trimmedInput);
    });
  };

  const addForm = () => {
    switch (title) {
      case "Contact":
        return (
          <div>
            <ContactChildForm
              setAddingItem={setAddingItem}
              fullName=""
              onClose={handleClose}
            />
          </div>
        );
      case "Group":
        return (
          <div>
            <StakeholderGroupChildForm
              setAddingItem={setAddingItem}
              groupName={newItem}
              setNewItem={setNewItem}
              setDidSaveChild={setDidSaveChild}
            />
          </div>
        );
      case t("objStk:objects.issue.name"):
        return (
          <div>
            <IssueChildForm
              setAddingItem={setAddingItem}
              issueName={newItem}
              setNewItem={setNewItem}
              setDidSaveChild={setDidSaveChild}
            />
          </div>
        );
      case "Spend":
        return (
          <div>
            <PaymentChildForm
              closeAction={handleClose}
              setDidSaveInventory={setDidSaveInventory}
              paymentPeriod={parentValue}
              paymentID={itemID}
            />
          </div>
        );
      case "Job History":
        return (
          <div>
            <JobHistoryChildForm
              closeAction={handleClose}
              employeeID={parentID}
              jobHistoryID={itemID}
            />
          </div>
        );
      case "Comment":
        return (
          <div>
            <InteractionCommentChildForm
              closeAction={handleClose}
              setDidSaveInventory={setDidSaveInventory}
              parentTitle={parentTitle}
              parentValue={null}
              parentID={parentID}
            />
          </div>
        );
      case "R&D Expense":
        return (
          <div>
            <RnDExpenseChildForm
              closeAction={handleClose}
              setDidSaveInventory={setDidSaveInventory}
              parentTitle={parentTitle}
              parentID={parentID}
              parentValue={parentValue}
            />
          </div>
        );
      case "E&T Expense":
        return (
          <div>
            <EnTExpenseChildForm
              closeAction={handleClose}
              setDidSaveInventory={setDidSaveInventory}
              parentTitle={parentTitle}
              parentID={parentID}
              parentValue={parentValue}
            />
          </div>
        );
      default:
        return <p>{t("strGen:nomatchingentryfound")}</p>;
    }
  };

  let tokenForm = (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6">
          {t("strGen:components.ippchilddelete.tokenform.title", {
            title: title,
            parenttitle: parentTitle,
          })}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          {t("strGen:components.ippchilddelete.tokenform.body", {
            title: title,
            parenttitle: parentTitle,
          })}
        </Typography>
      </Grid>
      {isTypingNewItem && (
        <Grid item xs={12}>
          <Typography color="primary" variant="caption">
            {t("strGen:components.ippchilddelete.tokenform.caption", {
              newitem: newItem,
            })}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        <Autocomplete
          multiple
          id={id}
          options={options}
          filterOptions={customFilter}
          value={selectedValues}
          freeSolo
          handleHomeEndKeys={true}
          onChange={handleChange}
          onInputChange={handleInputChange}
          PaperComponent={({ children }) => (
            <Paper>
              {children}
              {isTypingNewItem && (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <MenuList>
                    <MenuItem
                      onMouseDown={(event) => {
                        event.preventDefault();
                        handleClickAdd();
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="primary"
                        alignContent={"center"}
                      >
                        {t(
                          "strGen:components.ippchilddelete.tokenform.autocompletemessage",
                          {
                            newitem: newItem,
                          }
                        )}
                      </Typography>
                    </MenuItem>
                  </MenuList>
                </Box>
              )}
            </Paper>
          )}
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      </Grid>
      {showSuggestions && (
        <Grid item xs={12} marginX={2}>
          <SuggestionBanner
            title={suggestionTitle}
            suggestions={suggestions}
            list={selectedValues}
            setList={setSelectedValues}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <IppSaveButton
          handleSave={handleSaveClicked}
          disabled={isTypingNewItem}
        />
        <IppCancelButton onClick={closeAction} />
      </Grid>
    </Grid>
  );

  let contactForm = (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6">
          {t("strGen:components.ippchilddelete.contactform.title", {
            parenttitle: parentTitle,
          })}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          {t("strGen:components.ippchilddelete.contactform.body", {
            parenttitle: parentTitle,
          })}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <IppContactAutoComplete
          options={options}
          selectedContacts={selectedValues}
          setSelectedContacts={setSelectedValues}
        />
      </Grid>
      <Grid item xs={12}>
        <IppSaveButton handleSave={handleSaveClicked} />
        <IppCancelButton onClick={closeAction} />
      </Grid>
    </Grid>
  );

  let pageForm = (
    <>
      {addingItem ? (
        <>{addForm()}</>
      ) : (
        <>
          {title === "Contact" ? (
            <div>{contactForm}</div>
          ) : (
            <div>{tokenForm}</div>
          )}
        </>
      )}
    </>
  );

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md">
        <DialogTitle>
          <IppCloseButton onClick={() => handleClose(null, null)} />
        </DialogTitle>
        <DialogContent>
          <div>{pageForm}</div>
        </DialogContent>
      </Dialog>
    </>
  );
};
