import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchUnconfirmedCompanies } from "./CompaniesSlice";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";
import { IppInventory } from "components/Inventory/IppInventory";
import { useTypedTranslation } from "utils/customHooks";
import { fetchRegionsByRecordType } from "../regions/RegionSlice";
import { fetchUsers } from "features/users/UsersSlice";
import { type Company } from "api/companyAPI";

export const CompanyRequestInventoryPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objBen"]);

  const {
    companyList,
    isLoading,
    error: compError,
    companiesById,
  } = useSelector((state: RootState) => state.companies);

  const {
    clientId,
    ft_ben_Region,
    isLoading: clientIsLoading,
  } = useSelector((state: RootState) => state.client);

  const { regionList, regionsById } = useSelector(
    (state: RootState) => state.region
  );

  const { usersById, isLoading: usersLoading } = useSelector(
    (state: RootState) => state.users
  );

  const regions = ft_ben_Region ? regionList.map((id) => regionsById[id]) : [];
  const hasRegionTypes = !!(ft_ben_Region && regions.length > 0);

  const companies = companyList.map((CompanyID) => companiesById[CompanyID]);

  const data = companies.map((company: Company) => {
    const createdByName = company.CreatedBy
      ? `${usersById[company.CreatedBy]?.FirstName || "Unknown"} ${
          usersById[company.CreatedBy]?.Surname || ""
        }`
      : "Unknown";

    return {
      ...company,
      CreatedBy: createdByName,
    };
  });

  const regionColumn = hasRegionTypes
    ? [
        {
          field: "RegionName",
          title: t("objBen:objects.company.fields.region.name"),
        },
      ]
    : [];

  const compCols = [
    { field: "CompanyName", title: "Company" },
    { field: "VendorID", title: "Vendor ID", filter: "numeric" },
    { field: "TownCity", title: "Town/City" },
    { field: "ProvinceState", title: "Province/State" },
    { field: "CreatedBy", title: "Requested By" },
    ...regionColumn,
  ];

  useEffect(() => {
    (async () => {
      try {
        if (clientId !== -1) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchUnconfirmedCompanies(accessToken, clientId));
          if (ft_ben_Region) {
            dispatch(fetchRegionsByRecordType(accessToken, "Company"));
          }
          dispatch(fetchUsers(accessToken, clientId));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);

  if (compError) {
    return <ErrorPage errMsg={compError} />;
  }

  let compInvView =
    isLoading || clientIsLoading || usersLoading ? (
      <LoadingIndicator />
    ) : (
      <div>
        <IppInventory
          title="New Company Requests"
          tableData={data}
          idField="CompanyID"
          columns={compCols}
          isFiltered={true}
          showDetail={true}
          searchByColumn="CompanyName"
          searchByPlaceholder="Search by Company Name"
        />
      </div>
    );

  return <div id="company-inventory-page">{compInvView}</div>;
};
