import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { ChangeEvent, useEffect, useState } from "react";
import {
  addEducationTraining,
  updEducationTraining,
  delEducationTraining,
} from "./EducationTrainingSlice";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppFormHeader } from "components/IppFormHeader";
import { IppAutocomplete } from "components/IppAutocomplete";
import LoadingIndicator from "components/LoadingIndicator";
import { Prompt } from "react-router";
import { IppDisplayField } from "components/IppDisplayField";
import { UserWriteAccess } from "utils/userAccess";
import { useTypedTranslation } from "utils/customHooks";
import { IppCurrencyField } from "components/IppCurrencyField";
import { IppTextField } from "components/IppTextField";
import { IppDisplayRichText } from "components/IppDisplayRichText";
import { IppRichTextEditor } from "components/RichTextEditor/IppRichTextEditor";
import {
  ConvertDateOffset,
  ConvertToJSDate,
  GetJSDate,
} from "utils/DateFunctions";
import { fetchProjects } from "features/project/ProjectSlice";
import { fetchCompanies } from "../companies/CompaniesSlice";
import { EducationTrainingValidation } from "./EducationTrainingValidation";
import { IppDatePicker } from "components/IppDatePicker";
import { IppCurrencyDisplay } from "components/IppCurrencyDisplayField";
import { Company } from "api/companyAPI";
import { CompanyOptionsRender } from "utils/renderFunctions";
import { ShowActiveCompanies } from "../BenefitsUtils";
import { useCompanyListByRole } from "utils/hooks/useCompanyListByRole";

const PREFIX = "EducationTrainingForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    // minWidth: 650,
    maxWidth: 1150,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const EducationTrainingForm = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);
  //const customBP = IsMedSmall();

  let educationTrainingData = props.educationTraining || {};
  const [isEditing, setIsEditing] = useState(
    educationTrainingData.EducationTrainingID ? false : true
  );

  const [isAdding, setIsAdding] = useState(
    educationTrainingData.EducationTrainingID ? false : true
  );

  const showEdit = UserWriteAccess("Benefits");

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const {
    isLoading: companyIsLoading,
    companiesById,
    companyList,
  } = useSelector((state: RootState) => state.companies);
  const companies = companyList.map((id) => companiesById[id]);
  const confirmedCompanies = useCompanyListByRole(
    companies.filter((comp) => comp.ListingConfirmed)
  );

  const {
    isLoading: projectIsLoading,
    projectsById,
    projectList,
  } = useSelector((state: RootState) => state.projects);
  const projects = projectList.map((id) => projectsById[id]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchProjects(accessToken));
        dispatch(fetchCompanies(accessToken, clientId));
      } catch (error) {
        console.error(error);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(addEducationTraining(accessToken, values, true));
      } catch (error) {
        console.error(error);
      }
    })();
  };

  const handleDelete = () => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(
          delEducationTraining(
            accessToken,
            educationTrainingData.EducationTrainingID
          )
        );
      } catch (error) {
        console.error(error);
      }
    })();
  };

  let submitFunc = onSub;

  if (!isAdding) {
    // update case
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(updEducationTraining(accessToken, values, false));
        } catch (error) {
          console.error(error);
        }
      })();
    };
  } else {
    // add case set default values
    educationTrainingData.Type = null;
    educationTrainingData.ProjectID = null;
    educationTrainingData.CompanyID = currentProfile.CompanyID;
    educationTrainingData.Expenditure = 0;
    educationTrainingData.ExpenditureDate = new Date()
      .toISOString()
      .slice(0, 10);
    educationTrainingData.Activity = null;
    educationTrainingData.Description = null;
    educationTrainingData.Provider = null;
    educationTrainingData.Location = null;
  }

  const formik = useFormik({
    initialValues: educationTrainingData,
    validationSchema: EducationTrainingValidation(),
    onSubmit: submitFunc,
  });

  // Expense Type Options, todo: get from BE in the future if neccessary
  const expenseTypeOptions = [
    { id: 0, value: t("objBen:objects.entExpense.education") },
    { id: 1, value: t("objBen:objects.entExpense.training") },
  ];

  let viewForm = (
    <Box display="flex" justifyContent="center">
      <Grid container spacing={1} className={classes.editForm}>
        <Grid item xs={12}>
          <Paper className={classes.boxSpace}>
            <Grid container spacing={1}>
              <IppFormHeader
                title={t("objBen:objects.entExpense.entexpenditure")}
                isEditing={isEditing}
                isAdding={isAdding}
                showReturn={true}
                showReturnToInv={true}
              />
              <Grid item xs={12}>
                <IppDisplayField
                  label={t(
                    "objBen:objects.educationtraining.fields.classification"
                  )}
                  value={formik.values.Type}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objBen:objects.educationtraining.fields.activity")}
                  value={formik.values.Activity}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label="Project"
                  value={formik.values.ProjectName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppCurrencyDisplay
                  label="Expenditure"
                  value={formik.values.Expenditure}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label="Expenditure Date"
                  value={ConvertToJSDate(formik.values.ExpenditureDate)}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label={t(
                    "objBen:objects.educationtraining.fields.locationdetails"
                  )}
                  value={formik.values.Location}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>

              <Grid item xs={6}>
                <IppDisplayField
                  label="Provider"
                  value={formik.values.Provider}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label="Company"
                  value={formik.values.CompanyName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={12}>
                <IppDisplayRichText
                  label="Description"
                  value={formik.values.Description}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>

              <Grid item xs={12}>
                <IppFormButtons
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  isAdding={isAdding}
                  resetFunction={() => formik.resetForm()}
                  showDelete={showEdit}
                  deleteFunction={handleDelete}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );

  let editForm =
    clientIsLoading || companyIsLoading || projectIsLoading ? (
      <LoadingIndicator />
    ) : (
      <form noValidate onSubmit={formik.handleSubmit}>
        {!formik.isSubmitting && (
          <Prompt
            when={formik.dirty}
            message={t("strGen:prompts.unsavedchanges")}
          />
        )}
        <Grid container className={classes.editForm} spacing={1}>
          <IppFormHeader
            title={t("objBen:objects.entExpense.entexpenditure")}
            isEditing={isEditing}
            isAdding={isAdding}
            returnPath="/benefits/educationtrainings"
          />
          <Grid item xs={12}>
            <IppTextField
              id="Activity"
              label="Activity"
              required={true}
              value={formik.values.Activity}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.Activity}
              errorsExpression={formik.errors.Activity}
            />
          </Grid>
          <Grid item xs={6}>
            <IppAutocomplete
              id="Type"
              options={expenseTypeOptions}
              required={true}
              value={expenseTypeOptions.find((obj) => {
                return obj.value === formik.values.Type;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("Type", newValue.value);
                } else {
                  formik.setFieldValue("Type", null);
                }
              }}
              label="Expenditure Type"
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.value}
              errorFunction={formik.errors.Type}
              touchedFunction={formik.touched.Type}
              textValueFunction={formik.values.Type?.value}
            />
          </Grid>
          <Grid item xs={6}>
            <IppAutocomplete
              id="ProjectID"
              options={projects}
              required={true}
              value={projects.find((obj) => {
                return obj.ProjectID === formik.values.ProjectID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("ProjectID", newValue.ProjectID);
                  // formik.setFieldValue("ProjectName", newValue.ProjectName);
                } else {
                  formik.setFieldValue("ProjectID", null);
                  // formik.setFieldValue("ProjectName", null);
                }
              }}
              label={t("objPlt:objects.project.name")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.ProjectName}
              errorFunction={formik.errors.ProjectID}
              touchedFunction={formik.touched.ProjectID}
              textValueFunction={formik.values.ProjectName}
            />
          </Grid>
          <Grid item xs={6}>
            <IppCurrencyField
              id="Expenditure"
              label="Expenditure"
              required={true}
              value={formik.values.Expenditure}
              isEditing={isEditing}
              onChangeFunction={(newValue) =>
                formik.setFieldValue("Expenditure", newValue)
              }
              touchedExpression={formik.touched.Expenditure}
              errorsExpression={formik.errors.Expenditure}
            />
          </Grid>
          <Grid item xs={6}>
            <IppDatePicker
              id="ExpenditureDate"
              label="Expenditure Date"
              required={true}
              value={ConvertDateOffset(formik.values.ExpenditureDate)}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              onChangeFunction={(newValue: any) => {
                formik.setFieldValue(
                  "ExpenditureDate",
                  GetJSDate(newValue),
                  true
                );
                formik.setFieldTouched("Date", true, false);
              }}
              touchedExpression={formik.touched.ExpenditureDate}
              errorsExpression={formik.errors.ExpenditureDate}
              disableFuture={true}
              monthOnly={false}
            />
          </Grid>
          <Grid item xs={6}>
            <IppTextField
              id="Provider"
              label={t("objBen:objects.entExpense.fields.provider")}
              value={formik.values.Provider}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              onChangeFunction={formik.handleChange}
              errorsExpression={formik.errors.Provider}
              touchedExpression={formik.touched.Provider}
              //toolTip={t("objBen:objects.entExpense.tooltips.expenseprovider")}
            />
          </Grid>
          <Grid item xs={6}>
            <IppTextField
              id="Location"
              label={t(
                "objBen:objects.educationtraining.fields.locationdetails"
              )}
              value={formik.values.Location}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              onChangeFunction={formik.handleChange}
              errorsExpression={formik.errors.Location}
              touchedExpression={formik.touched.Location}
            />
          </Grid>
          <Grid item xs={6}>
            <IppAutocomplete
              id="CompanyID"
              required={true}
              options={ShowActiveCompanies(confirmedCompanies)}
              value={confirmedCompanies.find((obj) => {
                return obj.CompanyID === formik.values.CompanyID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("CompanyID", newValue.CompanyID);
                } else {
                  formik.setFieldValue("CompanyID", null);
                }
              }}
              disabled={
                !currentProfile?.IsClient && !currentProfile?.IsClientAdmin
              }
              label={t("objBen:objects.company.name")}
              isEditing={currentProfile.IsClient && isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.CompanyName}
              renderOption={(props: any, option: Company) => {
                return CompanyOptionsRender(props, option);
              }}
              //placeholder="Paid by"
              touchedFunction={formik.touched.CompanyID}
              errorFunction={formik.errors.CompanyID}
              textValueFunction={
                !companyIsLoading &&
                formik.values.CompanyID > 0 &&
                companiesById[formik.values.CompanyID]
                  ? companiesById[formik.values.CompanyID].CompanyName
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12}>
            <IppRichTextEditor
              id="Description"
              label="Description"
              value={formik.values.Description}
              isEditing={isEditing}
              touchedExpression={formik.touched.Description}
              errorsExpression={formik.errors.Description}
              setFieldValue={formik.setFieldValue}
              height={100}
            />
          </Grid>
          <Grid item xs={12}>
            <IppFormButtons
              isEditing={isEditing}
              isAdding={isAdding}
              setIsEditing={setIsEditing}
              resetFunction={() => formik.resetForm()}
              showDelete={showEdit}
              deleteFunction={handleDelete}
            />
          </Grid>
        </Grid>
      </form>
    );

  let pageForm =
    clientIsLoading || companyIsLoading || projectIsLoading ? (
      <LoadingIndicator />
    ) : isAdding ? (
      <Box display="flex" justifyContent="center">
        <Paper className={classes.boxSpace}>{editForm}</Paper>
      </Box>
    ) : (
      <Grid container spacing={1}>
        <Grid item justifyContent="center" xs={12}>
          {isEditing ? (
            <Dialog open={isEditing} fullWidth maxWidth="lg">
              <DialogContent>{editForm}</DialogContent>
            </Dialog>
          ) : (
            <div>{viewForm}</div>
          )}
        </Grid>
      </Grid>
    );

  return <Root>{pageForm}</Root>;
};
