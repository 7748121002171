import axiosClient from "../axiosClient";

export interface CommitmentGroup {
  CommitmentGroupID: number;
  GroupID: number;
  CommitmentID: number;
  GroupName: string;
  ClientID: number;
  ProjectName: string;
}

export interface CommitmentGroupResult {
  commitmentGroups: CommitmentGroup[];
}

export async function getCommitmentGroups(
  accessToken: String
): Promise<CommitmentGroupResult> {
  const url = `/commitmentGroup`;

  try {
    const { data } = await axiosClient.get<CommitmentGroupResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getCommitmentGroupsByCommitment(
  accessToken: String,
  commitmentID: number
): Promise<CommitmentGroupResult> {
  const url = `/commitmentGroup/commitment/${commitmentID}`;

  try {
    const { data } = await axiosClient.get<CommitmentGroupResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getCommitmentGroupsByGroupID(
  accessToken: String,
  groupID: number
): Promise<CommitmentGroupResult> {
  const url = `/commitmentGroup/group/${groupID}`;

  try {
    const { data } = await axiosClient.get<CommitmentGroupResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createCommitmentGroup(
  accessToken: String,
  newCommInv: Partial<CommitmentGroup>
): Promise<CommitmentGroup> {
  const url = `/commitmentGroup`;

  try {
    const { data } = await axiosClient.post<CommitmentGroup>(url, newCommInv, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteCommitmentGroup(
  accessToken: String,
  commInvID: number
): Promise<string> {
  const url = `/commitmentGroup/` + commInvID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
