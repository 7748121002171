import { useAuth0 } from "@auth0/auth0-react";
import { Grid, Typography } from "@mui/material";
import { type User } from "api/usersAPI";
import { RootState } from "app/rootReducer";
import { IppSkeleton } from "components/IppSkeleton";
import { format } from "date-fns";
import { fetchUsers } from "features/users/UsersSlice";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTypedTranslation } from "utils/customHooks";

interface Recordhistory {
  refetch?: boolean; // this should be false when there is already a get user dispatch in the parent file
  createdBy?: number;
  createDate: any;
  lastUpdatedBy?: number;
  lastUpdated: any;
}

export const RecordHistoryDisplay = (props: Recordhistory) => {
  const { refetch, createdBy, createDate, lastUpdatedBy, lastUpdated } = props;
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen"]);

  const { clientId } = useSelector((state: RootState) => state.client);

  const { usersById, isLoading } = useSelector(
    (state: RootState) => state.users
  );

  // Fetch users if refetch is true
  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        (refetch || !Object.keys(usersById).length) &&
          dispatch(fetchUsers(accessToken, clientId));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [refetch, dispatch, getAccessTokenSilently]);

  const formatHistoryString = useCallback(
    (
      date: string,
      userId: number | undefined,
      user: User | undefined,
      label: "created" | "lastUpdated"
    ) => {
      if (date && userId && user) {
        return t(`strGen:forms.${label}.withDateName`, {
          date: format(new Date(date), "do MMM yyyy"),
          name: `${user.FirstName} ${user.Surname}`,
        });
      } else if (userId && user) {
        return t(`strGen:forms.${label}.withName`, {
          name: `${user.FirstName} ${user.Surname}`,
        });
      } else if (date) {
        return t(`strGen:forms.${label}.withDate`, {
          date: format(new Date(date), "do MMM yyyy"),
        });
      }

      return undefined;
    },
    [t]
  );

  const createdByUser = createdBy ? usersById[createdBy] : undefined;
  const lastUpdatedUser = lastUpdatedBy ? usersById[lastUpdatedBy] : undefined;

  const createdStr = formatHistoryString(
    createDate,
    createdBy,
    createdByUser,
    "created"
  );
  const lastUpdatedStr = formatHistoryString(
    lastUpdated,
    lastUpdatedBy,
    lastUpdatedUser,
    "lastUpdated"
  );

  if (isLoading) return <IppSkeleton height={23} />;
  // shows no history if no date is present regardless of By
  // shows unknown if By is not valid for any reason
  return (
    <Grid item xs={12}>
      <Grid container direction="column">
        {createdStr && (
          <Typography variant="caption" sx={{ color: "text.secondary" }}>
            {createdStr}
          </Typography>
        )}
        {lastUpdatedStr && (
          <Typography variant="caption" sx={{ color: "text.secondary" }}>
            {lastUpdatedStr}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
