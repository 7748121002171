import { useTranslation } from "react-i18next";
import { useCallback, useEffect } from "react";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { selectUserRolesByUserAccountID } from "features/roles/UserRoleSlice";
import { ModuleRoles, Modules } from "./types/General.types";

type TypedTFunction = (key: string, options?: any) => string;

export function useTypedTranslation(namespaces: string[]): TypedTFunction {
  const { t, i18n } = useTranslation(namespaces);
  const memoizedT = useCallback(
    (key: string, options?: any) => {
      const result = t(key, options);
      return typeof result === "string" ? result : "";
    },
    [i18n.language]
  );
  return memoizedT;
}

export type SnackBarConstants = {
  UPDATE_SUCCESS: string;
  ADD_SUCCESS: string;
  FAILED: string;
  FAILED_MERGE_CONTACT: string;
  FAILED_MERGE_GROUP: string;
  DELETE_SUCCESS: string;
  REMOVE_SUCCESS: string;
  REPORT_REFRESH_SUCCESS: string;
  REFRESH_LIMIT_EXCEEDED: string;
  REPORT_CHANGES_WARNING: string;
  DOWNLOAD_REQUESTED: string;
};

export const useSnackBarConstants = (): SnackBarConstants => {
  const t = useTypedTranslation(["strGen"]);
  return {
    UPDATE_SUCCESS: t("strGen:components.snackbar.updatesuccess"),
    ADD_SUCCESS: t("strGen:components.snackbar.addsuccess"),
    FAILED: t("strGen:components.snackbar.failed"),
    FAILED_MERGE_CONTACT: t("strGen:components.snackbar.failedmergecontact"),
    FAILED_MERGE_GROUP: t("strGen:components.snackbar.failedmergegroup"),
    DELETE_SUCCESS: t("strGen:components.snackbar.deletesuccess"),
    REMOVE_SUCCESS: t("strGen:components.snackbar.removesuccess"),
    REPORT_REFRESH_SUCCESS: t("strGen:components.snackbar.refreshsuccess"),
    REFRESH_LIMIT_EXCEEDED: t(
      "strGen:components.snackbar.refreshlimitexceeded"
    ),
    REPORT_CHANGES_WARNING: t(
      "strGen:components.snackbar.reportchangeswarning"
    ),
    DOWNLOAD_REQUESTED: t("strGen:components.snackbar.downloadrequested"),
  };
};

export const useFormikContextWithSubmit = (onSubmit: () => void) => {
  const formik = useFormikContext();

  useEffect(() => {
    if (formik && formik.isSubmitting) {
      onSubmit();
    }
  }, [formik, formik?.isSubmitting, onSubmit]);
};

/* 
The useRoleChecks hook provides various role-checking utilities for a user's roles across the different Modules. It retrieves the user's profile and roles from the Redux store and provides several helper functions to check roles and permissions. 
*/
export const useRoleChecks = (projectID?: number) => {
  // Get the current profile from the Redux store
  const { currentProfile } = useSelector((state: RootState) => state.profile);

  // Get user roles based on the currentProfile's IsClientAdmin status
  const allUserRoles = useSelector((state: RootState) =>
    currentProfile.IsClientAdmin
      ? [] // If client admin, no roles needed
      : selectUserRolesByUserAccountID(currentProfile.UserAccountID)(state)
  );

  const getUserModuleRoleIdArray = () =>
    allUserRoles.map((role) => role.ModuleRoleID);

  const isAdmin = () => currentProfile.IsClientAdmin;

  // -------------Benefits-------------------
  const getBenefitsUserRoles = () =>
    allUserRoles.filter((role) => role.ModuleID === Modules.Benefits);

  // -------------Engagements----------------
  const getEngagementUserRoles = () =>
    allUserRoles.filter((role) => role.ModuleID === Modules.Engagements);

  const getEngagementsPowerUserRoles = () =>
    allUserRoles.filter(
      (role) => role.ModuleRoleID === ModuleRoles.EngagementsPowerUser
    );

  // -------------Commitments----------------
  const getCommitmentsUserRoles = () =>
    allUserRoles.filter((role) => role.ModuleID === Modules.Commitments);

  const hasSomeContributorRole = () =>
    allUserRoles.some(
      (role) => role.ModuleRoleID === ModuleRoles.CommitmentsContributor
    );

  const hasContributorRoleForProject = () =>
    !isAdmin() &&
    !!projectID &&
    allUserRoles.some(
      (role) =>
        role.ModuleRoleID === ModuleRoles.CommitmentsContributor &&
        role.ProjectID === projectID
    );

  // ----------Cross Module Roles-------------
  const hasCommitmentInteractionRoles = () => {
    const userModuduleRoleIds = getUserModuleRoleIdArray();

    return (
      userModuduleRoleIds.includes(ModuleRoles.CommitmentsPowerUser) &&
      userModuduleRoleIds.includes(ModuleRoles.EngagementsPowerUser)
    );
  };

  return {
    allUserRoles,
    isAdmin,
    getBenefitsUserRoles,
    getEngagementUserRoles,
    getEngagementsPowerUserRoles,
    getCommitmentsUserRoles,
    hasSomeContributorRole,
    hasContributorRoleForProject,
    hasCommitmentInteractionRoles,
  };
};
