import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { IppInventory } from "components/Inventory/IppInventory";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";
import {
  delStandardStatement,
  fetchStandardStatements,
} from "./StandardStatementSlice";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import * as Constants from "utils/snackBarConstants";
import IppConfirmDialog from "components/IppConfirmDialog";
import { StandardStatementForm } from "./StandardStatementForm";
import { useTypedTranslation } from "utils/customHooks";

export const StandardStatementInventory = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [editID, setEditID] = useState<number | undefined>(undefined);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteID, setDeleteID] = useState<number | undefined>(undefined);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const t = useTypedTranslation(["objPlt", "strGen"]);

  const {
    ft_eng_InternalNotes,
    ft_eng_Thread,
    ft_com_InternalNotes,
    ft_com_RegulatoryNotes,
    ft_ben_RND,
    ft_ben_SpendReport,
  } = useSelector((state: RootState) => state.client);
  const { clientModuleList, clientModulesById } = useSelector(
    (state: RootState) => state.clientModules
  );

  const clientModules = clientModuleList
    .map((id) => clientModulesById[id])
    .map((module) => module.ModuleID);

  const clientHasBenefits = clientModules.includes(1);

  const clientHasCommitments = clientModules.includes(2);

  const clientHasStakeholder = clientModules.includes(3);

  const {
    standardStatementsById,
    standardStatementList,
    isLoading: standardStatementIsLoading,
    error: standardStatementError,
  } = useSelector((state: RootState) => state.standardStatement);

  const standardStatements = standardStatementList.map(
    (id) => standardStatementsById[id]
  );

  const tableData = standardStatements.map((statement) => {
    const trueValues = [
      statement.InteractionDetails &&
        clientHasStakeholder &&
        t("objPlt:objects.standardstatement.fields.interactiondetails"),
      statement.InteractionInternalNotes &&
        clientHasStakeholder &&
        ft_eng_InternalNotes &&
        t("objPlt:objects.standardstatement.fields.interactioninternalnotes"),
      statement.InteractionActionDetails &&
        clientHasStakeholder &&
        t("objPlt:objects.standardstatement.fields.interactionactiondetails"),
      statement.InteractionThreadSummary &&
        clientHasStakeholder &&
        ft_eng_Thread &&
        t("objPlt:objects.standardstatement.fields.interactionthreadsummary"),
      statement.CommitmentDetails &&
        clientHasCommitments &&
        t("objPlt:objects.standardstatement.fields.commitmentdetails"),
      statement.CommitmentInternalNotes &&
        clientHasCommitments &&
        ft_com_InternalNotes &&
        t("objPlt:objects.standardstatement.fields.commitmentinternalnotes"),
      statement.CommitmentRegulatoryNotes &&
        clientHasCommitments &&
        ft_com_RegulatoryNotes &&
        t("objPlt:objects.standardstatement.fields.commitmentregulatorynotes"),
      statement.CommitmentLegalText &&
        clientHasCommitments &&
        t("objPlt:objects.standardstatement.fields.commitmentlegaltext"),
      statement.CommitmentActionDetails &&
        clientHasCommitments &&
        t("objPlt:objects.standardstatement.fields.commitmentactiondetails"),
      statement.InitiativeDetails &&
        clientHasBenefits &&
        ft_ben_RND &&
        t("objPlt:objects.standardstatement.fields.intiativedetails"),
      statement.PaymentPeriodNotes &&
        clientHasBenefits &&
        ft_ben_SpendReport &&
        t("objPlt:objects.standardstatement.fields.spendreportnotes"),
    ]
      .filter(Boolean)
      .join(",");

    return {
      ...statement,
      RecordTypes: trueValues,
    };
  });

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchStandardStatements(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  const handleDelete = (standardStatementID: number | undefined) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        if (standardStatementID) {
          dispatch(delStandardStatement(accessToken, standardStatementID));
          setDeleteOpen(false);
        } else {
          dispatch(openSnackBar(Constants.FAILED, "error"));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const dataColumns = [
    {
      field: "StandardStatement",
      title: t("objPlt:objects.standardstatement.fields.statement"),
      richText: true,
    },
    {
      field: "RecordTypes",
      title: t("objPlt:objects.standardstatement.recordtypes"),
      commaDelimitedChips: true,
    },
  ];

  if (standardStatementError) {
    return <ErrorPage errMsg={standardStatementError} />;
  }

  const standardStatementInvView = standardStatementIsLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <IppInventory
        title={t("objPlt:objects.standardstatement.name", { count: 2 })}
        tableData={tableData}
        idField="StandardStatementID"
        columns={dataColumns}
        optionsButtons={{
          showEdit: true,
          setEditID: setEditID,
          setEditOpen: setEditOpen,
          showDelete: true,
          setDeleteID: setDeleteID,
          setDeleteOpen: setDeleteOpen,
        }}
        isFiltered={false}
        showDetail={false}
        buttonProps={{
          showAdd: true,
          addBtnOnClick: () => setEditOpen(true),
        }}
        hideExport
      />

      <StandardStatementForm
        editOpen={editOpen}
        setEditOpen={setEditOpen}
        setEditID={setEditID}
        standardStatementID={editID}
      />

      <IppConfirmDialog
        title={t("strGen:prompts.deletechecks.titles.deleteobject", {
          objectname: t("objPlt:objects.standardstatement.name"),
        })}
        htmlDetails={
          tableData.find((item) => item.StandardStatementID === deleteID)
            ?.StandardStatement
        }
        open={deleteOpen}
        setOpen={() => setDeleteOpen(!deleteOpen)}
        onConfirm={() => handleDelete(deleteID)}
        recordID={deleteID}
      />
    </div>
  );

  return (
    <div id="standard-statement-inventory-page">{standardStatementInvView}</div>
  );
};
