import { styled } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Grid as KendoGrid,
  GridColumn,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { Delete, Edit } from "@mui/icons-material";
import { IppTextField } from "components/IppTextField";
import { useTypedTranslation } from "utils/customHooks";
import LoadingIndicator from "components/LoadingIndicator";
import { addTier, delTier, fetchAllTiers, updTier } from "./TierSlice";
import { TierValidationSchema } from "./TierValidation";
import IppConfirmDialog from "components/IppConfirmDialog";
import { fetchDeleteChecksByTier } from "features/deleteCheck/DeleteCheckSlice";
import { IppButton } from "components/Buttons/IppButton";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppAddButton } from "components/Buttons/IppAddButton";
import { IppDeleteButton } from "components/Buttons/IppDeleteButton";
import { IppCancelButton } from "components/Buttons/IppCancelButton";

const PREFIX = "TierPage";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
  roleCard: `${PREFIX}-roleCard`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1100,
  },

  [`& .${classes.boxSpace}`]: {
    padding: 10,
    maxWidth: 900,
  },

  [`& .${classes.roleCard}`]: {
    minWidth: 250,

    minHeight: 300,
  },
}));

const CustomCell = (props: any) => {
  return (
    <td style={{ textAlign: "left", paddingLeft: "0px" }}>
      {props.dataItem[props.field]}
    </td>
  );
};

export const TierPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [editID, setEditID] = useState(1);
  const [deleteName, setDeleteName] = useState("");
  const [isEditing, setIsEditing] = useState(true);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  const { clientId } = useSelector((state: RootState) => state.client);

  const { tierList, tiersById, tierIsLoading } = useSelector(
    (state: RootState) => state.tier
  );

  const tiers = tierList.map((id) => tiersById[id]);

  const newTier = {
    TierName: undefined,
    TierDescription: "",
    TierRank: tiers.length + 1,
  };
  const [editItem, setEditItem] = useState(newTier);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchAllTiers(accessToken));
        //setPageLoaded(true);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        if (isAdding) {
          dispatch(addTier(accessToken, values));
        } else {
          dispatch(updTier(accessToken, editID, values));
        }
        setEditOpen(false);
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const handleDelete = (tierID: number) => {
    // function to delete current Tier
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(delTier(accessToken, tierID));
        setDeleteOpen(false);
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  const deleteCell = (props: any) => {
    return (
      <td>
        <Tooltip
          title={t("strGen:buttons.deleteobj", {
            objectname: t("objBen:objects.tiers.name"),
          })}
        >
          <div
            style={{ cursor: "pointer", color: "blue" }}
            onClick={(e) => {
              setEditID(props.dataItem.TierID);
              setDeleteName(props.dataItem.TierName);
              setDeleteOpen(true);
            }}
          >
            <IppButton variant="text" startIcon={<Delete />} color={"error"} />
          </div>
        </Tooltip>
      </td>
    );
  };

  const editCell = (props: any) => {
    return (
      <td>
        <Tooltip
          title={t("strGen:buttons.editobj", {
            objectname: t("objBen:objects.tiers.name"),
          })}
        >
          <div
            style={{ cursor: "pointer", color: "blue" }}
            onClick={(e) => {
              setEditID(props.dataItem.TierID);
              formik.setValues(props.dataItem);
              setEditOpen(true);
            }}
          >
            <IppButton
              variant="text"
              startIcon={<Edit />}
              color={"secondary"}
            />
          </div>
        </Tooltip>
      </td>
    );
  };

  const formik = useFormik({
    initialValues: editItem,
    validationSchema: TierValidationSchema(),
    onSubmit: submitFunc,
    enableReinitialize: true,
  });

  let tierView = tierIsLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <Box display="flex" justifyContent="center">
        <Paper className={classes.boxSpace}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h5" component="h3">
                {t("objBen:objects.tiers.name", { count: 2 })}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Grid container justifyContent="flex-end">
                <IppAddButton
                  buttonText={t("strGen:buttons.addobj", {
                    objectname: t("objBen:objects.tiers.name"),
                  })}
                  onClick={() => {
                    setIsAdding(true);
                    setEditOpen(true);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} paddingTop={1}>
              <KendoGrid data={tiers} total={tiers.length}>
                <GridNoRecords>
                  <strong>
                    **{" "}
                    {t("strGen:inventory.norecordsexist", {
                      objectname: t("objBen:objects.tiers.name", {
                        count: 2,
                      }),
                    })}{" "}
                    **
                  </strong>
                </GridNoRecords>
                <GridColumn
                  field="TierRank"
                  title={t("objBen:objects.tiers.fields.rank")}
                  width={75}
                />
                <GridColumn
                  field="TierName"
                  title={t("objBen:objects.tiers.fields.name")}
                  width={150}
                  cell={CustomCell}
                />
                <GridColumn
                  field="TierDescription"
                  title={t("objBen:objects.tiers.fields.description")}
                  cell={CustomCell}
                />
                <GridColumn
                  cell={editCell}
                  width="50px"
                  field="TierID"
                  title=" "
                />
                <GridColumn cell={deleteCell} width="50px" title=" " />
              </KendoGrid>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <Dialog
        open={editOpen}
        onClose={() => {
          setEditOpen(false);
          formik.resetForm();
        }}
      >
        <DialogTitle>
          {isAdding
            ? t("strGen:prompts.add.addtitle", {
                fieldname: t("objBen:objects.tiers.name"),
              })
            : t("strGen:prompts.edit.editactive", {
                fieldname: t("objBen:objects.tiers.name"),
              })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText variant="body1">
            {isAdding
              ? t("strGen:prompts.add.message", {
                  fieldname: t("objBen:objects.tiers.name"),
                })
              : t("strGen:prompts.edit.modifyingmessage", {
                  fieldname: t("objBen:objects.tiers.name"),
                })}
          </DialogContentText>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <IppTextField
                  id="TierRank"
                  label={t("objBen:objects.tiers.fields.rankfull")}
                  value={formik.values.TierRank || ""}
                  required
                  onChangeFunction={formik.handleChange}
                  errorsExpression={formik.errors.TierRank}
                  touchedExpression={formik.touched.TierRank}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                />
              </Grid>
              <Grid item xs={9}>
                <IppTextField
                  id="TierName"
                  label={t("objBen:objects.tiers.fields.name")}
                  value={formik.values.TierName || ""}
                  required
                  onChangeFunction={formik.handleChange}
                  errorsExpression={formik.errors.TierName}
                  touchedExpression={formik.touched.TierName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                />
              </Grid>
              <Grid item xs={9}>
                <IppTextField
                  id="TierDescription"
                  label={t("objBen:objects.tiers.fields.description")}
                  value={formik.values.TierDescription || ""}
                  onChangeFunction={formik.handleChange}
                  errorsExpression={formik.errors.TierDescription}
                  touchedExpression={formik.touched.TierDescription}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                />
              </Grid>
              <Grid item xs={12}>
                <IppFormButtons
                  isEditing={true}
                  setIsEditing={null}
                  isAdding={isAdding}
                  showCancel={true}
                  resetFunction={() => {
                    setEditOpen(false);
                    formik.resetForm();
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
        <DialogTitle>
          {t("strGen:prompts.delete.deletetitle", {
            objectname: t("objBen:objects.tiers.name"),
          })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText variant="body1">
            {t("strGen:prompts.delete.deletecannotbeundone", {
              objectname: t("objBen:objects.tiers.name"),
            })}
          </DialogContentText>
          <DialogContentText
            variant="body1"
            sx={{ textAlign: "center", fontWeight: "bold", paddingTop: 1 }}
          >
            {deleteName}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IppDeleteButton onClick={() => setConfirmOpen(!confirmOpen)} />
          <IppCancelButton onClick={() => setDeleteOpen(false)} />
        </DialogActions>
      </Dialog>

      <IppConfirmDialog
        title={t("strGen:prompts.delete.deletetitle", {
          objectname: t("objBen:objects.tiers.name"),
        })}
        open={confirmOpen}
        setOpen={() => setConfirmOpen(!confirmOpen)}
        onConfirm={() => handleDelete(editID)}
        fetchFunc={fetchDeleteChecksByTier}
        recordID={editID}
        info={t("strGen:prompts.deletechecks.tiers")}
      />
    </div>
  );

  return <Root id="Tier-Page">{tierView}</Root>;
};
