import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Upload } from "@progress/kendo-react-upload";
import { read, utils } from "xlsx";
import { RootState } from "app/rootReducer";
import { Box, Grid, Paper, Tooltip } from "@mui/material";
import { IppFormHeader } from "components/IppFormHeader";
import { IppAutocomplete } from "components/IppAutocomplete";
import { FileUploadErrors } from "components/FileUploadErrors/FileUploadErrors";
import { fetchProjects } from "features/project/ProjectSlice";
import { fetchCommitmentSources } from "../../commitmentSource/CommitmentSourceSlice";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import { ConvertDateOffset, isExcelDateValid } from "utils/DateFunctions";
import { IppButton } from "components/Buttons/IppButton";
import { ProcessedUploadErrors } from "utils/types/UploadErrors.types";
import {
  generateCommitmentErrorTitles,
  getNewCommitmentConstructedErrors,
  getNewCommitmentConstructedWarnings,
} from "./CommitmentUploadErrors";
import {
  processConstructedErrorsObject,
  pushListError,
} from "utils/uploadUtils";

const PREFIX = "CommitmentUploadPage";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1000,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const CommitmentUploadPage = (props: any) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const [A0token, setA0token] = useState("");
  const [projectId, setProjectId] = useState(-1);
  const [commitmentSourceID, setCommitmentSourceID] = useState(-1);
  const [processing, setProcessing] = useState(false);
  const [warnings, setWarnings] = useState<ProcessedUploadErrors[]>([]);
  const [files, setFiles] = useState<Partial<any>>({
    files: [] as any,
    events: [] as any,
    errors: [] as any,
    emptyFile: false,
  });

  const [isEditing, setIsEditing] = useState(true);
  const [isAdding, setIsAdding] = useState(true);

  const {
    projectList,
    projectsById,
    isLoading: projectIsLoading,
  } = useSelector((state: RootState) => state.projects);

  const projects = projectList.map((id) => projectsById[id]);

  const {
    commitmentSourceList,
    commitmentSourcesById,
    isLoaded: sourceIsLoading,
  } = useSelector((state: RootState) => state.commitmentSources);

  const commitmentSources = commitmentSourceList.map(
    (id) => commitmentSourcesById[id]
  );

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        setA0token(accessToken);
        dispatch(fetchProjects(accessToken));
        dispatch(fetchCommitmentSources(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  const basePath = process.env.REACT_APP_API;
  const pathArray = window.location.pathname.split("/");
  const clientShortName = pathArray[1];
  const baseURL = `${basePath}/${clientShortName}/api`;
  const baseFilePath = `${process.env.REACT_APP_FILE_STORAGE}/templates/CommitmentsUpload.xlsx`;

  const fileStatuses = [
    "UploadFailed",
    "Initial",
    "Selected",
    "Uploading",
    "Uploaded",
    "RemoveFailed",
    "Removing",
  ];

  const onBeforeUpload = (event: any) => {
    event.headers.Authorization = `Bearer ${A0token}`;
    event.additionalData.projectID = projectId;
    event.additionalData.commitmentSourceID = commitmentSourceID;
  };

  const onAdd = (event: any) => {
    const afterStateChange = () => {
      event.affectedFiles
        .filter((file: any) => !file.validationErrors)
        .forEach((file: any) => {
          const reader = new FileReader();

          reader.onloadend = (ev) => {
            var data = ev.target ? ev.target.result : null;
            var workbook = read(data, {
              type: "binary",
              cellDates: true,
            });
            var sheetName = workbook.SheetNames[0];

            try {
              var XL_row_object = utils.sheet_to_json(
                workbook.Sheets[sheetName],
                //when no header provided, would need to map columns to object properties manually
                { defval: "" }
              );

              var XL_header_object: any[] = utils.sheet_to_json(
                workbook.Sheets[sheetName],
                { header: 1 }
              );

              let sheetIsValid = true;
              let emptyFile = false;

              //validate data from excel sheet here
              //-------------------------------------
              const constructedErrors = getNewCommitmentConstructedErrors();
              const constructedWarnings = getNewCommitmentConstructedWarnings();

              //Check file isn't empty
              if (XL_row_object.length < 1) {
                emptyFile = true;
              } else {
                let stringPropList = [
                  { propName: "Title", req: true },
                  { propName: "Date Recorded", req: true },
                  { propName: "Date Applicable", req: false },
                  { propName: "Status", req: true },
                  { propName: "Category", req: true },
                  { propName: "Person Responsible", req: true },
                  { propName: "Overall Responsibility", req: true },
                  { propName: "External Person Responsible", req: false },
                  { propName: "Associated Group", req: false },
                  { propName: "Associated Contact", req: false },
                  { propName: "Reference", req: false },
                  { propName: "Details", req: false },
                  { propName: "Legal Text", req: false },
                  { propName: "Phase", req: false },
                  { propName: "Internal Notes", req: false },
                ];

                //Check required headers exist
                const headers = XL_header_object[0];

                stringPropList.forEach((prop: any) => {
                  if (!headers.includes(prop.propName)) {
                    prop.req
                      ? pushListError(
                          constructedErrors.requiredColumn,
                          prop.propName
                        )
                      : pushListError(
                          constructedWarnings.optionalColumn,
                          prop.propName
                        );
                  }
                });

                // Check required data exists and is in the correct format.
                XL_row_object.forEach((row: any, ix: number) => {
                  let rowNum = row.__rowNum__ + 1;

                  stringPropList.forEach((prop: any) => {
                    if (
                      prop.req === true &&
                      (prop.propName in row !== true ||
                        !row[prop.propName] ||
                        row[prop.propName]?.toString().trim() === "")
                    ) {
                      pushListError(constructedErrors.requiredData, {
                        header: prop.propName,
                        rowNumber: rowNum,
                      });
                    }

                    if (prop.propName in row === true) {
                      if (typeof row[prop.propName].toString() != "string") {
                        pushListError(constructedErrors.incorrectType, {
                          header: prop.propName,
                          rowNumber: rowNum,
                          expectedValue: "a text value",
                        });
                      }

                      if (
                        ![
                          "Title",
                          "Details",
                          "Legal Text",
                          "Internal Notes",
                        ].includes(prop.propName) && // cleaner exclusion
                        row[prop.propName].toString().length >=
                          (prop.propName === "Associated Group" ||
                          prop.propName === "Date Recorded" ||
                          prop.propName === "Date Applicable"
                            ? 250
                            : 50)
                      ) {
                        pushListError(constructedErrors.characterLimit, {
                          header: prop.propName,
                          rowNumber: rowNum,
                          expectedValue:
                            prop.propName === "Associated Group" ||
                            prop.propName === "Date Recorded" ||
                            prop.propName === "Date Applicable"
                              ? 250
                              : 50,
                        });
                      }
                    }
                  });

                  if ("Date Recorded" in row === true && row["Date Recorded"]) {
                    // If row["Date Recorded"] isn't parsed into a valid date, push error
                    if (
                      row["Date Recorded"] === undefined ||
                      !isExcelDateValid(row["Date Recorded"])
                    ) {
                      pushListError(constructedErrors.incorrectType, {
                        header: "Date Recorded",
                        rowNumber: rowNum,
                        expectedValue:
                          "a valid Date in the format 'YYYY-MM-DD' (ex. 1970-01-24)",
                      });
                    }
                    row["Date Recorded"] = ConvertDateOffset(
                      new Date(row["Date Recorded"])
                    );
                  }

                  if (
                    "Date Applicable" in row === true &&
                    row["Date Applicable"]
                  ) {
                    // If row["Date Applicable"] isn't parsed into a valid date, push error
                    if (
                      row["Date Applicable"] === undefined ||
                      !isExcelDateValid(row["Date Applicable"])
                    ) {
                      pushListError(constructedErrors.incorrectType, {
                        header: "Date Applicable",
                        rowNumber: rowNum,
                        expectedValue:
                          "a valid Date in the format 'YYYY-MM-DD' (ex. 1970-01-24)",
                      });
                    }
                    row["Date Applicable"] = ConvertDateOffset(
                      new Date(row["Date Applicable"])
                    );
                  }
                });
              }
              //-------------------------------------
              const fileErrors = processConstructedErrorsObject(
                constructedErrors,
                generateCommitmentErrorTitles
              );

              const fileWarnings = processConstructedErrorsObject(
                constructedWarnings,
                generateCommitmentErrorTitles
              );

              if (fileWarnings.length > 0) {
                setWarnings(fileWarnings);
              }

              if (fileErrors.length > 0 || emptyFile) {
                sheetIsValid = false;
              }

              if (sheetIsValid) {
                setProcessing(false);
              } else {
                setFiles({
                  files: [],
                  events: [
                    ...files.events,
                    `File failed validation: ${event.affectedFiles[0].name}`,
                  ],
                  errors: fileErrors,
                  emptyFile: emptyFile,
                });
                setProcessing(false);
              }
            } catch (err: any) {
              throw err;
            }
          };

          reader.onerror = function (ex) {
            console.log(ex);
          };

          reader.readAsBinaryString(file.getRawFile());
        });
    };

    setProcessing(true);

    setFiles({
      files: event.newState,
      events: [
        ...files.events,
        `File selected: ${event.affectedFiles[0].name}`,
      ],
      errors: [],
    });
    afterStateChange();
    setProcessing(false);

    // clear state
    setWarnings([]);
  };

  const onRemove = (event: any) => {
    setFiles({
      files: event.newState,
      events: [...files.events, `File removed: ${event.affectedFiles[0].name}`],
      errors: [],
    });
    setWarnings([]);
    setProcessing(false);

    // clear state
    setWarnings([]);
  };

  const onProgress = (event: any) => {
    setFiles({
      files: event.newState,
      events: [
        ...files.events,
        `On Progress: ${event.affectedFiles[0].progress} %`,
      ],
      errors: [...files.errors],
    });
  };

  const onStatusChange = (event: any) => {
    const file = event.affectedFiles[0];

    // On success, clear state
    if (file.status === 4) {
      setWarnings([]);
    }

    setFiles({
      files: event.newState,
      events: [
        ...files.events,
        `File '${file.name}' status changed to: ${fileStatuses[file.status]}`,
      ],
      errors: [...files.errors],
    });
  };

  const onProjectChange = (event: any, newValue: any) => {
    if (newValue) {
      setProjectId(newValue.ProjectID);
    } else {
      setProjectId(-1);
    }
  };

  const onSourceChange = (event: any, newValue: any) => {
    if (newValue) {
      setCommitmentSourceID(newValue.CommitmentSourceID);
    } else {
      setCommitmentSourceID(-1);
    }
  };

  let commitmentsUploadView = (
    <Root>
      <Box display="flex" justifyContent="center">
        <Paper className={classes.boxSpace}>
          <Grid container className={classes.editForm} spacing={1}>
            <IppFormHeader
              title="Commitments"
              isEditing={isEditing}
              isAdding={isAdding}
              returnPath="/commitments/commitments"
            />
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end">
                <Tooltip title="Download Excel Template">
                  <a
                    href={baseFilePath}
                    // following line removed to avoid blank tab in Edge
                    // target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecorationLine: "none", color: "inherit" }}
                    download
                  >
                    <IppButton
                      variant="outlined"
                      startIcon={<AssignmentReturnedIcon />}
                      color={"secondary"}
                    >
                      Template
                    </IppButton>
                  </a>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <IppAutocomplete
                id="ProjectID"
                options={projects}
                value={projects.find((obj) => {
                  return obj.ProjectID === projectId;
                })}
                onChangeFunction={onProjectChange}
                label="Project"
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                optionLabelFunction={(option: any) => option.ProjectName}
                textValueFunction={
                  !projectIsLoading && projectId > 0
                    ? projectsById[projectId].ProjectName
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12}>
              <IppAutocomplete
                id="CommitmentSourceID"
                options={commitmentSources}
                value={commitmentSources.find((obj) => {
                  return obj.CommitmentSourceID === commitmentSourceID;
                })}
                onChangeFunction={onSourceChange}
                label="Commitment Source"
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                optionLabelFunction={(option: any) =>
                  option.CommitmentSourceName
                }
                textValueFunction={
                  !sourceIsLoading && commitmentSourceID > 0
                    ? commitmentSourcesById[commitmentSourceID]
                        .CommitmentSourceName
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <FileUploadErrors
                errors={files.errors}
                emptyFile={files.emptyFile}
              />

              {files.errors.length === 0 ? (
                <FileUploadErrors errors={warnings} isWarning />
              ) : (
                ""
              )}
            </Grid>

            <Grid item xs={12}>
              <Upload
                disabled={projectId === -1 || commitmentSourceID === -1}
                showActionButtons={
                  !processing && files.errors.length === 0 && A0token !== ""
                }
                autoUpload={false}
                multiple={false}
                files={files.files}
                restrictions={{
                  allowedExtensions: [".xlsx"],
                }}
                withCredentials={true}
                onAdd={onAdd}
                onRemove={onRemove}
                onProgress={onProgress}
                onStatusChange={onStatusChange}
                onBeforeUpload={onBeforeUpload}
                saveUrl={`${baseURL}/commitment/upload`}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Root>
  );

  return <div id="commitments-upload-page">{commitmentsUploadView}</div>;
};
