import * as yup from "yup";
import * as RegexConstants from "../../../validation/regexConstants";
import { useTypedTranslation } from "utils/customHooks";

export const CustomRnDValidationSchema = () => {
  const t = useTypedTranslation(["objPlt", "strGen", "objBen"]);

  let validationSchema: Record<string, yup.AnySchema> = {
    CompanyID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.company.name"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.company.name"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.company.name"),
        })
      ),
    ProjectID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.project.name"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.project.name"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objPlt:objects.project.name"),
        })
      ),
    RnDProjectName: yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.customRnD.fields.rndProject"),
        })
      )
      .max(
        250,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.customRnD.fields.rndProject"),
          count: 250,
        })
      )
      .typeError(t("strGen:validation.invalidformat")),
    ClaimYear: yup
      .number()
      .transform((value, originalValue) => {
        if (typeof originalValue === "string") {
          const parsedYear = parseInt(originalValue, 10);
          return isNaN(parsedYear) ? undefined : parsedYear;
        }
        return value;
      })
      .typeError(
        t("strGen:validation.date.yearformat", {
          fieldname: t("objBen:objects.customRnD.fields.claimYear"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.customRnD.fields.claimYear"),
        })
      )
      .min(
        1900,
        t("strGen:validation.date.notearlierthan", {
          fieldname: t("objBen:objects.customRnD.fields.claimYear"),
          fieldname2: "1900",
        })
      )
      .max(
        new Date().getFullYear(),
        t("strGen:validation.date.nofuturedate", {
          fieldname: t("objBen:objects.customRnD.fields.claimYear"),
        })
      ),
    Date: yup
      .date()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.customRnD.fields.date"),
        })
      )
      .max(
        new Date(),
        t("strGen:validation.date.nofuturedate", {
          fieldname: t("objBen:objects.customRnD.fields.date"),
        })
      )
      .typeError(
        t("strGen:validation.date.improperformat", {
          fieldname: t("objBen:objects.customRnD.fields.date"),
        })
      ),
    Expenditure: yup
      .number()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.customRnD.fields.expenditure"),
        })
      )
      .typeError(
        t("strGen:validation.invalid", {
          fieldname: t("objBen:objects.customRnD.fields.expenditure"),
        })
      ),
    Classification: yup
      .string()
      .trim()
      .max(
        250,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.customRnD.fields.classification"),
          count: 250,
        })
      )
      .nullable()
      .typeError(t("strGen:validation.invalidformat")),
    Prequalification: yup
      .string()
      .trim()
      .max(
        250,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.customRnD.fields.prequalification"),
          count: 250,
        })
      )
      .nullable()
      .typeError(t("strGen:validation.invalidformat")),
    Description: yup
      .string()
      .trim()
      .max(
        250,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.customRnD.fields.description"),
          count: 250,
        })
      )
      .nullable()
      .typeError(t("strGen:validation.invalidformat")),
  };

  return yup.object().shape(validationSchema);
};
