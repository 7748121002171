import { useAuth0 } from "@auth0/auth0-react";
import { styled } from "@mui/material/styles";
import { RootState } from "app/rootReducer";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCustomRnD, delCustomRnD, updCustomRnD } from "./CustomRnDSlice";
import { useFormik } from "formik";
import { IppAutocomplete } from "components/IppAutocomplete";
import { Box, Grid, Paper } from "@mui/material";
import { IppFormHeader } from "components/IppFormHeader";
import { IppDisplayField } from "components/IppDisplayField";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { UserWriteAccess } from "utils/userAccess";
import LoadingIndicator from "components/LoadingIndicator";
import { Prompt } from "react-router";
import { IppCurrencyField } from "components/IppCurrencyField";
import { IppStaticTextfield } from "components/IppStaticTextfield";
import { IppTextField } from "components/IppTextField";
import { ConvertDateOffset, GetJSDate, GetJSYear } from "utils/DateFunctions";
import { IppDatePicker } from "components/IppDatePicker";
import { useTypedTranslation } from "utils/customHooks";
import { fetchCompanies } from "../companies/CompaniesSlice";
import { IppRichTextEditor } from "components/RichTextEditor/IppRichTextEditor";
import { CustomRnDValidationSchema } from "./CustomRnDValidation";
import { IppMultiTextField } from "components/IppMultiTextField";
import { IppDisplayRichText } from "components/IppDisplayRichText";
import { fetchProjects } from "features/project/ProjectSlice";
import { IppCurrencyDisplay } from "components/IppCurrencyDisplayField";
import { ShowActiveCompanies } from "../BenefitsUtils";
import { useCompanyListByRole } from "utils/hooks/useCompanyListByRole";

const PREFIX = "CustomRnDForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  viewForm: `${PREFIX}-viewForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    maxWidth: 1150,
  },
  [`& .${classes.viewForm}`]: {
    maxWidth: 1150,
  },
  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const CustomRnDForm = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  let customRnDData = props.customRnD || {};

  const [isEditing, setIsEditing] = useState(
    customRnDData.CustomRnDID ? false : true
  );
  const [isAdding, setIsAdding] = useState(
    customRnDData.CustomRnDID ? false : true
  );

  const showEdit = UserWriteAccess("Benefits");

  const { isLoading: clientIsLoading, clientId } = useSelector(
    (state: RootState) => state.client
  );
  // fetch data on page load
  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchProjects(accessToken));
        dispatch(fetchCompanies(accessToken, clientId));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);
  const {
    clientCurrencyList,
    clientCurrenciesById,
    isLoading: clientCurrencyIsLoading,
  } = useSelector((state: RootState) => state.clientCurrencies);

  const clientCurrency = clientCurrencyList.map(
    (id) => clientCurrenciesById[id]
  );

  // get projects
  const { currentProfile, currentUserRoleList, currentUserRolesById } =
    useSelector((state: RootState) => state.profile);
  const userRoles = currentUserRoleList.map((id) => currentUserRolesById[id]);
  const {
    projectList,
    projectsById,
    isLoading: projectIsLoading,
  } = useSelector((state: RootState) => state.projects);

  const projects = projectList
    .map((id) => projectsById[id])
    .filter((proj) => {
      if (currentProfile.IsClientAdmin) {
        return true;
      } else {
        return userRoles.some((role) => {
          return role.ProjectID === proj.ProjectID && role.ModuleID === 1;
        });
      }
    });

  // get companies
  let {
    companiesById,
    companyList,
    isLoading: companyIsLoading,
  } = useSelector((state: RootState) => state.companies);
  let companies = companyList.map((CompanyID) => companiesById[CompanyID]);
  let confirmedCompanies = useCompanyListByRole(
    companies.filter((comp) => comp.ListingConfirmed)
  );

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(addCustomRnD(accessToken, values, true));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  if (customRnDData?.CustomRnDID) {
    //Update case
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(updCustomRnD(accessToken, values.CustomRnDID, values, true));
        } catch (e) {
          console.error(e);
        }
      })();
    };
  } else {
    // sets defaults for create case
    customRnDData.ProjectID = null;
    customRnDData.RnDProjectName = null;
    customRnDData.CompanyID = currentProfile.CompanyID;
    customRnDData.ClaimYear = null;
    customRnDData.Date = null;
    customRnDData.Expenditure = 0;
    customRnDData.Classification = "";
    customRnDData.Prequalification = "";
    customRnDData.Description = "";
  }

  const handleDelete = () => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(delCustomRnD(accessToken, customRnDData.CustomRnDID));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const formik = useFormik({
    initialValues: customRnDData,
    validationSchema: CustomRnDValidationSchema(),
    onSubmit: submitFunc,
  });

  // --------------------------------- VIEW FORM ------------------------------

  let viewForm = (
    <Paper className={classes.boxSpace}>
      <Grid container className={classes.viewForm} spacing={1}>
        <IppFormHeader
          title={t("objBen:objects.customRnD.name")}
          isEditing={isEditing}
          isAdding={isAdding}
          showReturn={true}
        />

        <Grid item xs={6}>
          <IppDisplayField
            label={t("objPlt:objects.project.name")}
            value={formik.values.ProjectName}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            showEdit={showEdit}
          />
        </Grid>
        <Grid item xs={6}>
          <IppDisplayField
            label={t("objBen:objects.customRnD.fields.rndProject")}
            value={formik.values.RnDProjectName}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            showEdit={showEdit}
          />
        </Grid>
        <Grid item xs={6}>
          <IppDisplayField
            label={t("objBen:objects.company.name")}
            value={formik.values.CompanyName}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            showEdit={showEdit}
          />
        </Grid>
        <Grid item xs={6}>
          <IppDisplayField
            label={t("objBen:objects.customRnD.fields.claimYear")}
            value={formik.values.ClaimYear?.slice(0, 4)}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            showEdit={showEdit}
          />
        </Grid>
        <Grid item xs={6}>
          <IppDisplayField
            label={t("objBen:objects.customRnD.fields.date")}
            value={formik.values.Date?.slice(0, 10)}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            showEdit={showEdit}
          />
        </Grid>
        <Grid item xs={6}>
          <IppCurrencyDisplay
            label={t("objBen:objects.customRnD.fields.expenditure")}
            value={formik.values.Expenditure}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            showEdit={showEdit}
          />
        </Grid>
        <Grid item xs={6}>
          <IppDisplayField
            label={t("objBen:objects.customRnD.fields.classification")}
            value={formik.values.Classification}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            showEdit={showEdit}
          />
        </Grid>
        <Grid item xs={6}>
          <IppDisplayField
            label={t("objBen:objects.customRnD.fields.prequalification")}
            value={formik.values.Prequalification}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            showEdit={showEdit}
          />
        </Grid>
        <Grid item xs={12}>
          <IppDisplayRichText
            label={t("objBen:objects.customRnD.fields.description")}
            value={formik.values.Description}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            showEdit={showEdit}
          />
        </Grid>
        <Grid item xs={12}>
          <IppFormButtons
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            isAdding={isAdding}
            resetFunction={() => formik.resetForm()}
            showDelete={
              currentProfile.IsClient ||
              currentProfile.CompanyID === formik.values.CompanyID
            }
            deleteFunction={handleDelete}
          />
        </Grid>
      </Grid>
    </Paper>
  );

  // --------------------------------- EDIT FORM ------------------------------
  let editForm =
    clientIsLoading || projectIsLoading || companyIsLoading ? (
      <LoadingIndicator />
    ) : (
      <Paper className={classes.boxSpace}>
        <form onSubmit={formik.handleSubmit}>
          {!formik.isSubmitting && (
            <Prompt
              when={formik.dirty}
              message={t("strGen:prompts.unsavedchanges")}
            />
          )}
          <Grid container className={classes.editForm} spacing={1}>
            <IppFormHeader
              title={t("objBen:objects.customRnD.name")}
              isEditing={isEditing}
              isAdding={isAdding}
              returnPath="/benefits/customrnds"
            />
            <Grid item xs={6}>
              <IppAutocomplete
                id="ProjectID"
                required
                options={projects}
                value={projects.find((obj) => {
                  return obj.ProjectID === formik.values.ProjectID;
                })}
                onChangeFunction={(event: ChangeEvent, newValue: any) => {
                  if (newValue) {
                    formik.setFieldValue("ProjectID", newValue.ProjectID);
                  } else {
                    formik.setFieldValue("ProjectID", null);
                  }
                }}
                label={t("objPlt:objects.project.name")}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                optionLabelFunction={(option: any) => option.ProjectName}
                errorFunction={formik.errors.ProjectID}
                touchedFunction={formik.touched.ProjectID}
                textValueFunction={
                  formik.values.ProjectID > 0 &&
                  projectsById[formik.values.ProjectID]
                    ? projectsById[formik.values.ProjectID].ProjectName
                    : ""
                }
              />
            </Grid>
            <Grid item xs={6}>
              <IppTextField
                id="RnDProjectName"
                label={t("objBen:objects.customRnD.fields.rndProject")}
                required={true}
                value={formik.values.RnDProjectName}
                onChangeFunction={formik.handleChange}
                touchedExpression={formik.touched.RnDProjectName}
                errorsExpression={formik.errors.RnDProjectName}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {currentProfile?.IsClient || currentProfile?.IsClientAdmin ? (
                <IppAutocomplete
                  id="CompanyID"
                  options={ShowActiveCompanies(confirmedCompanies)}
                  value={confirmedCompanies.find((obj: { CompanyID: any }) => {
                    return obj.CompanyID === formik.values.CompanyID;
                  })}
                  onChangeFunction={(event: ChangeEvent, newValue: any) => {
                    if (newValue) {
                      formik.setFieldValue("CompanyID", newValue.CompanyID);
                    } else {
                      formik.setFieldValue("CompanyID", -1);
                    }
                  }}
                  groupBy={(option: any) => option.ShowAsActive}
                  required
                  label={t("objSshe:objects.company.name")}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  errorFunction={formik.errors.CompanyID}
                  touchedFunction={
                    formik.touched.CompanyID || formik.errors.CompanyID
                  }
                  optionLabelFunction={(option: any) => option.CompanyName}
                  textValueFunction={
                    formik.values.CompanyID > 0 &&
                    companiesById[formik.values.CompanyID]
                      ? companiesById[formik.values.CompanyID].CompanyName
                      : ""
                  }
                />
              ) : (
                <Grid xs={12}>
                  <IppStaticTextfield
                    id="CompanyID"
                    label={t("objBen:objects.company.name")}
                    value={currentProfile.CompanyName}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={6}>
              <IppDatePicker
                id="ClaimYear"
                label={t("objBen:objects.customRnD.fields.claimYear")}
                required={true}
                value={ConvertDateOffset(formik.values.ClaimYear)}
                onChangeFunction={(newValue: any) => {
                  formik.setFieldValue(
                    "ClaimYear",
                    GetJSYear(newValue, 1, 2),
                    true
                  );
                  formik.setFieldTouched("ClaimYear", true, false);
                }}
                errorsExpression={formik.errors.ClaimYear}
                touchedExpression={formik.touched.ClaimYear}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                disableFuture={true}
                yearOnly={true}
              />
            </Grid>
            <Grid item xs={6}>
              <IppDatePicker
                id="Date"
                label={t("objBen:objects.customRnD.fields.date")}
                required={true}
                value={ConvertDateOffset(formik.values.Date)}
                onChangeFunction={(newValue: any) => {
                  formik.setFieldValue("Date", GetJSDate(newValue), true);
                  formik.setFieldTouched("Date", true, false);
                }}
                errorsExpression={formik.errors.Date}
                touchedExpression={formik.touched.Date}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                disableFuture={true}
              />
            </Grid>

            <Grid item xs={6}>
              <IppCurrencyField
                id="Expenditure"
                label={t("objBen:objects.customRnD.fields.expenditure")}
                required
                value={formik.values.Expenditure}
                onChangeFunction={(newValue) =>
                  formik.setFieldValue("Expenditure", newValue)
                }
                touchedExpression={formik.touched.Expenditure}
                errorsExpression={formik.errors.Expenditure}
                isEditing={isEditing}
              />
            </Grid>

            <Grid item xs={6}>
              <IppMultiTextField
                id="Classification"
                label={t("objBen:objects.customRnD.fields.classification")}
                required={false}
                options={[]}
                noOptionsText={t("strGen:components.nooptionstext")}
                value={formik.values.Classification}
                setValue={(newValue: any) =>
                  formik.setFieldValue("Classification", newValue.toString())
                }
                touchedFunction={formik.touched.Classification}
                errorFunction={formik.errors.Classification}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                multiple={true}
                autoPopulate={false}
                freeSolo={true}
                toolTip={t("objBen:objects.customRnD.tooltips.classification")}
              />
            </Grid>
            <Grid item xs={6}>
              <IppTextField
                id="Prequalification"
                label={t("objBen:objects.customRnD.fields.prequalification")}
                value={formik.values.Prequalification}
                onChangeFunction={formik.handleChange}
                touchedExpression={formik.touched.Prequalification}
                errorsExpression={formik.errors.Prequalification}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </Grid>
            <Grid item xs={12}>
              <IppRichTextEditor
                id="Description"
                label={t("objBen:objects.customRnD.fields.description")}
                value={formik.values.Description}
                isEditing={isEditing}
                touchedExpression={formik.touched.Description}
                errorsExpression={formik.errors.Description}
                setFieldValue={formik.setFieldValue}
                height={100}
              />
            </Grid>
            <Grid item xs={12}>
              <IppFormButtons
                isEditing={isEditing}
                isAdding={isAdding}
                setIsEditing={setIsEditing}
                resetFunction={() => formik.resetForm()}
                showDelete={
                  currentProfile.IsClient ||
                  currentProfile.CompanyID === formik.values.CompanyID
                }
                deleteFunction={handleDelete}
              />
            </Grid>
          </Grid>
        </form>
      </Paper>
    );

  // --------------------------------- PAGE FORM ------------------------------

  let pageForm = (
    <Box display="flex" justifyContent="center">
      {isAdding || isEditing ? editForm : viewForm}
    </Box>
  );

  return <Root>{pageForm}</Root>;
};
