// This form is used to show a subtable on stakeholder consultation forms.
// The object contains all embedded functionality to relate parent/child,
// and to present add to list, add new subitem, and remove subitem
// DG, Oct 2021
import { useDispatch, useSelector } from "react-redux";
import {
  Add,
  AddLink,
  CloudDownload,
  KeyboardArrowRight,
  AttachFile,
  Search,
} from "@mui/icons-material";
import {
  Grid,
  Tooltip,
  Typography,
  Dialog,
  DialogContent,
  Box,
  ButtonGroup,
  IconButton,
  InputAdornment,
  TextField,
  DialogTitle,
} from "@mui/material";
import { CheckboxFilter } from "./columnMenu";
import {
  Grid as KendoGrid,
  GridColumn,
  GridColumnMenuFilter,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import {
  CompositeFilterDescriptor,
  filterBy,
  orderBy,
  SortDescriptor,
} from "@progress/kendo-data-query";
import { useEffect, useMemo, useRef, useState } from "react";
import { IppChildAdd } from "../IppChildAdd";
import { IppChildEdit } from "../IppChildEdit";
import { Edit } from "@mui/icons-material";
import {
  DeleteMessageType,
  DeleteNoteType,
  IppChildDelete,
} from "../IppChildDelete";
import { ContactChildForm } from "../../features/platform/contacts/ContactChildForm";
import { StakeholderGroupChildForm } from "../../features/platform/groups/StakeholderGroupChildForm";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { IssueChildForm } from "features/stakeholder/issue/IssueChildForm";
import { InteractionChildForm } from "features/stakeholder/interaction/InteractionChildForm";
import { GrievanceChildForm } from "features/stakeholder/grievance/GrievanceChildForm";
import {
  CommaDelimitedCell,
  IconCell,
  CustomEmailCell,
  CustomLinkCell,
  MenuCell,
  StatusChipCell,
  CustomCheckboxCell,
  ExpandCell,
  TruncatedTextCell,
} from "./customCells";
import { getCurrentModule } from "utils/urlUtils";
import { CommitmentChildForm } from "features/commitments/commitment/CommitmentChildForm";
import { IppChildLink } from "components/IppChildLink";
import { InteractionCommentChildForm } from "features/stakeholder/interactionComment/InteractionCommentChildForm";
import { RootState } from "app/rootReducer";
import { PaymentChildForm } from "features/benefits/payments/PaymentChildForm";
import { ButtonsArrayProps, IppPopoverMenu } from "components/IppPopoverMenu";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { JobHistoryChildForm } from "features/benefits/jobHistory/JobHistoryChildForm";
import { RnDExpenseChildForm } from "features/benefits/RnDExpense/RnDExpenseChildForm";
import { useTypedTranslation } from "utils/customHooks";
import { WorkHourDetail } from "api/workHourDetailAPI";
import { WorkHourDetailChildForm } from "features/benefits/workHours/WorkHourDetailChildForm";
import { IppAttachmentModal } from "components/IppAttachmentModal";
import { EnTExpenseChildForm } from "features/benefits/entExpenses/EnTExpenseChildForm";
import { IppAddButton } from "components/Buttons/IppAddButton";
import { IppEditButton } from "components/Buttons/IppEditButton";
import { IppExportButton } from "components/Buttons/IppExportButton";
import { ThreadChildForm } from "features/stakeholder/thread/ThreadChildForm";
import { DragAndDrop } from "@progress/kendo-react-common";
import {
  DraggableRow,
  ReorderContext,
} from "components/Inventory/draggableGrid/DraggableRow";
import { IppChildTable, ippChildTableProps } from "components/IppChildTable";
import { IppCancelButton } from "components/Buttons/IppCancelButton";
import { IppMergeButton } from "components/Buttons/IppMergeButton";
import { IppSaveButton } from "components/Buttons/IppSaveButton";
import { formatStringForCompare, getChangedData } from "utils/functions";
import { useAuth0 } from "@auth0/auth0-react";
import { IppAlertHeader } from "components/IppAlertHeader";
import IppConfirmDialog from "components/IppConfirmDialog";
import { IppButton } from "components/Buttons/IppButton";
import { IppDisplayRichText } from "components/IppDisplayRichText";
import { SanitizeHTML } from "utils/customXSS";
import React from "react";
import { AttachmentCategoryRecordTypeName } from "utils/types/General.types";
import AiBadge from "components/AiBadge";
import IppCloseButton from "components/Buttons/IppCloseButton";

export interface column {
  columnWidth?: string | number | undefined;
  field: string;
  title: string;
  format?: any;
  filter?: string;
  sendEmail?: boolean;
  commaDelimited?: boolean;
  icons?: boolean;
  chips?: boolean;
  checkbox?: boolean;
  clearTrueValues?: boolean;
  clearFalseValues?: boolean;
  button?: boolean;
  buttonCell?: any;
  attachmentCategoryRecordType?: AttachmentCategoryRecordTypeName;
  truncateText?: boolean;
}

interface ippChildInvProps {
  title: string; // title to show on sub page
  parentTitle: string; // title to show for container page
  linkURL: string; // string to use for building detail URL
  tableData: any;
  idField: string;
  nameField: string;
  parentID: number;
  parentProjectID?: number | null; // to allow check for ModuleRoleID
  relatedField: string; // id of related table (for linkURL and edit child)
  linkField?: string; // field used to override relatedField for linkURL
  columns: column[];
  showAdd?: boolean;
  showEdit?: boolean;
  tableName: string; // name of underlying table
  id: string;
  label: string;
  options: any;
  unfilteredOptions?: any;
  selectedValues: any;
  setSelectedValues: any;
  setDidSaveInventory: any;
  parentValue: any;
  showOptions?: boolean;
  disabled?: string;
  popoverMenu?: boolean; // show popover menu, optional
  popoverBtns?: ButtonsArrayProps[]; // popover menu btns array, optional
  disableBtns?: boolean; // disable btns in popover menu, optional
  showExport?: boolean; // hide export btn, optional
  noLinkCell?: boolean;
  secondLinkCell?: number;
  addHeaderText?: boolean;
  deleteMessageType?: DeleteMessageType;
  deleteNoteType?: DeleteNoteType;
  deleteNameValueTitle?: string;
  showAttachBtn?: boolean;
  attachBtnDisabled?: boolean;
  suggestions?: any;
  showSuggestions?: boolean;
  suggestionTitle?: any;
  // Expand props
  isExpandable?: boolean;
  detailComponent?: any;
  // Editable props
  isDraggable?: boolean;
  orderByDate?: string; // if newly added item will be inserted in dateOrder
  showSave?: boolean; // hide child Save btn if parent form has one
  showCancel?: boolean;
  optionTableProps?: Partial<ippChildTableProps>;
  isChildSubmit?: boolean; // submit child update with parent Submit btn
  updAction?: any;
  setIsInvUpdated?: any; // update if child inventory data is updated
  showSearchBar?: boolean;
  showAttachmentButton?: boolean;
  showLinkButton?: boolean;
}

export const IppChildInventory = (props: ippChildInvProps) => {
  const {
    title,
    parentTitle,
    linkURL,
    tableData,
    idField,
    nameField,
    parentID,
    parentProjectID,
    relatedField,
    linkField,
    columns,
    showAdd,
    showEdit,
    tableName,
    id,
    label,
    options,
    unfilteredOptions,
    selectedValues,
    setSelectedValues,
    setDidSaveInventory,
    parentValue,
    showOptions,
    disabled,
    popoverMenu,
    popoverBtns,
    disableBtns,
    showExport,
    noLinkCell,
    secondLinkCell,
    addHeaderText,
    deleteMessageType,
    deleteNoteType,
    deleteNameValueTitle,
    showAttachBtn,
    attachBtnDisabled,
    suggestions,
    showSuggestions,
    suggestionTitle,
    isExpandable = false,
    detailComponent,
    isDraggable = false,
    orderByDate = "",
    showSave = false,
    showCancel = false,
    optionTableProps,
    isChildSubmit = false,
    updAction,
    setIsInvUpdated,
    showSearchBar,
    showLinkButton,
    showAttachmentButton,
  } = props;
  const { location } = useSelector((state: RootState) => state.router);
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation([
    "objPlt",
    "strGen",
    "objCom",
    "objStk",
    "objBen",
  ]);

  const [addFormOpen, setAddFormOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editEntityOpen, setEditEntityOpen] = useState(false);
  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
  const [linkingOpen, setLinkingOpen] = useState(false);
  const [itemID, setItemID] = useState(0);
  const [nameValue, setNameValue] = useState("");
  const [initialSelVals, setInitialSelVals] = useState(selectedValues);
  const [filename, setFilename] = useState(title);
  const [sortValues, setSortValues] = useState(tableData as SortDescriptor[]);

  // Child is editable if isDraggable or link from option Table
  let isEditable = isDraggable || optionTableProps;

  //..................... Things related to filtering ............................
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(isDraggable ? 5 : 10);
  const [sort, setSort] = useState([] as any);

  let filter = {
    filters: [] as any,
    logic: "and",
  } as CompositeFilterDescriptor;

  let filteredData = orderBy(filterBy(tableData, filter), sort);

  const columnsWithDetails = useMemo(() => {
    return tableData.some((row: any) => row.Details !== undefined)
      ? [...columns, { field: "Details", title: "", columnWidth: 0 }]
      : columns;
  }, [tableData, columns]);

  // Normalize search term for case-insensitive comparison
  const normalizedSearchTerm =
    formatStringForCompare(searchTerm)?.toLowerCase() || "";

  // Function to format values based on column definitions
  const formatFieldValue = (field: string, value: any): string => {
    if (value === null || value === undefined) return "";

    const columnDef = columnsWithDetails.find((col) => col.field === field);
    if (!columnDef) return value.toString().toLowerCase();

    if (columnDef.format) {
      if (columnDef.filter === "date") {
        return new Date(value).toLocaleDateString(); // Apply date format
      }

      return columnDef.format
        .replace(/\{0.*?\}/, value.toString())
        .toLowerCase(); // Apply dynamic format
    }

    return value.toString().toLowerCase();
  };

  // Apply search filter if needed
  if (normalizedSearchTerm) {
    filteredData = filteredData.filter((row: any) =>
      columnsWithDetails.some((col: column) => {
        const formattedValue = formatFieldValue(col.field, row[col.field]);
        return formattedValue.includes(normalizedSearchTerm);
      })
    );
  }

  // Sort the filtered data
  filteredData = orderBy(filteredData, sort);

  // Handle pagination change
  const pageChange = (event: GridPageChangeEvent) => {
    setSkip(event.page.skip);
    setTake(event.page.take);
  };

  // Handle sorting change
  const sortChanged = (event: GridSortChangeEvent) => {
    const newSort = event.sort;
    setSort(newSort);
    setSortValues(orderBy(sortValues, newSort));
  };

  // Determine if filtering is active
  const isFiltering = normalizedSearchTerm !== "" || filter.filters.length > 0;

  // Compute if draggable is allowed
  const computedIsDraggable = isDraggable && !isFiltering;

  // Determine the data to display based on edit mode
  const dataToDisplay = isEditable
    ? selectedValues.filter((row: any) =>
        columnsWithDetails.some(({ field }) =>
          formatFieldValue(field, row[field]).includes(normalizedSearchTerm)
        )
      )
    : filteredData;

  const checkboxFilter = (props: any) => (
    <CheckboxFilter {...props} data={isEditable ? selectedValues : tableData} />
  );

  // ................. end of filtering things ...................

  const handleClose = (event: any, reason: any) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      dispatch(
        openSnackBar(t("strGen:components.snackbar.saveorcancel"), "info")
      );
    } else {
      // form was closed using either Save or Cancel
      toggleEditEntityDialog();
    }
  };

  const handleAttachmentClose = () => {
    setAttachmentModalOpen(false);
  };

  const handleAdd = () => {
    toggleAddDialog();
  };

  const toggleAddDialog = () => {
    setAddFormOpen(!addFormOpen);
  };

  const handleEdit = () => {
    toggleEditDialog();
  };

  const toggleEditDialog = () => {
    setEditOpen(!editOpen);
    setSelectedValues(initialSelVals);
  };

  const handleLinking = () => {
    toggleLinkDialog();
  };
  const toggleLinkDialog = () => {
    setLinkingOpen(!linkingOpen);
  };

  const handleEditEntity = (itemID: any) => {
    setItemID(itemID);
    toggleEditEntityDialog();
  };

  const toggleEditEntityDialog = () => {
    setEditEntityOpen(!editEntityOpen);
  };

  const handleAttachmentEntity = (itemID: any) => {
    setItemID(itemID);
    toggleAttachmentEntityDialog();
  };

  const toggleAttachmentEntityDialog = () => {
    setAttachmentModalOpen(!attachmentModalOpen);
  };

  const handleDeleteEntity = (itemID: any, itemText: any) => {
    setItemID(itemID);
    setNameValue(itemText);
    toggleDeleteDialog();
  };

  const toggleDeleteDialog = () => {
    setDeleteOpen(!deleteOpen);
  };

  const attachmentCategoryRecordType =
    props.columns.find((column) => column.field === "Attachments")
      ?.attachmentCategoryRecordType ?? null;

  useEffect(() => {
    //When a new item is added successfully, we should add it to the selected values.
    if (!editOpen && !deleteOpen) {
      setInitialSelVals(selectedValues);
    }
  }, [selectedValues]);

  const { currentProfile, currentUserRoleList, currentUserRolesById } =
    useSelector((state: RootState) => state.profile);

  // set canWRITE to true for Client Admins
  let canWRITE = true;
  let pathName: string = `${location.pathname}`;

  // THIS LOGIC CURRENTLY DOES NOT MATTER AS USERS CURRENTLY CAN ONLY HAVE EITHER VIEW ONLY OR POWERUSER, NOT BOTH. THIS LOGIC WILL DISABLE BUTTONS ON VIEW ONLY RECORDS IF A USER IS ALLOWED TO HAVE POWERUSER ACCESS ON SOME RECORDS
  // if the User is not an Admin, check their Role relating to the parent Record
  if (!currentProfile.IsClientAdmin) {
    const userRoles = currentUserRoleList.map((id) => currentUserRolesById[id]);
    if (pathName.includes("/engagement")) {
      if (
        parentTitle.toLowerCase() === "communication" ||
        parentTitle.toLowerCase() === "concern" ||
        parentTitle.toLowerCase() === "thread"
      ) {
        // Must have Stakeholder PowerUser Role for the Parent Project
        canWRITE = userRoles.some(
          (role) =>
            role.ProjectID === parentProjectID && role.ModuleRoleID === 5
        );
      }
      if (
        parentTitle.toLowerCase() === "group" ||
        parentTitle.toLowerCase() === "contact"
      ) {
        // Must have a Role with WRITE access on either Stakeholder or Commitments
        canWRITE = userRoles.some(
          (role) =>
            // UNCOMMENT WHEN VIEW ONLY AND POWERUSER ARE ALLOWED TOGETHER
            // role.ModuleRoleID === 3 ||
            // role.ModuleRoleID === 4 ||
            role.ModuleRoleID === 5
        );
      }
      if (parentTitle === t("objStk:objects.issue.name")) {
        // Must have Stakeholder PowerUser Role
        canWRITE = userRoles.some((role) => role.ModuleRoleID === 5);
      }
    } else if (pathName.includes("/commitments")) {
      if (
        parentTitle.toLowerCase() === "commitment" &&
        title.toLowerCase() === "communication"
      ) {
        // Must have Stakeholder PowerUser Role
        canWRITE = userRoles.some((role) => role.ModuleRoleID === 5);
      }
    }
  }
  /////////////////////////////////////////////////////////////////////////////

  //Map the options down to an array of strings
  let strOptions;
  if (title.toLowerCase() === "group") {
    strOptions = options.map((option: any) => option.GroupName);
  } else if (title === t("objStk:objects.issue.name")) {
    strOptions = options.map((option: any) => option.IssueName);
  } else if (title.toLowerCase() === "communication") {
    strOptions = options.map(
      (option: any) => option.InteractionTitle + option.InteractionID
    );
  } else if (title.toLowerCase() === "concern") {
    strOptions = options.map(
      (option: any) => option.GrievanceName + option.GrievanceID
    );
  } else if (title.toLowerCase() === "thread") {
    strOptions = options.map(
      (option: any) => option.ThreadID + option.ThreadTitle
    );
  } else {
    strOptions = options;
  }

  const editForm = () => {
    switch (title.toLowerCase()) {
      case "contact":
        return (
          <div>
            <ContactChildForm
              contact={options.find(
                (contact: any) => contact.ContactID === itemID
              )}
              setAddingItem={handleClose}
              fullName={""}
              onClose={handleClose}
              parentTitle={parentTitle}
            />
          </div>
        );
      case "group":
        return (
          <div>
            <StakeholderGroupChildForm
              setAddingItem={handleClose}
              groupName={nameValue}
              setNewItem={handleClose} //consider replacing with better function. won't get called on edit
              setDidSaveChild={setDidSaveInventory}
              group={options.find((group: any) => group.GroupID === itemID)}
            />
          </div>
        );
      case t("objStk:objects.issue.name").toLowerCase():
        return (
          <div>
            <IssueChildForm
              setAddingItem={handleClose}
              issueName={""}
              setNewItem={handleClose}
              setDidSaveChild={setDidSaveInventory}
            />
          </div>
        );
      case "communication":
        return (
          <div>
            <InteractionChildForm
              closeAction={handleClose}
              setDidSaveInventory={setDidSaveInventory}
              parentTitle={parentTitle}
              parentValue={parentValue}
              parentID={parentID}
              interaction={
                unfilteredOptions
                  ? unfilteredOptions.find(
                      (item: any) => item.InteractionID === itemID
                    )
                  : options.find((item: any) => item.InteractionID === itemID)
              }
            />
          </div>
        );
      case "concern":
        return (
          <div>
            <GrievanceChildForm
              closeAction={handleClose}
              setDidSaveInventory={setDidSaveInventory}
              parentTitle={parentTitle}
              parentValue={parentValue}
              grievance={
                unfilteredOptions
                  ? unfilteredOptions.find(
                      (item: any) => item.GrievanceID === itemID
                    )
                  : options.find((item: any) => item.GrievanceID === itemID)
              }
            />
          </div>
        );
      case "commitment":
        return (
          <div>
            <CommitmentChildForm
              closeAction={handleClose}
              setDidSaveInventory={setDidSaveInventory}
              parentTitle={parentTitle}
              parentValue={parentValue}
              parentID={parentID}
              commitment={
                unfilteredOptions
                  ? unfilteredOptions.find(
                      (item: any) => item.CommitmentID === itemID
                    )
                  : options.find((item: any) => item.CommitmentID === itemID)
              }
            />
          </div>
        );
      case "spend":
        return (
          <div>
            <PaymentChildForm
              closeAction={handleClose}
              setDidSaveInventory={setDidSaveInventory}
              paymentPeriod={parentValue}
              paymentID={itemID}
            />
          </div>
        );
      case "job history":
        return (
          <div>
            <JobHistoryChildForm
              closeAction={handleClose}
              employeeID={parentID}
              jobHistoryID={itemID}
            />
          </div>
        );
      case "comment":
        return (
          <div>
            <InteractionCommentChildForm
              closeAction={handleClose}
              setDidSaveInventory={setDidSaveInventory}
              parentTitle={parentTitle}
              parentValue={parentValue}
              parentID={parentID}
              interactionComment={
                options.find(
                  (item: any) => item.InteractionCommentID === itemID
                ) || {}
              }
            />
          </div>
        );
      case "r&d expense":
        return (
          <div>
            <RnDExpenseChildForm
              closeAction={handleClose}
              setDidSaveInventory={setDidSaveInventory}
              parentTitle={parentTitle}
              parentID={parentID}
              parentValue={parentValue}
              expense={options.find(
                (expense: any) => expense.RnDExpenseID === itemID
              )}
            />
          </div>
        );
      case "e&t expense":
        return (
          <div>
            <EnTExpenseChildForm
              closeAction={handleClose}
              setDidSaveInventory={setDidSaveInventory}
              parentTitle={parentTitle}
              parentID={parentID}
              parentValue={parentValue}
              expense={options.find(
                (expense: any) => expense.EnTExpenseID === itemID
              )}
            />
          </div>
        );
      case "work-hour details":
        return (
          <div>
            <WorkHourDetailChildForm
              handleClose={handleClose}
              parentID={parentID}
              whd={options.find(
                (whd: WorkHourDetail) => whd.WorkHourDetailID === itemID
              )}
            />
          </div>
        );
      case t("objStk:objects.thread.name").toLowerCase():
        return (
          <div>
            <ThreadChildForm
              closeAction={handleClose}
              setDidSaveInventory={setDidSaveInventory}
              parentTitle={parentTitle}
              parentValue={parentValue}
              thread={
                unfilteredOptions
                  ? unfilteredOptions.find(
                      (item: any) => item.ThreadID === itemID
                    )
                  : options.find((item: any) => item.ThreadID === itemID)
              }
              canWRITE={canWRITE}
            />
          </div>
        );
      default:
        return <p>{t("strGen:nomatchingentryfound")}</p>;
    }
  };

  // exporting to excel
  const _exporter = useRef<ExcelExport>(null);
  const excelExport = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  };

  let buttons: ButtonsArrayProps[] = [];

  if (popoverBtns) {
    const addBtnObj: ButtonsArrayProps = {
      text: t("strGen:buttons.addnew"),
      startIcon: <Add />,
      color: "secondary",
      onClick: () => handleAdd(),
      disabled: disableBtns || false,
    };

    const editBtnObj: ButtonsArrayProps = {
      text: t("strGen:buttons.edit"),
      startIcon: <Edit />,
      color: "secondary",
      onClick: () => handleEdit(),
      disabled: disableBtns || false,
    };

    const exportBtnObj: ButtonsArrayProps = {
      text: t("strGen:buttons.exporttoexcel"),
      startIcon: <CloudDownload />,
      color: "secondary",
      onClick: () => excelExport(),
      disabled: disableBtns || false,
    };

    buttons = [
      ...(showAdd ? [addBtnObj] : []),
      ...(showEdit ? [editBtnObj] : []),
      ...(showExport ? [exportBtnObj] : []),
      ...(popoverBtns || []),
    ];
  }

  const HeaderCell = (props: any) => (
    <span className="k-cell-inner" title={props.title}>
      <span className="k-link" onClick={props.onClick}>
        <span className="k-column-title">
          {props.title}
          {props.children}
        </span>
      </span>
    </span>
  );

  /********  Expand Row Action  ***********/
  const onItemChange = (event: any) => {
    const updData = selectedValues.map((item: any) =>
      item[nameField] === event.dataItem[nameField]
        ? { ...item, expanded: !item.expanded }
        : item
    );
    setSelectedValues(updData);
  };

  /********  Editable Row Setup  ***********/
  // Row reorder Action for Draggable Rows
  const [activeItem, setActiveItem] = useState<any>(null);
  const reorder = (dataItem: any, direction: "before" | "after" | null) => {
    if (!activeItem || activeItem === dataItem) {
      return;
    }
    const reorderedData = [...selectedValues];
    const draggedIndex = reorderedData.findIndex((item) => item === activeItem);
    const targetIndex = reorderedData.findIndex((item) => item === dataItem);
    const movedItem = reorderedData[draggedIndex];
    reorderedData.splice(draggedIndex, 1);
    reorderedData.splice(
      targetIndex + (direction === "before" ? 0 : 1),
      0,
      movedItem
    );
    filteredData = reorderedData;
    setSelectedValues(reorderedData);
  };

  const dragStart = (dataItem: any) => {
    setActiveItem(dataItem);
  };

  /********  Link item from IppChildTable  ***********/
  const [childTableOpen, setChildTableOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<any>([]);
  const [openDialog, setOpenDialog] = useState(false);
  // Update options upon table data change
  const updateFilteredOptions = () => {
    if (optionTableProps) {
      const currentIDs = selectedValues.map((item: any) => item[relatedField]);

      const filteredOptions = options.filter(
        (item: any) => !currentIDs.includes(item[relatedField])
      );
      const updatedFilteredOptions = filteredOptions.map((item: any) => ({
        ...item,
        isAdded: false,
      }));
      setFilteredOptions(updatedFilteredOptions);
    }
  };
  const handleLinkTable = () => {
    toggleChildTableDialog();
  };
  const toggleChildTableDialog = () => {
    // Filter options before open dialog
    !childTableOpen && updateFilteredOptions();
    setChildTableOpen(!childTableOpen);
  };

  // Option Table Item Select and Unselect
  const handleAddItem = (item: any) => {
    // Find the highest OrderIndex (convert to number)
    let maxOrderIndex =
      selectedValues.length > 0
        ? Math.max(
            ...selectedValues.map((data: any) => Number(data.OrderIndex))
          )
        : 0;

    const newData = [
      ...selectedValues,
      {
        ...item,
        isAdded: true,
        expanded: false,
        OrderIndex: maxOrderIndex + 1,
      },
    ].sort((a, b) => b.OrderIndex - a.OrderIndex); // Sort in descending order
    item.isAdded = true;
    // Sort the list by date in descending order

    // if (orderByDate) {
    //   newData.sort((a, b) => {
    //     const dateA = new Date(a[orderByDate]).getTime();
    //     const dateB = new Date(b[orderByDate]).getTime();
    //     return dateB - dateA;
    //   });
    // }
    setSelectedValues(newData);
  };
  const handleRemoveItem = (item: any) => {
    const remainingData = selectedValues.filter(
      (data: any) => data[relatedField] !== item[relatedField]
    );
    setSelectedValues(remainingData);
    if (item.isAdded === true) {
      item.isAdded = false;
    }
  };

  const SelectCell = (props: any) => {
    return (
      <td>
        <Grid container justifyContent="center" alignItems="center">
          <Tooltip
            title={t("strGen:components.customcells.addCell.addTo", {
              fieldname: parentTitle,
            })}
          >
            {props.dataItem?.isAdded === true ? (
              <IppCancelButton
                buttonText={t("strGen:buttons.unselect")}
                onClick={() => handleRemoveItem(props?.dataItem)}
              />
            ) : (
              <IppMergeButton
                buttonText={t("strGen:pages.merge.select")}
                onClick={() => handleAddItem(props?.dataItem)}
              />
            )}
          </Tooltip>
        </Grid>
      </td>
    );
  };
  const updOptionsColumns =
    canWRITE && optionTableProps?.optionColumns
      ? [
          ...optionTableProps?.optionColumns,
          {
            field: idField,
            title: "",
            button: true,
            buttonCell: SelectCell,
            columnWidth: 175,
          },
        ]
      : [];

  // Check if table data change
  let tbChanges = ["ThreadInteraction", "InteractionThread"].includes(tableName)
    ? getChangedData(tableData, selectedValues, nameField)
    : { newItems: [], deletedItems: [], updatedItems: [] };
  // updatedItems are refering to items with Order chane. only matters when isDraggable=true
  let isUpdated =
    tbChanges?.newItems?.length > 0 ||
    tbChanges?.deletedItems?.length > 0 ||
    (isDraggable && tbChanges?.updatedItems?.length > 0);
  useEffect(() => {
    if (isEditable && canWRITE) {
      setIsInvUpdated(isUpdated);
    }
  }, [isUpdated]);

  // Handle Editable table changes
  const handleReset = () => {
    setSelectedValues(tableData);
  };

  const handleSave = async () => {
    let deletedData = tbChanges?.deletedItems?.map((i) => ({
      [idField]: i[idField],
    }));
    let selectedValuesCopy = [...selectedValues];
    let updatedData: Object[] = [];
    const tableDataMap = new Map(
      tableData.map((data: any) => [data[nameField], data])
    );
    // Remove items with invalid nameField values
    selectedValuesCopy = selectedValuesCopy.filter(
      (item: any) => item[idField] !== undefined && item[idField] !== null
    );
    let descIndex = selectedValuesCopy.length - 1;
    selectedValuesCopy.forEach((item: any) => {
      const matchedRecord: any = tableDataMap.get(item[nameField]);
      updatedData.push({
        [idField]: matchedRecord[idField],
        OldOrderIndex: matchedRecord.OrderIndex,
        NewOrderIndex: descIndex--,
      });
    });
    let newData = optionTableProps
      ? tbChanges?.newItems?.map((i: any) => ({
          [nameField]: i[nameField],
          OrderIndex:
            selectedValues?.find(
              (data: any) => data[nameField] === i[nameField]
            )?.OrderIndex ?? 0, // Get OrderIndex from selectedValues, default to 0 if not found
        }))
      : [];
    const changes = [{ deletedData }, { updatedData }, { newData }];
    let data: any;
    if (tableName === "ThreadInteraction") {
      data = changes;
    } else if (tableName === "InteractionThread") {
      data = selectedValues;
    }
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
        },
      });
      dispatch(updAction(accessToken, parentID, data));
      setIsInvUpdated(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (isChildSubmit) {
      handleSave();
    }
  }, [isChildSubmit]);

  // Handle confirm dialog for editable tables
  let canContinue = !isUpdated && showSave;
  const [action, setAction] = useState({ type: "", id: -1 });
  const handleConfirm = () => {
    if (action.type === "Add") {
      handleAdd();
    } else if (action.type === "Edit") {
      handleEditEntity(action.id);
    }
    setOpenDialog(false);
  };
  // Editable Table Buttons
  const getEditableInvBtns = (isEditable: any) => {
    let editableBtns: any[] = [];

    if (isEditable) {
      const cancelBtn = <IppCancelButton onClick={handleReset} />;
      const saveBtn = <IppSaveButton handleSave={handleSave} />;
      const linkBtn = (
        <Tooltip
          title={
            disabled ||
            t("strGen:components.ippchildinventory.tooltips.link.linknewobj", {
              childobjectname: t("objStk:objects.interaction.name"),
              objectname: t("objStk:objects.thread.name"),
            })
          }
        >
          <IppAddButton
            buttonText={t("strGen:buttons.linkitem")}
            startIcon={<AddLink />}
            onClick={handleLinkTable}
            disabled={!!disabled}
          />
        </Tooltip>
      );

      const addBtn = (
        <Tooltip
          title={
            disabled ||
            t("strGen:components.ippchildinventory.tooltips.add.addnewobj", {
              childobjectname: title,
              objectname: parentTitle,
            })
          }
        >
          <span>
            <IppAddButton
              buttonText={t("strGen:buttons.addnew")}
              startIcon={<Add />}
              onClick={() => {
                setAction({ ...action, type: "Add" });
                canContinue ? handleAdd() : setOpenDialog(true);
              }}
              disabled={!!disabled}
            />
          </span>
        </Tooltip>
      );

      editableBtns = [
        isUpdated && showCancel && cancelBtn,
        isUpdated && showSave && saveBtn,
        showEdit && linkBtn,
        showAdd && addBtn,
      ].filter(Boolean); // Filter out any false values
    }

    return (
      <Grid container item justifyContent="flex-end" rowSpacing={1}>
        {editableBtns.map((btn, index) => (
          <Grid item key={index}>
            {btn}
          </Grid>
        ))}
      </Grid>
    );
  };

  //----------------- partial overview detail -------------------
  const PartialDetailComponent = (props: any) => {
    return (
      <div style={{ padding: "0px 0px 20px", marginLeft: "60px" }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="caption">
              <div
                style={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 3,
                  overflow: "hidden",
                  height: "auto",
                  width: "100%",
                }}
                dangerouslySetInnerHTML={{
                  __html: SanitizeHTML(
                    props.dataItem?.Details
                      ? props.dataItem.Details
                      : "** No Details **"
                  ),
                }}
              />
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  };

  /********  Alert component for Editable Table  ***********/
  const clickTips = (
    <Grid container alignItems="center">
      {t("strGen:tips.tip.click")} <KeyboardArrowRight fontSize="small" />
      {t("strGen:tips.tip.ippExpandContent")}
      {isDraggable && t("strGen:tips.tip.ippDragContent")}
    </Grid>
  );
  // Hide Alert if no data in table
  const AlertComponent = canWRITE &&
    isEditable &&
    showEdit &&
    (!!selectedValues?.length || !!tableData?.length) && (
      <Grid item xs={12} sx={{ ".MuiAlert-message": { padding: 0 } }}>
        <IppAlertHeader
          type={isUpdated && showCancel ? "warning" : "info"}
          header={
            isUpdated && showCancel
              ? t("strGen:tips.update.title", { fieldname: title })
              : t("strGen:tips.tip.title")
          }
          description={
            isUpdated && showCancel
              ? t("strGen:tips.update.ippDraggableContent")
              : isExpandable
              ? clickTips
              : ""
          }
        />
      </Grid>
    );

  let returnGrid = (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={isEditable ? 5 : 7}>
              <Typography variant="h6">
                {tableName === "PeriodWorkHourDetails"
                  ? title
                  : title !== "Spend" &&
                    title !== "Job History" &&
                    title !== "Work History" &&
                    title !== "Spend Activity"
                  ? `${title}s for this ${parentTitle}`
                  : `${title} for this ${parentTitle}`}
              </Typography>
            </Grid>
            <Grid item xs={isEditable ? 7 : 5}>
              {canWRITE && (
                <>
                  {popoverMenu ? (
                    <Box display="flex" justifyContent="flex-end">
                      <IppPopoverMenu
                        tooltipMessage={t(
                          "strGen:components.ippchildinventory.tooltips.popovermenu"
                        )}
                        buttons={buttons}
                        width="260px"
                      />
                    </Box>
                  ) : (
                    <Grid container justifyContent="flex-end">
                      {/* Add Button */}
                      {showAdd && !isEditable && (
                        <Tooltip
                          title={
                            disabled
                              ? disabled
                              : title === "Job History"
                              ? t(
                                  "strGen:components.ippchildinventory.tooltips.add.jobhistory"
                                )
                              : t(
                                  "strGen:components.ippchildinventory.tooltips.add.addnewobj",
                                  {
                                    childobjectname: title,
                                    objectname: parentTitle,
                                  }
                                )
                          }
                        >
                          <span>
                            <IppAddButton
                              buttonText={
                                title ===
                                t("objBen:objects.employee.jobhistory.name")
                                  ? t("strGen:buttons.addobj", {
                                      objectname: t(
                                        "objBen:objects.employee.jobhistory.previousposition"
                                      ),
                                    })
                                  : t("strGen:buttons.addnew")
                              }
                              onClick={handleAdd}
                              disabled={!!disabled}
                            />
                          </span>
                        </Tooltip>
                      )}
                      {/* Link Button */}
                      {showLinkButton && !isEditable && (
                        <Tooltip
                          title={t(
                            "strGen:components.ippchildinventory.tooltips.link.linknewobj",
                            {
                              childobjectname: title,
                              objectname: parentTitle,
                            }
                          )}
                        >
                          <IppAddButton
                            buttonText={t("strGen:buttons.linkitem")}
                            startIcon={<AddLink />}
                            onClick={handleLinking}
                          />
                        </Tooltip>
                      )}
                      {/* Edit Button */}
                      {showEdit && !isEditable && (
                        <Tooltip
                          title={t(
                            "strGen:components.ippchildinventory.tooltips.edit.editobj",
                            {
                              childobjectname: title,
                              objectname: parentTitle,
                            }
                          )}
                        >
                          <IppEditButton
                            buttonText={t("strGen:buttons.editobj", {
                              objectname: `${title}s`,
                            })}
                            onClick={handleEdit}
                          />
                        </Tooltip>
                      )}
                      {/* Export Button */}
                      {showExport && !isEditable && (
                        <Tooltip
                          title={t("strGen:buttons.tooltip.exportalltoexcel")}
                        >
                          <IppExportButton exporter={_exporter} />
                        </Tooltip>
                      )}
                    </Grid>
                  )}
                  {/* Editable Child Inventory Buttons */}
                  {isEditable && getEditableInvBtns(isEditable)}
                </>
              )}
            </Grid>
            {showSearchBar && (
              <Grid container spacing={1} alignItems="center" paddingLeft="8px">
                {/* Search Bar */}
                <Grid item xs={8}>
                  <TextField
                    id="search"
                    label={t("strGen:components.ippinventory.searchbarlabel")}
                    size="small"
                    value={searchTerm}
                    placeholder="Search..."
                    onChange={(e) => setSearchTerm(e.target.value)}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Search fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {AlertComponent}
        </Grid>

        <Grid item xs={12}>
          <ExcelExport
            data={filteredData}
            filterable={false}
            ref={_exporter}
            fileName={filename}
          >
            <ReorderContext.Provider
              value={{
                reorder: reorder,
                dragStart: dragStart,
              }}
            >
              <DragAndDrop>
                <KendoGrid
                  className="custom-communications-grid"
                  data={dataToDisplay?.slice(
                    skip,
                    (take ? take : 10) + (skip || 0)
                  )}
                  pageable={{
                    buttonCount: 5,
                    info: true,
                    // type: "input",
                    pageSizes: [5, 10, 25, 50, 100],
                  }}
                  skip={skip}
                  take={take}
                  total={dataToDisplay?.length}
                  onPageChange={pageChange}
                  sortable={true}
                  sort={sort || ({} as SortDescriptor[])}
                  onSortChange={(e) => sortChanged(e)}
                  rowRender={(row, rowProps) => {
                    let rowElement;

                    if (computedIsDraggable) {
                      rowElement = (
                        <DraggableRow
                          {...rowProps}
                          dataItem={rowProps.dataItem}
                        />
                      );
                    } else {
                      rowElement = row;
                    }

                    const numberOfColumns = columns.length + 3;
                    // Get the expanded state of the row
                    const isExpanded = rowProps.dataItem.expanded;

                    return (
                      <>
                        {React.cloneElement(rowElement, {
                          style: {
                            ...rowElement.props.style,
                          },
                        })}
                        {!isExpanded && tableName === "ThreadInteraction" && (
                          <tr>
                            <td
                              colSpan={numberOfColumns}
                              style={{
                                borderBottom: "3px solid #e0e0e0",
                                borderTop: "none",
                              }}
                            >
                              <PartialDetailComponent
                                dataItem={rowProps.dataItem}
                              />
                            </td>
                          </tr>
                        )}
                      </>
                    );
                  }}
                  expandField={isExpandable ? "expanded" : ""}
                  onItemChange={onItemChange}
                  detail={detailComponent}
                >
                  {/* expand column */}
                  {isExpandable && (
                    <GridColumn
                      field="expanded"
                      title=" "
                      width={60}
                      cell={ExpandCell}
                      editable={false}
                    />
                  )}
                  {columns.map((col: column, ci: number) => {
                    const isLinkCell =
                      !noLinkCell && (ci === 0 || ci === secondLinkCell);

                    // Create the cell
                    const linkCell = (props: any) => {
                      const currentModule = getCurrentModule();
                      // Determine the path based on the title and parentTitle
                      let path = `/${getCurrentModule()}/${linkURL}/${
                        props.dataItem[linkField || relatedField]
                      }`;
                      if (
                        title === "Communication" &&
                        parentTitle === "Commitment"
                      ) {
                        path = `/engagement/${linkURL}/${
                          props.dataItem[linkField || relatedField]
                        }`;
                      } else if (
                        title === "Commitment" &&
                        parentTitle === "Communication"
                      ) {
                        path = `/commitments/${linkURL}/${
                          props.dataItem[linkField || relatedField]
                        }`;
                      } else if (
                        currentModule === "grievance" &&
                        title !== "Grievance" &&
                        title !== "Concern"
                      ) {
                        path = `/engagement/${linkURL}/${
                          props.dataItem[linkField || relatedField]
                        }`;
                      }
                      return (
                        <CustomLinkCell
                          {...props}
                          format={col.format}
                          path={path}
                        />
                      );
                    };

                    if (isLinkCell) {
                      return (
                        <GridColumn
                          field={col.field}
                          title={col.title}
                          width={col.columnWidth ? col.columnWidth : undefined}
                          cell={linkCell}
                          key={col.title}
                          headerCell={HeaderCell}
                        />
                      );
                    } else if (col.chips) {
                      return (
                        <GridColumn
                          key={col.title}
                          field={col.field}
                          title={col.title}
                          width={col.columnWidth ? col.columnWidth : undefined}
                          cell={(props) => <StatusChipCell {...props} />}
                          columnMenu={checkboxFilter}
                          headerClassName={
                            GridColumnMenuFilter.active(
                              col.field,
                              filter as CompositeFilterDescriptor
                            )
                              ? "active"
                              : ""
                          }
                          headerCell={HeaderCell}
                        />
                      );
                    } else if (col.commaDelimited) {
                      return (
                        <GridColumn
                          key={col.title}
                          field={col.field}
                          title={col.title}
                          cell={CommaDelimitedCell}
                          headerCell={HeaderCell}
                        />
                      );
                    } else if (col.truncateText) {
                      return (
                        <GridColumn
                          key={col.title}
                          field={col.field}
                          title={col.title}
                          cell={TruncatedTextCell}
                          headerCell={HeaderCell}
                        />
                      );
                    } else if (col.icons) {
                      return (
                        <GridColumn
                          key={col.title}
                          field={col.field}
                          title={col.title}
                          cell={(props) => <IconCell {...props} />}
                          width={col.columnWidth ? col.columnWidth : undefined}
                          headerCell={HeaderCell}
                        />
                      );
                    } else if (col.sendEmail) {
                      return (
                        <GridColumn
                          key={col.title}
                          field={col.field}
                          title={col.title}
                          cell={CustomEmailCell}
                          headerCell={HeaderCell}
                        />
                      );
                    } else if (col.checkbox) {
                      return (
                        <GridColumn
                          key={col.title}
                          field={col.field}
                          title={col.title}
                          cell={(props) => (
                            <CustomCheckboxCell
                              {...props}
                              {...{
                                clearTrueValues: col.clearTrueValues,
                                clearFalseValues: col.clearFalseValues,
                              }}
                            />
                          )}
                          width={col.columnWidth ? col.columnWidth : undefined}
                          headerCell={HeaderCell}
                        />
                      );
                    } else if (col.field === "Attachments") {
                      return (
                        <GridColumn
                          field="Attachment"
                          title=" "
                          cell={(props) => {
                            const { AttachmentCount } = props.dataItem;
                            return (
                              <td>
                                <Tooltip
                                  title={t(
                                    "objPlt:objects.attachment.manageattachments"
                                  )}
                                >
                                  <div
                                    style={{ cursor: "pointer", color: "blue" }}
                                  >
                                    <IppButton
                                      variant="text"
                                      startIcon={<AttachFile />}
                                      color="secondary"
                                      onClick={() =>
                                        handleAttachmentEntity(
                                          props.dataItem[idField]
                                        )
                                      }
                                    >
                                      {AttachmentCount !== undefined &&
                                        AttachmentCount > 0 && (
                                          <sup style={{ fontSize: "0.75em" }}>
                                            ({AttachmentCount})
                                          </sup>
                                        )}
                                    </IppButton>
                                  </div>
                                </Tooltip>
                              </td>
                            );
                          }}
                          width={60}
                        />
                      );
                    } else if (title === "Comment" && !col.commaDelimited) {
                      return (
                        <GridColumn
                          field={col.field}
                          title={col.title}
                          format={col.format ? col.format : undefined}
                          width={col.columnWidth ? col.columnWidth : undefined}
                          className="impact-nowrap"
                          key={col.title}
                          headerCell={HeaderCell}
                        />
                      );
                    } else if (col?.button) {
                      return (
                        <GridColumn
                          cell={col?.buttonCell}
                          width={col.columnWidth ? col.columnWidth : undefined}
                        />
                      );
                    } else {
                      return (
                        <GridColumn
                          field={col.field}
                          title={col.title}
                          format={
                            col.filter === "date"
                              ? "{0:yyyy-MM-dd}"
                              : col.format
                          }
                          width={col.columnWidth ? col.columnWidth : undefined}
                          key={col.title}
                          headerCell={HeaderCell}
                        />
                      );
                    }
                  })}
                  {/* && canWRITE here hides buttons completely from View Only Users, can remove if View Only and PowerUser become allowed on a single user */}
                  {showOptions && canWRITE ? (
                    <GridColumn
                      cell={(props) => {
                        return (
                          <MenuCell
                            {...props}
                            handleEditEntity={(id: any) => {
                              if (isEditable) {
                                setAction({
                                  ...action,
                                  type: "Edit",
                                  id: id,
                                });
                                canContinue
                                  ? handleEditEntity(id)
                                  : setOpenDialog(true);
                              } else {
                                handleEditEntity(id);
                              }
                            }}
                            handleDeleteEntity={
                              isEditable ? handleRemoveItem : handleDeleteEntity
                            }
                            setDidSaveInventory={setDidSaveInventory}
                            title={title}
                            parentTitle={parentTitle}
                            relatedField={relatedField}
                            idField={idField}
                            field={nameField}
                            disabled={disableBtns || !canWRITE}
                            showAttachBtn={showAttachBtn}
                            handleAttachmentEntity={handleAttachmentEntity}
                          />
                        );
                      }}
                      width={60}
                    />
                  ) : (
                    ""
                  )}
                  {/* if the record is generated by ai or not */}
                  <GridColumn
                    cell={(props) => (
                      <td>
                        {props.dataItem?.ShowAiBadge === true && (
                          <AiBadge
                            objectName={t("objStk:objects.interaction.name")}
                          />
                        )}
                      </td>
                    )}
                    width={60}
                  />
                </KendoGrid>
              </DragAndDrop>
            </ReorderContext.Provider>
          </ExcelExport>
        </Grid>
      </Grid>
      <IppChildAdd
        title={title}
        parentTitle={parentTitle}
        parentID={parentID}
        addFormOpen={addFormOpen}
        closeAction={toggleAddDialog}
        setDidSaveInventory={setDidSaveInventory}
        parentValue={parentValue}
        headerText={addHeaderText}
      />
      <IppChildEdit
        title={title}
        parentTitle={parentTitle}
        parentID={parentID}
        isOpen={editOpen}
        closeAction={toggleEditDialog}
        tableName={tableName}
        id={id}
        label={label}
        options={strOptions}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        setDidSaveInventory={setDidSaveInventory}
        parentValue={parentValue}
        itemID={itemID}
        suggestions={suggestions}
        showSuggestions={showSuggestions}
        suggestionTitle={suggestionTitle}
      />
      <IppChildLink
        title={title}
        parentTitle={parentTitle}
        parentID={parentID}
        isOpen={linkingOpen}
        closeAction={toggleLinkDialog}
        tableName={tableName}
        id={id}
        label={label}
        options={options}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        setDidSaveInventory={setDidSaveInventory}
      />
      <IppChildDelete
        title={title}
        parentTitle={parentTitle}
        itemID={itemID}
        nameValue={nameValue}
        isOpen={deleteOpen}
        closeAction={toggleDeleteDialog}
        tableName={tableName}
        parentID={parentID}
        deleteMessageType={deleteMessageType}
        deleteNoteType={deleteNoteType}
        deleteNameValueTitle={deleteNameValueTitle}
      />
      <IppChildTable
        title={title}
        parentTitle={parentTitle}
        isOpen={childTableOpen}
        closeAction={toggleChildTableDialog}
        tableName={tableName}
        idField={relatedField}
        options={filteredOptions}
        optionColumns={updOptionsColumns}
        optionSearch={optionTableProps?.optionSearch}
        optionDetail={optionTableProps?.optionDetail}
        linkColumn={optionTableProps?.linkColumn}
        route={optionTableProps?.route}
      />
      <Dialog open={editEntityOpen} onClose={handleClose} maxWidth="md">
        <DialogTitle>
          <IppCloseButton onClick={() => handleClose(null, null)} />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div>{editForm()}</div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {!!attachmentCategoryRecordType && (
        <IppAttachmentModal
          attachmentModalOpen={attachmentModalOpen}
          handleAttachmentClose={handleAttachmentClose}
          recordType={tableName} // This works for Spend but may need to be updated if expanded
          itemID={itemID}
          companyID={currentProfile.CompanyID}
          projectID={parentProjectID || 0}
          moduleID={1}
          disabled={attachBtnDisabled || false}
          objectName={title}
          categoryRecordType={attachmentCategoryRecordType}
        />
      )}
      {/* show confirm dialog when editable table changes */}
      <IppConfirmDialog
        title={t("strGen:components.ippconfirmdialog.unsavedchanges")}
        open={openDialog}
        setOpen={() => setOpenDialog(!openDialog)}
        onConfirm={handleConfirm}
        content={t("strGen:components.ippconfirmdialog.confirminfo", {
          objectname:
            isUpdated && !showSave
              ? `${title} and ${parentTitle}`
              : isUpdated
              ? `${title}`
              : parentTitle,
        })}
        confirmText={t("strGen:buttons.continueto", {
          actionname: action?.type,
        })}
      />
    </div>
  );

  return <>{returnGrid}</>;
};
