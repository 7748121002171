import { Chip } from "@mui/material";

export const getStatusChips = (field: string, data: any) => {
  switch (field) {
    case "CommitmentStatusTypeName":
      return getCommitmentStatusChips(data);
    case "WorkflowStageName":
      return getPaymentStageChips(data);
    case "StatusTypeName":
      return getCommunicationStatusChips(data);
    case "ActionStatusName":
      return getActionStatusChips(data);
    case "StatusName":
      return getSubmitStatusChips(data);
    default:
      return null;
  }
};

// Now expects a status object with id and label properties
export const getCommitmentStatusChips = (status: {
  id: number;
  label: string;
}) => {
  switch (status.id) {
    case 1:
      // Not Started, IsCompleted = 0 -> error red chip
      return <Chip label={status.label} size="small" color="error" />;
    case 2:
      // In Progress, IsCompleted = 0 -> info blue chip
      return <Chip label={status.label} size="small" color="info" />;
    case 3:
      // Complete - Commitment Met, IsCompleted = 1 -> success green chip
      return <Chip label={status.label} size="small" color="success" />;
    case 4:
      // Action Not Yet Required, IsCompleted = 0 -> grey chip
      return (
        <Chip
          label={status.label}
          size="small"
          style={{ backgroundColor: "#B0BEC5" }}
        />
      );
    case 5:
      // Ongoing - Commitment Being Met, IsCompleted = 0 -> info blue chip
      return <Chip label={status.label} size="small" color="info" />;
    case 6:
      // Complete - Commitment Not Met, IsCompleted = 0 -> success green chip
      return <Chip label={status.label} size="small" color="success" />;
    case 7:
      // Ongoing - Commitment Not Being Met, IsCompleted = 0 -> warning chip
      return <Chip label={status.label} size="small" color="warning" />;
    case 8:
      // Complete - Not Required, IsCompleted = 1 -> success green chip
      return <Chip label={status.label} size="small" color="success" />;
    case 9:
      // Completed, IsCompleted = 1 -> success green chip
      return <Chip label={status.label} size="small" color="success" />;
    case 10:
      // Action Not Required, IsCompleted = 0 -> grey chip
      return (
        <Chip
          label={status.label}
          size="small"
          style={{ backgroundColor: "#B0BEC5" }}
        />
      );
    default:
      return <Chip label={status.label} size="small" />;
  }
};

function getPaymentStageChips(type: string) {
  switch (type) {
    case "In Progress":
      return <Chip label={type} size="small" color="info" />;
    case "Submitted for Review":
      return <Chip label={type} size="small" color="warning" />;
    case "Complete":
      return <Chip label={type} size="small" color="success" />;
    case "Referred Back":
      return <Chip label={type} size="small" color="error" />;
    default:
      return <Chip label={type} size="small" />;
  }
}

export function getCommunicationStatusChips(type: string) {
  switch (type) {
    case "Scheduled":
      return <Chip label={type} size="small" color="info" />;
    case "In Progress":
      return <Chip label={type} size="small" color="info" />;
    case "Completed":
      return <Chip label={type} size="small" color="success" />;
    case "Overdue":
      return <Chip label={type} size="small" color="error" />;
    case "Cancelled":
      return <Chip label={type} size="small" color="error" />;
    default:
      return <Chip label={type} size="small" />;
  }
}

export const getActionStatusChips = (type: string) => {
  switch (type) {
    case "Not Started":
      return <Chip label={type} size="small" color="error" />;
    case "In Progress":
      return <Chip label={type} size="small" color="info" />;
    case "Closed Completed":
      return <Chip label={type} size="small" color="success" />;
    case "Closed Incomplete":
      return <Chip label={type} size="small" color="warning" />;
    case "No Longer Needed":
      return <Chip label={type} size="small" color="info" />;
    default:
      return <Chip label={type} size="small" />;
  }
};

function getSubmitStatusChips(type: string) {
  switch (type) {
    case "On Hold":
      return <Chip label={type} size="small" color="info" />;
    case "Not Started":
      return <Chip label={type} size="small" color="warning" />;
    case "Ongoing":
      return <Chip label={type} size="small" color="warning" />;
    case "Completed":
      return <Chip label={type} size="small" color="success" />;
    case "Draft":
      return <Chip label={type} size="small" color="warning" />;
    case "Submitted":
      return <Chip label={type} size="small" color="success" />;
    case "Rejected":
      return <Chip label={type} size="small" color="error" />;
    default:
      return <Chip label={type} size="small" />;
  }
}
