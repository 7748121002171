import { Trans } from "react-i18next";
import {
  ConstructedErrors,
  GenericErrorTitlesObject,
} from "utils/types/index.types";
import {
  generateErrorTitle,
  getGenericConstructedErrors,
  getGenericConstructedWarnings,
} from "utils/uploadUtils";

type GroupErrorTitles = GenericErrorTitlesObject & {
  groupOrganizationType: (count: number) => JSX.Element | "";
};

export const generateGroupErrorTitles: GroupErrorTitles = {
  ...generateErrorTitle,
  groupOrganizationType: (count: number) =>
    count > 0 ? (
      <Trans
        i18nKey="strGen:uploaderrors.errors.group.organizationtype.title"
        count={count}
      />
    ) : (
      ""
    ),
};

export const getNewGroupConstructedErrors: () => ConstructedErrors = () => ({
  ...getGenericConstructedErrors(),
});

export const getNewGroupConstructedWarnings: () => ConstructedErrors = () => ({
  ...getGenericConstructedWarnings(),
  groupOrganizationType: {
    count: 0,
    get message() {
      return this.count > 0 ? (
        <>
          The following{" "}
          {this.count === 1 ? (
            <>
              <Trans
                i18nKey="strGen:uploaderrors.errors.group.organizationtype.title"
                count={this.count}
              />{" "}
              that does
            </>
          ) : (
            <>
              <Trans
                i18nKey="strGen:uploaderrors.errors.group.organizationtype.title_other"
                count={this.count}
              />{" "}
              that do
            </>
          )}{" "}
          not exist on the platform. If you continue with this upload, the{" "}
          {this.count === 1 ? "value" : "values"} will be discarded. If the
          spelling is correct and the {this.count === 1 ? "value" : "values"}{" "}
          should exist, please contact your Administrator.
        </>
      ) : (
        ""
      );
    },
    list: [],
    showSingleItem: true,
  },
});
