import * as yup from "yup";
import { useTypedTranslation } from "utils/customHooks";

export const AttachmentValidationSchema = (isLinkAttachment?: boolean) => {
  const t = useTypedTranslation(["objPlt", "strGen"]);

  // Base fields for both file and link attachments
  const baseFields = {
    FileDescription: yup
      .string()
      .nullable()
      .max(
        250,
        t("strGen:validation.max", {
          fieldname: t("objPlt:objects.attachment.fields.description"),
          count: 250,
        })
      ),
    AttachmentCategoryID: yup.number().nullable(),
  };

  // Additional fields if it is a link attachment
  const linkFields = {
    Link: yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.attachment.fields.hyperlink"),
        })
      )
      .max(
        500,
        t("strGen:validation.max", {
          fieldname: t("objPlt:objects.attachment.fields.hyperlink"),
          count: 500,
        })
      ),
    LinkTitle: yup
      .string()
      .trim()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:objects.attachment.fields.linktitle"),
        })
      )
      .max(
        500,
        t("strGen:validation.max", {
          fieldname: t("objPlt:objects.attachment.fields.linktitle"),
          count: 500,
        })
      ),
  };

  // Conditionally merge the link fields if needed
  const shape = {
    ...baseFields,
    ...(isLinkAttachment ? linkFields : {}),
  };

  return yup.object().shape(shape);
};
