import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  Grievance,
  GrievancesResult,
  getGrievances,
  createGrievance,
  updateGrievance,
  deleteGrievance,
} from "api/stakeholder/grievanceAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { Contact } from "../../../api/stakeholder/contactAPI";
import { fetchGrievanceInteractions } from "../grievanceInteraction/GrievanceInteractionSlice";
import { fetchGrievanceContacts } from "../../stakeholder/grievanceContact/GrievanceContactSlice";
import { fetchGrievanceGroups } from "../../stakeholder/grievanceGroup/GrievancGroupSlice";
import { SnackBarConstants } from "utils/customHooks";

interface GrievanceState {
  grievancesById: Record<number, Grievance>;
  grievanceList: number[];
  isLoading: boolean;
  isLoadingAdd: boolean;
  subGrievanceisLoading: boolean;
  error: string | null;
  errorList: any[] | null;
}

const GrievanceInitialState: GrievanceState = {
  grievancesById: {},
  grievanceList: [],
  isLoading: false,
  isLoadingAdd: false,
  subGrievanceisLoading: false,
  error: null,
  errorList: null,
};

function startLoading(state: GrievanceState) {
  state.isLoading = true;
}

function startLoadingAdd(state: GrievanceState) {
  state.isLoadingAdd = true;
}

function loadingFailed(
  state: GrievanceState,
  action: PayloadAction<{ status: string; message: string; errors: [] }>
) {
  state.isLoading = false;
  state.error = action.payload.message;
  if (action.payload.errors) {
    state.errorList = action.payload.errors;
  }
}

const grievances = createSlice({
  name: "grievances",
  initialState: GrievanceInitialState,
  reducers: {
    getGrievancesStart: startLoading,
    getGrievancesSuccess(state, { payload }: PayloadAction<GrievancesResult>) {
      const { grievances } = payload;

      state.isLoading = false;
      state.error = null;
      state.errorList = null;

      grievances.forEach((grievance) => {
        state.grievancesById[grievance.GrievanceID] = grievance;
      });

      state.grievanceList = grievances.map(
        (grievance) => grievance.GrievanceID
      );
    },
    getGrievancesFailure: loadingFailed,
    createGrievanceStart: startLoadingAdd,
    createGrievanceSuccess(state, { payload }: PayloadAction<Grievance>) {
      const { GrievanceID } = payload;
      state.grievancesById[GrievanceID] = payload;
      state.grievanceList.push(GrievanceID);

      state.isLoading = false;
      state.isLoadingAdd = false;
      state.error = null;
      state.errorList = null;
    },
    updateGrievanceSuccess(state, { payload }: PayloadAction<Grievance>) {
      const { GrievanceID } = payload;

      state.grievancesById[GrievanceID] = payload;

      state.isLoading = false;
      state.error = null;
      state.errorList = null;
    },
    deleteGrievanceSuccess(state, { payload }: PayloadAction<number>) {
      const GrievanceID = payload;
      delete state.grievancesById[GrievanceID];
      state.grievanceList = state.grievanceList.filter(
        (item) => item !== GrievanceID
      );

      state.isLoading = false;
      state.error = null;
      state.errorList = null;
    },
    createGrievanceFailure: loadingFailed,
  },
});

export const {
  getGrievancesStart,
  getGrievancesSuccess,
  getGrievancesFailure,
  createGrievanceStart,
  createGrievanceSuccess,
  updateGrievanceSuccess,
  deleteGrievanceSuccess,
  createGrievanceFailure,
} = grievances.actions;

export default grievances.reducer;

export const fetchGrievances =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getGrievancesStart());
      const grievances = await getGrievances(accessToken);
      dispatch(getGrievancesSuccess(grievances));
    } catch (err: any) {
      dispatch(getGrievancesFailure(err.toString()));
    }
  };

export const addGrievance =
  (
    accessToken: String,
    newGrievance: Partial<Grievance>,
    contactList: Contact[],
    groupList: string[],
    issueList: string[],
    interactionList: number[],
    setReturnRoute: { status: boolean; basePath: string | null },
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createGrievanceStart());
      const grievance = await createGrievance(
        accessToken,
        newGrievance,
        contactList,
        groupList,
        issueList,
        interactionList
      );
      dispatch(createGrievanceSuccess(grievance));
      dispatch(openSnackBar(snackbarConstants.ADD_SUCCESS, "success"));
      if (setReturnRoute.status && setReturnRoute.basePath) {
        dispatch(push(`${setReturnRoute.basePath}/${grievance.GrievanceID}`));
      }
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(createGrievanceFailure(error.response.data));
        dispatch(openSnackBar(error.response.data.message, "error"));
      } else {
        dispatch(createGrievanceFailure(error.toString()));
        dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
      }
    }
  };

export const updGrievance =
  (
    accessToken: String,
    grievanceID: number,
    newGrievance: Partial<Grievance>,
    contactList: Contact[] | null,
    groupList: string[] | null,
    issueList: string[] | null,
    setReturnRoute: { status: boolean; basePath: string | null },
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      let contactIDs: any[] | null = contactList
        ? contactList.map((contact) => {
            return { ContactID: contact.ContactID };
          })
        : null;

      dispatch(createGrievanceStart());
      const grievance = await updateGrievance(
        accessToken,
        grievanceID,
        newGrievance,
        contactIDs,
        groupList,
        issueList
      );
      dispatch(updateGrievanceSuccess(grievance));
      dispatch(fetchGrievanceInteractions(accessToken));
      dispatch(fetchGrievanceContacts(accessToken));
      dispatch(fetchGrievanceGroups(accessToken));
      dispatch(openSnackBar(snackbarConstants.UPDATE_SUCCESS, "success"));
      if (setReturnRoute.status && setReturnRoute.basePath) {
        dispatch(push(`${setReturnRoute.basePath}/${grievanceID}`));
      }
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(createGrievanceFailure(error.response.data));
        dispatch(openSnackBar(error.response.data.message, "error"));
      } else {
        dispatch(createGrievanceFailure(error.toString()));
        dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
      }
    }
  };

export const delGrievance =
  (
    accessToken: String,
    grievanceID: number,
    snackbarConstants: SnackBarConstants,
    redirect: String
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createGrievanceStart());
      const result = await deleteGrievance(accessToken, grievanceID);
      dispatch(deleteGrievanceSuccess(grievanceID));
      dispatch(openSnackBar(snackbarConstants.DELETE_SUCCESS, "success"));
      dispatch(push(`${redirect}`));
    } catch (error: any) {
      if (error.response.data.message) {
        dispatch(createGrievanceFailure(error.response.data));
        dispatch(openSnackBar(error.response.data.message, "error"));
      } else {
        dispatch(createGrievanceFailure(error.toString()));
        dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
      }
    }
  };
