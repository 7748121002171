import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { addCommitment, updCommitment, delCommitment } from "./CommitmentSlice";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { IppFormHeader } from "components/IppFormHeader";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppTextField } from "components/IppTextField";
import LoadingIndicator from "components/LoadingIndicator";
import { Prompt } from "react-router";
import { IppChildInventory } from "components/Inventory/IppChildInventory";
import { IppAttachmentInventory } from "components/IppAttachmentInventory";
import {
  ConvertDateOffset,
  ConvertToJSDate,
  GetJSDate,
} from "../../../utils/DateFunctions";
import { IppAutocomplete } from "components/IppAutocomplete";
import { IppDisplayField } from "components/IppDisplayField";
import { fetchCommitmentActionsByCommitment } from "../commitmentAction/CommitmentActionSlice";
import { fetchCommitmentSources } from "../commitmentSource/CommitmentSourceSlice";
import { fetchCommitmentSourceAmendments } from "../commitmentSourceAmendment/CommitmentSourceAmendmentSlice";
import { fetchClientCommitmentStatusTypes } from "../../platform/admin/clientCommitmentStatusType/ClientCommitmentStatusTypeSlice";
import { fetchCommitmentCategories } from "../commitmentCategory/CommitmentCategorySlice";
import { fetchClientCommitmentCategories } from "features/platform/admin/clientCommitmentCategories/ClientCommitmentCategorySlice";
import { fetchUsers } from "features/users/UsersSlice";
import { fetchStakeholderGroups } from "features/platform/groups/StakeholderGroupSlice";
import { IppRichTextEditor } from "components/RichTextEditor/IppRichTextEditor";
import { IppDisplayRichText } from "components/IppDisplayRichText";
import { fetchDeleteChecksByCommitment } from "features/deleteCheck/DeleteCheckSlice";
import { SanitizeHTML } from "utils/customXSS";
import { ValidationSchema } from "./CommitmentFormValidation";
import { IppDatePicker } from "components/IppDatePicker";
import { countAttachments } from "api/attachmentsAPI";
import { IppPersonResponsible } from "components/IppPersonResponsible";
import { fetchContacts } from "features/platform/contacts/ContactSlice";
import { IsMedSmall } from "utils/mediaQueries";
import { IppMultiTextField } from "components/IppMultiTextField";
import {
  addCommitmentInteraction,
  fetchCommitmentInteractionsByCommitment,
} from "features/crossModule/stakeholderCommitment/commitmentInteractionSlice";
import { fetchInteractions } from "features/stakeholder/interaction/InteractionSlice";
import { Interaction } from "api/stakeholder/interactionAPI";
import { CommitmentInteraction } from "api/crossModule/stakeholderCommitment/commitmentInteractionAPI";
import { IppTabPanel } from "components/IppTabPanel";
import { fetchPhases } from "features/platform/admin/phase/PhaseSlice";
import { useRoleChecks, useTypedTranslation } from "utils/customHooks";
import { IppTokenMultiSelect } from "components/IppTokenMultiSelect";
import { fetchCommitmentPhasesByCommitment } from "../commitmentPhase/CommitmentPhaseSlice";
import { IppDisplayChip } from "components/IppDisplayChip";
import { IppFormDivider } from "components/IppFormDivider";
import { PersonRenderOption } from "utils/renderFunctions";
import { Contact } from "api/stakeholder/contactAPI";
import { fetchPriorities } from "features/datalists/PrioritySlice";
import { IppDisplayPriority } from "components/IppDisplayPriority";
import { fetchTagsByRecordType } from "features/platform/admin/tag/TagSlice";
import { fetchStandardStatements } from "features/platform/admin/standardStatement/StandardStatementSlice";
import { usePermissions } from "utils/permissions/usePermissions";
import {
  CommitmentsActionCreateResource,
  CommitmentsCommitmentDeleteResource,
  CommitmentsCommitmentEditResource,
} from "utils/types/index.types";
import { isCompletedCommitmentStatus } from "utils/commitments/commitmentsFunctions";
import { RecordHistoryDisplay } from "components/Reusable Utils/RecordHistoryDisplay";
import IppCollapsibleHeaders from "components/IppCollapsibleHeaders";
import { getCommitmentStatusChips } from "components/Inventory/inventoryStatusChips";
import useOverallResponsibilities from "components/CommitmentResponsibility";
import { IppTokenAutocomplete } from "components/IppTokenAutoComplete";
import { fetchCommitmentGroupsByCommitment } from "../commitmentGroup/commitmentGroupSlice";
import IppCloseButton from "components/Buttons/IppCloseButton";

const PREFIX = "CommitmentForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    maxWidth: 1150,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const CommitmentForm = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { isActionAllowed } = usePermissions();
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);
  const OverallResponsibilities = useOverallResponsibilities();
  const customBP = IsMedSmall();
  const [groupList, setGroupList] = useState<Array<string>>([]);
  const [listInteractions, setListInteractions] = useState<Interaction>();
  const [didSaveInventory, setDidSaveInventory] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  // used to display attachments count
  const [attachCount, setAttachCount] = useState({ Count: 0 } as any);

  let itemData = props.commitment || {};

  const [tagItems, setTagItems] = useState(
    itemData.Tags ? itemData.Tags : undefined
  );

  const [isEditing, setIsEditing] = useState(
    itemData.CommitmentID ? false : true
  );

  const [isAdding, setIsAdding] = useState(
    itemData.CommitmentID ? false : true
  );

  const [commitmentSourceID, setCommitmentSourceID] = useState(
    itemData.CommitmentSourceID || 0
  );

  //------------------Define Permissions------------------
  const editPermissionsResource: CommitmentsCommitmentEditResource = {
    module: "Commitments",
    type: "Commitment",
    action: "edit",
    projectID: itemData.ProjectID,
    personResponsible: itemData.PersonResponsible,
  };

  const canEdit = useMemo(
    () => isActionAllowed(editPermissionsResource),
    [editPermissionsResource]
  );

  const deletePermissionsResource: CommitmentsCommitmentDeleteResource = {
    module: "Commitments",
    type: "Commitment",
    action: "delete",
    projectID: itemData.ProjectID,
    personResponsible: itemData.PersonResponsible,
  };

  const canDelete = useMemo(
    () => isActionAllowed(deletePermissionsResource),
    [deletePermissionsResource]
  );

  const createActionPermissionsResource: CommitmentsActionCreateResource = {
    module: "Commitments",
    type: "Action",
    action: "create",
    projectID: itemData.ProjectID,
    parent: itemData,
  };

  const canCreateAction = useMemo(
    () => isActionAllowed(createActionPermissionsResource),
    [createActionPermissionsResource]
  );
  //------------------------------------------------------

  const {
    clientId,
    isLoading: clientIsLoading,
    ft_all_ExternalPersonRes,
    ft_comeng_CommitmentInteraction,
    ft_com_Phase,
    ft_all_Tag,
    ft_com_Priority,
    ft_com_DateApplicable,
    ft_com_InternalNotes,
    ft_com_RegulatoryNotes,
    ft_com_AssociatedContacts,
    ft_com_AssociatedGroups,
  } = useSelector((state: RootState) => state.client);

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const { phaseList, phasesById } = useSelector(
    (state: RootState) => state.phase
  );
  const phases = phaseList.map((id) => phasesById[id]);

  const {
    commitmentPhaseList,
    commitmentPhasesById,
    isLoading: commPhaseIsLoading,
  } = useSelector((state: RootState) => state.commitmentPhases);

  const CommPhase = commitmentPhaseList.map((id) => commitmentPhasesById[id]);

  const {
    commitmentActionList,
    commitmentActionsById,
    isLoading: commitmentActionIsLoading,
  } = useSelector((state: RootState) => state.commitmentActions);

  const commitmentActions = commitmentActionList.map(
    (id) => commitmentActionsById[id]
  );

  const data = commitmentActions.map((p) => {
    let n = Object.assign({}, p) as any;
    if (p.RequiredDate !== null) {
      n.RequiredDate = ConvertDateOffset(p.RequiredDate);
    } else {
      n.RequiredDate = null;
    }
    if (p.CompletionDate !== null) {
      n.CompletionDate = ConvertDateOffset(p.CompletionDate);
    } else {
      n.CompletionDate = null;
    }
    return n;
  });

  const {
    commitmentCategoryList,
    commitmentCategoriesById,
    isLoading: commitmentCategoryIsLoading,
  } = useSelector((state: RootState) => state.commitmentCategories);

  const commitmentCategories = commitmentCategoryList.map(
    (id) => commitmentCategoriesById[id]
  );

  const {
    clientCommitmentCategoryList,
    clientCommitmentCategoriesById,
    isLoading: clientCommitmentCategoryIsLoading,
  } = useSelector((state: RootState) => state.clientCommitmentCategories);

  const clientCommitmentCategories = clientCommitmentCategoryList.map(
    (id) => clientCommitmentCategoriesById[id]
  );

  const {
    clientCommitmentStatusTypeList,
    clientCommitmentStatusTypesById,
    isLoading: clientCommitmentStatusTypesIsLoading,
  } = useSelector((state: RootState) => state.clientCommitmentStatusTypes);

  const clientCommitmentStatusTypes = clientCommitmentStatusTypeList.map(
    (id) => clientCommitmentStatusTypesById[id]
  );

  const {
    priorityList,
    prioritiesById,
    isLoading: priorityIsLoading,
  } = useSelector((state: RootState) => state.priorities);

  const priorities = priorityList.map((id) => prioritiesById[id]);

  const {
    getCommitmentsUserRoles,
    hasContributorRoleForProject,
    getEngagementsPowerUserRoles,
    hasCommitmentInteractionRoles,
  } = useRoleChecks(itemData.ProjectID ?? null);

  const contributorUserRoles = getCommitmentsUserRoles();
  const isContributorForProject = hasContributorRoleForProject();
  const engagementsPowerUserRoles = getEngagementsPowerUserRoles();
  const isCommitmentInteractionUser = hasCommitmentInteractionRoles();

  // list of project IDs available in this Module
  const accessibleProjectIDs = contributorUserRoles.map(
    (role) => role.ProjectID
  );

  const {
    projectList,
    projectsById,
    isLoading: projectIsLoading,
  } = useSelector((state: RootState) => state.projects);

  // if admin, show all projects
  // if non-admin, only show projects included in accessibleProjectIds
  const projects = currentProfile.IsClientAdmin
    ? projectList.map((id) => projectsById[id])
    : projectList
        .map((id) => projectsById[id])
        .filter((project) => accessibleProjectIDs.includes(project.ProjectID));

  const {
    commitmentSourceList,
    commitmentSourcesById,
    isLoading: commitmentSourceIsLoading,
  } = useSelector((state: RootState) => state.commitmentSources);

  const commitmentSources = commitmentSourceList.map(
    (id) => commitmentSourcesById[id]
  );

  const { commitmentSourceAmendmentList, commitmentSourceAmendmentsById } =
    useSelector((state: RootState) => state.commitmentSourceAmendments);

  const amendments = commitmentSourceAmendmentList.map(
    (id) => commitmentSourceAmendmentsById[id]
  );

  const {
    tagList,
    tagsById,
    isLoading: tagIsLoading,
  } = useSelector((state: RootState) => state.tag);

  const tags = ft_all_Tag ? tagList.map((id) => tagsById[id]) : [];

  // useEffect for when source id changes
  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        if (commitmentSourceID > 0) {
          dispatch(
            fetchCommitmentSourceAmendments(accessToken, commitmentSourceID)
          );
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [commitmentSourceID, dispatch, getAccessTokenSilently]);

  const { userList, usersById } = useSelector(
    (state: RootState) => state.users
  );

  const allUsers = userList.map((id) => usersById[id]);

  //Used to populate Associated Groups field with saved groups in edit/view forms
  const {
    commitmentGroupList,
    commitmentGroupsById,
    isLoading: commitmentGroupIsLoading,
  } = useSelector((state: RootState) => state.commitmentGroups);

  const commitmentGroups = commitmentGroupList.map(
    (id) => commitmentGroupsById[id]
  );

  //Used to populate the Associated Groups dropdown
  const {
    stakeholderGroupList,
    stakeholderGroupsById,
    isLoading: groupIsLoading,
  } = useSelector((state: RootState) => state.stakeholderGroups);

  const stakeholderGroups = stakeholderGroupList.map(
    (id) => stakeholderGroupsById[id]
  );

  //usestate for Rootstate of attachment
  const { attachmentsById, attachmentList } = useSelector(
    (state: RootState) => state.attachments
  );

  const attachments = attachmentList.map((id) => attachmentsById[id]);

  const {
    contactList,
    contactsById,
    isLoading: contactIsLoading,
  } = useSelector((state: RootState) => state.contacts);

  const {
    commitmentInteractionList,
    commitmentInteractionsByID,
    isLoading: commitmentInteractionIsLoading,
  } = useSelector((state: RootState) => state.commitmentInteractions);

  const { interactionList, interactionsById } = useSelector(
    (state: RootState) => state.interactions
  );

  let showCommitmentInteractions =
    ft_comeng_CommitmentInteraction &&
    (currentProfile.IsClientAdmin || isCommitmentInteractionUser)
      ? true
      : false;

  let commitmentInteractions: CommitmentInteraction[] = [];
  let interactions: Interaction[] = [];
  let interactionsFiltered: Interaction[] = [];

  if (showCommitmentInteractions) {
    const commitmentInteractionsUnfiltered = commitmentInteractionList.map(
      (id: number) => commitmentInteractionsByID[id]
    );

    const commitmentInteractionsFiltered =
      commitmentInteractionsUnfiltered.filter(
        (commitmentInteractions) =>
          commitmentInteractions.CommitmentID === itemData.CommitmentID
      );

    // Create list of current interactions on this Commitment
    commitmentInteractions = commitmentInteractionsFiltered.map(
      (commitmentInteraction) => {
        let newObj = Object.assign({}, commitmentInteraction) as any;
        if (commitmentInteraction.InteractionDate !== null) {
          newObj.InteractionDate = ConvertDateOffset(
            commitmentInteraction.InteractionDate
          );
        } else {
          newObj.InteractionDate = null;
        }

        return newObj;
      }
    );

    const commitIntsInteractionIDs = commitmentInteractions.map(
      (commitInt) => commitInt.InteractionID
    );

    // Create list of all available interactions
    interactions = interactionList.map((id) => {
      let displayInteraction = Object.assign({}, interactionsById[id]) as any;
      displayInteraction.DisplayValue = `C-${displayInteraction.CommunicationID} ${displayInteraction.InteractionTitle}`;
      return displayInteraction;
    });

    interactionsFiltered = interactions.filter((interaction) => {
      if (commitIntsInteractionIDs.includes(interaction.InteractionID)) {
        return false;
      }

      if (!currentProfile.IsClientAdmin) {
        return engagementsPowerUserRoles.some((role: any) => {
          return role.ProjectID === interaction.ProjectID;
        });
      }

      return true;
    });
  }

  const contacts = contactList.map((id) => contactsById[id]);

  const { isLoading: standardStatementIsLoading } = useSelector(
    (state: RootState) => state.standardStatement
  );

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        dispatch(fetchCommitmentSources(accessToken));
        dispatch(fetchClientCommitmentStatusTypes(accessToken));
        dispatch(fetchCommitmentCategories(accessToken));
        dispatch(fetchClientCommitmentCategories(accessToken));
        dispatch(fetchStandardStatements(accessToken));
        dispatch(fetchUsers(accessToken, clientId));
        if (ft_com_AssociatedGroups) {
          dispatch(fetchStakeholderGroups(accessToken));
        }
        if (!isAdding && ft_com_AssociatedGroups) {
          dispatch(
            fetchCommitmentGroupsByCommitment(
              accessToken,
              itemData.CommitmentID
            )
          );
        }
        if (ft_com_AssociatedContacts || ft_all_ExternalPersonRes) {
          dispatch(fetchContacts(accessToken));
        }
        if (ft_com_Priority) {
          dispatch(fetchPriorities(accessToken));
        }
        if (ft_com_Phase) {
          dispatch(fetchPhases(accessToken));
          if (itemData.CommitmentID) {
            dispatch(
              fetchCommitmentPhasesByCommitment(
                accessToken,
                itemData.CommitmentID
              )
            );
          }
        }
        if (ft_comeng_CommitmentInteraction) {
          dispatch(fetchInteractions(accessToken));
          if (itemData.CommitmentID) {
            dispatch(
              fetchCommitmentInteractionsByCommitment(
                accessToken,
                itemData.CommitmentID
              )
            );
          }
        }
        if (ft_all_Tag) {
          dispatch(fetchTagsByRecordType(accessToken, "Commitment"));
        }
        if (itemData.CommitmentID) {
          dispatch(
            fetchCommitmentActionsByCommitment(
              accessToken,
              itemData.CommitmentID
            )
          );

          const count = await countAttachments(
            accessToken,
            "Commitments",
            itemData.CommitmentID
          );
          setAttachCount(count.attachments ? count.attachments : 0);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, itemData.CommitmentID, dispatch, getAccessTokenSilently]);

  // save when changes made to child records
  useEffect(() => {
    if (didSaveInventory) {
      if (ft_comeng_CommitmentInteraction && listInteractions) {
        linkInteraction(listInteractions);
      }
      submitFunc(itemData);
      setDidSaveInventory(false);
    }
    return () => {
      // cleanup
    };
  }, [didSaveInventory]);

  useEffect(() => {
    if (itemData.CommitmentID && !commitmentGroupIsLoading) {
      // Initial load when not editing or when groups haven't been loaded yet during editing
      if (!isEditing || (isEditing && groupList.length === 0)) {
        const newList: string[] = commitmentGroups.map(
          (item) => item.GroupName
        );
        setGroupList(newList);
      }
    }

    return () => {
      // Cleanup
    };
  }, [itemData.CommitmentID, commitmentGroupIsLoading, isEditing]);

  // used for attachment count
  useEffect(() => {
    (async () => {
      try {
        if (attachments.length > 0) {
          const attach = attachments.filter((item) => {
            return (
              item.RecordID === itemData.CommitmentID &&
              item.RecordType === "Commitments"
            );
          });
          if (attach && attach.length > 0)
            setAttachCount({ Count: attach.length });
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [attachmentList]);

  // convert tags to objects for multi autocomplete field
  useEffect(() => {
    // effect
    if (
      ft_all_Tag &&
      tags &&
      tags.length > 0 &&
      !tagIsLoading &&
      itemData.Tags
    ) {
      let newList = tags.filter((item) => itemData.Tags.includes(item.Name));
      setTagItems(newList);
    }
    return () => {
      // cleanup
    };
  }, [tagIsLoading, isEditing]);

  const linkInteraction = (interaction: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        let newCommitmentInteraction = {
          CommitmentID: itemData.CommitmentID,
          InteractionID: interaction.InteractionID,
        };

        dispatch(
          addCommitmentInteraction(
            accessToken,
            newCommitmentInteraction,
            true,
            itemData.CommitmentID
          )
        );
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        const phases = values.CommitmentPhase || []; // Retrieve phases from form values
        dispatch(addCommitment(accessToken, values, true, groupList, phases));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  if (itemData.CommitmentID) {
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          const phases = values.CommitmentPhase || []; // Retrieve phases from form values
          dispatch(
            updCommitment(
              accessToken,
              values.CommitmentID,
              values,
              true,
              null, // ParentInteractionID
              groupList,
              phases
            )
          );

          setIsEditing(false);
        } catch (e) {
          console.error(e);
        }
      })();
    };
  } else {
    //let thisDate = ConvertDateOffset(new Date());
    let thisDate = new Date();
    itemData.CommitmentTitle = "";
    itemData.CommitmentDetails = "";
    itemData.RecordedDate = thisDate.toISOString().slice(0, 10);
    itemData.ApplicableDate = thisDate.toISOString().slice(0, 10);
    itemData.CommitmentStatusTypeID = -1;
    itemData.CommitmentSourceID = -1;
    itemData.CommitmentReference = "";
    itemData.CommitmentCategoryID = -1;
    itemData.OverallResponsibility = "";
    itemData.ProjectID = -1;
    itemData.PersonResponsible = currentProfile.UserAccountID;
    itemData.PersonResponsibleName =
      currentProfile.FirstName + " " + currentProfile.Surname;
    itemData.LegalText = "";
    itemData.ContactID = null;
    itemData.CommitmentPhase = [];
    itemData.AssociatedContactID = null;
    itemData.AmendmentID = -1;
    itemData.InternalNotes = "";
    itemData.PriorityID = null;
    itemData.Tags = [];
    itemData.CompletedDate = null;
  }

  const handleDelete = () => {
    // function to delete current community investment entry
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(delCommitment(accessToken, itemData.CommitmentID));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const handleTabSelect = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };

  const namePhaseString = CommPhase.map((phase: any) => phase.Name).join(",");

  const formik = useFormik({
    initialValues: itemData,
    validationSchema: ValidationSchema(),
    onSubmit: submitFunc,
  });

  const contact = contacts.find((obj) => {
    return obj.ContactID === formik.values.ContactID;
  });

  const associatedContact = contacts.find((obj) => {
    return obj.ContactID === formik.values.AssociatedContactID;
  });

  let viewForm = (
    <Box display="flex" justifyContent="center">
      <Grid container className={classes.editForm} spacing={1}>
        <Grid item xs={12}>
          <Paper className={classes.boxSpace}>
            <Grid container spacing={1}>
              <IppFormHeader
                title={t("objCom:objects.commitment.name")}
                isEditing={isEditing}
                isAdding={isAdding}
              />
              <Grid item xs={12}>
                <IppDisplayPriority
                  label={t("objCom:objects.commitment.name")}
                  showLabel={false}
                  value={formik.values.CommitmentTitle}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={canEdit}
                  priorityValue={ft_com_Priority && formik.values.PriorityID}
                  showEmphasis={formik.values.CommitmentTitle?.length < 100}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objPlt:platformwide.fields.daterecorded")}
                  value={ConvertToJSDate(formik.values.RecordedDate)}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={canEdit}
                />
              </Grid>
              {ft_com_DateApplicable && (
                <Grid item xs={6}>
                  <IppDisplayField
                    label={t("objCom:objects.commitment.fields.dateapplicable")}
                    value={ConvertToJSDate(formik.values.ApplicableDate)}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={canEdit}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <IppDisplayChip
                  label={t("objCom:objects.commitment.fields.status")}
                  value={formik.values.CommitmentStatusTypeName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={canEdit}
                  statusChip={(status: string | null) =>
                    status ? (
                      getCommitmentStatusChips({
                        id: formik.values.CommitmentStatusTypeID,
                        label: status,
                      })
                    ) : (
                      <></>
                    )
                  }
                />
              </Grid>
              {isCompletedCommitmentStatus(
                clientCommitmentStatusTypes,
                itemData.CommitmentStatusTypeID
              ) && (
                <Grid item xs={6}>
                  <IppDisplayField
                    label={t("objCom:objects.commitment.fields.datecompleted")}
                    value={ConvertToJSDate(formik.values.CompletedDate)}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={canEdit}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objCom:objects.commitment.fields.category")}
                  value={formik.values.CommitmentCategoryName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={canEdit}
                />
              </Grid>
              {ft_all_Tag && tags.length > 0 && (
                <Grid item xs={6}>
                  <IppDisplayChip
                    label={t("objPlt:objects.tag.name", {
                      count: itemData.Tags ? itemData.Tags.length : 1,
                    })}
                    value={itemData.Tags}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={canEdit}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <IppDisplayField
                  label={t("objCom:objects.commitmentsource.name", {
                    sourcecount: 1,
                  })}
                  value={formik.values.CommitmentSourceName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  isWebLink={
                    !isContributorForProject &&
                    formik.values.CommitmentSourceName
                      ? true
                      : false
                  }
                  route={`${window.location.href.substring(
                    0,
                    window.location.href.lastIndexOf("commitments")
                  )}sources/${formik.values.CommitmentSourceID}`}
                  openInNewTab={false}
                  showFooter={false}
                  showEdit={isContributorForProject ? false : canEdit}
                />
                <IppDisplayField
                  label=""
                  showLabel={false}
                  value={formik.values.CommitmentReference}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={false}
                />
              </Grid>
              {itemData.AmendmentID > 0 &&
                commitmentSourceAmendmentsById[itemData.AmendmentID] && (
                  <Grid item xs={6}>
                    <IppDisplayField
                      label={t(
                        "objCom:objects.commitment.fields.sourceamendment"
                      )}
                      value={
                        commitmentSourceAmendmentsById[itemData.AmendmentID]
                          .AmendmentName
                      }
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      showEdit={isContributorForProject ? false : canEdit}
                    />
                  </Grid>
                )}

              <Typography
                variant="subtitle1"
                color="black"
                sx={{ fontWeight: "500" }}
                style={{ marginBottom: "8px", marginLeft: "8px" }}
              >
                Click on the headers below for more details:
              </Typography>

              {(ft_com_AssociatedGroups || ft_com_AssociatedContacts) && (
                <IppCollapsibleHeaders
                  title={t(
                    "objCom:objects.commitment.headers.relatedparty_other"
                  )}
                >
                  {ft_com_AssociatedGroups && (
                    <Grid item xs={6} style={{ paddingLeft: "16px" }}>
                      <IppDisplayChip
                        label={t(
                          "objCom:objects.commitment.fields.associatedgroups"
                        )}
                        value={groupList.map((opt) => opt).join(",")}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        showEdit={canEdit}
                      />
                    </Grid>
                  )}

                  {ft_com_AssociatedContacts && (
                    <Grid item xs={6} style={{ paddingLeft: "16px" }}>
                      <IppDisplayField
                        label={t(
                          "objPlt:objects.contact.fields.associatedcontact",
                          {
                            count: 1,
                          }
                        )}
                        value={
                          associatedContact?.ContactName?.trim() !== ""
                            ? associatedContact?.ContactName
                            : `* ${t(
                                "objPlt:objects.contact.missingname"
                              )} * (${
                                associatedContact?.Email &&
                                associatedContact?.Email?.trim() !== ""
                                  ? associatedContact?.Email
                                  : t("objPlt:objects.contact.missingemail")
                              })`
                        }
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        showEdit={canEdit}
                      />
                    </Grid>
                  )}
                </IppCollapsibleHeaders>
              )}

              <IppCollapsibleHeaders
                title={t("objCom:objects.commitment.headers.projectdetails")}
              >
                <Grid item xs={6} style={{ paddingLeft: "16px" }}>
                  <IppDisplayField
                    label={t("objPlt:objects.project.name")}
                    value={formik.values.ProjectName}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={canEdit}
                  />
                </Grid>
                {ft_com_Phase && (
                  <Grid item xs={6}>
                    <IppDisplayChip
                      label={t("objPlt:platformwide.phase.name", {
                        count: namePhaseString.split(",").length,
                      })}
                      value={namePhaseString}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      showEdit={canEdit}
                    />
                  </Grid>
                )}
              </IppCollapsibleHeaders>

              <IppCollapsibleHeaders
                title={t("objCom:objects.commitment.headers.commitmentowner")}
              >
                <Grid item xs={6} style={{ paddingLeft: "16px" }}>
                  <IppDisplayField
                    label={t(
                      "objCom:objects.commitment.fields.overallresponsibility"
                    )}
                    value={formik.values.OverallResponsibility}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={canEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <IppDisplayField
                    label={t(
                      "objCom:objects.commitment.fields.personresponsible"
                    )}
                    value={formik.values.PersonResponsibleName}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={isContributorForProject ? false : canEdit}
                  />
                </Grid>
                {ft_all_ExternalPersonRes && (
                  <Grid item xs={6} style={{ paddingLeft: "16px" }}>
                    <IppDisplayField
                      label={t(
                        "objCom:objects.commitment.fields.additionalpersonresponsible"
                      )}
                      value={contact?.ContactName}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      showEdit={canEdit}
                    />
                  </Grid>
                )}
              </IppCollapsibleHeaders>

              <Grid item xs={12} style={{ height: "20px" }} />

              <Grid item xs={12}>
                <IppDisplayRichText
                  label={t("objCom:objects.commitment.fields.details")}
                  value={SanitizeHTML(formik.values.CommitmentDetails)}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  height="4.5em"
                  showEdit={canEdit}
                />
              </Grid>
              {ft_com_InternalNotes && (
                <Grid item xs={12}>
                  <IppDisplayRichText
                    label={t("objCom:objects.commitment.fields.internalnotes")}
                    value={formik.values.InternalNotes}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={canEdit}
                  />
                </Grid>
              )}
              {ft_com_RegulatoryNotes && (
                <Grid item xs={12}>
                  <IppDisplayRichText
                    label={t(
                      "objCom:objects.commitment.fields.regulatorynotes"
                    )}
                    value={formik.values.RegulatoryNotes}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={canEdit}
                  />
                </Grid>
              )}
              <RecordHistoryDisplay
                createdBy={formik.values.CreatedBy}
                createDate={formik.values.CreateDate}
                lastUpdated={formik.values.LastUpdated}
                lastUpdatedBy={formik.values.LastUpdatedBy}
              />
              <Grid item xs={12}>
                <IppFormButtons
                  isEditing={isEditing}
                  isAdding={isAdding}
                  setIsEditing={setIsEditing}
                  resetFunction={() => formik.resetForm()}
                  showDelete={canDelete}
                  deleteFunction={() => handleDelete()}
                  fetchFunc={fetchDeleteChecksByCommitment}
                  info={t("strGen:prompts.deletechecks.unlinkitems")}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );

  let editForm = clientIsLoading ? (
    <LoadingIndicator />
  ) : (
    <form noValidate onSubmit={formik.handleSubmit}>
      {!formik.isSubmitting && (
        <Prompt
          when={formik.dirty}
          message={t("strGen:prompts.unsavedchanges")}
        />
      )}

      <Grid container spacing={1}>
        <IppFormHeader
          title={t("objCom:objects.commitment.name")}
          isEditing={isEditing}
          isAdding={isAdding}
        />
        <Grid item xs={12}>
          <IppTextField
            id="CommitmentTitle"
            label={t("objCom:objects.commitment.name")}
            required={true}
            value={formik.values.CommitmentTitle}
            onChangeFunction={formik.handleChange}
            touchedExpression={formik.touched.CommitmentTitle}
            errorsExpression={formik.errors.CommitmentTitle}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            multiLine={true}
          />
        </Grid>
        {ft_com_Priority && (
          <Grid item xs={6}>
            <IppAutocomplete
              id="PriorityID"
              options={priorities}
              required={false}
              value={priorities.find((obj) => {
                return obj.PriorityID === formik.values.PriorityID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("PriorityID", newValue.PriorityID);
                } else {
                  formik.setFieldValue("PriorityID", null);
                }
              }}
              label={t("objCom:objects.commitment.fields.priority")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.PriorityName}
              errorFunction={formik.errors.PriorityID}
              touchedFunction={formik.touched.PriorityID}
              textValueFunction={
                priorities.find(
                  (priority) => priority.PriorityID === formik.values.PriorityID
                )?.PriorityName
              }
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <IppDatePicker
            id="RecordedDate"
            required={true}
            label={t("objPlt:platformwide.fields.daterecorded")}
            value={ConvertDateOffset(formik.values.RecordedDate)}
            onChangeFunction={(newValue: any) => {
              formik.setFieldValue("RecordedDate", GetJSDate(newValue), true);
              formik.setFieldTouched("RecordedDate", true, true);
            }}
            errorsExpression={formik.errors.RecordedDate}
            touchedExpression={formik.touched.RecordedDate}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        {ft_com_DateApplicable && (
          <Grid item xs={6}>
            <IppDatePicker
              id="ApplicableDate"
              required={false}
              label={t("objCom:objects.commitment.fields.dateapplicable")}
              value={ConvertDateOffset(formik.values.ApplicableDate)}
              onChangeFunction={(newValue: any) => {
                formik.setFieldValue(
                  "ApplicableDate",
                  GetJSDate(newValue),
                  true
                );
                formik.setFieldTouched("ApplicableDate", true, true);
              }}
              errorsExpression={formik.errors.ApplicableDate}
              touchedExpression={formik.touched.ApplicableDate}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              toolTip={t(
                "objCom:objects.commitment.fields.dateapplicabletooltip"
              )}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <IppAutocomplete
            id="CommitmentStatusTypeID"
            required={true}
            options={clientCommitmentStatusTypes}
            value={clientCommitmentStatusTypes.find((obj) => {
              return (
                obj.CommitmentStatusTypeID ===
                formik.values.CommitmentStatusTypeID
              );
            })}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              const commitmentStatusTypeID = newValue
                ? newValue.CommitmentStatusTypeID
                : -1;
              formik.setFieldValue(
                "CommitmentStatusTypeID",
                commitmentStatusTypeID
              );

              if (
                !isCompletedCommitmentStatus(
                  clientCommitmentStatusTypes,
                  commitmentStatusTypeID
                ) &&
                formik.values.CompletedDate
              ) {
                formik.setFieldValue("CompletedDate", null);
              }
            }}
            label={t("objCom:objects.commitment.fields.status")}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            optionLabelFunction={(option: any) =>
              option.CommitmentStatusTypeName
            }
            errorFunction={formik.errors.CommitmentStatusTypeID}
            touchedFunction={formik.touched.CommitmentStatusTypeID}
            textValueFunction={formik.values.CommitmentStatusTypeName}
          />
        </Grid>
        {isCompletedCommitmentStatus(
          clientCommitmentStatusTypes,
          formik.values.CommitmentStatusTypeID
        ) && (
          <Grid item xs={6}>
            <IppDatePicker
              id="CompletedDate"
              required={false}
              label={t("objCom:objects.commitment.fields.datecompleted")}
              value={ConvertDateOffset(formik.values.CompletedDate)}
              onChangeFunction={(newValue: any) => {
                formik.setFieldValue(
                  "CompletedDate",
                  GetJSDate(newValue),
                  true
                );
                formik.setFieldTouched("CompletedDate", true, true);
              }}
              errorsExpression={formik.errors.CompletedDate}
              touchedExpression={formik.touched.CompletedDate}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <IppAutocomplete
            id="CommitmentCategoryID"
            options={clientCommitmentCategories}
            required={true}
            value={commitmentCategories.find((obj) => {
              return (
                obj.CommitmentCategoryID === formik.values.CommitmentCategoryID
              );
            })}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                formik.setFieldValue(
                  "CommitmentCategoryID",
                  newValue.CommitmentCategoryID
                );
              } else {
                formik.setFieldValue("CommitmentCategoryID", -1);
              }
            }}
            label={t("objCom:objects.commitment.fields.category")}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            optionLabelFunction={(option: any) => option.CommitmentCategoryName}
            errorFunction={formik.errors.CommitmentCategoryID}
            touchedFunction={formik.touched.CommitmentCategoryID}
            textValueFunction={formik.values.CommitmentCategoryName}
          />
        </Grid>
        <Grid item xs={6}>
          <IppAutocomplete
            id="CommitmentSourceID"
            options={commitmentSources}
            required={true}
            value={commitmentSources.find((obj) => {
              return (
                obj.CommitmentSourceID === formik.values.CommitmentSourceID
              );
            })}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                setCommitmentSourceID(newValue.CommitmentSourceID);
                formik.setFieldValue(
                  "CommitmentSourceID",
                  newValue.CommitmentSourceID
                );
              } else {
                setCommitmentSourceID(-1);
                formik.setFieldValue("CommitmentSourceID", -1);
              }
            }}
            label={t("objCom:objects.commitmentsource.name", {
              sourcecount: 1,
            })}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            optionLabelFunction={(option: any) => option.CommitmentSourceName}
            errorFunction={formik.errors.CommitmentSourceID}
            touchedFunction={formik.touched.CommitmentSourceID}
            textValueFunction={formik.values.CommitmentSourceName}
            toolTip={
              !isContributorForProject
                ? "To select a Source, it must first be created under the Sources menu"
                : undefined
            }
            disabled={isContributorForProject}
          />
        </Grid>
        <Grid item xs={6}>
          <IppMultiTextField
            id="CommitmentReference"
            label={t("objCom:objects.commitment.fields.reference")}
            required={false}
            options={[]}
            noOptionsText={t("strGen:components.nooptionstext")}
            value={formik.values.CommitmentReference}
            setValue={(newValue: any) =>
              formik.setFieldValue("CommitmentReference", newValue.toString())
            }
            touchedFunction={formik.touched.CommitmentReference}
            errorFunction={formik.errors.CommitmentReference}
            isEditing={true}
            setIsEditing={null}
            multiple={true}
            autoPopulate={false}
            freeSolo={true}
          />
        </Grid>
        {amendments.length > 0 && ( // Check if there are amendments available
          <Grid item xs={6}>
            <IppAutocomplete
              id="AmendmentID"
              options={amendments}
              value={amendments.find((obj) => {
                return obj.AmendmentID === formik.values.AmendmentID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("AmendmentID", newValue.AmendmentID);
                } else {
                  formik.setFieldValue("AmendmentID", -1);
                }
              }}
              label={t("objCom:objects.commitment.fields.sourceamendment")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.AmendmentName}
              errorFunction={formik.errors.AmendmentID}
              touchedFunction={formik.touched.AmendmentID}
              textValueFunction={formik.values.AmendmentName}
              autoPopulate={false}
              disabled={isContributorForProject}
            />
          </Grid>
        )}

        {ft_com_AssociatedGroups && (
          <Grid item xs={6}>
            <Grid item xs={12}>
              <IppTokenAutocomplete
                id="GroupList"
                label={t("objCom:objects.commitment.fields.associatedgroups", {
                  count: 2,
                })}
                options={stakeholderGroups.map((option) => option.GroupName)}
                selectedValues={groupList}
                setSelectedValues={(newValue: any) => {
                  setGroupList(newValue);
                }}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                //Prop that prevents contributors from creating new groups
                preventCreate={isContributorForProject}
              />
            </Grid>
          </Grid>
        )}

        {ft_com_AssociatedContacts && (
          <Grid item xs={6}>
            <IppAutocomplete
              id="AssociatedContactID"
              options={contacts}
              renderOption={(props: any, option: Contact) =>
                PersonRenderOption(
                  props,
                  option,
                  [
                    option.Email,
                    option.FirstGroupName,
                    option.PrimaryPhone,
                    option.ContactTitle,
                  ],
                  `* ${t("objPlt:objects.contact.missingname")} *`
                )
              }
              value={contacts.find((obj) => {
                return obj.ContactID === formik.values.AssociatedContactID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue(
                    "AssociatedContactID",
                    newValue.ContactID
                  );
                } else {
                  formik.setFieldValue("AssociatedContactID", null);
                }
              }}
              label={t("objPlt:objects.contact.fields.associatedcontact", {
                count: 1,
              })}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) =>
                option.ContactName?.trim() !== ""
                  ? option.ContactName
                  : `* ${t("objPlt:objects.contact.missingname")} * (${
                      option.Email && option.Email?.trim() !== ""
                        ? option.Email
                        : t("objPlt:objects.contact.missingemail")
                    })`
              }
              errorFunction={formik.errors.AssociatedContactID}
              touchedFunction={formik.touched.AssociatedContactID}
              textValueFunction={formik.values.ContactName}
            />
          </Grid>
        )}

        {ft_all_Tag && tags.length > 0 && (
          <Grid item xs={6}>
            <IppTokenMultiSelect
              id="Tags"
              label={t("objPlt:objects.tag.name_other", {
                count: formik.values.Tags ? formik.values.Tags.length : 1,
              })}
              required={false}
              options={tags}
              noOptionsText={t("strGen:components.nooptionstext")}
              value={tagItems}
              setValue={(newValue: any) => {
                setTagItems(newValue);
                formik.setFieldValue("Tags", newValue);
              }}
              touchedFunction={formik.touched.Tags}
              errorFunction={formik.errors.Tags}
              isEditing={true}
              optionLabelFunction={(option: any) => option.Name}
              setIsEditing={null}
              toolTip={t("strGen:tooltips.tags")}
            />
          </Grid>
        )}
        <IppFormDivider
          title={t("objCom:objects.commitment.headers.projectdetails")}
        />
        <Grid item xs={6}>
          <IppAutocomplete
            id="ProjectID"
            options={projects}
            required={true}
            value={projects.find((obj) => {
              return obj.ProjectID === formik.values.ProjectID;
            })}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                formik.setFieldValue("ProjectID", newValue.ProjectID);
              } else {
                formik.setFieldValue("ProjectID", -1);
              }
            }}
            label={t("objPlt:objects.project.name")}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            optionLabelFunction={(option: any) => option.ProjectName}
            errorFunction={formik.errors.ProjectID}
            touchedFunction={formik.touched.ProjectID}
            textValueFunction={formik.values.ProjectName}
          />
        </Grid>
        {ft_com_Phase && (
          <Grid item xs={6}>
            <IppTokenMultiSelect
              id="CommitmentPhase"
              label={t("objPlt:platformwide.phase.name", {
                count: formik.values.CommitmentPhase
                  ? formik.values.CommitmentPhase.length
                  : 1,
              })}
              required={false}
              options={phases}
              noOptionsText={t("strGen:components.nooptionstext")}
              value={
                formik.values.CommitmentPhase
                  ? formik.values.CommitmentPhase
                  : CommPhase
              }
              setValue={(newValue: any) => {
                formik.setFieldValue("CommitmentPhase", newValue);
              }}
              touchedFunction={formik.touched.CommitmentPhase}
              errorFunction={formik.errors.CommitmentPhase}
              isEditing={true}
              optionLabelFunction={(option: any) => option.Name}
              setIsEditing={null}
            />
          </Grid>
        )}
        <IppFormDivider
          title={t("objCom:objects.commitment.headers.commitmentowner")}
        />
        <Grid item xs={6}>
          <IppAutocomplete
            id="OverallResponsibility"
            required={true}
            options={OverallResponsibilities}
            value={formik.values.OverallResponsibility}
            textValueFunction={formik.values.OverallResponsibility}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                formik.setFieldValue("OverallResponsibility", newValue);
              } else {
                formik.setFieldValue("OverallResponsibility", "");
              }
            }}
            touchedFunction={formik.touched.OverallResponsibility}
            errorFunction={formik.errors.OverallResponsibility}
            label={t("objCom:objects.commitment.fields.overallresponsibility")}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        <Grid item xs={6}>
          <IppPersonResponsible
            id="PersonResponsible"
            required={true}
            value={allUsers.find((obj) => {
              return obj.UserAccountID === formik.values.PersonResponsible;
            })}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                formik.setFieldValue(
                  "PersonResponsible",
                  newValue.UserAccountID
                );
              } else {
                formik.setFieldValue("PersonResponsible", null);
              }
            }}
            label={t("objCom:objects.commitment.fields.personresponsible")}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            optionLabelFunction={(option: any) =>
              option.FirstName + " " + option.Surname
            }
            errorFunction={formik.errors.PersonResponsible}
            touchedFunction={formik.touched.PersonResponsible}
            textValueFunction={formik.values.PersonResponsibleName}
            projectID={formik.values.ProjectID}
            disabled={isContributorForProject}
          />
        </Grid>
        {ft_all_ExternalPersonRes && (
          <Grid item xs={6}>
            <IppAutocomplete
              id="ContactID"
              options={contacts}
              renderOption={(props: any, option: Contact) =>
                PersonRenderOption(
                  props,
                  option,
                  [
                    option.Email,
                    option.FirstGroupName,
                    option.PrimaryPhone,
                    option.ContactTitle,
                  ],
                  `* ${t("objPlt:objects.contact.missingname")} *`
                )
              }
              value={contacts.find((obj) => {
                return obj.ContactID === formik.values.ContactID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("ContactID", newValue.ContactID);
                } else {
                  formik.setFieldValue("ContactID", null);
                }
              }}
              label={t(
                "objCom:objects.commitment.fields.additionalpersonresponsible"
              )}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) =>
                option.ContactName?.trim() !== ""
                  ? option.ContactName
                  : `* ${t("objPlt:objects.contact.missingname")} * (${
                      option.Email && option.Email?.trim() !== ""
                        ? option.Email
                        : t("objPlt:objects.contact.missingemail")
                    })`
              }
              errorFunction={formik.errors.ContactID}
              touchedFunction={formik.touched.ContactID}
              textValueFunction={formik.values.ContactName}
              toolTip="An external contact who shares responsibility for this record but does not have access to the platform"
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <IppRichTextEditor
            id="CommitmentDetails"
            label={t("objCom:objects.commitment.fields.commitmentdetails")}
            value={formik.values.CommitmentDetails}
            isEditing={isEditing}
            touchedExpression={formik.touched.CommitmentDetails}
            errorsExpression={formik.errors.CommitmentDetails}
            setFieldValue={formik.setFieldValue}
            height={150}
            standardStatementsRecordType="CommitmentDetails"
          />
        </Grid>
        {ft_com_InternalNotes && (
          <Grid item xs={12}>
            <IppRichTextEditor
              id="InternalNotes"
              label={t("objCom:objects.commitment.fields.internalnotes")}
              value={formik.values.InternalNotes}
              touchedExpression={formik.touched.InternalNotes}
              errorsExpression={formik.errors.InternalNotes}
              isEditing={isEditing}
              setFieldValue={formik.setFieldValue}
              height={150}
              toolTip="Remarks/notes meant for internal reference only"
              standardStatementsRecordType="CommitmentInternalNotes"
            />
          </Grid>
        )}
        {ft_com_RegulatoryNotes && (
          <Grid item xs={12}>
            <IppRichTextEditor
              id="RegulatoryNotes"
              label={t("objCom:objects.commitment.fields.regulatorynotes")}
              value={formik.values.RegulatoryNotes}
              touchedExpression={formik.touched.RegulatoryNotes}
              errorsExpression={formik.errors.RegulatoryNotes}
              isEditing={isEditing}
              setFieldValue={formik.setFieldValue}
              height={150}
              toolTip="Remarks/notes meant for regulatory details only"
              standardStatementsRecordType="CommitmentRegulatoryNotes"
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <IppRichTextEditor
            id="LegalText"
            label={t("objCom:objects.commitment.fields.legaltext")}
            value={formik.values.LegalText}
            isEditing={isEditing}
            touchedExpression={formik.touched.LegalText}
            errorsExpression={formik.errors.LegalText}
            setFieldValue={formik.setFieldValue}
            standardStatementsRecordType="CommitmentLegalText"
          />
        </Grid>

        <Grid item xs={12}>
          <IppFormButtons
            isEditing={isEditing}
            isAdding={isAdding}
            setIsEditing={setIsEditing}
            resetFunction={() => formik.resetForm()}
            showDelete={canDelete}
            deleteFunction={() => handleDelete()}
          />
        </Grid>
      </Grid>
    </form>
  );

  const commitmentActionColumns = [
    {
      field: "ActionDescription",
      title: t("objCom:objects.commitmentaction.name"),
    },
    {
      field: "PersonResponsibleName",
      title: t("objCom:objects.commitmentaction.fields.responsible"),
    },
    {
      field: "RequiredDate",
      title: t("objPlt:platformwide.fields.daterequired"),
      filter: "date",
      format: "{0:d}",
    },
    {
      field: "CompletionDate",
      title: t("objPlt:platformwide.fields.datecompleted"),
      filter: "date",
      format: "{0:d}",
    },
    {
      field: "ActionStatusName",
      title: t("objCom:objects.commitmentaction.fields.status"),
      chips: true,
      columnWidth: 150,
    },
  ];

  const interactionColumns = [
    {
      field: "CommunicationID",
      title: "ID",
      format: "C-{0:0}",
      filter: "string",
      columnWidth: 80,
    },
    { field: "InteractionTitle", title: "Subject" },
    {
      field: "InteractionDate",
      title: t("objPlt:platformwide.fields.date"),
      filter: "date",
      format: "{0:d}",
      columnWidth: 120,
    },
    {
      field: "Contacts",
      title: t("objPlt:objects.contact.name", { count: 2 }),
      commaDelimited: true,
    },
  ];

  let pageForm =
    clientIsLoading ||
    commitmentActionIsLoading ||
    commPhaseIsLoading ||
    commitmentCategoryIsLoading ||
    contactIsLoading ||
    groupIsLoading ||
    projectIsLoading ||
    clientCommitmentCategoryIsLoading ||
    clientCommitmentStatusTypesIsLoading ||
    commitmentSourceIsLoading ||
    commitmentInteractionIsLoading ||
    priorityIsLoading ||
    tagIsLoading ||
    standardStatementIsLoading ? (
      <LoadingIndicator />
    ) : isAdding ? (
      <Box display="flex" justifyContent="center">
        <Grid container className={classes.editForm} spacing={1}>
          <Grid item xs={12}>
            <Paper className={classes.boxSpace}>{editForm}</Paper>
          </Grid>
        </Grid>
      </Box>
    ) : (
      <Grid container spacing={1}>
        <Grid item xl={5} xs={5}>
          {isEditing ? (
            <Dialog open={isEditing} fullWidth maxWidth="lg">
              <DialogTitle>
                <IppCloseButton onClick={() => setIsEditing(false)} />
              </DialogTitle>
              <DialogContent>{editForm}</DialogContent>
            </Dialog>
          ) : (
            <div>{viewForm}</div>
          )}
        </Grid>
        <Grid item xl={7} xs={7}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={activeTab}
                      onChange={handleTabSelect}
                      variant="scrollable"
                      scrollButtons
                      allowScrollButtonsMobile
                    >
                      <Tab
                        value={0}
                        label={`${t(
                          "objCom:objects.commitmentaction.name_other",
                          {
                            count: 2,
                          }
                        )} (${commitmentActions.length})`}
                      />
                      {showCommitmentInteractions && (
                        <Tab
                          value={1}
                          label={
                            "Communications (" +
                            commitmentInteractions.length +
                            ")"
                          }
                        />
                      )}
                      <Tab
                        value={2}
                        label={t("objCom:objects.commitment.fields.legaltext")}
                      />
                      <Tab
                        value={3}
                        label={`${t(
                          "objCom:objects.commitment.attachments"
                        )} (${attachCount.Count})`}
                      />
                    </Tabs>
                  </Box>
                  <IppTabPanel value={activeTab} index={0}>
                    <Box>
                      <IppChildInventory
                        title={t("objCom:objects.commitmentaction.name")}
                        parentTitle={t("objCom:objects.commitment.name")}
                        linkURL="actions"
                        tableData={data}
                        idField="CommitmentActionID"
                        nameField="ActionDescription"
                        parentID={itemData.CommitmentID}
                        relatedField="CommitmentActionID"
                        columns={commitmentActionColumns}
                        showAdd={canCreateAction}
                        tableName="CommitmentAction"
                        id="CommitmentActionList"
                        label={t(
                          "objCom:objects.commitmentaction.fields.commitmentactions",
                          { actioncount: 2 }
                        )}
                        options={commitmentActions}
                        selectedValues={commitmentActions}
                        setSelectedValues={null}
                        setDidSaveInventory={setDidSaveInventory}
                        parentValue={itemData.CommitmentTitle}
                      />
                    </Box>
                  </IppTabPanel>

                  {showCommitmentInteractions && (
                    <IppTabPanel value={activeTab} index={1}>
                      <Box>
                        <IppChildInventory
                          title="Communication"
                          parentTitle={t("objCom:objects.commitment.name")}
                          linkURL="communications"
                          tableData={commitmentInteractions}
                          idField="CommitmentInteractionID"
                          nameField="InteractionTitle"
                          parentID={itemData.CommitmentID}
                          relatedField="InteractionID"
                          columns={interactionColumns}
                          tableName="CommitmentInteraction"
                          id="CommunicationList"
                          label="Communication History"
                          options={interactionsFiltered}
                          unfilteredOptions={interactions}
                          selectedValues={listInteractions}
                          setSelectedValues={setListInteractions}
                          setDidSaveInventory={setDidSaveInventory}
                          parentValue={itemData.CommitmentTitle}
                          showOptions={true}
                          secondLinkCell={1}
                          showLinkButton
                        />
                      </Box>
                    </IppTabPanel>
                  )}
                  <IppTabPanel value={activeTab} index={2}>
                    <Box>
                      <IppDisplayRichText
                        label={t("objCom:objects.commitment.fields.legaltext")}
                        value={SanitizeHTML(formik.values.LegalText)}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        showTitle={false}
                        showEdit={canEdit}
                      />
                    </Box>
                  </IppTabPanel>
                  <IppTabPanel value={activeTab} index={3}>
                    <Box>
                      <IppAttachmentInventory
                        recordType={t("objCom:objects.commitment.name_other", {
                          count: 2,
                        })}
                        itemID={itemData.CommitmentID}
                        companyID={formik.values.CompanyID}
                        projectID={0}
                        moduleID={3}
                        canEdit={canEdit}
                        title={t("objCom:objects.commitment.attachments")}
                        categoryRecordType="Commitment"
                      />
                    </Box>
                  </IppTabPanel>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );

  return <Root>{pageForm}</Root>;
};
