import React, { useEffect, useState } from "react";
import { IconButton, Menu, Fade, List } from "@mui/material";
import {
  Apps,
  Assignment,
  Chat,
  HealthAndSafety,
  People,
  ReceiptLong,
  School,
  Work,
} from "@mui/icons-material";
import ModuleSelector from "./ModuleSelector";
import TrainingHubSelector from "./TrainingHubSelector";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { fetchClientModules } from "features/roles/ClientModuleSlice";
import { RootState } from "app/rootReducer";

interface AppSelectorProps {
  type: "modules" | "training";
}

const AppSelector: React.FC<AppSelectorProps> = ({ type }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const { clientModuleList, clientModulesById } = useSelector(
    (state: RootState) => state.clientModules
  );

  const clientModules: Array<{
    ClientModuleID: number;
    ModuleID: number;
    ShortName: string;
    ModuleName: string;
  }> = clientModuleList.map((id) => clientModulesById[id]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchClientModules(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently, type]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const iconList = (moduleID: number) => {
    switch (moduleID) {
      case 1:
        return <People />;
      case 2:
        return <Assignment />;
      case 3:
        return <Chat />;
      case 5:
        return <ReceiptLong />;
      case 6:
        return <HealthAndSafety />;
      case 7:
        return <School />;
      case 8:
        return <Work />;
      default:
        return null;
    }
  };

  return (
    <div>
      <IconButton
        color="inherit"
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {type === "modules" ? <Apps /> : <School />}
      </IconButton>
      <Menu
        id="app-menu"
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {type === "modules" && (
          <ModuleSelector
            iconList={iconList}
            clientModules={clientModules}
            handleClose={handleClose}
          />
        )}
        {type === "training" && (
          <TrainingHubSelector
            iconList={iconList}
            handleClose={handleClose}
            clientModules={clientModules}
          />
        )}
      </Menu>
    </div>
  );
};

export default AppSelector;
