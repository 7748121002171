import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import {
  addWorkHourPeriod,
  updWorkHourPeriod,
  delWorkHourPeriod,
} from "./WorkHourPeriodSlice";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Tab,
  Tabs,
} from "@mui/material";
import {
  AssignmentReturned,
  CloudUpload,
  Delete,
  Upload,
} from "@mui/icons-material";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppFormHeader } from "components/IppFormHeader";
import { IppAutocomplete } from "components/IppAutocomplete";
import { fetchCompanies } from "features/benefits/companies/CompaniesSlice";
import { fetchProjects } from "features/project/ProjectSlice";
import {
  FirstDayInPreviousMonth,
  LastDayInPreviousMonth,
  GetJSDate,
  ConvertDateOffset,
} from "utils/DateFunctions";
import { fetchProjectComponents } from "features/project/ProjectComponentSlice";
import {
  bulkDelWorkHourDetailsByPeriod,
  fetchWorkHourDetailsByPeriod,
} from "./WorkHourDetailSlice";
import { fetchEmployeesByCompany } from "../employees/EmployeeSlice";
import { push } from "redux-first-history";
import LoadingIndicator from "components/LoadingIndicator";
import { IppAttachmentInventory } from "components/IppAttachmentInventory";
import { fetchDeleteChecksByWorkHourPeriod } from "features/deleteCheck/DeleteCheckSlice";
import { Prompt } from "react-router";
import { IppDisplayField } from "components/IppDisplayField";
import { IppStaticTextfield } from "components/IppStaticTextfield";
import { IppDatePicker } from "components/IppDatePicker";
import { IsMedLg } from "utils/mediaQueries";
import { UserWriteAccess } from "utils/userAccess";
import { fetchContracts } from "features/contract/ContractSlice";
import { WorkHourPeriodValidationSchema } from "./WorkHourPeriodValidation";
import { useTypedTranslation } from "utils/customHooks";
import { IppTabPanel } from "components/IppTabPanel";
import { IppChildInventory } from "components/Inventory/IppChildInventory";
import { ButtonProps } from "components/IppPopoverMenu";
import { countAttachments } from "api/attachmentsAPI";
import { IppBulkDeleteDialog } from "components/IppBulkDeleteDialog";
import { fetchJobPositionsByClient } from "features/datalists/JobPositionSlice";
import { generateTemplate } from "utils/uploadUtils";
import { IppButton } from "components/Buttons/IppButton";
import { IppCancelButton } from "components/Buttons/IppCancelButton";
import { IppCheckbox } from "components/IppCheckbox";
import { Company } from "api/companyAPI";
import { CompanyOptionsRender } from "utils/renderFunctions";
import { IppCurrencyDisplay } from "components/IppCurrencyDisplayField";
import { ShowActiveCompanies } from "../BenefitsUtils";
import { useCompanyListByRole } from "utils/hooks/useCompanyListByRole";

const PREFIX = "WorkHourPeriodForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    // minWidth: 650,
    maxWidth: 1200,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const WorkHourPeriodForm = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const customBP = IsMedLg();
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  const {
    clientId,
    isLoading: clientIsLoading,
    ft_ben_Contract,
    ft_ben_AltWorkHours2,
    ft_ben_AltWorkHours3,
    ft_ben_JobPosition,
    ft_ben_WorkHourSubContractor,
    ft_ben_WageAmount,
  } = useSelector((state: RootState) => state.client);

  const subcontractorEnabled = ft_ben_WorkHourSubContractor && !ft_ben_Contract;

  const { location } = useSelector((state: RootState) => state.router);
  const [pageLoading, setPageLoading] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [attachCount, setAttachCount] = useState({ Count: 0 } as any);
  const [isBulkdDeleteOpen, setIsBulkDeleteOpen] = useState(false);

  let workHourPeriodData = props.workHourPeriod
    ? {
        ...props.workHourPeriod,
        IsSubcontractor:
          subcontractorEnabled && !!props.workHourPeriod.ContractorID,
      }
    : {};

  const [isEditing, setIsEditing] = useState(
    workHourPeriodData.PeriodID ? false : true
  );

  const isAdding = workHourPeriodData.PeriodID ? false : true;
  const companyID = workHourPeriodData ? workHourPeriodData.CompanyID : 0;
  const periodID = workHourPeriodData ? workHourPeriodData.PeriodID : 0;

  const handleTabSelect = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };

  const handleBulkDeleteConfirm = () => {
    // delete all Work Hour Details for the PeriodID
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        if (periodID)
          dispatch(bulkDelWorkHourDetailsByPeriod(accessToken, periodID));
      } catch (e) {
        console.error(e);
      }
    })();
    setIsBulkDeleteOpen(false);
  };

  const handleBulkDeleteCancel = () => {
    setIsBulkDeleteOpen(false);
  };

  const showEdit = UserWriteAccess("Benefits", workHourPeriodData.ProjectID);

  const { currentProfile, currentUserRoleList, currentUserRolesById } =
    useSelector((state: RootState) => state.profile);

  const userRoles = currentUserRoleList.map((id) => currentUserRolesById[id]);

  const { isLoading: workHourPeriodIsLoading } = useSelector(
    (state: RootState) => state.workHourPeriods
  );

  const {
    companiesById,
    companyList,
    isLoading: companyIsLoading,
  } = useSelector((state: RootState) => state.companies);

  const companies = companyList.map((CompanyID) => companiesById[CompanyID]);
  const filteredCompanies = companies.filter((comp) => comp.ListingConfirmed);

  const contractorCompaniesList = !currentProfile.IsClient
    ? filteredCompanies.filter(
        (comp) => comp.CompanyID !== currentProfile.CompanyID
      )
    : filteredCompanies;

  const confirmedCompanies = useCompanyListByRole(filteredCompanies);

  const {
    projectList,
    projectsById,
    isLoading: projectIsLoading,
  } = useSelector((state: RootState) => state.projects);

  const allProjects = projectList.map((id) => projectsById[id]);

  const projects = allProjects.filter((proj) => {
    if (currentProfile.IsClientAdmin) {
      return true;
    } else {
      return userRoles.some((role) => {
        return role.ProjectID === proj.ProjectID && role.ModuleID === 1;
      });
    }
  });

  const {
    projectComponentList,
    projectComponentsById,
    isLoading: projCompIsLoading,
  } = useSelector((state: RootState) => state.projectComponents);

  const allProjectcomponents = useMemo(
    () => projectComponentList.map((id) => projectComponentsById[id]),
    [projectComponentList, projectComponentsById]
  );

  const {
    workHourDetailList,
    workHourDetailsById,
    isLoading: whdIsLoading,
  } = useSelector((state: RootState) => state.workHourDetails);

  const workhourdetails = workHourDetailList
    .map((id) => workHourDetailsById[id])
    .filter((detail) => detail && detail.PeriodID === periodID);

  const { employeesById, isLoading: employeeIsLoading } = useSelector(
    (state: RootState) => state.employees
  );

  const {
    contractList,
    contractsById,
    isLoading: contractIsLoading,
  } = useSelector((state: RootState) => state.contracts);

  const allContracts = useMemo(
    () => contractList.map((id) => contractsById[id]),
    [contractList, contractsById]
  );

  //usestate for Rootstate of attachment
  const { attachmentsById, attachmentList } = useSelector(
    (state: RootState) => state.attachments
  );

  const attachments = attachmentList.map((id) => attachmentsById[id]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchCompanies(accessToken, clientId));
        dispatch(fetchProjects(accessToken));
        dispatch(fetchProjectComponents(accessToken));
        if (ft_ben_JobPosition)
          dispatch(fetchJobPositionsByClient(accessToken));
        if (periodID) {
          dispatch(fetchWorkHourDetailsByPeriod(accessToken, periodID));
          dispatch(fetchDeleteChecksByWorkHourPeriod(accessToken, periodID));
          const count = await countAttachments(
            accessToken,
            "WorkHourPeriods",
            periodID
          );
          setAttachCount(count.attachments ? count.attachments : 0);
        }

        if (ft_ben_Contract) {
          dispatch(fetchContracts(accessToken));
        }

        if (companyID) {
          dispatch(fetchEmployeesByCompany(accessToken, companyID));
        }

        setPageLoading(false);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, periodID, companyID, dispatch, getAccessTokenSilently]);

  // used for attachment count
  useEffect(() => {
    (async () => {
      try {
        if (attachments.length > 0 && !isAdding && periodID) {
          const attach = attachments.filter((item) => {
            return (
              item.RecordID === periodID &&
              item.RecordType === "WorkHourPeriods"
            );
          });
          if (attach && attach.length > 0)
            setAttachCount({ Count: attach.length });
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [attachmentList]);

  const whdTableData = workhourdetails.map((p) => {
    let n = Object.assign({}, p) as any;
    n.CompanyEmployeeID = employeesById[n.EmployeeID]
      ? employeesById[n.EmployeeID].CompanyEmployeeID
      : "";
    return n;
  });

  const handleDelete = () => {
    // function to delete current Work Hour Period entry
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(delWorkHourPeriod(accessToken, periodID));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const handleUpload = () => {
    const route: string = `${location.pathname}/upload`;
    dispatch(push(route));
  };

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(addWorkHourPeriod(accessToken, values));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  if (!isAdding) {
    //Update case
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(
            updWorkHourPeriod(accessToken, workHourPeriodData.PeriodID, values)
          );
          setIsEditing(false);
        } catch (e) {
          console.error(e);
        }
      })();
    };
  } else {
    //set defaults for create case
    workHourPeriodData.ProjectID = -1;
    workHourPeriodData.ProjectComponentID = -1;
    workHourPeriodData.CompanyID = -1;
    workHourPeriodData.StartDate = FirstDayInPreviousMonth();
    workHourPeriodData.EndDate = LastDayInPreviousMonth();
    workHourPeriodData.ContractID = null;
    workHourPeriodData.IsSubcontractor = false;
    workHourPeriodData.ContractorID = null;
    workHourPeriodData.TotalWageAmount = 0;
  }

  const formik = useFormik({
    initialValues: workHourPeriodData,
    validationSchema: WorkHourPeriodValidationSchema(
      ft_ben_Contract || false,
      subcontractorEnabled || false
    ),
    onSubmit: submitFunc,
  });

  const companyProjectContracts = useMemo(() => {
    if (
      formik.values.CompanyID &&
      formik.values.ProjectID &&
      allContracts.length
    ) {
      return allContracts.filter(
        (item) =>
          item.CompanyID === formik.values.CompanyID &&
          item.ProjectID === formik.values.ProjectID
      );
    } else {
      return [];
    }
  }, [formik.values.CompanyID, formik.values.ProjectID, allContracts]);

  const projectComponents = useMemo(() => {
    if (formik.values.ProjectID && allProjectcomponents.length) {
      return allProjectcomponents.filter(
        (item) => item.ProjectID === formik.values.ProjectID
      );
    } else {
      return [];
    }
  }, [formik.values.ProjectID, allProjectcomponents]);

  let viewForm = (
    <Root>
      <Box display="flex" justifyContent="center">
        <Paper className={classes.boxSpace} sx={{ width: "100%" }}>
          <Grid container className={classes.editForm} spacing={1}>
            <IppFormHeader
              title={t("objBen:objects.workhour.period.fullname")}
              isEditing={isEditing}
              isAdding={isAdding}
              returnPath="/benefits/workhours"
            />
            <Grid item xs={6}>
              <IppDisplayField
                label={t("objPlt:objects.project.name") || ""}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                value={formik.values.ProjectName ?? ""}
                showEdit={showEdit}
              />
            </Grid>
            <Grid item xs={6}>
              <IppDisplayField
                label={t("objPlt:objects.projectcomponent.name")}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                value={formik.values.ProjectComponentName ?? ""}
                showEdit={showEdit}
              />
            </Grid>
            <Grid item xs={6}>
              <IppDisplayField
                label={t("objBen:objects.company.name")}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                value={formik.values.CompanyName ?? ""}
                showEdit={false}
              />
            </Grid>
            <Grid item xs={6}>
              <IppDisplayField
                label={t("objBen:objects.workhour.period.fullname")}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                value={
                  formik.values.StartDate && formik.values.EndDate
                    ? "W-" +
                      workHourPeriodData.SequenceID +
                      "   (" +
                      formik.values.StartDate.substr(0, 10) +
                      " to " +
                      formik.values.EndDate.substr(0, 10) +
                      ")"
                    : ""
                }
                showEdit={showEdit}
              />
            </Grid>

            {subcontractorEnabled && workHourPeriodData.IsSubcontractor && (
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objBen:objects.workhour.period.fields.contractor")}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  value={formik.values.ContractorName ?? ""}
                  showEdit={showEdit}
                />
              </Grid>
            )}

            {/* Contract  */}
            {ft_ben_Contract && (
              <>
                <Grid item xs={6}>
                  <IppDisplayField
                    label={t("objBen:objects.workhour.period.fields.contract")}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    value={formik.values.ContractTitle ?? ""}
                    showEdit={showEdit}
                  />
                </Grid>
              </>
            )}
            {ft_ben_WageAmount && (
              <Grid item xs={6}>
                <IppCurrencyDisplay
                  value={formik.values.TotalWageAmount}
                  label={t(
                    "objBen:objects.workhour.period.fields.totalwageamount"
                  )}
                  isEditing={isEditing}
                  showEdit={false}
                  setIsEditing={setIsEditing}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <IppFormButtons
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                isAdding={isAdding}
                resetFunction={() => formik.resetForm()}
                showDelete={showEdit}
                deleteFunction={handleDelete}
                fetchFunc={fetchDeleteChecksByWorkHourPeriod}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Root>
  );

  let editForm = (
    <Root>
      {!formik.isSubmitting && (
        <Prompt
          when={formik.dirty}
          message={t("strGen:prompts.unsavedchanges") || ""}
        />
      )}

      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" justifyContent="center">
          <Paper className={classes.boxSpace}>
            <Grid container className={classes.editForm} spacing={1}>
              <IppFormHeader
                title={t("objBen:objects.workhour.period.fullname")}
                isEditing={isEditing}
                isAdding={isAdding}
                returnPath="/benefits/workhours"
              />

              <Grid item xs={6}>
                <IppAutocomplete
                  id="ProjectID"
                  options={projects}
                  required={true}
                  value={allProjects.find((obj) => {
                    return obj.ProjectID === formik.values.ProjectID;
                  })}
                  onChangeFunction={(event: ChangeEvent, newValue: any) => {
                    if (newValue) {
                      formik.setFieldValue("ProjectID", newValue.ProjectID);
                      formik.setFieldValue("ContractID", null);
                      formik.setFieldValue("ProjectComponentID", null);
                    } else {
                      formik.setFieldValue("ProjectID", -1);
                    }
                  }}
                  label={t("objPlt:objects.project.name")}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  optionLabelFunction={(option: any) => option.ProjectName}
                  textValueFunction={
                    formik.values.ProjectID > 0 &&
                    !projectIsLoading &&
                    projectsById[formik.values.ProjectID]
                      ? projectsById[formik.values.ProjectID].ProjectName
                      : ""
                  }
                  errorFunction={formik.errors.ProjectID}
                  touchedFunction={formik.touched.ProjectID}
                />
              </Grid>
              <Grid item xs={6}>
                <IppAutocomplete
                  id="ProjectComponentID"
                  options={projectComponents}
                  value={projectComponents.find((obj) => {
                    return (
                      obj.ProjectComponentID ===
                      formik.values.ProjectComponentID
                    );
                  })}
                  onChangeFunction={(event: ChangeEvent, newValue: any) => {
                    if (newValue) {
                      formik.setFieldValue(
                        "ProjectComponentID",
                        newValue.ProjectComponentID
                      );
                    } else {
                      formik.setFieldValue("ProjectComponentID", null);
                    }
                  }}
                  required={true}
                  label={t("objPlt:objects.projectcomponent.fullname")}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  errorFunction={formik.errors.ProjectComponentID}
                  touchedFunction={formik.touched.ProjectComponentID}
                  optionLabelFunction={(option: any) =>
                    option.ProjectComponentName
                  }
                  textValueFunction={
                    formik.values.ProjectComponentID > 0 &&
                    formik.values.ProjectID > 0 &&
                    !projCompIsLoading &&
                    !projectIsLoading &&
                    projectComponentsById[formik.values.ProjectComponentID]
                      ? projectComponentsById[formik.values.ProjectComponentID]
                          .ProjectComponentName
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={6}>
                {!workHourPeriodData.PeriodID ? (
                  <IppAutocomplete
                    id="CompanyID"
                    options={ShowActiveCompanies(confirmedCompanies).filter(
                      (comp) => comp.CompanyID !== formik.values.ContractorID
                    )}
                    value={confirmedCompanies.find((obj) => {
                      return obj.CompanyID === formik.values.CompanyID;
                    })}
                    onChangeFunction={(event: ChangeEvent, newValue: any) => {
                      if (newValue) {
                        formik.setFieldValue("CompanyID", newValue.CompanyID);
                        //setCompanyID(newValue.CompanyID)
                        formik.setFieldValue("ContractID", null);
                      } else {
                        formik.setFieldValue("CompanyID", -1);
                      }
                    }}
                    required
                    label={t("objBen:objects.company.name")}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    errorFunction={formik.errors.CompanyID}
                    touchedFunction={formik.touched.CompanyID}
                    optionLabelFunction={(option: any) => option.CompanyName}
                    renderOption={(props: any, option: Company) => {
                      return CompanyOptionsRender(props, option);
                    }}
                    textValueFunction={
                      formik.values.CompanyID > 0 &&
                      companiesById[formik.values.CompanyID]
                        ? companiesById[formik.values.CompanyID].CompanyName
                        : ""
                    }
                    disabled={!currentProfile.IsClient}
                  />
                ) : (
                  <IppStaticTextfield
                    id="CompanyID"
                    label={t("objBen:objects.company.name")}
                    value={
                      formik.values.CompanyID > 0 &&
                      companiesById[formik.values.CompanyID]
                        ? companiesById[formik.values.CompanyID].CompanyName
                        : ""
                    }
                  />
                )}
              </Grid>
              {!isAdding && (
                <Grid item xs={6}>
                  <IppStaticTextfield
                    id="SequenceID"
                    label={t("objBen:objects.workhour.period.periodid")}
                    value={"W-" + workHourPeriodData.SequenceID}
                  />
                </Grid>
              )}

              {ft_ben_Contract && (
                <>
                  <Grid item xs={6}>
                    <IppAutocomplete
                      id="ContractID"
                      options={companyProjectContracts}
                      value={
                        companyProjectContracts.find((obj) => {
                          return obj.ContractID === formik.values.ContractID;
                        }) || ""
                      }
                      onChangeFunction={(event: ChangeEvent, newValue: any) => {
                        if (newValue) {
                          formik.setFieldValue(
                            "ContractID",
                            newValue.ContractID
                          );
                        } else {
                          formik.setFieldValue("ContractID", null);
                        }
                      }}
                      required={ft_ben_Contract}
                      label={t(
                        "objBen:objects.workhour.period.fields.contract"
                      )}
                      noOptionsText="No contract for this company & project"
                      disabled={
                        !(
                          formik.values.CompanyID > 0 &&
                          formik.values.ProjectID > 0
                        )
                      }
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      errorFunction={formik.errors.ContractID}
                      touchedFunction={formik.touched.ContractID}
                      optionLabelFunction={(option: any) =>
                        option.ContractTitle || ""
                      }
                      textValueFunction={
                        formik.values.ContractID > 0 &&
                        contractsById[formik.values.ContractID]
                          ? contractsById[formik.values.ContractID]
                              .ContractTitle
                          : ""
                      }
                    />
                  </Grid>
                </>
              )}
              {!isAdding && ft_ben_WageAmount && (
                <Grid item xs={6}>
                  <IppStaticTextfield
                    id="TotalPayment"
                    value={`$${(
                      formik.values.TotalWageAmount ?? 0
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}
                    label={t(
                      "objBen:objects.workhour.period.fields.totalwageamount"
                    )}
                    isCurrency={true}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <IppDatePicker
                  id="StartDate"
                  required
                  label={t("objPlt:platformwide.fields.datestarted")}
                  value={ConvertDateOffset(formik.values.StartDate)}
                  onChangeFunction={(newValue: any) => {
                    formik.setFieldValue(
                      "StartDate",
                      GetJSDate(newValue),
                      true
                    );
                    formik.setFieldTouched("StartDate", true, false);
                  }}
                  errorsExpression={formik.errors.StartDate}
                  touchedExpression={formik.touched.StartDate}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDatePicker
                  id="EndDate"
                  label={t("objPlt:platformwide.fields.dateended")}
                  value={ConvertDateOffset(formik.values.EndDate)}
                  onChangeFunction={(newValue: any) => {
                    formik.setFieldValue("EndDate", GetJSDate(newValue), true);
                    formik.setFieldTouched("EndDate", true, false);
                  }}
                  errorsExpression={formik.errors.EndDate}
                  touchedExpression={formik.touched.EndDate}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                />
              </Grid>

              {subcontractorEnabled && (
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <IppCheckbox
                        id="IsSubcontractor"
                        label={t(
                          "objBen:objects.workhour.period.fields.contractorcheck"
                        )}
                        value={formik.values.IsSubcontractor}
                        isEditing={isEditing}
                        onChangeFunction={(_: ChangeEvent, newValue: any) => {
                          if (newValue) {
                            formik.setFieldValue("IsSubcontractor", newValue);
                          } else {
                            formik.setFieldValue("ContractorID", null);
                            formik.setFieldValue("IsSubcontractor", false);
                          }
                        }}
                      />
                    </Grid>

                    {formik.values.IsSubcontractor && (
                      <Grid item xs={6}>
                        <IppAutocomplete
                          id="ContractorID"
                          options={ShowActiveCompanies(contractorCompaniesList)}
                          value={
                            contractorCompaniesList.find((obj) => {
                              return (
                                obj.CompanyID === formik.values.ContractorID
                              );
                            }) || ""
                          }
                          onChangeFunction={(_: ChangeEvent, newValue: any) => {
                            if (newValue) {
                              formik.setFieldValue(
                                "ContractorID",
                                newValue.CompanyID
                              );
                            } else {
                              formik.setFieldValue("ContractorID", null);
                            }
                          }}
                          required
                          label={t(
                            "objBen:objects.workhour.period.fields.contractor"
                          )}
                          isEditing={isEditing}
                          setIsEditing={setIsEditing}
                          errorFunction={formik.errors.ContractorID}
                          touchedFunction={formik.touched.ContractorID}
                          optionLabelFunction={(option: any) =>
                            option.CompanyName
                          }
                          textValueFunction={
                            formik.values.ContractorID > 0 &&
                            companiesById[formik.values.ContractorID]
                              ? companiesById[formik.values.ContractorID]
                                  .CompanyName
                              : ""
                          }
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}

              <Grid item xs={12}>
                <IppFormButtons
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  isAdding={isAdding}
                  resetFunction={() => formik.resetForm()}
                  showDelete={true}
                  deleteFunction={handleDelete}
                  fetchFunc={fetchDeleteChecksByWorkHourPeriod}
                />
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </form>
    </Root>
  );

  const altHours2 = ft_ben_AltWorkHours2
    ? [
        {
          field: "AltHours2",
          title: t("objBen:objects.workhour.detail.fields.althours1"),
        },
      ]
    : [];

  const altHours3 = ft_ben_AltWorkHours3
    ? [
        {
          field: "AltHours3",
          title: t("objBen:objects.workhour.detail.fields.althours2"),
        },
      ]
    : [];

  const wageAmount = ft_ben_WageAmount
    ? [
        {
          field: "WageAmount",
          title: t("objBen:objects.workhour.detail.fields.wageamount"),
          filter: "numeric",
          format: "{0:c2}",
        },
      ]
    : [];

  const whdColumns = [
    {
      field: "CompanyEmployeeID",
      title: t("objBen:objects.employee.name"),
    },
    {
      field: "RegularHours",
      title: t("objBen:objects.workhour.detail.fields.hours"),
    },
    ...altHours2,
    ...altHours3,
    ...wageAmount,
  ];

  const templateBtn: ButtonProps = {
    onClick: () =>
      generateTemplate(getAccessTokenSilently, "workhourdetail", periodID),
    startIcon: <AssignmentReturned />,
    disabled: false,
    text: "Template",
    gridSize: 12,
  };

  const uploadBtn: ButtonProps = {
    onClick: () => {
      workhourdetails.length === 0 ? handleUpload() : setShowConfirm(true);
    },
    startIcon: <CloudUpload />,
    disabled: false,
    text: "Upload",
    gridSize: 12,
  };

  const bulkDeleteBtn: ButtonProps = {
    onClick: () => {
      setIsBulkDeleteOpen(true);
    },
    startIcon: <Delete />,
    color: "error",
    disabled: workhourdetails.length < 1,
    text: "Delete All",
    gridSize: 12,
    tooltip: "Delete all Work-Hour Details for this Period",
  };

  const popoverBtns = currentProfile.IsClientAdmin
    ? [templateBtn, uploadBtn, bulkDeleteBtn]
    : [templateBtn, uploadBtn];

  return pageLoading ||
    workHourPeriodIsLoading ||
    whdIsLoading ||
    clientIsLoading ||
    companyIsLoading ||
    projectIsLoading ||
    projCompIsLoading ||
    contractIsLoading ||
    employeeIsLoading ? (
    <LoadingIndicator />
  ) : isAdding ? (
    <div>{editForm}</div>
  ) : (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={customBP ? 12 : 5}>
          {isEditing ? <div>{editForm}</div> : <div>{viewForm}</div>}
        </Grid>
        <Grid item xs={customBP ? 12 : 7}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={activeTab}
                      onChange={handleTabSelect}
                      variant="scrollable"
                      scrollButtons
                      allowScrollButtonsMobile
                    >
                      <Tab
                        value={0}
                        label={`${t(
                          "objBen:objects.workhour.detail.fullname_other"
                        )} (${workhourdetails.length})`}
                      />
                      <Tab
                        value={1}
                        label={`${t("objBen:objects.workhour.attachments")} (${
                          attachCount.Count
                        })`}
                      />
                    </Tabs>
                  </Box>

                  <IppTabPanel value={activeTab} index={0}>
                    <Box>
                      {/* double check these values */}
                      <IppChildInventory
                        title={t(
                          "objBen:objects.workhour.detail.fullname_other"
                        )}
                        parentTitle={t(
                          "objBen:objects.workhour.period.fullname"
                        )}
                        linkURL="employees"
                        tableData={whdTableData}
                        idField="WorkHourDetailID"
                        nameField="CompanyEmployeeID"
                        parentID={workHourPeriodData.PeriodID}
                        relatedField="WorkHourDetailID"
                        linkField="EmployeeID"
                        columns={whdColumns}
                        showAdd={true}
                        tableName="PeriodWorkHourDetails"
                        id="WorkHourDetailsList"
                        label={t("objBen:objects.workhour.detail.fullname", {
                          count: 2,
                        })}
                        options={workhourdetails}
                        selectedValues={workhourdetails}
                        setSelectedValues={null}
                        setDidSaveInventory={null}
                        parentValue={workHourPeriodData}
                        showOptions={true}
                        popoverMenu={true}
                        popoverBtns={popoverBtns}
                        addHeaderText={false}
                        deleteMessageType="NameWithTitle"
                        deleteNoteType="DeletePlural"
                        deleteNameValueTitle={t("objBen:objects.employee.name")}
                      />
                    </Box>
                  </IppTabPanel>

                  <IppTabPanel value={activeTab} index={1}>
                    <Box>
                      <Grid className={classes.editForm}>
                        <IppAttachmentInventory
                          recordType="WorkHourPeriods"
                          itemID={workHourPeriodData.PeriodID}
                          companyID={formik.values.CompanyID}
                          projectID={formik.values.ProjectID}
                          moduleID={1}
                          title={t("objBen:objects.workhour.attachments")}
                          categoryRecordType="WorkHourPeriod"
                        />
                      </Grid>
                    </Box>
                  </IppTabPanel>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={showConfirm}>
        <DialogTitle>{t("objBen:objects.workhour.pleaseconfirm")}</DialogTitle>
        <DialogContent>
          {t("objBen:objects.workhour.confirmdialog", {
            length: workhourdetails.length,
          })}
        </DialogContent>
        <DialogActions>
          <IppButton
            startIcon={<Upload />}
            color="success"
            onClick={() => {
              setShowConfirm(false);
              handleUpload();
            }}
          >
            {t("strGen:buttons.continue")}
          </IppButton>
          <IppCancelButton onClick={() => setShowConfirm(false)} />
        </DialogActions>
      </Dialog>

      <IppBulkDeleteDialog
        open={isBulkdDeleteOpen}
        onConfirm={handleBulkDeleteConfirm}
        onCancel={handleBulkDeleteCancel}
        title={t("objBen:objects.workhour.detail.fullname", {
          count: 2,
        })}
        parentTitle={t("objBen:objects.workhour.period.fullname")}
      />
    </div>
  );
};
