import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { RootState } from "app/rootReducer";
import { fetchEmbedToken, refreshReport } from "./ReportEmbedSlice";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import LoadingIndicator from "components/LoadingIndicator";
import {
  Box,
  Paper,
  Typography,
  Grid,
  Tooltip,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Refresh, Fullscreen, FullscreenExit, Help } from "@mui/icons-material";
import { ReportHelpPage } from "./ReportHelpPage";
import { warningSnackBar } from "features/snackBar/SnackBarSlice";
import { IppButton } from "components/Buttons/IppButton";
import { useSnackBarConstants, useTypedTranslation } from "utils/customHooks";

const PREFIX = "ReportPage";

const classes = {
  partialReport: `${PREFIX}-partialPageReport`,
  fullReport: `${PREFIX}-fullPageReport`,
  boxSpace: `${PREFIX}-boxSpace`,
  errorSpace: `${PREFIX}-errorSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.partialReport}`]: {
    width: "100%",
    height: "80vh",
  },

  [`& .${classes.fullReport}`]: {
    width: "100%",
    height: "95vh",
  },

  [`& .${classes.errorSpace}`]: {
    padding: theme.spacing(1),
    width: "400px",
  },
}));

interface ReportPageProps {
  moduleShortName: string;
}

export const ReportPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["strGen"]);
  const snackbarConstants = useSnackBarConstants();

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showRefresh, setShowRefresh] = useState(false);
  const [canShow, setCanShow] = useState(true);
  const [showHelp, setShowHelp] = useState(false);

  // get company ID from url param and attempt to find company in store
  const { moduleShortName } = useParams<ReportPageProps>();

  // set editing flag based on whether we have loaded admin or
  // standard report url
  const isEditing = window.location.href.indexOf("admin") > 0 ? true : false;

  const {
    reportEmbedToken,
    isLoading,
    error: embedError,
  } = useSelector((state: RootState) => state.reportEmbed);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchEmbedToken(accessToken, moduleShortName));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, moduleShortName, getAccessTokenSilently]);

  useEffect(() => {
    isEditing &&
      dispatch(warningSnackBar(snackbarConstants.REPORT_CHANGES_WARNING, null));
  }, []);

  const handleSetFullscreen = () => {
    let appBarElement = document.getElementsByClassName("MuiAppBar-root")[0];
    let appBar = ReactDOM.findDOMNode(appBarElement) as HTMLInputElement;
    appBar.style.display = "none";

    let footerElement = document.getElementsByClassName("Footer-footer")[0];
    let footer = ReactDOM.findDOMNode(footerElement) as HTMLInputElement;
    footer.style.display = "none";

    setIsFullScreen(true);
  };

  const handleExitFullscreen = () => {
    let appBarElement = document.getElementsByClassName("MuiAppBar-root")[0];
    let appBar = ReactDOM.findDOMNode(appBarElement) as HTMLInputElement;
    appBar.style.display = "block";

    let footerElement = document.getElementsByClassName("Footer-footer")[0];
    let footer = ReactDOM.findDOMNode(footerElement) as HTMLInputElement;
    footer.style.display = "block";

    setIsFullScreen(false);
  };

  const handleRefresh = async () => {
    setShowRefresh(false);
    setCanShow(false);
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
        },
      });
      const route = window.location.pathname.substring(
        window.location.pathname.indexOf("/", 1)
      );

      dispatch(
        refreshReport(accessToken, moduleShortName, route, snackbarConstants)
      );
    } catch (e) {
      console.error(e);
    }
  };

  const handleCancel = () => {
    setShowRefresh(false);
    setCanShow(false);
  };

  let dateOptions = {
    month: "short" as any,
    day: "numeric" as any,
    year: "2-digit" as any,
  };

  let lastSync = new Date();
  // lastSync.setDate(lastSync.getDate() - 1);
  // lastSync.setHours(22, 5, 0);

  let date = reportEmbedToken?.LastReportRefresh
    ? new Date(reportEmbedToken?.LastReportRefresh)
    : lastSync;

  // if (date < lastSync) {
  //   date = lastSync;
  // }

  let localeDate = new Date(date).toLocaleDateString(undefined, dateOptions);
  let localeTime = new Date(date).toLocaleTimeString();

  // check to see if data is current
  // otherwise show dialog
  useEffect(() => {
    const rightNow = new Date();
    // time difference, converted from ms to hours
    const timeDiff = (rightNow.getTime() - date.getTime()) / 3600000;

    if (timeDiff > 24 && canShow) {
      setShowRefresh(true);
    } else {
      setShowRefresh(false);
    }
  }, [date, canShow]);

  let errorView = (
    <Box display="flex" justifyContent="center">
      <Paper className={classes.errorSpace}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5">
              {t("strGen:pages.reports.reportspage.error.accesserror")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              {t("strGen:pages.reports.reportspage.error.noaccessmessage")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              {t("strGen:pages.reports.reportspage.error.assistance")}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );

  let reportEmbedView =
    isLoading || reportEmbedToken == null ? (
      embedError ? (
        { errorView }
      ) : (
        <LoadingIndicator />
      )
    ) : embedError ? (
      { errorView }
    ) : (
      <Box display="flex" justifyContent="center">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6} md={12} lg={12}>
                {isFullScreen ? (
                  <IppButton
                    startIcon={<FullscreenExit />}
                    onClick={handleExitFullscreen}
                    fullWidth={true}
                    noTimeout
                  >
                    {t("strGen:pages.reports.reportspage.fullscreenexit")}
                  </IppButton>
                ) : (
                  <IppButton
                    startIcon={<Fullscreen />}
                    onClick={handleSetFullscreen}
                    fullWidth={true}
                    noTimeout
                  >
                    {t("strGen:pages.reports.reportspage.fullscreen")}
                  </IppButton>
                )}
              </Grid>
              <Grid item xs={6} sm={6} md={12} lg={12}>
                <Tooltip
                  title={t(
                    "strGen:pages.reports.reportspage.tooltips.clicktorefresh"
                  )}
                >
                  <span>
                    <IppButton
                      disabled={
                        new Date(date) >
                          new Date(Date.now() - 60 * 15 * 1000) ||
                        !reportEmbedToken
                      }
                      startIcon={<Refresh />}
                      fullWidth={true}
                      onClick={handleRefresh}
                    >
                      {t("strGen:buttons.initiaterefresh")}
                    </IppButton>
                  </span>
                </Tooltip>
                <Typography variant="caption" sx={{ fontStyle: "italic" }}>
                  {t("strGen:pages.reports.reportspage.lastrefresh", {
                    date: localeDate,
                    time: localeTime,
                  })}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mt: 2 }}
                >
                  {t("strGen:pages.reports.reportspage.dataInfo", {
                    years: 3,
                  })}
                </Typography>
              </Grid>
              {isEditing && (
                <>
                  <Grid item xs={6} sm={6} md={12} lg={12} mt={2}>
                    <Divider />
                    <Typography variant="body1" mt={2}>
                      {t(
                        "strGen:pages.reports.reportspage.reportchangeswarning"
                      )}
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid item mt={4} xs={6} sm={6} md={12} lg={12}>
                <Tooltip
                  title={t(
                    "strGen:pages.reports.reportspage.tooltips.quickreference"
                  )}
                >
                  <IppButton
                    startIcon={<Help />}
                    fullWidth={true}
                    color="info"
                    onClick={() => setShowHelp(true)}
                  >
                    {t("strGen:buttons.quicktips")}
                  </IppButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <Paper>
              <PowerBIEmbed
                embedConfig={{
                  type: "report", // Supported types: report, dashboard, tile, visual and qna
                  id: reportEmbedToken.embedUrl[0].id,
                  embedUrl: reportEmbedToken.embedUrl[0].embedUrl,
                  accessToken: reportEmbedToken.accessToken, // Keep as empty string, null or undefined
                  tokenType: models.TokenType.Embed,
                  pageView: "actualSize",
                  permissions: models.Permissions.All, //Probably need to update this dynamically
                  // set edit mode based on isEditing flag
                  viewMode: isEditing
                    ? models.ViewMode.Edit
                    : models.ViewMode.View,
                  // settings: {
                  //   layoutType: models.LayoutType.Custom,
                  //   customLayout: {
                  //     displayOption: models.DisplayOption.FitToWidth,
                  //     pageSize: {
                  //       type: models.PageSizeType.Custom, // Change to "Custom"
                  //     },
                  //   },
                  // },
                }}
                cssClassName={
                  isFullScreen ? classes.fullReport : classes.partialReport
                }
              />
            </Paper>
          </Grid>
        </Grid>
        <Dialog open={showRefresh} onClose={() => setShowRefresh(false)}>
          <DialogTitle>
            {t("strGen:pages.reports.reportspage.refreshdialog.title")}
          </DialogTitle>
          <DialogContent>
            <Typography variant="body2">
              {t("strGen:pages.reports.reportspage.refreshdialog.message")}
            </Typography>
          </DialogContent>
          <DialogActions>
            <IppButton
              onClick={() => {
                handleRefresh();
              }}
            >
              {t("strGen:buttons.refreshnow")}
            </IppButton>
            <IppButton onClick={handleCancel} variant="outlined">
              {t("strGen:buttons.no")}
            </IppButton>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showHelp}
          onClose={() => setShowHelp(false)}
          maxWidth="lg"
        >
          <DialogTitle>
            {t("strGen:pages.reports.reportspage.quicktipsandnavigation")}
          </DialogTitle>
          <DialogContent>
            <ReportHelpPage />
          </DialogContent>
          <DialogActions>
            <IppButton onClick={() => setShowHelp(false)}>
              {t("strGen:buttons.ok")}
            </IppButton>
          </DialogActions>
        </Dialog>
      </Box>
    );

  return (
    <div id="report-embed-page">
      <Root>{reportEmbedView}</Root>
    </div>
  );
};
