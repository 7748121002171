import { Box, Tooltip, Typography } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { useTypedTranslation } from "utils/customHooks";

interface AiBadgeProps {
  objectName: string;
}

const AiBadge = ({ objectName }: AiBadgeProps) => {
  const t = useTypedTranslation(["strGen"]);

  return (
    <Tooltip
      title={t("strGen:components.ippformheader.title.aigeneratedtooltip", {
        objectname: objectName,
      })}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <AutoAwesomeIcon sx={{ color: "#4C9A2A", fontSize: 16 }} />
        <Typography
          sx={{
            color: "#4C9A2A",
            fontSize: 15,
            fontWeight: "bold",
          }}
          variant="h6"
        >
          Ai
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default AiBadge;
