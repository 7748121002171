import { Menu, MenuItem } from "@progress/kendo-react-layout";
import menuLinkRender from "./menuLinkRender";
import { RootState } from "app/rootReducer";
import { useSelector } from "react-redux";
import { useTypedTranslation } from "utils/customHooks";

interface itemProps {
  currentProfile: any;
  clntModsByModID: any;
  showVertical: boolean;
}

export const AdminMenu = (props: itemProps) => {
  const { currentProfile, clntModsByModID, showVertical } = props;
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  const {
    ft_com_Phase,
    ft_eng_Phase,
    ft_ben_Tier,
    ft_all_Tag,
    ft_all_ProjectTypes,
    ft_ben_JobPosition,
    ft_ben_WorkForce,
    ft_ben_Ethnicity,
    ft_ben_CustomEmpCategory,
  } = useSelector((state: RootState) => state.client);

  return (
    <Menu linkRender={menuLinkRender} vertical={showVertical}>
      <MenuItem text="Insights" data="/admin" />
      <MenuItem
        text={t("objPlt:objects.project.name", { count: 5 })}
        data="/admin/projects"
      />
      <MenuItem text="Users" data="/admin/users/all">
        <MenuItem
          text={`${currentProfile.CompanyName} Users`}
          data="/admin/users"
        />
        <MenuItem text="All Users" data="/admin/users/all" />
        <MenuItem
          text={t("objPlt:objects.user.name_inactive")}
          data="/admin/users/inactiveUsers"
        />
      </MenuItem>
      <MenuItem text="Notifications" data="/admin/scheduler">
        <MenuItem text="Scheduled Notifications" data="/admin/scheduler" />
        {(clntModsByModID[2] || clntModsByModID[3]) && (
          <MenuItem text="Reminders" data="/admin/actionreminder" />
        )}
        <MenuItem
          text="Triggered Notifications"
          data="/admin/triggerednotificationsfeatures"
        ></MenuItem>
      </MenuItem>

      <MenuItem text="Branding" data="/admin/branding" />
      <MenuItem text="Features" data="/admin/features" />
      <MenuItem text="Lookup Lists" data="/admin/clientgroups">
        {(clntModsByModID[2] || clntModsByModID[3]) && (
          <MenuItem text="Action Types" data="/admin/clientActionTypes" />
        )}
        <MenuItem
          text={t("objPlt:objects.attachmentcategories.name", { count: 2 })}
          data="/admin/attachmentCategories"
        />
        {clntModsByModID[2] && (
          <MenuItem
            text="Commitment Categories"
            data="/admin/clientCommitmentCategories"
          />
        )}
        {clntModsByModID[2] && (
          <MenuItem
            text="Commitment Source Types"
            data="/admin/clientCommitmentSourceTypes"
          />
        )}
        {clntModsByModID[2] && (
          <MenuItem
            text="Commitment Status Types"
            data="/admin/clientCommitmentStatusTypes"
          />
        )}
        {clntModsByModID[3] && (
          <MenuItem
            text="Communication Types"
            data="/admin/clientInteractionTypes"
          />
        )}
        {clntModsByModID[1] && (
          <MenuItem
            text={t("objBen:objects.company.fields.ownershipstructure")}
            data="/admin/clientCompanyStructures"
          />
        )}
        <MenuItem text="Countries" data="/admin/clientCountry" />
        <MenuItem text="Currency" data="/admin/clientCurrency" />
        {clntModsByModID[1] && (
          <MenuItem
            text="Diversity Monitoring"
            data="/admin/diversityMonitoring"
          />
        )}
        <MenuItem
          text={t("objPlt:platformwide.indigenous.indigenousgroup_other")}
          data="/admin/clientgroups"
        />
        {clntModsByModID[1] && ft_ben_Ethnicity && (
          <MenuItem
            text={t("objBen:objects.employee.fields.ethnicity_other")}
            data="/admin/ethnicitys"
          />
        )}
        {clntModsByModID[1] && ft_ben_CustomEmpCategory && (
          <MenuItem
            text={t("objBen:objects.employee.fields.customCategory_other")}
            data="/admin/customEmpCategories"
          />
        )}
        {clntModsByModID[1] && ft_ben_JobPosition && ft_ben_WorkForce && (
          <MenuItem text="Job Positions" data="/admin/clientpositions" />
        )}
        {(clntModsByModID[3] || clntModsByModID[2]) && (
          <MenuItem
            text="Organization Types"
            data="/admin/clientOrganizationTypes"
          />
        )}
        {((clntModsByModID[2] && ft_com_Phase) || //feature access commitments
          (clntModsByModID[3] && ft_eng_Phase)) && ( //feature access engagment
          <MenuItem text="Phases" data="/admin/phases" />
        )}
        {ft_all_ProjectTypes && (
          <MenuItem
            text={t("objPlt:objects.project.projecttype.name", { count: 2 })}
            data="/admin/projectTypes"
          />
        )}
        <MenuItem
          text={t("objPlt:objects.standardstatement.name", { count: 2 })}
          data="/admin/standardStatement"
        />
        {ft_all_Tag && <MenuItem text="Tags" data="/admin/tags" />}
        {clntModsByModID[1] && ft_ben_Tier && (
          <MenuItem
            text={t("objBen:objects.tiers.name", { count: 2 })}
            data="/admin/tiers"
          />
        )}
      </MenuItem>
      <MenuItem text="Edit Reports">
        {currentProfile.IsClientAdmin &&
          clntModsByModID[3] &&
          clntModsByModID[3].ReportID && (
            <MenuItem text="Engagement" data="/admin/engagement/reporting" />
          )}
        {currentProfile.IsClientAdmin &&
          clntModsByModID[2] &&
          clntModsByModID[2].ReportID && (
            <MenuItem text="Commitments" data="/admin/commitments/reporting" />
          )}
        {currentProfile.IsClientAdmin &&
          clntModsByModID[1] &&
          clntModsByModID[1].ReportID && (
            <MenuItem text="Benefits" data="/admin/benefits/reporting" />
          )}
        {currentProfile.IsClientAdmin &&
          clntModsByModID[6] &&
          clntModsByModID[6].ReportID && (
            <MenuItem text="SSHE" data="/admin/sshe/reporting" />
          )}
      </MenuItem>
    </Menu>
  );
};
