import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Payload {
  page: string;
}

type SetFilterPayload = Payload & FilterState;
type SetSkipPayload = Payload & {
  skip: FilterState["skip"];
};
type SetSortPayload = Payload & {
  sort: FilterState["sort"];
};
type SetTakePayload = Payload & {
  take: FilterState["take"];
};
type SetDisplayColumnsPayload = Payload & {
  columns: FilterState["displayColumns"];
};

// how to handle type coversion of states since its also built in
interface FilterState {
  filter?: any;
  take?: number;
  skip?: number;
  sort?: any;
  displayColumns?: {
    [columnId: string]: boolean;
  };
}

interface InventoryState {
  filtersByInv: Record<string, FilterState>;
}

const filterInitialState: FilterState = {
  filter: {
    filters: [] as any,
    logic: "and",
  },
  take: 25,
  skip: 0,
  sort: [] as any,
  displayColumns: {},
};

const invInitialState: InventoryState = {
  filtersByInv: {},
};

const filterStates = createSlice({
  name: "filterState",
  initialState: invInitialState,
  reducers: {
    setFilter(state, { payload }: PayloadAction<SetFilterPayload>) {
      const { page, filter, take } = payload;

      if (!state.filtersByInv[page]) {
        state.filtersByInv[page] = {
          ...filterInitialState,
          take,
        };
      }

      state.filtersByInv[page].filter = filter;
    },

    setSkip(state, { payload }: PayloadAction<SetSkipPayload>) {
      const { page, skip } = payload;

      if (!state.filtersByInv[page]) {
        state.filtersByInv[page] = {
          ...filterInitialState,
        };
      }

      state.filtersByInv[payload.page].skip = skip;
    },

    setSort(state, { payload }: PayloadAction<SetSortPayload>) {
      const { page, sort } = payload;

      if (!state.filtersByInv[page]) {
        state.filtersByInv[page] = {
          ...filterInitialState,
        };
      }

      state.filtersByInv[payload.page].sort = sort;
    },

    setTake(state, { payload }: PayloadAction<SetTakePayload>) {
      const { page, take } = payload;

      if (!state.filtersByInv[page]) {
        state.filtersByInv[page] = {
          ...filterInitialState,
        };
      }

      state.filtersByInv[page].take = take;
    },

    setDisplayColumns(
      state,
      { payload }: PayloadAction<SetDisplayColumnsPayload>
    ) {
      const { page, columns } = payload;

      if (!state.filtersByInv[page]) {
        state.filtersByInv[page] = {
          ...filterInitialState,
        };
      }

      state.filtersByInv[page].displayColumns = {
        ...state.filtersByInv[page].displayColumns,
        ...columns,
      };
    },

    clearFilterState: (state, { payload: page }: PayloadAction<string>) => {
      if (!state.filtersByInv[page]) {
        state.filtersByInv[page] = {
          ...filterInitialState,
        };
        return;
      }

      state.filtersByInv[page].filter = {
        ...filterInitialState.filter,
      };
      state.filtersByInv[page].skip = filterInitialState.skip;
      state.filtersByInv[page].sort = [...filterInitialState.sort];
    },
  },
});

export const {
  setFilter,
  setSkip,
  setSort,
  setTake,
  setDisplayColumns,
  clearFilterState,
} = filterStates.actions;

export default filterStates.reducer;
