import { useRef, useEffect, useState, useMemo, useCallback } from "react";
import { styled } from "@mui/material/styles";
import {
  Editor,
  EditorTools,
  ProseMirror,
  EditorUtils,
  EditorPasteEvent,
  EditorChangeEvent,
} from "@progress/kendo-react-editor";
import { Box, FormHelperText, Grid, Tooltip } from "@mui/material";
import { IppFormDivider } from "../IppFormDivider";
import { SanitizeHTML } from "utils/customXSS";
import { InfoOutlined } from "@mui/icons-material";
import { EditorView as KendoEditorView } from "@progress/kendo-editor-common";
import { InsertTextTool } from "./insertStandardStatementsTool";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { StandardStatement } from "api/standardStatementAPI";
import debounce from "lodash/debounce";
import { useFormikContextWithSubmit } from "utils/customHooks";

const PREFIX = "IppRichTextEditor";

const classes = {
  popoverWindow: `${PREFIX}-popoverWindow`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.popoverWindow}`]: {
    padding: theme.spacing(2),
    maxWidth: 600,
  },
}));

export type StandardStatementRecordTypes =
  | "InteractionDetails"
  | "InteractionInternalNotes"
  | "InteractionActionDetails"
  | "CommitmentDetails"
  | "CommitmentInternalNotes"
  | "CommitmentRegulatoryNotes"
  | "CommitmentLegalText"
  | "CommitmentActionDetails"
  | "InitiativeDetails"
  | "PaymentPeriodNotes"
  | "InteractionThreadSummary";

interface editorProps {
  id?: string;
  label: string;
  value: any;
  touchedExpression?: any;
  errorsExpression?: any;
  isEditing: boolean;
  height?: number;
  setFieldValue: any;
  toolTip?: string;
  standardStatementsRecordType?: StandardStatementRecordTypes;
  customEditorTool?: any;
}

interface InsertTextToolProps {
  view: KendoEditorView;
  standardStatements: StandardStatement[];
}

const { EditorState, EditorView, Plugin, PluginKey } = ProseMirror;

export const IppRichTextEditor = (props: editorProps) => {
  const {
    id,
    label,
    value,
    touchedExpression,
    errorsExpression,
    isEditing,
    height,
    setFieldValue,
    toolTip,
    standardStatementsRecordType,
    customEditorTool,
  } = props;

  const { standardStatementsById, standardStatementList } = useSelector(
    (state: RootState) => state.standardStatement
  );

  const standardStatements = useMemo(
    () => standardStatementList.map((id) => standardStatementsById[id]),
    [standardStatementsById, standardStatementList]
  );

  const filteredStandardStatements = useMemo(
    () =>
      standardStatementsRecordType
        ? standardStatements.filter(
            (statement) => statement[standardStatementsRecordType]
          )
        : undefined,
    [standardStatements, standardStatementsRecordType]
  );

  // objects for editor toolbar
  const {
    Bold,
    Italic,
    Underline,
    AlignLeft,
    AlignRight,
    AlignCenter,
    OrderedList,
    UnorderedList,
    FormatBlock,
  } = EditorTools;

  // utility functions to clean up pasted content
  const {
    pasteCleanup,
    sanitize,
    sanitizeClassAttr,
    sanitizeStyleAttr,
    removeAttribute,
    replaceImageSourcesFromRtf,
  } = EditorUtils;

  const pasteSettings = {
    convertMsLists: true,
    // stripTags: 'span|font'
    attributes: {
      class: sanitizeClassAttr,
      style: sanitizeStyleAttr,
      // lang: removeAttribute,
      "*": removeAttribute,
    },
  };

  // to control editable state
  const [editable, setEditable] = useState<boolean>(false);
  const editableRef = useRef<boolean>(false);
  const view = useRef<any>(null);

  const onMount = useCallback((event: any) => {
    const state = event.viewProps.state;
    const plugins = [
      ...state.plugins,
      new Plugin({
        key: new PluginKey("readonly"),
        props: { editable: () => editableRef.current },
        filterTransaction: (tr, _st) => editableRef.current || !tr.docChanged,
      }),
    ];
    view.current = new EditorView(
      { mount: event.dom },
      {
        ...event.viewProps,
        state: EditorState.create({ doc: state.doc, plugins }),
      }
    );

    return view.current;
  }, []);

  const debouncedHandleChange = useMemo(
    () =>
      debounce((event: EditorChangeEvent) => {
        let sanitizedHtml = SanitizeHTML(event.html);

        const strippedContent = sanitizedHtml.replace(/<[^>]*>/g, "").trim();
        if (strippedContent === "") {
          sanitizedHtml = "";
        }

        id ? setFieldValue(id, sanitizedHtml) : setFieldValue(sanitizedHtml);
      }, 300),
    [id, setFieldValue]
  );

  const handleChange = (event: EditorChangeEvent) => {
    debouncedHandleChange(event);
  };

  useFormikContextWithSubmit(() => {
    debouncedHandleChange.flush();
  });

  useEffect(() => {
    setEditable(isEditing);
    editableRef.current = isEditing;
    if (view.current && editable) {
      view.current.updateState(view.current.state);
    }
  }, [editable, isEditing]);

  const hasError = touchedExpression && errorsExpression;

  return (
    <Root>
      <Box display="flex" alignItems="center" mb={0.5}>
        {label && (
          <Box mr={2} display="flex" alignItems="center">
            <IppFormDivider
              title={label}
              titleColor={hasError ? "error" : undefined}
            />
          </Box>
        )}
        {toolTip && (
          <Box display="flex" alignItems="center" pb={0.5}>
            <Tooltip title={toolTip} placement="top">
              <InfoOutlined fontSize="small" />
            </Tooltip>
          </Box>
        )}
      </Box>

      <Editor
        tools={[
          [Bold, Italic, Underline],
          [AlignLeft, AlignCenter, AlignRight],
          [OrderedList, UnorderedList],
          [FormatBlock],
          ...[
            filteredStandardStatements?.length
              ? [
                  (props: InsertTextToolProps) => (
                    <InsertTextTool
                      {...props}
                      standardStatements={filteredStandardStatements}
                    />
                  ),
                ]
              : [],
          ],
          ...(customEditorTool ? [() => customEditorTool] : []),
        ]}
        contentStyle={{ height: height }}
        resizable={true}
        defaultContent={value || ""}
        onChange={handleChange}
        defaultEditMode="div"
        onMount={onMount}
        onPasteHtml={(event: EditorPasteEvent) => {
          let html = pasteCleanup(sanitize(event.pastedHtml), pasteSettings);

          if (event.nativeEvent.clipboardData) {
            html = replaceImageSourcesFromRtf(
              html,
              event.nativeEvent.clipboardData
            );
          }

          const sanitizedHtml = SanitizeHTML(html);

          return sanitizedHtml;
        }}
      />

      {hasError && <FormHelperText error>{errorsExpression}</FormHelperText>}
    </Root>
  );
};

IppRichTextEditor.defaultProps = {
  height: 250,
};
