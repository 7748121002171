import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchCompanies } from "./CompaniesSlice";
import { fetchRegionsByRecordType } from "../regions/RegionSlice";
import { ConvertDateOffset } from "../../../utils/DateFunctions";
import { IppInventory } from "components/Inventory/IppInventory";
import LoadingIndicator from "components/LoadingIndicator";
import ErrorPage from "components/ErrorPage";
import { IsExtraLargeScreen } from "utils/mediaQueries";
import { useTypedTranslation } from "utils/customHooks";
import { IppChildAdd } from "components/IppChildAdd";

export const CompanyInventoryPage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);
  const isExtraLargeScreen = IsExtraLargeScreen();
  const [isAddingNewComp, setIsAddingNewComp] = useState(false);

  const { currentProfile } = useSelector((state: RootState) => state.profile);
  const { IsClient } = currentProfile;

  const {
    companyList,
    isLoading: companyIsLoading,
    error: compError,
    companiesById,
  } = useSelector((state: RootState) => state.companies);

  const {
    clientId,
    ft_ben_Tier,
    ft_ben_Region,
    ft_ben_WorkForce,
    ft_ben_Spend,
    ft_ben_ContractorsViewCompanies,
    isLoading: clientIsLoading,
  } = useSelector((state: RootState) => state.client);

  const { regionList, regionIsLoading } = useSelector(
    (state: RootState) => state.region
  );

  const isContractorView = !IsClient && ft_ben_ContractorsViewCompanies;

  useEffect(() => {
    const fetchData = async () => {
      if (clientId !== -1) {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(fetchCompanies(accessToken, clientId));
          if (ft_ben_Region && !isContractorView) {
            dispatch(fetchRegionsByRecordType(accessToken, "Company"));
          }
        } catch (e) {
          console.error(e);
        }
      }
    };

    fetchData();
  }, [
    clientId,
    dispatch,
    ft_ben_Region,
    getAccessTokenSilently,
    isContractorView,
  ]);

  if (compError) return <ErrorPage errMsg={compError} />;
  if (companyIsLoading || clientIsLoading || regionIsLoading)
    return <LoadingIndicator />;

  const companies = companyList.map((id) => companiesById[id]);

  const data = companies.map((p) => ({
    ...p,
    CompanyName: p.CompanyName || "* Missing name",
    ...(isContractorView
      ? {}
      : {
          LastPaymentDate: p.LastPaymentDate
            ? ConvertDateOffset(p.LastPaymentDate)
            : null,
          LastHoursDate: p.LastHoursDate
            ? ConvertDateOffset(p.LastHoursDate)
            : null,
        }),
  }));

  const compCols = isContractorView
    ? [
        { field: "CompanyName", title: "Company" },
        { field: "Address", title: "Address" },
        { field: "TownCity", title: "Town/City" },
        { field: "ProvinceState", title: "Province/State" },
        { field: "CountryName", title: "Country" },
        { field: "PostalCode", title: "Postal Code" },
      ]
    : [
        { field: "CompanyName", title: "Company" },
        ...(ft_ben_Tier
          ? [
              {
                field: "TierName",
                title: t("objBen:objects.tiers.name"),
                columnWidth: 150,
              },
            ]
          : []),
        { field: "TownCity", title: "Town/City" },
        { field: "ProvinceState", title: "Province/State" },
        ...(ft_ben_Region && regionList.length && !isContractorView
          ? [
              {
                field: "RegionName",
                title: t("objBen:objects.company.fields.region.name"),
              },
            ]
          : []),
        { field: "ShowAsActive", title: "Active?", filter: "boolean" },
        {
          field: "ActiveUsersCount",
          title: "Active Users",
          filter: "numeric",
          format: "{0:n0}",
          columnWidth: isExtraLargeScreen ? 200 : 150,
        },
        ...(ft_ben_WorkForce
          ? [
              {
                field: "EmployeeCount",
                title: "Active Employees",
                filter: "numeric",
                format: "{0:n0}",
                columnWidth: isExtraLargeScreen ? 200 : 150,
              },
              {
                field: "LastHoursDate",
                title: "Last Work Hours",
                filter: "date",
                format: "{0:d}",
                columnWidth: isExtraLargeScreen ? 200 : 150,
              },
            ]
          : []),
        ...(ft_ben_Spend
          ? [
              {
                field: "LastPaymentDate",
                title: "Last Spend Date",
                filter: "date",
                format: "{0:d}",
                columnWidth: isExtraLargeScreen ? 200 : 150,
              },
            ]
          : []),
      ];

  const buttonProps = {
    showAdd: true,
    ...(isContractorView
      ? {
          addBtnOnClick: () => setIsAddingNewComp(true),
          showAddText: t("objBen:objects.company.newcompanyrequest"),
        }
      : {}),
  };

  return (
    <div id="company-inventory-page">
      <IppInventory
        title="Companies"
        tableData={data}
        idField="CompanyID"
        columns={compCols}
        isFiltered
        showDetail
        buttonProps={buttonProps}
        hideExport={isContractorView}
        searchByColumn="CompanyName"
        searchByPlaceholder="Search by Company Name"
      />
      {isAddingNewComp && (
        <IppChildAdd
          title={t("objBen:objects.company.name")}
          parentTitle={""}
          parentID={-1}
          addFormOpen={isAddingNewComp}
          closeAction={() => setIsAddingNewComp(false)}
          setDidSaveInventory={false}
          parentValue={null}
          formTitle={t("strGen:prompts.add.addtitlewitharticle", {
            fieldname: t("objBen:objects.company.newcompanyrequest"),
          })}
          formDescription={t("strGen:forms.description.addobjrequestreview", {
            objectname: t("objBen:objects.company.name"),
            button: t("strGen:buttons.savechanges"),
          })}
        />
      )}
    </div>
  );
};
