import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  TextField,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { Grid as KendoGrid, GridColumn } from "@progress/kendo-react-grid";
import { GridPageChangeEvent } from "@progress/kendo-react-grid";
import LoadingIndicator from "components/LoadingIndicator";
import { Link } from "react-router-dom";
import { getCurrentModule } from "../../../utils/urlUtils";
import { useTypedTranslation } from "utils/customHooks";
import { IppMergeButton } from "components/Buttons/IppMergeButton";

interface CheckDuplicatesProps {
  originalID: number;
  isOpen: boolean;
  closeWindow: any;
}

export const ContactCheckDuplicates = (props: CheckDuplicatesProps) => {
  const { originalID, isOpen, closeWindow } = props;
  const t = useTypedTranslation(["objPlt", "strGen"]);
  const [filterString, setFilterString] = useState("");

  const {
    contactList,
    contactsById,
    isLoading: contactIsLoading,
  } = useSelector((state: RootState) => state.contacts);

  const contacts = contactList.map((id) => contactsById[id]);

  const processedData = contacts
    .filter((item) => item.ContactID !== originalID)
    .map((p) => {
      const groupCount = p.Groups ? p.Groups.split(",").length : 0;

      return {
        ...p,
        FullName: `${p.FirstName} ${p.Surname}`,
        SearchName: p.FirstName ?? "",
        GroupList:
          groupCount > 1
            ? `${p.FirstGroupName} +${groupCount - 1}`
            : p.FirstGroupName ?? "",
      };
    });

  // Pagination stuff
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);

  const filteredData =
    filterString === ""
      ? processedData
      : processedData.filter((item) =>
          item.FullName.toLowerCase().includes(filterString.toLowerCase())
        );

  const onPageChange = (event: GridPageChangeEvent) => {
    setSkip(event.page.skip);
    setTake(event.page.take);
  };

  const linkCell = (props: any) => {
    return (
      <td align="right">
        <Link
          to={`/${getCurrentModule()}/contacts/merge/${originalID}/${
            props.dataItem.ContactID
          }`}
        >
          <Tooltip
            title={t("strGen:pages.merge.selecttooltip", {
              fieldname: t("objPlt:objects.contact.name", { count: 2 }),
            })}
          >
            <IppMergeButton buttonText={t("strGen:pages.merge.select")} />
          </Tooltip>
        </Link>
      </td>
    );
  };

  return contactIsLoading ? (
    <LoadingIndicator />
  ) : (
    <Dialog open={isOpen} onClose={closeWindow} maxWidth="lg" fullWidth>
      <DialogTitle>
        {t("strGen:pages.merge.checkforduplicate", {
          fieldname: t("objPlt:objects.contact.name", { count: 2 }),
        })}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body2">
              {t("strGen:pages.merge.checkinfo", {
                fieldname: t("objPlt:objects.contact.name", { count: 2 }),
                fieldname2: t("objPlt:objects.contact.name"),
              })}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1">
              {t("strGen:pages.merge.searchfor")}
            </Typography>
          </Grid>
          <Grid item xs>
            <TextField
              id="txtSearch"
              placeholder={t("strGen:pages.merge.searchplaceholder")}
              onChange={(e) => setFilterString(e.target.value)}
              value={filterString}
            />
          </Grid>
          <Grid item xs={12}>
            <KendoGrid
              data={filteredData.slice(skip, skip + take)}
              pageable={{
                buttonCount: 5,
                info: true,
                pageSizes: [5, 10, 25, 50, 100],
              }}
              skip={skip}
              take={take}
              total={filteredData.length}
              onPageChange={onPageChange}
              sortable={true}
              groupable={false}
              rowHeight={1}
            >
              <GridColumn
                field="FullName"
                title={t("objPlt:objects.contact.fields.name")}
              />
              <GridColumn
                field="Email"
                title={t("objPlt:objects.contact.fields.emailaddress")}
              />
              <GridColumn
                field="PrimaryPhone"
                title={t("objPlt:objects.contact.fields.phone")}
              />
              <GridColumn
                field="TownCity"
                title={t("objPlt:objects.contact.fields.towncity")}
              />
              <GridColumn
                field="GroupList"
                title={t("objPlt:objects.contact.fields.group", { count: 2 })}
              />
              <GridColumn
                field="ContactID"
                title=" "
                cell={linkCell}
                width="110"
              />
            </KendoGrid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
