import { Grid } from "@mui/material";
import { IppTextField } from "./IppTextField";
import { IppAutocomplete } from "./IppAutocomplete";
import { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { FormikProps } from "formik";
import { markRequired } from "utils/functions";
import { useTypedTranslation } from "utils/customHooks";

interface IppLocationAutoCompleteProps {
  formik: FormikProps<{ [key: string]: any }>;
  isEditing: boolean;
  setIsEditing: ((isEditing: boolean) => void) | null;
  addressOptions?: {
    show: boolean;
    required?: boolean;
  };
  townCityOptions?: {
    show: boolean;
    required?: boolean;
  };
  provinceStateOptions?: {
    show: boolean;
    required?: boolean;
  };
  countryOptions?: {
    show: boolean;
    required?: boolean;
  };
  postalCodeOptions?: {
    show: boolean;
    required?: boolean;
  };
  parentTitle?: string;
}

export const IppLocationAutoComplete: React.FC<
  IppLocationAutoCompleteProps
> = ({
  formik,
  isEditing,
  setIsEditing,
  addressOptions,
  townCityOptions,
  provinceStateOptions,
  countryOptions,
  postalCodeOptions,
  parentTitle,
}) => {
  const [initialLoad, setInitialLoad] = useState(true);
  const t = useTypedTranslation(["objPlt"]);

  const { countriesStatesList, countriesStatesById } = useSelector(
    (state: RootState) => state.countriesStates
  );

  const countriesStates = countriesStatesList.map(
    (id) => countriesStatesById[id]
  );

  const { clientCountryList, clientCountriesById } = useSelector(
    (state: RootState) => state.clientCountries
  );

  const clientCountriesObject = clientCountryList.map(
    (id) => clientCountriesById[id]
  );
  const clientCountries = clientCountriesObject.map((item) => item.CountryName);
  const clientProvinceStates = countriesStates
    .filter((obj) => clientCountries.includes(obj.CountryName))
    .map((obj) => {
      return obj.ProvinceState;
    });
  const [provinces, setProvinces] = useState(clientProvinceStates);
  const countryFieldName =
    parentTitle === "Contact" ? "Country" : "CountryName";

  // Update Province/State Options anytime Form Country value changes
  useEffect(() => {
    const countryField =
      parentTitle === "Contact"
        ? formik.values.Country
        : formik.values.CountryName;

    const foundCountry = clientCountries.find(
      (country) => country === countryField
    );

    if (!countryField || !foundCountry) {
      setProvinces(clientProvinceStates);
    } else {
      const filteredProvinces = countriesStates
        .filter((obj) => obj.CountryName === countryField)
        .map((ele) => ele.ProvinceState);

      if (filteredProvinces.length === 0) {
        setProvinces(clientProvinceStates);
      } else {
        setProvinces(filteredProvinces);
      }
    }
  }, [formik.values.CountryName, formik.values.Country]);

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
      return;
    }

    formik.setFieldTouched(countryFieldName, true, true);
    formik.setFieldTouched("ProvinceState", true, true);
  }, [formik.values[countryFieldName], formik.values.ProvinceState]);

  return (
    <>
      {addressOptions && addressOptions.show && (
        <Grid item xs={6}>
          <IppTextField
            id="Address"
            label={t("objPlt:platformwide.fields.address")}
            value={formik.values.Address}
            onChangeFunction={formik.handleChange}
            touchedExpression={formik.touched.Address}
            errorsExpression={formik.errors.Address}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            required={addressOptions.required}
          />
        </Grid>
      )}
      {townCityOptions && townCityOptions.show && (
        <Grid item xs={6}>
          <IppTextField
            id="TownCity"
            label={markRequired(
              t("objPlt:platformwide.fields.towncity"),
              townCityOptions.required
            )}
            value={formik.values.TownCity}
            onChangeFunction={formik.handleChange}
            touchedExpression={formik.touched.TownCity}
            errorsExpression={formik.errors.TownCity}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
      )}
      {provinceStateOptions && provinceStateOptions.show && (
        <Grid item xs={6}>
          <IppAutocomplete
            id="ProvinceState"
            options={provinces}
            value={formik.values.ProvinceState}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                formik.setFieldValue("ProvinceState", newValue);
                formik.setFieldValue(
                  parentTitle === "Contact" ? "Country" : "CountryName",
                  countriesStates.find((obj) => obj.ProvinceState === newValue)
                    ?.CountryName || ""
                );
              } else {
                formik.setFieldValue("ProvinceState", "");
              }
            }}
            textValueFunction={formik.values.ProvinceState}
            touchedFunction={formik.touched.ProvinceState}
            errorFunction={formik.errors.ProvinceState}
            label={markRequired(
              t("objPlt:platformwide.fields.provincestate"),
              provinceStateOptions.required
            )}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
      )}
      {countryOptions && countryOptions.show && (
        <Grid item xs={6}>
          <IppAutocomplete
            id={parentTitle === "Contact" ? "Country" : "CountryName"}
            options={clientCountries}
            value={
              parentTitle === "Contact"
                ? formik.values.Country
                : formik.values.CountryName
            }
            textValueFunction={
              parentTitle === "Contact"
                ? formik.values.Country
                : formik.values.CountryName
            }
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                formik.setFieldValue(
                  parentTitle === "Contact" ? "Country" : "CountryName",
                  newValue
                );
                formik.setFieldValue("ProvinceState", "");
                setProvinces(
                  countriesStates
                    .filter((obj) => obj.CountryName === newValue)
                    .map((ele) => ele.ProvinceState)
                );
              } else {
                formik.setFieldValue(
                  parentTitle === "Contact" ? "Country" : "CountryName",
                  ""
                );
                setProvinces(clientProvinceStates);
              }
            }}
            label={markRequired(
              t("objPlt:platformwide.fields.country"),
              countryOptions.required
            )}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            touchedFunction={
              parentTitle === "Contact"
                ? formik.touched.Country
                : formik.touched.CountryName
            }
            errorFunction={
              parentTitle === "Contact"
                ? formik.errors.Country
                : formik.errors.CountryName
            }
          />
        </Grid>
      )}
      {postalCodeOptions && postalCodeOptions.show && (
        <Grid item xs={6}>
          <IppTextField
            id="PostalCode"
            label={t("objPlt:platformwide.fields.postalcode")}
            value={formik.values.PostalCode}
            onChangeFunction={formik.handleChange}
            touchedExpression={formik.touched.PostalCode}
            errorsExpression={formik.errors.PostalCode}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            required={postalCodeOptions.required}
          />
        </Grid>
      )}
    </>
  );
};

IppLocationAutoComplete.defaultProps = {
  addressOptions: {
    show: true,
    required: false,
  },
  townCityOptions: {
    show: true,
    required: false,
  },
  provinceStateOptions: {
    show: true,
    required: false,
  },
  countryOptions: {
    show: true,
    required: false,
  },
  postalCodeOptions: {
    show: true,
    required: false,
  },
};
