import { Grid } from "@mui/material";
import { AttachmentCategory } from "api/attachmentCategoryAPI";
import { isLinkAttachment } from "api/attachmentsAPI";
import { RootState } from "app/rootReducer";
import { IppAutocomplete } from "components/IppAutocomplete";
import { IppTextField } from "components/IppTextField";
import { FormikErrors, FormikProps, FormikTouched } from "formik";
import { ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { useTypedTranslation } from "utils/customHooks";

type BaseAttachmentFormValues = {
  FileDescription: string;
  AttachmentCategoryID: number | null;
};

// File attachment type
export type FileAttachmentFormValues = BaseAttachmentFormValues & {
  type: "fileAttachment";
};

// Link attachment type
export type LinkAttachmentFormValues = BaseAttachmentFormValues & {
  type: "linkAttachment";
  Link: string;
  LinkTitle: string;
};

// Union type
export type AttachmentFormValues =
  | FileAttachmentFormValues
  | LinkAttachmentFormValues;

interface AttachmentFormFieldsProps {
  values: AttachmentFormValues;
  touched: FormikTouched<AttachmentFormValues>;
  errors: FormikErrors<AttachmentFormValues>;
  handleChange: FormikProps<AttachmentFormValues>["handleChange"];
  setFieldValue: FormikProps<AttachmentFormValues>["setFieldValue"];
}

export const AttachmentFormFields = ({
  values,
  touched,
  errors,
  handleChange,
  setFieldValue,
}: AttachmentFormFieldsProps) => {
  const t = useTypedTranslation(["objPlt", "strGen"]);

  const { attachmentCategoriesById, attachmentCategoryList } = useSelector(
    (state: RootState) => state.attachmentCategory
  );

  const attachmentCategories = attachmentCategoryList.map(
    (id) => attachmentCategoriesById[id] as AttachmentCategory
  );

  return (
    <>
      {isLinkAttachment(values) && (
        <>
          <Grid item xs={12}>
            <IppTextField
              id="LinkTitle"
              label={t("objPlt:objects.attachment.fields.linktitle")}
              required
              isEditing={true}
              setIsEditing={null}
              onChangeFunction={handleChange}
              value={values.LinkTitle}
              touchedExpression={
                (touched as FormikTouched<LinkAttachmentFormValues>).LinkTitle
              }
              errorsExpression={
                (errors as FormikErrors<LinkAttachmentFormValues>).LinkTitle
              }
            />
          </Grid>
          <Grid item xs={12}>
            <IppTextField
              id="Link"
              label={t("objPlt:objects.attachment.fields.hyperlink")}
              required
              isEditing={true}
              placeholder="http://..., https://..., ftp://..."
              setIsEditing={null}
              onChangeFunction={handleChange}
              value={values.Link}
              touchedExpression={
                (touched as FormikTouched<LinkAttachmentFormValues>).Link
              }
              errorsExpression={
                (errors as FormikErrors<LinkAttachmentFormValues>).Link
              }
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <IppTextField
          id="FileDescription"
          label={t("objPlt:objects.attachment.fields.description")}
          isEditing={true}
          setIsEditing={null}
          onChangeFunction={handleChange}
          value={values.FileDescription}
          touchedExpression={touched.FileDescription}
          errorsExpression={errors.FileDescription}
        />
      </Grid>
      <Grid item xs={12}>
        <IppAutocomplete
          id="AttachmentCategoryID"
          label={t("objPlt:objects.attachment.fields.attachmentcategory")}
          options={attachmentCategories}
          optionLabelFunction={(option: AttachmentCategory) =>
            option.AttachmentCategoryName
          }
          value={
            attachmentCategories.find((obj) => {
              return obj.AttachmentCategoryID === values.AttachmentCategoryID;
            }) ?? null
          }
          onChangeFunction={(_: ChangeEvent, newValue: AttachmentCategory) => {
            setFieldValue(
              "AttachmentCategoryID",
              newValue?.AttachmentCategoryID ?? null
            );
          }}
          autoPopulate={false}
          isEditing={true}
          setIsEditing={null}
          touchedFunction={touched.AttachmentCategoryID}
          errorFunction={errors.AttachmentCategoryID}
        />
      </Grid>
    </>
  );
};
