import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchUsers } from "../../users/UsersSlice";
import LoadingIndicator from "components/LoadingIndicator";
import { IppInventory } from "components/Inventory/IppInventory";
import { fetchCompanies } from "features/benefits/companies/CompaniesSlice";
import ErrorPage from "components/ErrorPage";
import { ConvertDateOffset } from "../../../utils/DateFunctions";
import { useTypedTranslation } from "utils/customHooks";

interface userInvProps {
  companyID?: number;
}

export const UserInventoryPage = (props: userInvProps) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "objBen", "strGen"]);

  const compID = props.companyID;

  const {
    userList,
    isLoading,
    error: usersError,
    usersById,
  } = useSelector((state: RootState) => state.users);

  const { companiesById, isLoading: companyIsLoading } = useSelector(
    (state: RootState) => state.companies
  );

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const users = userList.map((id) => usersById[id]);

  useEffect(() => {
    (async () => {
      try {
        if (clientId !== -1 && currentProfile?.CompanyID) {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          if (compID) {
            dispatch(fetchUsers(accessToken, clientId, compID));
          } else {
            dispatch(
              fetchUsers(accessToken, clientId, currentProfile.CompanyID)
            );
          }
          dispatch(fetchCompanies(accessToken, clientId));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, currentProfile, compID, dispatch, getAccessTokenSilently]);

  const newList = users.map((p) => {
    let n = Object.assign({}, p) as any;
    if (!companyIsLoading && companiesById && companiesById[n.CompanyID]) {
      n.CompanyName = companiesById[n.CompanyID].CompanyName;
    }

    if (p.LastActive !== null) {
      n.LastActive = ConvertDateOffset(p.LastActive);
    } else {
      n.LastActive = null;
    }

    return n;
  });

  const dataColumns = [
    { field: "Surname", title: t("objPlt:objects.user.fields.surname") },
    { field: "FirstName", title: t("objPlt:objects.user.fields.firstname") },
    { field: "CompanyName", title: t("objBen:objects.company.name") },
    {
      field: "EmailAddress",
      title: t("objPlt:objects.user.fields.emailaddress"),
    },
    {
      field: "LastActive",
      title: t("objPlt:objects.user.fields.lastonline"),
      filter: "date",
      format: "{0:g}",
    },
  ];

  if (usersError) {
    return <ErrorPage errMsg={usersError} />;
  }

  let userInvView =
    isLoading || clientIsLoading || companyIsLoading ? (
      <LoadingIndicator />
    ) : (
      <div>
        <IppInventory
          title={t("objPlt:objects.user.name", { count: 2 })}
          tableData={newList}
          idField="UserAccountID"
          columns={dataColumns}
          isFiltered={true}
          showDetail={true}
          buttonProps={{
            showAdd: true,
          }}
          searchByColumn={"Surname"}
          searchByPlaceholder={t("strGen:filters.searchby", {
            fieldname: t("objPlt:objects.user.fields.surname"),
          })}
        />
      </div>
    );

  return <div id="user-inventory-page">{userInvView}</div>;
};
