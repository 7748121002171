import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import {
  fetchThreadInteractionsByThread,
  updBulkThreadInteractions,
} from "./ThreadInteractionSlice";
import { Box, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { IppFormHeader } from "components/IppFormHeader";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import LoadingIndicator from "components/LoadingIndicator";
import { Prompt } from "react-router";
import { IppDisplayField } from "components/IppDisplayField";
import { IppRichTextEditor } from "components/RichTextEditor/IppRichTextEditor";
import { IppDisplayRichText } from "components/IppDisplayRichText";
import {
  fetchInteractions,
  updInteraction,
} from "../interaction/InteractionSlice";
import { IsMedLg, IsMedSmall } from "utils/mediaQueries";
import { useSnackBarConstants, useTypedTranslation } from "utils/customHooks";
import { addThread, delThread, updThread } from "./ThreadSlice";
import { IppTextField } from "components/IppTextField";
import { ThreadValidationSchema } from "./ThreadValidation";
import { IppTabPanel } from "components/IppTabPanel";
import { SanitizeHTML } from "utils/customXSS";
import { ConvertDateOffset } from "utils/DateFunctions";
import { fetchProjects } from "features/project/ProjectSlice";
import { IppAutocomplete } from "components/IppAutocomplete";
import { UserWriteAccess } from "utils/userAccess";
import { fetchDeleteChecksByThread } from "features/deleteCheck/DeleteCheckSlice";
import { IppChildInventory } from "components/Inventory/IppChildInventory";
import { fetchStandardStatements } from "features/platform/admin/standardStatement/StandardStatementSlice";
import { IppStaticTextfield } from "components/IppStaticTextfield";
import { IppGenerateSummaryButton } from "components/Buttons/IppGenerateSummaryButton";
import { generateThreadSummaryWithAi } from "api/stakeholder/threadAPI";
import { IppGenerateSummaryDialog } from "components/IppGenerateSummaryDialog";
import { generateInteractionDetailsWithAi } from "api/stakeholder/interactionAPI";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { IppDownloadPdfButton } from "components/Buttons/IppDownloadPdfButton";
import { generatePDF } from "utils/functions";

const PREFIX = "InteractionThreadForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    maxWidth: 1150,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const ThreadForm = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen", "objStk"]);
  const customBP = IsMedSmall();
  const isML = IsMedLg();
  const snackbarConstants = useSnackBarConstants();

  const [activeTab, setActiveTab] = useState(0);

  const handleTabSelect = (e: any) => {
    setActiveTab(e?.selected);
  };

  let itemData = props.thread || {};

  const [isEditing, setIsEditing] = useState(itemData.ThreadID ? false : true);
  const [isAdding, setIsAdding] = useState(itemData.ThreadID ? false : true);
  const [isInvUpdated, setIsInvUpdated] = useState(false);
  const [generateDialogOpen, setGenerateDialogOpen] = useState(false);
  const [summaryKey, setSummaryKey] = useState(0);
  const [generateObjectType, setGenerateObjectType] = useState<
    "thread" | "interaction"
  >("thread");
  const [childInteractionDataForGenerate, setChildInteractionDataForGenerate] =
    useState<any>(null);

  const {
    clientId,
    aiEnabled,
    isLoading: clientIsLoading,
    ft_eng_InternalNotes,
  } = useSelector((state: RootState) => state.client);

  // get projects
  const { currentProfile, currentUserRoleList, currentUserRolesById } =
    useSelector((state: RootState) => state.profile);
  const userRoles = currentUserRoleList.map((id) => currentUserRolesById[id]);
  const {
    projectList,
    projectsById,
    isLoading: projectIsLoading,
  } = useSelector((state: RootState) => state.projects);
  // Only Admin and ClientPowerUser can create thread with ProjectID
  const projects = projectList
    .map((id) => projectsById[id])
    .filter((proj) => {
      if (currentProfile.IsClientAdmin) {
        return true;
      } else {
        return userRoles.some((role) => {
          return role.ProjectID === proj.ProjectID && role.ModuleRoleID === 5;
        });
      }
    });
  // get threadInteractions for Interaction tab
  let {
    threadInteractionList,
    threadInteractionsById,
    isLoading: tiIsLoading,
  } = useSelector((state: RootState) => state.threadInteractions);
  let threadInteractions = threadInteractionList.map(
    (id) => threadInteractionsById[id]
  );
  // get interactions for threadInteractions options
  const {
    interactionList,
    interactionsById,
    isLoading: interactionIsLoading,
  } = useSelector((state: RootState) => state.interactions);
  const interactions = interactionList.map((id) => interactionsById[id]);

  const { isLoading: standardStatementIsLoading } = useSelector(
    (state: RootState) => state.standardStatement
  );

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        isAdding && dispatch(fetchProjects(accessToken));
        !!itemData?.ThreadID &&
          dispatch(
            fetchThreadInteractionsByThread(accessToken, itemData.ThreadID)
          );
        dispatch(fetchInteractions(accessToken));
        dispatch(fetchStandardStatements(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, itemData.ThreadID, dispatch, getAccessTokenSilently]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        !!itemData?.ThreadID &&
          dispatch(
            fetchThreadInteractionsByThread(accessToken, itemData.ThreadID)
          );
      } catch (e) {
        console.error(e);
      }
    })();
  }, [interactionIsLoading]);

  /******** Thread form setup  ***********/
  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        // add Thread with interactions
        values.interactions = currentData?.map((i: any, index: number) => ({
          InteractionID: i?.InteractionID,
          OrderIndex: index + 1,
        }));
        dispatch(addThread(accessToken, values, true));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  if (itemData?.ThreadID) {
    //Update case
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(updThread(accessToken, values.ThreadID, values, true));
        } catch (e) {
          console.error(e);
        }
      })();
    };
  } else {
    itemData.ThreadID = "";
    itemData.ProjectID = null;
    itemData.Summary = "";
    itemData.interactions = {};
  }

  const handleDelete = () => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(delThread(accessToken, itemData?.ThreadID, true));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const checkChildInteractionForGenerateIsValid = (childInteraction: any) => {
    return (
      childInteraction?.InteractionID &&
      childInteraction?.InteractionTitle &&
      childInteraction?.ProjectID &&
      childInteraction?.InteractionDate &&
      childInteraction?.PersonResponsible &&
      childInteraction?.InteractionTypeID &&
      childInteraction?.StatusTypeID &&
      childInteraction?.InitiatedBy
    );
  };

  const childInteractionSubmitHandler = (values: any) => {
    if (!checkChildInteractionForGenerateIsValid(values)) {
      dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
      return;
    }

    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        const updateInteraction = {
          InteractionID: values.InteractionID,
          InteractionTitle: values.InteractionTitle,
          ProjectID: values.ProjectID,
          InteractionDate: values.InteractionDate,
          PersonResponsible: values.PersonResponsible,
          InteractionTypeID: values.InteractionTypeID,
          StatusTypeID: values.StatusTypeID,
          InitiatedBy: values.InitiatedBy,
          Details: values.Details,
        };

        dispatch(
          updInteraction(
            accessToken,
            updateInteraction.InteractionID,
            updateInteraction,
            null,
            null,
            null,
            true,
            snackbarConstants
          )
        );
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const showEdit = UserWriteAccess("Engagement", itemData.ProjectID);

  const formik = useFormik({
    initialValues: itemData,
    validationSchema: ThreadValidationSchema(t),
    onSubmit: submitFunc,
  });

  /******** ThreadInteraction tab  ***********/
  /******** Interaction options setup  ***********/
  let interactionData = interactions.map((p) => {
    let n = Object.assign({}, p) as any;
    if (p.InteractionDate !== null) {
      n.InteractionDate = ConvertDateOffset(p.InteractionDate);
    } else {
      n.InteractionDate = null;
    }
    if (p.IsOverdue) {
      n.StatusTypeName = t("objStk:objects.interaction.statustype.overdue");
    }
    if (!p.InteractionTitle || p.InteractionTitle === " ") {
      n.InteractionTitle = t("objStk:objects.interaction.missingtitle");
    }
    if (!p.ProjectID) {
      n.ProjectName = "";
    }
    n = { ...n, expanded: false, changeType: null };
    return n;
  });

  // Update interaction options based on ProjectID = selected or Null
  const [interactionOptions, setInteractionOptions] = useState(interactionData);
  useEffect(() => {
    let options = interactionData?.filter(
      (i) => i?.ProjectID === formik.values?.ProjectID || i?.ProjectID === null
    );
    setInteractionOptions(options);
  }, [formik.values?.ProjectID, interactionIsLoading]);

  const interactionColumns = [
    {
      field: "CommunicationID",
      title: t("objStk:objects.interaction.sequenceid"),
      format: "C-{0:0}",
      filter: "string",
      columnWidth: 80,
      noLink: true,
    },
    {
      field: "InteractionTitle",
      title: t("objStk:objects.interaction.fields.title"),
    },
    {
      field: "InteractionTypeName",
      title: t("objStk:objects.interaction.fields.type"),
      icons: true,
      columnWidth: 100,
    },
    {
      field: "InteractionDate",
      title: t("objStk:objects.interaction.fields.date"),
      filter: "date",
      format: "{0:d}",
      columnWidth: 110,
    },
    {
      field: "PersonResponsibleName",
      title: t("objStk:objects.interaction.fields.personresponsible"),
      columnWidth: 175,
    },
  ];

  /******** ThreadInteractions setup  ***********/
  let originalData = threadInteractions.map((ti: any, i: number) => {
    let formattedInteractionDate = ti?.InteractionDate
      ? ConvertDateOffset(ti?.InteractionDate)
      : null;
    return {
      ...ti,
      InteractionDate: formattedInteractionDate, // Update the date format
      InteractionProjectID: ti.ProjectID,
      expanded: false,
      changeType: null,
    };
  });

  // Generate Summary button should only show if EmailContent is not empty or there are interactions
  const hasContentForGenerateButton =
    aiEnabled && (formik.values?.EmailContent || originalData.length > 0);

  // Current data in threadinteraction table, update on page change
  let [currentData, setCurrentData] = useState<any[]>([]);
  useEffect(() => {
    let data = isAdding ? [] : originalData;
    setCurrentData(data);
  }, [tiIsLoading]);

  // Empty table once Project changes
  useEffect(() => {
    setCurrentData([]);
  }, [formik.values?.ProjectID]);

  const threadInteractionsColumns = [
    {
      field: "CommunicationID",
      title: t("objStk:objects.interaction.sequenceid"),
      format: "C-{0:0}",
      filter: "string",
      columnWidth: 80,
    },
    {
      field: "InteractionTitle",
      title: t("objStk:objects.interaction.fields.title"),
      isLink: true,
    },
    {
      field: "InteractionTypeName",
      title: t("objStk:objects.interaction.fields.type"),
      icons: true,
      columnWidth: 60,
    },
    {
      field: "InteractionDate",
      title: t("objStk:objects.interaction.fields.date"),
      filter: "date",
      format: "{0:d}",
      columnWidth: 150,
    },
    {
      field: "PersonResponsibleName",
      title: t("objStk:objects.interaction.fields.personresponsible"),
      columnWidth: 175,
    },
  ];

  // Thread & TI expanded detail component
  const DetailComponent = (props: any) => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <IppDisplayField
            label={t("objStk:objects.interaction.fields.project")}
            value={props.dataItem?.ProjectName}
            isEditing={false}
            setIsEditing={() => {}}
            showEdit={false}
          />
        </Grid>
        <Grid item xs={6}>
          <IppDisplayField
            label={t("objStk:objects.interaction.fields.status")}
            value={props.dataItem?.StatusTypeName}
            isEditing={false}
            setIsEditing={() => {}}
            showWarning={props.dataItem?.IsOverdue}
            warningMessage={t("objStk:objects.interaction.statustype.overdue", {
              fieldname: t("objStk:objects.interaction.fields.date"),
            })}
            showEdit={false}
          />
        </Grid>
        <Grid item xs={6}>
          <IppDisplayField
            label={t("objStk:objects.interaction.fields.contact")}
            value={props.dataItem?.Contacts}
            isEditing={false}
            setIsEditing={() => {}}
            showEdit={false}
          />
        </Grid>
        <Grid item xs={6}>
          <IppDisplayField
            label={t("objStk:objects.interaction.fields.groups")}
            value={props.dataItem?.Groups}
            isEditing={false}
            setIsEditing={() => {}}
            showEdit={false}
          />
        </Grid>
        <Grid item xs={6}>
          <IppDisplayField
            label={t("objStk:objects.interaction.fields.initiatedby")}
            value={props.dataItem?.InitiatedBy}
            isEditing={false}
            setIsEditing={() => {}}
            showEdit={false}
          />
        </Grid>
        {ft_eng_InternalNotes && props.dataItem?.InternalNotes && (
          <Grid item xs={12}>
            <IppDisplayRichText
              label={t("objStk:objects.interaction.fields.internalnotes")}
              value={props.dataItem?.InternalNotes}
              isEditing={false}
              setIsEditing={() => {}}
              showEdit={false}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <IppDisplayRichText
            label={t("objStk:objects.interaction.fields.details")}
            value={props.dataItem?.Details}
            isEditing={false}
            setIsEditing={() => {}}
            showEdit={false}
            button={
              aiEnabled && showEdit ? (
                <IppGenerateSummaryButton
                  onClick={() => {
                    setGenerateObjectType("interaction");
                    setChildInteractionDataForGenerate(props.dataItem);
                    setGenerateDialogOpen(true);
                  }}
                  disabled={
                    !checkChildInteractionForGenerateIsValid(props.dataItem)
                  }
                  toolTipMessage={
                    !checkChildInteractionForGenerateIsValid(props.dataItem)
                      ? t("strGen:buttons.tooltip.generatesummarydisabled", {
                          fieldname: t(
                            "objStk:objects.interaction.fields.details"
                          ),
                        })
                      : t("strGen:buttons.tooltip.generatedetails")
                  }
                  isIconButton={false}
                  variant="text"
                  child={t("strGen:buttons.generatefieldname", {
                    fieldname: t("objStk:objects.interaction.fields.details"),
                  })}
                  sx={{
                    fontSize: "0.7rem",
                  }}
                />
              ) : undefined
            }
          />
          <Grid item xs={12}>
            <IppDisplayRichText
              label={t("objStk:objects.interaction.fields.emailcontent")}
              value={SanitizeHTML(props.dataItem?.EmailContent)}
              isEditing={false}
              setIsEditing={() => {}}
              showEdit={false}
              button={
                props.dataItem?.EmailContent ? (
                  <IppDownloadPdfButton
                    onClick={() =>
                      generatePDF(
                        props.dataItem.EmailContent,
                        "C-" +
                          props.dataItem.CommunicationID +
                          "  " +
                          (props.dataItem.InteractionTitle ?? "Title Missing")
                      )
                    }
                    toolTipMessage={t("strGen:buttons.tooltip.downloadpdf")}
                    isIconButton={false}
                    variant="text"
                    child={t("strGen:buttons.dowloadpdf")}
                    sx={{
                      fontSize: "0.7rem",
                    }}
                  />
                ) : undefined
              }
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  let interactionOptionProps = {
    optionColumns: interactionColumns,
    optionDetail: DetailComponent,
    optionSearch: "InteractionTitle",
  };

  let viewForm = (
    <Box display="flex" justifyContent="center">
      <Grid container className={classes.editForm} spacing={1}>
        <Grid item xs={12}>
          {isInvUpdated && (
            <Prompt
              when={isInvUpdated}
              message={t("strGen:prompts.unsavedchanges")}
            />
          )}
          <Paper className={classes.boxSpace}>
            <Grid container spacing={1}>
              <IppFormHeader
                title={t("objStk:objects.thread.name")}
                isEditing={isEditing}
                isAdding={isAdding}
                returnPath={`/engagement/communicationthreads`}
                ShowAiBadge={formik.values.ShowAiBadge}
              />
              <Grid item xs={12}>
                <IppDisplayField
                  showLabel={false}
                  value={
                    <>
                      <Typography variant="subtitle1" gutterBottom>
                        {`T-${formik.values.SequenceID} ${formik.values.ThreadTitle}`}
                      </Typography>
                    </>
                  }
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={12}>
                <IppDisplayField
                  label={t("objStk:objects.thread.fields.project")}
                  value={formik.values.ProjectName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={false}
                />
              </Grid>
              <Grid item xs={12}>
                <IppDisplayRichText
                  label={t("objStk:objects.thread.fields.summary")}
                  value={formik.values.Summary}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                  button={
                    aiEnabled && showEdit ? (
                      <IppGenerateSummaryButton
                        onClick={() => {
                          setGenerateObjectType("thread");
                          setGenerateDialogOpen(true);
                        }}
                        disabled={
                          !formik.isValid || !hasContentForGenerateButton
                        }
                        toolTipMessage={
                          !formik.isValid
                            ? t(
                                "strGen:buttons.tooltip.generatesummarydisabled",
                                {
                                  fieldname: `a ${t(
                                    "objStk:objects.thread.fields.summary"
                                  )}`,
                                }
                              )
                            : !hasContentForGenerateButton
                            ? t(
                                "strGen:buttons.tooltip.generatesummarymissingcontent",
                                {
                                  fieldname: `a ${t(
                                    "objStk:objects.thread.fields.summary"
                                  )}`,
                                  objectname: t("objStk:objects.thread.name"),
                                  conditions: t(
                                    "strGen:buttons.tooltip.generatesummarymissingcontentconditions.thread"
                                  ),
                                }
                              )
                            : t("strGen:buttons.tooltip.generatesummary")
                        }
                        isIconButton={false}
                        variant="text"
                        child={t("strGen:buttons.generatefieldname", {
                          fieldname: t("objStk:objects.thread.fields.summary"),
                        })}
                        sx={{
                          fontSize: "0.7rem",
                        }}
                      />
                    ) : undefined
                  }
                />
              </Grid>
              {aiEnabled && formik.values.EmailContent && (
                <Grid item xs={12}>
                  <IppDisplayRichText
                    label={t("objStk:objects.thread.fields.emailcontent")}
                    value={SanitizeHTML(formik.values.EmailContent)}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    button={
                      <IppDownloadPdfButton
                        onClick={() =>
                          generatePDF(
                            formik.values.EmailContent,
                            "T-" +
                              formik.values.ThreadID +
                              "  " +
                              (formik.values.ThreadTitle ?? "Title Missing")
                          )
                        }
                        toolTipMessage={t("strGen:buttons.tooltip.downloadpdf")}
                        isIconButton={false}
                        variant="text"
                        child={t("strGen:buttons.dowloadpdf")}
                        sx={{
                          fontSize: "0.7rem",
                        }}
                      />
                    }
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <IppFormButtons
                  isEditing={isEditing}
                  isAdding={isAdding}
                  setIsEditing={setIsEditing}
                  resetFunction={() => formik.resetForm()}
                  showDelete={showEdit}
                  deleteFunction={() => handleDelete()}
                  fetchFunc={fetchDeleteChecksByThread}
                  info={t("strGen:prompts.deletechecks.unlinkitems")}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );

  let editForm =
    clientIsLoading || interactionIsLoading || tiIsLoading ? (
      <LoadingIndicator />
    ) : (
      <Paper className={classes.boxSpace}>
        <form noValidate onSubmit={formik.handleSubmit}>
          {!formik.isSubmitting && (
            <Prompt
              when={formik.dirty || isInvUpdated}
              message={t("strGen:prompts.unsavedchanges")}
            />
          )}
          <Grid container spacing={1}>
            <IppFormHeader
              title={t("objStk:objects.thread.name")}
              isEditing={isEditing}
              isAdding={isAdding}
              returnPath={`/engagement/communicationthreads`}
              ShowAiBadge={formik.values.ShowAiBadge}
            />
            <Grid item xs={isAdding ? 12 : 9}>
              <IppTextField
                id="ThreadTitle"
                required={true}
                label={t("objStk:objects.thread.fields.title")}
                value={formik.values.ThreadTitle}
                onChangeFunction={formik.handleChange}
                errorsExpression={formik.errors.ThreadTitle}
                touchedExpression={
                  formik.touched.ThreadTitle || formik.errors.ThreadTitle
                }
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </Grid>
            {!isAdding && (
              <Grid item xs={3}>
                <IppStaticTextfield
                  id="SequenceID"
                  value={"T-" + formik.values.SequenceID}
                  label={t("objStk:objects.thread.fields.id")}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              {isAdding ? (
                <IppAutocomplete
                  id="ProjectID"
                  required
                  options={projects}
                  value={projects.find((obj) => {
                    return obj.ProjectID === formik.values.ProjectID;
                  })}
                  onChangeFunction={(event: ChangeEvent, newValue: any) => {
                    if (newValue) {
                      formik.setFieldValue("ProjectID", newValue.ProjectID);
                      formik.setFieldValue("ProjectName", newValue.ProjectName);
                    } else {
                      formik.setFieldValue("ProjectID", null);
                      formik.setFieldValue("ProjectName", null);
                    }
                  }}
                  label={t("objStk:objects.thread.fields.project")}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  optionLabelFunction={(option: any) => option.ProjectName}
                  errorFunction={formik.errors.ProjectID}
                  touchedFunction={formik.touched.ProjectID}
                  textValueFunction={
                    formik.values.ProjectID > 0 &&
                    projectsById[formik.values.ProjectID]
                      ? projectsById[formik.values.ProjectID].ProjectName
                      : ""
                  }
                />
              ) : (
                <IppTextField
                  id={"ProjectName"}
                  label={t("objStk:objects.thread.fields.project")}
                  value={formik.values.ProjectName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  disabled={true}
                  onChangeFunction={() => {}}
                  toolTip={t("strGen:tooltips.thread.project")}
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <IppRichTextEditor
                key={summaryKey}
                id="Summary"
                label={t("objStk:objects.thread.fields.summary")}
                value={formik.values.Summary}
                isEditing={isEditing}
                touchedExpression={formik.touched.Summary}
                errorsExpression={formik.errors.Summary}
                setFieldValue={formik.setFieldValue}
                height={160}
                standardStatementsRecordType="InteractionThreadSummary"
                customEditorTool={
                  aiEnabled && !isAdding ? (
                    <IppGenerateSummaryButton
                      onClick={() => {
                        setGenerateObjectType("thread");
                        setGenerateDialogOpen(true);
                      }}
                      disabled={!hasContentForGenerateButton}
                      toolTipMessage={
                        !hasContentForGenerateButton
                          ? t(
                              "strGen:buttons.tooltip.generatesummarymissingcontent",
                              {
                                fieldname: `a ${t(
                                  "objStk:objects.thread.fields.summary"
                                )}`,
                                objectname: t("objStk:objects.thread.name"),
                                conditions: t(
                                  "strGen:buttons.tooltip.generatesummarymissingcontentconditions.thread"
                                ),
                              }
                            )
                          : t("strGen:buttons.tooltip.generatesummary")
                      }
                      onMouseDown={(e) => e.preventDefault()}
                      onPointerDown={(e) => e.preventDefault()}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                      sx={{
                        ...props.sx,
                        textTransform: "none",
                        fontWeight: "normal",
                        fontFamily: "inherit",
                        fontSize: "inherit",
                        padding: "8px",
                        height: "38px",
                        width: "38px",
                        "& .MuiSvgIcon-root": {
                          fontSize: "1.25rem",
                        },
                      }}
                    />
                  ) : undefined
                }
              />
            </Grid>
            <Grid item xs={12}>
              <IppFormButtons
                isEditing={isEditing}
                isAdding={isAdding}
                setIsEditing={setIsEditing}
                resetFunction={() => formik.resetForm()}
                showDelete={true}
                deleteFunction={() => handleDelete()}
                fetchFunc={fetchDeleteChecksByThread}
                info={t("strGen:prompts.deletechecks.unlinkitems")}
              />
            </Grid>
          </Grid>
        </form>
      </Paper>
    );

  let pageForm =
    clientIsLoading ||
    tiIsLoading ||
    interactionIsLoading ||
    standardStatementIsLoading ? (
      <LoadingIndicator />
    ) : (
      <Grid container spacing={1}>
        <Grid item xl={4} xs={customBP ? 12 : 5}>
          {/* Note: expanded tab is too long, so the form is set to be sticky here */}
          <Box sx={{ position: "sticky", top: 8 }}>
            {isEditing ? editForm : viewForm}
          </Box>
        </Grid>
        <Grid item xl={8} xs={customBP ? 12 : 7}>
          {/* Related Tabs */}
          <Box>
            <Paper>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={activeTab}
                    onChange={handleTabSelect}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                  >
                    <Tab
                      value={0}
                      label={`${t("objStk:objects.interaction.name", {
                        count: currentData?.length,
                      })} (${currentData?.length})`}
                    />
                  </Tabs>
                </Box>
                <IppTabPanel value={activeTab} index={0}>
                  <Box>
                    {!tiIsLoading && (
                      <IppChildInventory
                        title={t("objStk:objects.interaction.name")}
                        parentTitle={t("objStk:objects.thread.name")}
                        linkURL={"communications"}
                        tableData={originalData}
                        idField={"ThreadInteractionID"}
                        nameField={"InteractionID"}
                        parentID={itemData?.ThreadID}
                        parentProjectID={formik.values?.ProjectID}
                        relatedField="InteractionID"
                        showSearchBar={true}
                        columns={
                          isML && !customBP
                            ? threadInteractionsColumns.slice(0, 4)
                            : threadInteractionsColumns
                        }
                        showAdd={!isAdding}
                        showLinkButton={true}
                        showEdit={!!formik.values?.ProjectID}
                        tableName={"ThreadInteraction"}
                        id={"ThreadInteractionList"}
                        label={t("objStk:objects.interaction.name")}
                        options={interactionOptions}
                        unfilteredOptions={interactionData}
                        selectedValues={currentData}
                        setSelectedValues={setCurrentData}
                        setDidSaveInventory={setIsInvUpdated}
                        parentValue={itemData}
                        isExpandable={true}
                        detailComponent={DetailComponent}
                        isDraggable={showEdit}
                        orderByDate={"InteractionDate"}
                        // Adding view hide Save and Cancel btns
                        // Hide Save btn if parent form has one
                        showSave={!isAdding && !isEditing}
                        showCancel={!isAdding}
                        optionTableProps={interactionOptionProps}
                        isChildSubmit={formik.isSubmitting && !isAdding}
                        updAction={updBulkThreadInteractions}
                        setIsInvUpdated={setIsInvUpdated}
                        showOptions={true}
                        disableBtns={false}
                      />
                    )}
                  </Box>
                </IppTabPanel>
              </Box>
            </Paper>
          </Box>
        </Grid>
        {aiEnabled &&
          generateDialogOpen &&
          (generateObjectType === "thread" ? (
            <IppGenerateSummaryDialog
              titleKey="threads"
              fieldname={t("objStk:objects.thread.fields.summary")}
              data={formik.values}
              initialValue={formik.values.Summary}
              aiSummaryApiFunction={generateThreadSummaryWithAi}
              open={generateDialogOpen}
              setOpen={setGenerateDialogOpen}
              onSubmit={(value: string) => {
                formik.setFieldValue("Summary", value);
                setSummaryKey((prev) => prev + 1);

                if (!isEditing) formik.handleSubmit();
              }}
              standardStatementsRecordType="InteractionThreadSummary"
            />
          ) : (
            <IppGenerateSummaryDialog
              titleKey="interactions"
              fieldname={t("objStk:objects.interaction.fields.details")}
              data={childInteractionDataForGenerate}
              initialValue={childInteractionDataForGenerate.Details}
              aiSummaryApiFunction={generateInteractionDetailsWithAi}
              open={generateDialogOpen}
              setOpen={setGenerateDialogOpen}
              onSubmit={(value: string) => {
                setChildInteractionDataForGenerate({
                  ...childInteractionDataForGenerate,
                  Details: value,
                });
                childInteractionSubmitHandler({
                  ...childInteractionDataForGenerate,
                  Details: value,
                });
              }}
              standardStatementsRecordType="InteractionDetails"
            />
          ))}
      </Grid>
    );

  return <Root>{pageForm}</Root>;
};
