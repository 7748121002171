import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  TextField,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { Grid as KendoGrid, GridColumn } from "@progress/kendo-react-grid";
import { GridPageChangeEvent } from "@progress/kendo-react-grid";
import LoadingIndicator from "components/LoadingIndicator";
import { Link } from "react-router-dom";
import { getCurrentModule } from "../../../utils/urlUtils";
import { IppMergeButton } from "components/Buttons/IppMergeButton";
import { useTypedTranslation } from "utils/customHooks";

interface CheckDuplicatesProps {
  originalID: number;
  isOpen: boolean;
  closeWindow: any;
}

export const CompanyCheckDuplicates = (props: CheckDuplicatesProps) => {
  const t = useTypedTranslation(["strGen"]);
  const { originalID, isOpen, closeWindow } = props;
  const [filterString, setFilterString] = useState("");

  const { companyList, companiesById, isLoading } = useSelector(
    (state: RootState) => state.companies
  );

  const companies = companyList.map((id) => companiesById[id]);

  // Filter company list should exclude Client Company.
  const processedData = companies
    .filter((item) => item.CompanyID !== originalID && !item.IsClient)
    .map((p) => ({
      ...p,
      CompanyName: p.CompanyName || "* Missing",
    }));

  // ***** Pagination Stuff *****
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);

  // Filtered Data
  const filteredData =
    filterString === ""
      ? processedData
      : processedData.filter((item) =>
          item.CompanyName.toLowerCase().includes(filterString.toLowerCase())
        );

  // Handle page change
  const onPageChange = (event: GridPageChangeEvent) => {
    setSkip(event.page.skip);
    setTake(event.page.take);
  };

  // Custom cell for the merge link
  const linkCell = (props: any) => (
    <td align="right">
      <Link
        to={`/${getCurrentModule()}/companies/merge/${originalID}/${
          props.dataItem.CompanyID
        }`}
      >
        <Tooltip
          title={t("strGen:pages.merge.selecttooltip", {
            fieldname: t("objBen:objects.company.name", { count: 2 }),
          })}
        >
          <IppMergeButton buttonText={t("strGen:pages.merge.select")} />
        </Tooltip>
      </Link>
    </td>
  );

  return isLoading ? (
    <LoadingIndicator />
  ) : (
    <Dialog open={isOpen} onClose={closeWindow} maxWidth="lg" fullWidth>
      <DialogTitle>Check for Duplicate Companies</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body2">
              This option is used to check for any duplicate companies, and
              potentially to merge and consolidate them. If there is a duplicate
              for this group, please select it from the list below.
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1">Search for:</Typography>
          </Grid>
          <Grid item xs>
            <TextField
              id="txtSearch"
              placeholder="Enter text to search for name"
              onChange={(e) => setFilterString(e.target.value)}
              value={filterString}
            />
          </Grid>
          <Grid item xs={12}>
            <KendoGrid
              data={filteredData.slice(skip, skip + take)}
              pageable={{
                buttonCount: 5,
                info: true,
                pageSizes: [5, 10, 25, 50, 100],
              }}
              skip={skip}
              take={take}
              total={filteredData.length}
              onPageChange={onPageChange}
              sortable={true}
              groupable={false}
              rowHeight={1}
            >
              <GridColumn field="CompanyName" title="Company" />
              <GridColumn field="VendorID" title="Vendor ID" />
              <GridColumn field="TownCity" title="City" />
              <GridColumn field="ProvinceState" title="Province/State" />
              <GridColumn field="EmployeeCount" title="Employees" />
              <GridColumn
                field="CompanyID"
                title=" "
                cell={linkCell}
                width="110"
              />
            </KendoGrid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
