import { Grid, Typography, IconButton, Box } from "@mui/material";
import { BackspaceOutlined } from "@mui/icons-material";
import { ChangeEvent, useEffect, useState } from "react";
import { FormikTouched } from "formik";
import { IppButton } from "./Buttons/IppButton";
import { useTypedTranslation } from "utils/customHooks";
import { IppMultiTextField } from "./IppMultiTextField";
import { ProximityIndicatorLSD } from "api/PILSDApi";
import { IppFreeSoloField } from "./IppFreeSoloField";

interface IppDynamicPILSDProps {
  id: string;
  options: ProximityIndicatorLSD[]; // Should be of type PILSD[]
  value: ProximityIndicatorLSD[]; // Should be of type PILSD[]
  onChangeFunction: any;
  label: string;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  touchedFunction?:
    | boolean
    | FormikTouched<any>
    | FormikTouched<any>[]
    | undefined;
}
export const IppDynamicPILSD = (props: IppDynamicPILSDProps) => {
  const {
    options,
    value,
    onChangeFunction,
    label,
    isEditing,
    setIsEditing,
    touchedFunction,
  } = props;

  const t = useTypedTranslation(["strGen"]);
  const proximityIndicators = Array.from(
    new Set(
      options
        .map((item) => item.ProximityIndicator.trim())
        .filter((indicator) => indicator)
    )
  );

  const [proximityIndicatorLSDs, setProximityIndicatorLSDs] = useState<
    ProximityIndicatorLSD[]
  >([]);

  useEffect(() => {
    if (value && Array.isArray(value) && value?.length) {
      setProximityIndicatorLSDs(value);
    } else {
      // Initialize with a blank row if value is null, undefined, or not an array
      setProximityIndicatorLSDs([
        {
          PILSDID: -1,
          ProximityIndicator: "",
          LegalSubdivision: "",
        },
      ]);
    }
  }, [value]);

  const handlePILSDs = () => {
    setProximityIndicatorLSDs([
      ...proximityIndicatorLSDs,
      {
        PILSDID: -1,
        ProximityIndicator: "",
        LegalSubdivision: "",
      },
    ]);
  };

  const handleRemovePILSD = (index: number) => {
    const updatedPILSDs = proximityIndicatorLSDs.filter(
      (_, idx) => idx !== index
    );
    onChangeFunction(updatedPILSDs);
  };

  const handlePILSDChange = (
    index: number,
    field: keyof ProximityIndicatorLSD,
    newValue: any
  ) => {
    const updatedPILSDs = proximityIndicatorLSDs.map((pilsd, idx) => {
      if (idx === index) {
        // Handle null or undefined newValue for ProximityIndicator
        const updatedValue = newValue == null ? "" : newValue;
        return { ...pilsd, [field]: updatedValue };
      } else {
        return pilsd;
      }
    });
    onChangeFunction(updatedPILSDs);
  };

  return (
    <>
      <Grid item xs={12} marginY={1}>
        <Typography>{label}</Typography>
      </Grid>
      {proximityIndicatorLSDs.map((pilsd, index) => (
        <Grid container spacing={2} key={index}>
          <Grid item xs={5.5}>
            <IppFreeSoloField
              id={`ProximityIndicatorLSD.${index}.ProximityIndicator`}
              options={proximityIndicators.filter(
                (indicator) =>
                  !proximityIndicatorLSDs.some(
                    (item) =>
                      item.ProximityIndicator?.toLowerCase() ===
                      indicator?.toLowerCase()
                  )
              )}
              value={pilsd?.ProximityIndicator}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                handlePILSDChange(index, "ProximityIndicator", newValue);
              }}
              label={t("objPlt:platformwide.fields.proximityindicator")}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              onInputChangeFunction={(event: ChangeEvent, newValue: any) => {
                handlePILSDChange(index, "ProximityIndicator", newValue);
              }}
              inputValue={pilsd?.ProximityIndicator}
              autoPopulate={false}
              freeSolo={true}
            />
          </Grid>
          <Grid item xs={5.5}>
            <IppMultiTextField
              id={`ProximityIndicator.${index}.LegalSubdivision`}
              label={t("objPlt:platformwide.fields.legalsubdivision")}
              required={false}
              options={[]}
              noOptionsText={t("strGen:components.nooptionstext")}
              value={pilsd?.LegalSubdivision}
              setValue={(newValue: any) => {
                handlePILSDChange(
                  index,
                  "LegalSubdivision",
                  newValue.join(", ")
                );
              }}
              touchedFunction={touchedFunction}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              multiple={true}
              autoPopulate={false}
              freeSolo={true}
              toolTip={t("strGen:tooltips.legalsubdivision")}
            />
          </Grid>
          <Grid item xs={1} alignSelf={"center"}>
            <IconButton
              color="error"
              onClick={() => handleRemovePILSD(index)}
              disabled={proximityIndicatorLSDs?.length <= 1}
              size="large"
            >
              <BackspaceOutlined />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <IppButton
          variant="outlined"
          color="primary"
          size="medium"
          onClick={() => handlePILSDs()}
          disabled={!isEditing}
          noTimeout={true}
        >
          {t("strGen:buttons.addobj", {
            objectname: t("objPlt:platformwide.fields.proximitylsd"),
          })}
        </IppButton>
      </Box>
    </>
  );
};
