import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  Company,
  CompaniesResult,
  getCompanies,
  createCompany,
  updateCompany,
  mergeCompany,
  deleteCompany,
  getCompaniesForReview,
  getUnconfirmedCompanies,
  rejectCompany,
} from "api/companyAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { appendCompanyShortForm } from "./CompanyShortFormSlice";

interface CompanyState {
  companiesById: Record<number, Company>;
  companyList: number[];
  isLoading: boolean;
  isLoadingAdd: boolean;
  error: string | null;
}

const CompanyInitialState: CompanyState = {
  companiesById: {},
  companyList: [],
  isLoading: false,
  isLoadingAdd: false,
  error: null,
};

function startLoading(state: CompanyState) {
  state.isLoading = true;
}

function startLoadingAdd(state: CompanyState) {
  state.isLoadingAdd = true;
}

function loadingFailed(state: CompanyState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const companies = createSlice({
  name: "company",
  initialState: CompanyInitialState,
  reducers: {
    getCompaniesStart: startLoading,
    getCompaniesSuccess(state, { payload }: PayloadAction<CompaniesResult>) {
      const { companies } = payload;
      // console.log("PAYLOAD", companies);
      state.isLoading = false;
      state.error = null;

      companies.forEach((company) => {
        state.companiesById[company.CompanyID] = company;
      });

      state.companyList = companies.map((company) => company.CompanyID);
    },
    getCompaniesFailure: loadingFailed,
    createCompanyStart: startLoadingAdd,
    createCompanySuccess(state, { payload }: PayloadAction<Company>) {
      const { CompanyID } = payload;
      state.companiesById[CompanyID] = payload;
      state.companyList = [...state.companyList, CompanyID];

      state.isLoading = false;
      state.error = null;
    },
    updateCompanySuccess(state, { payload }: PayloadAction<Company>) {
      const { CompanyID } = payload;
      state.companiesById[CompanyID] = payload;
      state.companyList.push(CompanyID);

      state.isLoading = false;
      state.error = null;
    },
    deleteCompanySuccess(state, { payload }: PayloadAction<number>) {
      const CompanyID = payload;
      delete state.companiesById[CompanyID];
      state.companyList = state.companyList.filter(
        (item) => item !== CompanyID
      );

      state.isLoading = false;
      state.error = null;
    },
    createCompanyFailure: loadingFailed,
  },
});

export const {
  getCompaniesStart,
  getCompaniesSuccess,
  getCompaniesFailure,
  createCompanyStart,
  createCompanySuccess,
  updateCompanySuccess,
  deleteCompanySuccess,
  createCompanyFailure,
} = companies.actions;

export default companies.reducer;

export const fetchCompanies =
  (accessToken: String, clientId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCompaniesStart());
      const companies = await getCompanies(accessToken, clientId);
      dispatch(getCompaniesSuccess(companies));
    } catch (err: any) {
      dispatch(getCompaniesFailure(err.toString()));
    }
  };

export const fetchCompaniesForReview =
  (accessToken: String, clientId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCompaniesStart());
      const companies = await getCompaniesForReview(accessToken, clientId);
      dispatch(getCompaniesSuccess(companies));
    } catch (err: any) {
      dispatch(getCompaniesFailure(err.toString()));
    }
  };

export const fetchUnconfirmedCompanies =
  (accessToken: String, clientId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCompaniesStart());
      const companies = await getUnconfirmedCompanies(accessToken, clientId);
      dispatch(getCompaniesSuccess(companies));
    } catch (err: any) {
      dispatch(getCompaniesFailure(err.toString()));
    }
  };

export const addCompany =
  (
    accessToken: String,
    clientId: number,
    newCompany: Partial<Company>,
    redirect: string
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCompanyStart());
      const company = await createCompany(accessToken, clientId, newCompany);
      dispatch(createCompanySuccess(company));
      const shortForm = {
        CompanyName: company.CompanyName,
        CompanyID: company.CompanyID,
        VendorID: company.VendorID,
        ShowAsActive: company.ShowAsActive,
        IsClient: company.IsClient,
      };
      dispatch(appendCompanyShortForm(shortForm));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      if (newCompany.ListingConfirmed === true) {
        dispatch(push(`${redirect + "/" + company.CompanyID}`));
      }
    } catch (err: any) {
      dispatch(createCompanyFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updCompany =
  (
    accessToken: String,
    clientId: number,
    companyID: number,
    newCompany: Partial<Company>,
    redirect: string
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCompanyStart());
      const company = await updateCompany(
        accessToken,
        clientId,
        companyID,
        newCompany
      );
      dispatch(updateCompanySuccess(company));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(push(`${redirect + "/" + companyID}`));
    } catch (err: any) {
      dispatch(createCompanyFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const rjtCompany =
  (
    accessToken: String,
    companyID: number,
    reason: String,
    duplicateID: number,
    redirect: string
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCompanyStart());
      const company = await rejectCompany(
        accessToken,
        companyID,
        reason,
        duplicateID
      );
      dispatch(updateCompanySuccess(company));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      duplicateID > 0
        ? dispatch(push(`${redirect + "/" + duplicateID}`))
        : dispatch(push(`${redirect + "/"}unconfirmed`));
    } catch (err: any) {
      dispatch(createCompanyFailure(err.toString()));
      if (companyID === duplicateID) {
        dispatch(
          openSnackBar(
            `${Constants.FAILED}: Cannot Merge a Company into itself.`,
            "error"
          )
        );
      } else {
        dispatch(openSnackBar(Constants.FAILED, "error"));
      }
    }
  };

export const mrgCompany =
  (
    accessToken: String,
    companyID: number,
    newCompany: Partial<Company>,
    duplicateID: number,
    redirect: string
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCompanyStart());
      const company = await mergeCompany(
        accessToken,
        companyID,
        newCompany,
        duplicateID
      );
      dispatch(updateCompanySuccess(company));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
      dispatch(push(`${redirect + "/" + companyID}`));
    } catch (err: any) {
      dispatch(createCompanyFailure(err.toString()));
      if (companyID === duplicateID) {
        dispatch(
          openSnackBar(
            `${Constants.FAILED}: Cannot Merge a Company into itself.`,
            "error"
          )
        );
      } else {
        dispatch(openSnackBar(Constants.FAILED, "error"));
      }
    }
  };

export const delCompany =
  (accessToken: String, companyID: number, redirect: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCompanyStart());
      const result = await deleteCompany(accessToken, companyID);
      dispatch(deleteCompanySuccess(companyID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
      dispatch(push(`${redirect}`));
    } catch (err: any) {
      dispatch(createCompanyFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };
