export const classifications = [
  "Material",
  "Rental",
  "Equipment",
  "Infrastructure",
  "Overhead",
  "Salaries/Wages",
  "Internal Wages/Hours",
  "Expense",
  "Other",
  "All",
];
