import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  User,
  UsersResult,
  getUser,
  getUsers,
  createUser,
  updateUser,
  deleteUser,
  getInactiveUsers,
} from "api/usersAPI";
import { push } from "redux-first-history";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface UsersState {
  usersById: Record<number, User>;
  userList: number[];
  isLoading: boolean;
  error: string | null;
}

const usersInitialState: UsersState = {
  usersById: {},
  userList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: UsersState) {
  state.isLoading = true;
}

function loadingFailed(state: UsersState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = action.payload;
}

const users = createSlice({
  name: "users",
  initialState: usersInitialState,
  reducers: {
    getUserStart: startLoading,
    getUsersStart: startLoading,
    getUserSuccess(state, { payload }: PayloadAction<User>) {
      const { UserAccountID } = payload;
      state.usersById[UserAccountID] = payload;
      state.userList.push(UserAccountID);

      state.isLoading = false;
      state.error = null;
    },
    getUsersSuccess(state, { payload }: PayloadAction<UsersResult>) {
      const { users } = payload;
      state.isLoading = false;
      state.error = null;

      users.forEach((user) => {
        state.usersById[user.UserAccountID] = user;
      });

      state.userList = users.map((user) => user.UserAccountID);
    },
    getUserFailure: loadingFailed,
    getUsersFailure: loadingFailed,
    createUserStart: startLoading,
    createUserSuccess(state, { payload }: PayloadAction<User>) {
      const { UserAccountID } = payload;
      state.usersById[UserAccountID] = payload;
      state.userList.push(UserAccountID);

      state.isLoading = false;
      state.error = null;
    },
    updateUserSuccess(state, { payload }: PayloadAction<User>) {
      const { UserAccountID } = payload;
      state.usersById[UserAccountID] = payload;
      state.userList.push(UserAccountID);

      state.isLoading = false;
      state.error = null;
    },
    deleteUserSuccess(state, { payload }: PayloadAction<number>) {
      const UserAccountID = payload;
      delete state.usersById[UserAccountID];
      state.userList = state.userList.filter((item) => item !== UserAccountID);

      state.isLoading = false;
      state.error = null;
    },
    createUserFailure: loadingFailed,
  },
});

export const {
  getUsersStart,
  getUsersSuccess,
  getUserStart,
  getUserSuccess,
  getUserFailure,
  getUsersFailure,
  createUserStart,
  createUserSuccess,
  updateUserSuccess,
  deleteUserSuccess,
  createUserFailure,
} = users.actions;

export default users.reducer;

export const fetchUsers =
  (accessToken: String, clientId: number, companyId?: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getUsersStart());
      const users = await getUsers(accessToken, clientId, companyId);
      dispatch(getUsersSuccess(users));
    } catch (err: any) {
      dispatch(getUsersFailure(err.toString()));
    }
  };

export const fetchInactiveUsers =
  (accessToken: string, clientId: number, companyId?: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getUsersStart());
      const users = await getInactiveUsers(accessToken, clientId, companyId);
      dispatch(getUsersSuccess(users));
    } catch (err: any) {
      console.error("fetchInactiveUsers error:", err);
      dispatch(getUsersFailure(err.toString()));
    }
  };

export const fetchUser =
  (accessToken: String, clientId: number, userId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getUserStart());
      const user = await getUser(accessToken, clientId, userId);
      dispatch(getUserSuccess(user));
    } catch (err: any) {
      dispatch(getUserFailure(err.toString()));
    }
  };

export const addUser =
  (
    accessToken: String,
    clientId: number,
    newUser: Partial<User>,
    returnRoute: string = "/admin/users"
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createUserStart());
      const user = await createUser(accessToken, clientId, newUser);
      dispatch(createUserSuccess(user));
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
      dispatch(push(`${returnRoute}/${user.UserAccountID}`));
    } catch (err: any) {
      dispatch(createUserFailure(err.toString()));
      dispatch(openSnackBar(err.message || Constants.FAILED, "error"));
    }
  };

export const updUser =
  (
    accessToken: String,
    clientId: number,
    userID: number,
    newUser: Partial<User>,
    returnRoute: string = "/admin/users"
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createUserStart());
      const user = await updateUser(accessToken, clientId, userID, newUser);
      dispatch(fetchUsers(accessToken, clientId, user.CompanyID));
      dispatch(updateUserSuccess(user));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createUserFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delUser =
  (accessToken: String, clientId: number, userID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createUserStart());
      const result = await deleteUser(accessToken, clientId, userID);
      dispatch(deleteUserSuccess(userID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
      dispatch(push("/admin/users/" + userID));
    } catch (err: any) {
      dispatch(createUserFailure(err.toString()));
    }
  };
