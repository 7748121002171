import { ensureStringArray, formatStringForCompare } from "utils/functions";

// Define a function that creates a filter based on the operator and value for columns formatted as "monthyear"
export const createMonthYearFilter = (filter: any) => {
  const { value, operator, field } = filter;

  if (value) {
    // Calculate the start and end of the month from the value to encapsulate the full month
    const startOfMonth = new Date(value.getFullYear(), value.getMonth(), 1);
    const endOfMonth = new Date(value.getFullYear(), value.getMonth() + 1, 0);

    switch (operator) {
      case "eq": // return a filter for the entire month
        return {
          logic: "and",
          filters: [
            { field, operator: "gte", value: startOfMonth },
            { field, operator: "lte", value: endOfMonth },
          ],
        };
      case "lt": // return a filter for dates before the start of the month
        return { field, operator: "lt", value: startOfMonth };
      case "lte": // return a filter for dates up to the end of the month
        return { field, operator: "lte", value: endOfMonth };
      case "gt": // return a filter for dates after the end of the month
        return { field, operator: "gt", value: endOfMonth };
      case "gte": // return a filter for dates from the start of the month
        return { field, operator: "gte", value: startOfMonth };
      default:
        // For any other operator, return the original filter
        return filter;
    }
  } else if (operator === "isnull") {
    return { field, operator: "isnull" }; // return a filter for null values
  } else if (operator === "isnotnull") {
    return { field, operator: "isnotnull" }; // return a filter for non-null values
  } else {
    // For any other operator, return the original filter
    return filter;
  }
};

export const createRecordTypeChipFilter = ({ filters }: any) => ({
  logic: "and",
  filters: filters.map(({ field, value }: any) => ({
    field,
    value,
    operator: (item: any, filterValue: any) =>
      Array.isArray(item)
        ? item
            .map((i: string) => formatStringForCompare(i))
            .includes(formatStringForCompare(filterValue))
        : false,
  })),
});

export const createCommaDelimitedFilter = ({ filters }: any) => ({
  logic: "or",
  filters: filters.map(({ field, value }: any) => ({
    field,
    value,
    operator: (item: any, filterValue: any) => {
      if (item === null || filterValue === null) return false;

      return (
        ensureStringArray(item)?.some(
          (i: string) =>
            formatStringForCompare(i) === formatStringForCompare(filterValue)
        ) ?? false
      );
    },
  })),
});
