import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { IppInventory } from "components/Inventory/IppInventory";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";
import { ConvertDateOffset } from "../../../utils/DateFunctions";
import { useTypedTranslation } from "utils/customHooks";
import { fetchEnTExpenses } from "./EnTExpenseSlice";

interface entExpInvProps {
  type?: "Education" | "Training";
}

export const EnTExpenseInventoryPage = (props: entExpInvProps) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);

  const type = props.type;
  const {
    isLoading,
    error: entExpensesError,
    entExpensesById,
    entExpenseList,
  } = useSelector((state: RootState) => state.entExpenses);
  const entExpenses = entExpenseList.map((id) => entExpensesById[id]);

  const entExps = entExpenses.map((p) => {
    let newEntExp = Object.assign({}, p) as any;

    if (p.InEligible) {
      newEntExp.EligibilityStatus = "No";
    } else newEntExp.EligibilityStatus = "Yes";

    newEntExp.ExpenseDate = ConvertDateOffset(p.Date);

    if (!p.InitiativeName || p.InitiativeName === "") {
      newEntExp.InitiativeName = "Missing Name";
    }

    return newEntExp;
  });

  const entExpCols = [
    {
      field: "SequenceID",
      title: "ID",
      filter: "text",
      format: "ET-{0:0}",
      columnWidth: 150,
    },
    {
      field: "CourseName",
      title: t("objBen:objects.entExpense.fields.coursename"),
    },
    {
      field: "ExpenseDate",
      title: t("objBen:objects.entExpense.fields.expensedate"),
      filter: "date",
      format: "{0:d}",
      columnWidth: 150,
    },
    {
      field: "ExpenseAmount",
      title: t("objBen:objects.entExpense.fields.expenseamount"),
      filter: "numeric",
      format: "{0:c0}",
      columnWidth: 200,
    },
    {
      field: "EligibilityStatus",
      title: t("objBen:objects.entExpense.eligible"),
      columnWidth: 150,
    },
    {
      field: "Provider",
      title: t("objBen:objects.entExpense.fields.provider"),
    },
    {
      field: "Description",
      title: t("objBen:objects.entExpense.fields.description"),
      richText: true,
    },
    {
      field: "ExpenseType",
      title: t("objBen:objects.entExpense.fields.expenseType"),
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchEnTExpenses(accessToken));
      } catch (e) {
        console.log(e);
      }
    })();
  }, [type, dispatch, getAccessTokenSilently]);

  if (entExpensesError) {
    return <ErrorPage errMsg={entExpensesError} />;
  }

  let entExpInvView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <IppInventory
        title={t("objBen:objects.entExpense.entexpenses")}
        tableData={entExps}
        idField="EnTExpenseID"
        columns={entExpCols}
        isFiltered={true}
        showDetail={true}
        buttonProps={{
          showAdd: true,
        }}
        sortColumn={2}
        sortReverse={true}
        linkColumn={0}
        searchByColumn="CourseName"
        searchByPlaceholder={t("strGen:filters.searchfor", {
          fieldname: t("objBen:objects.entExpense.fields.coursename"),
        })}
      />
    </div>
  );

  return <div id="entExpense-inventory-page">{entExpInvView}</div>;
};
