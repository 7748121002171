import { styled } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import {
  fetchCustomEmpCategories,
  addCustomEmpCategory,
  updCustomEmpCategory,
  delCustomEmpCategory,
} from "./CustomEmpCategorySlice";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Grid as KendoGrid,
  GridColumn,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { Delete, Edit } from "@mui/icons-material";
import { IppTextField } from "components/IppTextField";
import { useTypedTranslation } from "utils/customHooks";
import LoadingIndicator from "components/LoadingIndicator";
import { IppButton } from "components/Buttons/IppButton";
import { IppAddButton } from "components/Buttons/IppAddButton";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import IppConfirmDialog from "components/IppConfirmDialog";
import { fetchDeleteChecksByCustomEmpCategory } from "features/deleteCheck/DeleteCheckSlice";

const PREFIX = "CustomEmpCategoryForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
  roleCard: `${PREFIX}-roleCard`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1100,
  },

  [`& .${classes.boxSpace}`]: {
    padding: 10,
    maxWidth: 900,
  },

  [`& .${classes.roleCard}`]: {
    minWidth: 250,

    minHeight: 300,
  },
}));

export const CustomEmpCategoryForm = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [editID, setEditID] = useState(-1);
  const [isEditing, setIsEditing] = useState(true);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  // const [deleteName, setDeleteName] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);

  const { clientId } = useSelector((state: RootState) => state.client);

  let { customEmpCategoryList, customEmpCategoriesById, isLoading } =
    useSelector((state: RootState) => state.customEmpCategories);
  let customEmpCategories = customEmpCategoryList.map(
    (id) => customEmpCategoriesById[id]
  );

  let initialItem = {
    Name: "",
    Rank: customEmpCategories.length + 1,
  };

  const validationSchema = yup.object({
    Name: yup
      .string()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objBen:objects.employee.fields.customCategory"),
        })
      )
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objBen:objects.employee.fields.customCategory"),
          count: 50,
        })
      )
      .test(
        "checkDuplicate",
        t("strGen:validation.exists", {
          fieldname: t("objBen:objects.employee.fields.customCategory"),
        }),
        function (value: any) {
          const nameExists = customEmpCategories.some(
            (category) =>
              category?.Name?.toLowerCase()?.trim() ===
                value?.toLowerCase()?.trim() &&
              category.CustomEmpCategoryID !== editID
          );
          return !nameExists;
        }
      ),
    Rank: yup
      .number()
      .positive(
        t("strGen:validation.morethanone", {
          fieldname: t("strGen:order"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("strGen:order"),
        })
      ),
  });

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchCustomEmpCategories(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, dispatch, getAccessTokenSilently]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        if (isAdding) {
          dispatch(addCustomEmpCategory(accessToken, values));
        } else {
          dispatch(
            updCustomEmpCategory(
              accessToken,
              values.CustomEmpCategoryID,
              values
            )
          );
        }
        setEditOpen(false);
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const handleDelete = (customEmpCategoryId: number) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(delCustomEmpCategory(accessToken, customEmpCategoryId));
        setDeleteOpen(false);
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  const deleteCell = (props: any) => {
    return (
      <td>
        <Tooltip
          title={t("strGen:buttons.deleteobj", {
            objectname: t("objBen:objects.employee.fields.customCategory"),
          })}
        >
          <div
            style={{ cursor: "pointer", color: "blue" }}
            onClick={(e) => {
              setEditID(props.dataItem.CustomEmpCategoryID);
              setDeleteOpen(true);
            }}
          >
            <IppButton variant="text" startIcon={<Delete />} color={"error"} />
          </div>
        </Tooltip>
      </td>
    );
  };

  const editCell = (props: any) => {
    return (
      <td>
        <Tooltip
          title={t("strGen:prompts.edit.edittitle", {
            fieldname: t("objBen:objects.employee.fields.customCategory"),
          })}
        >
          <div
            style={{ cursor: "pointer", color: "blue" }}
            onClick={async (e) => {
              await setEditID(props.dataItem.CustomEmpCategoryID);
              await formik.setValues(props.dataItem);
              setEditOpen(true);
            }}
          >
            <IppButton variant="text" startIcon={<Edit />} />
          </div>
        </Tooltip>
      </td>
    );
  };

  const formik = useFormik({
    initialValues: initialItem,
    validationSchema: validationSchema,
    onSubmit: submitFunc,
    enableReinitialize: true,
  });

  let customEmpCategoryView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <Box display="flex" justifyContent="center">
        <Paper className={classes.boxSpace}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h5" component="h3">
                {t("objBen:objects.employee.fields.customCategory_other")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Grid container justifyContent="flex-end">
                <IppAddButton
                  buttonText={t("strGen:buttons.addobj", {
                    objectname: t(
                      "objBen:objects.employee.fields.customCategory"
                    ),
                  })}
                  onClick={() => {
                    setIsAdding(true);
                    setEditOpen(true);
                    formik.resetForm();
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <KendoGrid
                data={customEmpCategories}
                total={customEmpCategories.length}
              >
                <GridNoRecords>
                  {`** No ${t(
                    "objBen:objects.employee.fields.customCategory"
                  )} added yet. **`}
                </GridNoRecords>
                <GridColumn field="Rank" title={t("strGen:order")} width={50} />
                <GridColumn
                  field="Name"
                  title={t("objBen:objects.employee.fields.customCategory")}
                />
                <GridColumn
                  cell={editCell}
                  width="50px"
                  field="CustomEmpCategoryID"
                  title=" "
                />
                <GridColumn cell={deleteCell} width="50px" title=" " />
              </KendoGrid>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <Dialog
        open={editOpen}
        onClose={async () => {
          setEditOpen(false);
        }}
      >
        <DialogTitle>
          {isAdding
            ? t("strGen:prompts.add.addnewtitle", {
                fieldname: t("objBen:objects.employee.fields.customCategory"),
              })
            : t("strGen:prompts.edit.edittitle", {
                fieldname: t("objBen:objects.employee.fields.customCategory"),
              })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText variant="body1">
            {isAdding
              ? t("strGen:prompts.add.message", {
                  fieldname: t("objBen:objects.employee.fields.customCategory"),
                })
              : t("strGen:prompts.edit.modifyingmessage", {
                  fieldname: t("objBen:objects.employee.fields.customCategory"),
                })}
          </DialogContentText>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <IppTextField
                  id="Rank"
                  label={t("strGen:order")}
                  value={formik.values.Rank || ""}
                  required
                  onChangeFunction={formik.handleChange}
                  errorsExpression={formik.errors.Rank}
                  touchedExpression={formik.touched.Rank}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                />
              </Grid>
              <Grid item xs={9}>
                <IppTextField
                  id="Name"
                  label={`${t(
                    "objBen:objects.employee.fields.customCategory"
                  )} Name`}
                  value={formik.values.Name || ""}
                  required
                  onChangeFunction={formik.handleChange}
                  errorsExpression={formik.errors.Name}
                  touchedExpression={formik.touched.Name}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                />
              </Grid>
              <Grid item xs={12}>
                <IppFormButtons
                  isEditing={true}
                  setIsEditing={null}
                  isAdding={isAdding}
                  showCancel={true}
                  resetFunction={async () => {
                    setEditOpen(false);
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      <IppConfirmDialog
        title={t("strGen:prompts.deletechecks.titles.customEmpCategory", {
          title:
            customEmpCategories.find(
              (customEmpCategory) =>
                customEmpCategory.CustomEmpCategoryID === editID
            )?.Name || "",
        })}
        open={deleteOpen}
        setOpen={() => setDeleteOpen(!deleteOpen)}
        onConfirm={() => handleDelete(editID)}
        fetchFunc={fetchDeleteChecksByCustomEmpCategory}
        recordID={editID}
        info={t("strGen:prompts.deletechecks.customEmpCategory")}
      />
    </div>
  );

  return <Root id="CustomEmpCategory-Page">{customEmpCategoryView}</Root>;
};
