import { Dialog, DialogContent, Grid, Typography, styled } from "@mui/material";

import { IppCancelButton } from "components/Buttons/IppCancelButton";
import { useTypedTranslation } from "utils/customHooks";
import { IppInventory, column } from "./Inventory/IppInventory";

const PREFIX = "IppChildTable";
const classes = {
  boxSpace: `${PREFIX}-boxSpace`,
  kgrid: `${PREFIX}-kgrid`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
  [`& .${classes.kgrid}`]: {
    tableLayout: "fixed",
  },
}));

export interface ippChildTableProps {
  title: string;
  parentTitle: string;
  isOpen: boolean;
  closeAction?: any;
  idField?: string; // unique id field for displayed table data
  options?: any[]; // all available options for link item
  optionColumns: column[];
  optionSearch?: string;
  optionDetail?: any; // detail component for option tables
  tableName?: string;
  linkColumn?: number;
  route?: string;
}

export const IppChildTable = (props: ippChildTableProps) => {
  let {
    title,
    parentTitle,
    isOpen,
    closeAction,
    idField = "",
    options = [],
    optionColumns,
    optionSearch = "",
    optionDetail,
    tableName = "",
    linkColumn,
    route,
  } = props;
  const childTableName = ["ThreadInteraction", "InteractionThread"];
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);
  let childTable = childTableName.includes(tableName) && (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={8}>
        <Grid item xs={12}>
          <Typography variant="h6">
            {t("strGen:components.ippchildlink.title", {
              title: title,
              parenttitle: parentTitle,
            })}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end" item xs={4}>
        <IppCancelButton
          buttonText={t("strGen:buttons.close")}
          onClick={closeAction}
        />
      </Grid>
      <Grid item xs={12}>
        <IppInventory
          title={""}
          hideExport={true}
          tableData={options}
          idField={idField}
          route={route}
          columns={optionColumns}
          isFiltered={false}
          showDetail={true}
          buttonProps={{
            showAdd: idField !== "InteractionID" && idField !== "ThreadID",
          }}
          sortReverse={true}
          linkColumn={linkColumn}
          searchByColumn={optionSearch}
          searchByPlaceholder={t("strGen:filters.searchobj", {
            objectname: optionColumns?.find((i) => i.field === optionSearch)
              ?.title,
          })}
          isExpandable={optionDetail}
          detailComponent={optionDetail}
        />
      </Grid>
    </Grid>
  );
  return (
    <Root id="impact-draggable-inventory">
      <Dialog open={isOpen} onClose={closeAction} fullWidth maxWidth="xl">
        <DialogContent>{childTable}</DialogContent>
      </Dialog>
    </Root>
  );
};
