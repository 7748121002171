import { useLocation } from "react-router";

export const useGetBasePath = () => {
  const location = useLocation();
  const basePath = location.pathname
    .replace(/\/\d+$/, "") // removes id (e.g., 14223) -- For Merge pages
    .replace(/\/\d+$/, "") // removes the next trailing id (e.g., 14744) -- For Merge pages
    .replace(/\/merge$/, "") // removes '/merge' -- For Merge pages
    .replace(/\/\d+$/, "") // removes id if present -- For New pages
    .replace(/\/new$/, ""); // removes '/new' -- For New pages

  return basePath;
};
