// Add child entry using interaction table. Called from IppChildInventory
// DG, Oct 2021
// This form is only used for adding communications to parent tables

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { InteractionChildForm } from "features/stakeholder/interaction/InteractionChildForm";
import { GrievanceChildForm } from "features/stakeholder/grievance/GrievanceChildForm";
import { CommitmentActionChildForm } from "features/commitments/commitmentAction/CommitmentActionChildForm";
import { CommitmentChildForm } from "features/commitments/commitment/CommitmentChildForm";
import { InteractionActionChildForm } from "features/stakeholder/interactionAction/InteractionActionChildForm";

import { useDispatch } from "react-redux";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { InteractionCommentChildForm } from "features/stakeholder/interactionComment/InteractionCommentChildForm";
import { PaymentChildForm } from "features/benefits/payments/PaymentChildForm";
import { CompanyChildForm } from "features/benefits/companies/CompanyChildForm";
import { JobHistoryChildForm } from "features/benefits/jobHistory/JobHistoryChildForm";
import { RnDExpenseChildForm } from "features/benefits/RnDExpense/RnDExpenseChildForm";
import { WorkHourDetailChildForm } from "features/benefits/workHours/WorkHourDetailChildForm";
import { EnTExpenseChildForm } from "features/benefits/entExpenses/EnTExpenseChildForm";
import { useTypedTranslation } from "utils/customHooks";
import { ThreadChildForm } from "features/stakeholder/thread/ThreadChildForm";
import IppCloseButton from "./Buttons/IppCloseButton";

interface ippChildAddProps {
  title: string; // title to show on sub page
  parentTitle: string; // title to show for container page
  parentID: number; // id value for parent record
  addFormOpen: boolean; // whether dialog is open or not
  closeAction: any;
  setDidSaveInventory: any;
  parentValue: any; // existing value from parent
  headerText?: boolean;
  formTitle?: string;
  formDescription?: string;
}

export const IppChildAdd = (props: ippChildAddProps) => {
  const {
    title,
    parentTitle,
    parentID,
    addFormOpen,
    closeAction,
    setDidSaveInventory,
    parentValue,
    headerText = true,
    formTitle,
    formDescription,
  } = props;

  const dispatch = useDispatch();
  const t = useTypedTranslation(["strGen"]);

  const defaultFormTitle =
    title === t("objCom:objects.commitmentaction.name") ||
    title === "R&D Expense" ||
    title === "E&T Expense"
      ? t("strGen:components.ippchildadd.titles.addan", {
          title: title,
          parenttitle: parentTitle,
        })
      : title === "Spend"
      ? t("strGen:components.ippchildadd.titles.add", {
          title: title,
          parenttitle: parentTitle,
        })
      : title === "Job History"
      ? t("strGen:components.ippchildadd.titles.addpreviousposition")
      : t("strGen:components.ippchildadd.titles.adda", {
          title: title,
          parenttitle: parentTitle,
        });

  const defaultFormDescription =
    title === "Job History"
      ? t("strGen:components.ippchildadd.messages.jobhistory")
      : t("strGen:components.ippchildadd.messages.default", {
          title: title,
          parenttitle: parentTitle,
        });

  const handleClose = (event: any, reason: any) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      dispatch(
        openSnackBar(t("strGen:components.snackbar.saveorcancel"), "error")
      );
    } else {
      // form was closed using either Save or Cancel
      closeAction();
    }
  };

  const form = () => {
    switch (title.toLowerCase()) {
      case "communication":
        return (
          <div>
            <InteractionChildForm
              closeAction={handleClose}
              setDidSaveInventory={setDidSaveInventory}
              parentTitle={parentTitle}
              parentValue={parentValue}
            />
          </div>
        );
      case t("objStk:objects.interactionaction.namefull").toLowerCase():
        return (
          <div>
            <InteractionActionChildForm
              closeAction={handleClose}
              setDidSaveInventory={setDidSaveInventory}
              parentTitle={parentTitle}
              parentValue={parentValue}
              parentID={parentID}
            />
          </div>
        );
      case "concern":
        return (
          <div>
            <GrievanceChildForm
              closeAction={handleClose}
              setDidSaveInventory={setDidSaveInventory}
              parentTitle={parentTitle}
              parentValue={parentValue}
            />
          </div>
        );
      case t("objCom:objects.commitmentaction.name").toLowerCase():
        return (
          <div>
            <CommitmentActionChildForm
              closeAction={handleClose}
              setDidSaveInventory={setDidSaveInventory}
              parentTitle={parentTitle}
              parentValue={parentValue}
              parentID={parentID}
            />
          </div>
        );
      case t("objStk:objects.thread.name").toLowerCase():
        return (
          <div>
            <ThreadChildForm
              closeAction={handleClose}
              setDidSaveInventory={setDidSaveInventory}
              parentTitle={parentTitle}
              parentValue={parentValue}
            />
          </div>
        );
      case "commitment":
        return (
          <div>
            <CommitmentChildForm
              closeAction={handleClose}
              setDidSaveInventory={setDidSaveInventory}
              parentTitle={parentTitle}
              parentValue={parentValue}
              parentID={parentID}
            />
          </div>
        );
      case "spend":
        return (
          <div>
            <PaymentChildForm
              closeAction={handleClose}
              setDidSaveInventory={setDidSaveInventory}
              paymentPeriod={parentValue}
            />
          </div>
        );
      case "job history":
        return (
          <div>
            <JobHistoryChildForm
              closeAction={handleClose}
              employeeID={parentID}
            />
          </div>
        );
      case "comment":
        return (
          <div>
            <InteractionCommentChildForm
              closeAction={handleClose}
              setDidSaveInventory={setDidSaveInventory}
              parentTitle={parentTitle}
              parentValue={parentValue}
              parentID={parentID}
            />
          </div>
        );
      case "company":
        return (
          <div>
            <CompanyChildForm onClose={handleClose} parentTitle={parentTitle} />
          </div>
        );
      case "r&d expense":
        return (
          <div>
            <RnDExpenseChildForm
              closeAction={handleClose}
              setDidSaveInventory={setDidSaveInventory}
              parentTitle={parentTitle}
              parentID={parentID}
              parentValue={parentValue}
            />
          </div>
        );
      case "e&t expense":
        return (
          <div>
            <EnTExpenseChildForm
              closeAction={handleClose}
              setDidSaveInventory={setDidSaveInventory}
              parentTitle={parentTitle}
              parentID={parentID}
              parentValue={parentValue}
            />
          </div>
        );
      case "work-hour details":
        return (
          <div>
            <WorkHourDetailChildForm
              handleClose={handleClose}
              parentID={parentID}
            />
          </div>
        );
      default:
        return <p>{t("strGen:nomatchingentryfound")}</p>;
    }
  };

  return (
    <Dialog open={addFormOpen} onClose={handleClose} maxWidth="md">
      <DialogTitle>
        <IppCloseButton onClick={() => handleClose(null, null)} />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {headerText && (
            <>
              <Grid item xs={12}>
                <Typography variant="h6">
                  {formTitle || defaultFormTitle}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {formDescription || defaultFormDescription}
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <div>{form()}</div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
