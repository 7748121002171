import { Grid, IconButton } from "@mui/material";
import { IppFormDivider } from "./IppFormDivider";
import { useTypedTranslation } from "utils/customHooks";
import { IppDisplayField } from "./IppDisplayField";

interface IppDisplayFieldProps {
  address: string;
  townCity: string;
  provinceState: string;
  country: string;
  postalCode: string;
  isEditing: boolean;
  setIsEditing: any;
  showEdit?: boolean;
}

export const IppLocationDisplay = (props: IppDisplayFieldProps) => {
  const {
    address,
    townCity,
    provinceState,
    country,
    postalCode,
    isEditing,
    setIsEditing,
    showEdit,
  } = props;
  const t = useTypedTranslation(["objPlt", "strGen"]);
  return (
    <>
      <IppFormDivider
        title={t("objPlt:platformwide.headers.locationdetails")}
        showEdit={showEdit}
        setIsEditing={setIsEditing}
      />
      <Grid item xs={12}>
        {!(address || townCity || provinceState || country || postalCode) && (
          <IppDisplayField
            value={t("objPlt:platformwide.addressnotprovided")}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            showEdit={false}
          />
        )}
        {address && (
          <IppDisplayField
            showLabel={false}
            value={address}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            showFooter={
              !(townCity || provinceState || country || postalCode)
                ? true
                : false
            }
            showEdit={false}
          />
        )}
        {(townCity || provinceState) && (
          <IppDisplayField
            showLabel={false}
            value={`${townCity ? townCity : ""}${
              townCity && provinceState ? ", " : ""
            } ${provinceState ? provinceState : ""}`}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            showFooter={!(country || postalCode) ? true : false}
            showEdit={false}
          />
        )}
        {(country || postalCode) && (
          <IppDisplayField
            showLabel={false}
            value={`${country ? country : ""}${
              country && postalCode ? " " : ""
            } ${postalCode ? postalCode : ""}`}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            showEdit={false}
          />
        )}
      </Grid>
    </>
  );
};

IppLocationDisplay.defaultProps = {
  showEdit: true,
};
