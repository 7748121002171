import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { addLinkAttachment } from "../AttachmentSlice";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { useSnackBarConstants, useTypedTranslation } from "utils/customHooks";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import {
  AttachmentFormFields,
  LinkAttachmentFormValues,
} from "./AttachmentFormFields";
import { AttachmentValidationSchema } from "./AttachmentValidationSchema";

const PREFIX = "AttachmentLinkDialog";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1000,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

interface Props {
  isOpen: boolean;
  handleClose: any;
  recordType: string;
  recordID: number;
  companyID: number;
  projectID: number;
  moduleID: number;
  setIsUploading: (isUploading: boolean) => void;
  objectName?: string;
}

export const AttachmentLinkDialog = (props: Props) => {
  const {
    isOpen,
    handleClose,
    recordType,
    recordID,
    companyID,
    projectID,
    moduleID,
    setIsUploading,
    objectName,
  } = props;
  const dispatch = useDispatch();
  const t = useTypedTranslation(["objPlt", "strGen"]);
  const snackbarConstants = useSnackBarConstants();

  const { getAccessTokenSilently } = useAuth0();

  const closeAttachment = (
    _: React.MouseEvent<HTMLButtonElement>,
    reason?: "escapeKeyDown" | "backdropClick"
  ) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      dispatch(
        openSnackBar(t("strGen:components.snackbar.saveorcancel"), "info")
      );
    } else {
      formik.resetForm();
      handleClose();
    }
  };

  const onSubmit = async ({
    Link,
    LinkTitle,
    FileDescription,
    AttachmentCategoryID,
  }: LinkAttachmentFormValues) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
      },
    });
    setIsUploading(true);
    dispatch(
      addLinkAttachment(
        accessToken,
        recordType,
        recordID,
        Link,
        LinkTitle,
        FileDescription,
        AttachmentCategoryID,
        companyID,
        projectID,
        moduleID,
        setIsUploading,
        snackbarConstants
      )
    );
    formik.resetForm();
    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      type: "linkAttachment",
      Link: "",
      LinkTitle: "",
      FileDescription: "",
      AttachmentCategoryID: null,
    },
    validationSchema: AttachmentValidationSchema(true),
    onSubmit,
  });

  return (
    <StyledDialog maxWidth="sm" open={isOpen} onClose={closeAttachment}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Attach link to {objectName}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <AttachmentFormFields
              values={formik.values}
              touched={formik.touched}
              errors={formik.errors}
              handleChange={formik.handleChange}
              setFieldValue={formik.setFieldValue}
            />
            <Grid item xs={12}>
              <IppFormButtons
                isEditing={true}
                setIsEditing={null}
                isAdding={true}
                showCancel={true}
                resetFunction={closeAttachment}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </form>
    </StyledDialog>
  );
};
