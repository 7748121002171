import { useMemo } from "react";
import { Grid } from "@mui/material";
import { SuggestionBanner } from "components/SuggestionBanner";
import { Contact } from "api/stakeholder/contactAPI";
import { ContactGroup } from "api/stakeholder/contactGroupAPI";
import { useTypedTranslation } from "utils/customHooks";

interface GroupSuggestionProps {
  selectedContacts: Contact[];
  contactGroups: ContactGroup[];
  listGroups: string[];
  setListGroups: (groups: string[]) => void;
}

export const GroupSuggestion = ({
  selectedContacts,
  contactGroups,
  listGroups,
  setListGroups,
}: GroupSuggestionProps) => {
  const t = useTypedTranslation(["objPlt", "strGen"]);

  // Extract ContactIDs from selected contacts
  const groupCounts = useMemo(() => {
    const selectedContactIDsSet = new Set(
      selectedContacts.map(({ ContactID }) => ContactID)
    );
    return contactGroups.reduce<Record<string, number>>(
      (acc, { GroupName, ContactID }) => {
        if (selectedContactIDsSet.has(ContactID)) {
          acc[GroupName] = (acc[GroupName] ?? 0) + 1;
        }
        return acc;
      },
      {}
    );
  }, [selectedContacts, contactGroups]);

  // Ensure ContactGroups are unique and map them to UI format
  const groupSuggestions = useMemo(
    () =>
      Object.entries(groupCounts)
        .filter(([groupName]) => !listGroups.includes(groupName))
        .slice(0, 5) // limit to 5 groups
        .map(([groupName, count]) => ({
          value: groupName,
          label: `${groupName} (${count} ${t("objPlt:objects.contact.name", {
            count,
          })})`,
        })),
    [groupCounts, listGroups, t]
  );

  if (!groupSuggestions.length) return null;

  return (
    <Grid item xs={12} marginX={2}>
      <SuggestionBanner
        title={t("strGen:suggestion.title", {
          objectname1: t("objPlt:objects.group.name", {
            count: groupSuggestions.length,
          }),
          number: "one",
          objectname2: t("objPlt:objects.contact.name"),
        })}
        suggestions={groupSuggestions}
        list={listGroups}
        setList={setListGroups}
      />
    </Grid>
  );
};
