export function getClickableLink(link) {
  return link.startsWith("http://") || link.startsWith("https://")
    ? link
    : `//${link}`;
}

export function getCurrentModule() {
  let path = window.location.pathname;

  if (path.includes("commitments")) {
    return "commitments";
  } else if (path.includes("engagement")) {
    return "engagement";
  } else if (path.includes("benefits")) {
    return "benefits";
  } else if (path.includes("sshe")) {
    return "sshe";
  } else if (path.includes("grievance")) {
    return "grievance";
  } else {
    return null;
  }
}
