import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchEducationTrainings } from "./EducationTrainingSlice";
import { IppInventory } from "components/Inventory/IppInventory";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";
import { ConvertDateOffset } from "utils/DateFunctions";
import { IsMedSmall } from "utils/mediaQueries";
import { title } from "process";
import { useTypedTranslation } from "utils/customHooks";
import { filter } from "@progress/kendo-data-query/dist/npm/transducers";
import { RemoveAllHTMLTags } from "utils/functions";

export const EducationTrainingInventoryPage = () => {
  const dispatch = useDispatch();
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);
  const { getAccessTokenSilently } = useAuth0();

  const {
    educationTrainingList,
    educationTrainingsById,
    isLoading,
    error: educationTrainingsError,
  } = useSelector((state: RootState) => state.educationTrainings);
  const educationTrainings = educationTrainingList.map(
    (id) => educationTrainingsById[id]
  );

  const data = educationTrainings.map((p: any) => {
    let newItem = Object.assign({}, p) as any;
    newItem.ExpenditureDate = ConvertDateOffset(p.ExpenditureDate);
    if (newItem.Description) {
      newItem.Description = RemoveAllHTMLTags(newItem.Description);
    }
    return newItem;
  });

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchEducationTrainings(accessToken));
      } catch (error) {
        console.error(error);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  const dataColumns = [
    {
      field: "Activity",
      title: "Activity",
      //columnWidth: 150,
    },
    {
      field: "Type",
      title: "Type",
      columnWidth: 150,
    },
    {
      field: "ProjectName",
      title: t("objPlt:objects.project.name"),
    },
    {
      field: "Provider",
      title: "Provider",
      filter: "text",
    },
    {
      field: "Description",
      title: "Description",
      filter: "text",
      richText: true,
      //columnWidth: 200,
    },
    {
      field: "ExpenditureDate",
      title: "Expenditure Date",
      filter: "date",
      format: "{0:d}",
      columnWidth: 125,
    },
    {
      field: "Expenditure",
      title: "Expenditure",
      format: "{0:c}",
      filter: "numeric",
      columnWidth: 175,
    },
  ];

  if (educationTrainingsError) {
    return <ErrorPage errMsg={educationTrainingsError} />;
  }

  let EducationTrainingInvView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <IppInventory
        title={t("objBen:objects.educationtraining.entexpenditure")}
        tableData={data}
        idField="EducationTrainingID"
        columns={dataColumns}
        isFiltered={true}
        buttonProps={{
          showAdd: true,
        }}
        showDetail={true}
        //linkColumn={2}
        searchByColumn="Activity"
        searchByPlaceholder={"Activity"}
      />
    </div>
  );

  return (
    <div id="EducationTraining-Inventory-page">{EducationTrainingInvView}</div>
  );
};
