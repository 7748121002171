import axiosClient from "../axiosClient";

export interface CommitmentStatusType {
  CommitmentStatusTypeID: number;
  CommitmentStatusTypeName: string;
  IsCompleted?: boolean;
  IsRequired?: boolean;
}

export interface CommitmentStatusTypesResult {
  commitmentStatusTypes: CommitmentStatusType[];
}

export async function getCommitmentStatusTypes(
  accessToken: String
): Promise<CommitmentStatusTypesResult> {
  const url = `/commitmentStatusType`;

  try {
    const { data } = await axiosClient.get<CommitmentStatusTypesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createCommitmentStatusType(
  accessToken: String,
  newCommInv: Partial<CommitmentStatusType>
): Promise<CommitmentStatusType> {
  const url = `/commitmentStatusType`;

  try {
    const { data } = await axiosClient.post<CommitmentStatusType>(
      url,
      newCommInv,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateCommitmentStatusType(
  accessToken: String,
  commInvID: number,
  newCommInv: Partial<CommitmentStatusType>
): Promise<CommitmentStatusType> {
  const url = `/commitmentStatusType/` + commInvID;

  try {
    const { data } = await axiosClient.post<CommitmentStatusType>(
      url,
      newCommInv,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteCommitmentStatusType(
  accessToken: String,
  commInvID: number
): Promise<string> {
  const url = `/commitmentStatusType/` + commInvID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
