import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  TextField,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { Grid as KendoGrid, GridColumn } from "@progress/kendo-react-grid";
import { GridPageChangeEvent } from "@progress/kendo-react-grid";
import LoadingIndicator from "components/LoadingIndicator";
import { Link } from "react-router-dom";
import { getCurrentModule } from "../../../utils/urlUtils";
import { useTypedTranslation } from "utils/customHooks";
import { IppMergeButton } from "components/Buttons/IppMergeButton";

interface CheckDuplicatesProps {
  originalID: number;
  isOpen: boolean;
  closeWindow: any;
}

export const GroupCheckDuplicates = (props: CheckDuplicatesProps) => {
  const t = useTypedTranslation(["objPlt", "strGen"]);
  const { originalID, isOpen, closeWindow } = props;
  const [filterString, setFilterString] = useState("");

  const {
    stakeholderGroupList,
    stakeholderGroupsById,
    isLoading: groupIsLoading,
  } = useSelector((state: RootState) => state.stakeholderGroups);

  const stakeholdergroups = stakeholderGroupList.map(
    (id) => stakeholderGroupsById[id]
  );

  const processedData = stakeholdergroups
    .filter((item) => item.GroupID !== originalID)
    .map((p) => {
      const contacts = p.Contacts?.split(",") ?? [];

      return {
        ...p,
        SearchName: p.FirstName,
        ContactList:
          contacts.length > 1
            ? `${p.Contacts.slice(0, 10)} +${contacts.length - 1}`
            : p.Contacts?.slice(0, 10) ?? "",
      };
    });

  // ***** Pagination Stuff *****
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);

  const filteredData =
    filterString === ""
      ? processedData
      : processedData.filter((item) =>
          item.GroupName.toLowerCase().includes(filterString.toLowerCase())
        );

  const onPageChange = (event: GridPageChangeEvent) => {
    setSkip(event.page.skip);
    setTake(event.page.take);
  };

  const linkCell = (props: any) => (
    <td align="right">
      <Link
        to={`/${getCurrentModule()}/groups/merge/${originalID}/${
          props.dataItem.GroupID
        }`}
      >
        <Tooltip
          title={t("strGen:pages.merge.selecttooltip", {
            fieldname: t("objPlt:objects.group.name", { count: 2 }),
          })}
        >
          <IppMergeButton buttonText={t("strGen:pages.merge.select")} />
        </Tooltip>
      </Link>
    </td>
  );

  return groupIsLoading ? (
    <LoadingIndicator />
  ) : (
    <Dialog open={isOpen} onClose={closeWindow} maxWidth="lg" fullWidth>
      <DialogTitle>
        {t("strGen:pages.merge.checkforduplicate", {
          fieldname: t("objPlt:objects.group.name", { count: 2 }),
        })}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body2">
              {t("strGen:pages.merge.checkinfo", {
                fieldname: t("objPlt:objects.group.name", { count: 2 }),
                fieldname2: t("objPlt:objects.group.name"),
              })}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1">
              {t("strGen:pages.merge.searchfor")}
            </Typography>
          </Grid>
          <Grid item xs>
            <TextField
              id="txtSearch"
              placeholder={t("strGen:pages.merge.searchplaceholder")}
              onChange={(e) => setFilterString(e.target.value)}
              value={filterString}
            />
          </Grid>
          <Grid item xs={12}>
            <KendoGrid
              data={filteredData.slice(skip, skip + take)}
              pageable={{
                buttonCount: 5,
                info: true,
                pageSizes: [5, 10, 25, 50, 100],
              }}
              skip={skip}
              take={take}
              total={filteredData.length}
              onPageChange={onPageChange}
              sortable={true}
              groupable={false}
              rowHeight={1}
            >
              <GridColumn
                field="GroupName"
                title={t("objPlt:objects.group.name")}
              />
              <GridColumn
                field="OrganizationTypeName"
                title={t("objPlt:objects.group.fields.organizationtype")}
              />
              <GridColumn
                field="TownCity"
                title={t("objPlt:objects.group.fields.towncity")}
              />
              <GridColumn
                field="ProvinceState"
                title={t("objPlt:objects.group.fields.provincestate")}
              />
              <GridColumn
                field="ContactList"
                title={t("objPlt:objects.group.fields.contact", { count: 2 })}
              />
              <GridColumn
                field="GroupID"
                title=" "
                cell={linkCell}
                width="110"
              />
            </KendoGrid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
