import { styled } from "@mui/material/styles";
import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useSnackBarConstants, useTypedTranslation } from "utils/customHooks";
import { RootState } from "app/rootReducer";
import { isLinkAttachment } from "api/attachmentsAPI";
import { useFormik } from "formik";
import { AttachmentValidationSchema } from "./AttachmentValidationSchema";
import {
  AttachmentFormFields,
  AttachmentFormValues,
} from "./AttachmentFormFields";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { Trans } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { updAttachment } from "../AttachmentSlice";

const PREFIX = "EditAttachmentDialog";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1000,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

interface EditAttachmentDialogProps {
  attachmentID: number;
  handleClose: () => void;
}

export const EditAttachmentDialog = ({
  attachmentID,
  handleClose,
}: EditAttachmentDialogProps) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["strGen"]);
  const snackbarConstants = useSnackBarConstants();

  const { attachmentsById, attachmentList } = useSelector(
    (state: RootState) => state.attachments
  );
  const attachments = attachmentList.map((id) => attachmentsById[id]);
  const attachment = attachments.find(
    (attachment) => attachment.AttachmentID === attachmentID
  );

  const attachmentName = isLinkAttachment(attachment)
    ? attachment.LinkTitle ?? ""
    : attachment?.FileName ?? "";

  const objectName = isLinkAttachment(attachment)
    ? t("objPlt:objects.attachment.link")
    : t("objPlt:objects.attachment.name");

  const initialValues: AttachmentFormValues = isLinkAttachment(attachment)
    ? {
        type: "linkAttachment",
        FileDescription: attachment.FileDescription ?? "",
        AttachmentCategoryID: attachment.AttachmentCategoryID ?? null,
        Link: attachment.Link ?? "",
        LinkTitle: attachment.LinkTitle ?? "",
      }
    : {
        type: "fileAttachment",
        FileDescription: attachment?.FileDescription ?? "",
        AttachmentCategoryID: attachment?.AttachmentCategoryID ?? null,
      };

  const onSubmit = async (values: AttachmentFormValues) => {
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
        },
      });

      const { type, ...rest } = values;
      const updAttachmentValues = {
        ...rest,
        RecordType: attachment?.RecordType ?? "",
        RecordID: attachment?.RecordID ?? 0,
      };

      dispatch(
        updAttachment(
          accessToken,
          attachmentID,
          updAttachmentValues,
          snackbarConstants
        )
      );
      handleClose();
    } catch (e) {
      console.error(e);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: AttachmentValidationSchema(isLinkAttachment(attachment)),
    onSubmit,
  });

  return (
    <StyledDialog
      open={true}
      onClose={handleClose}
      maxWidth="sm"
      aria-labelledby="edit-attachment-dialog"
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          <Trans
            i18nKey="strGen:pages.attachments.headeredittitle"
            values={{
              objectname: objectName,
              filename: attachmentName,
            }}
          />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <AttachmentFormFields
              values={formik.values}
              touched={formik.touched}
              errors={formik.errors}
              handleChange={formik.handleChange}
              setFieldValue={formik.setFieldValue}
            />
            <Grid item xs={12}>
              <IppFormButtons
                isEditing={true}
                setIsEditing={null}
                isAdding={false}
                showCancel={true}
                resetFunction={handleClose}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </form>
    </StyledDialog>
  );
};
