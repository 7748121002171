import { Edit } from "@mui/icons-material";
import { ButtonProps } from "@mui/material/Button";
import { IppButton, IppButtonProps } from "components/Buttons/IppButton";
import { useTypedTranslation } from "utils/customHooks";

export interface IppEditButtonProps extends IppButtonProps {
  buttonText?: string;
  color?: ButtonProps["color"];
  showStartIcon?: boolean;
}

export const IppEditButton = ({
  buttonText,
  color = "secondary",
  showStartIcon = true,
  ...props
}: IppEditButtonProps) => {
  const t = useTypedTranslation(["strGen"]);
  const T_buttonText = buttonText || t("strGen:buttons.edit");
  const startIcon = showStartIcon ? <Edit /> : undefined;

  return (
    <IppButton color={color} startIcon={startIcon} {...props}>
      {T_buttonText}
    </IppButton>
  );
};
