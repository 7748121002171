import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  BenefitsQuestionnaire,
  BenefitsQuestionnaireResult,
  getAllBenefitsQuestionnaire,
} from "api/benefitsQuestionnaire/benefitsQuestionnaireAPI";
import { AppThunk } from "app/store";

interface BenefitsQuestionnaireState {
  benefitsQuestionnaireByID: Record<number, BenefitsQuestionnaire>;
  benefitsQuestionnaireList: number[];
  isLoading: boolean;
  error: string | null;
}

const initialState: BenefitsQuestionnaireState = {
  benefitsQuestionnaireByID: {},
  benefitsQuestionnaireList: [],
  isLoading: false,
  error: null,
};

const startLoading = (state: BenefitsQuestionnaireState) => {
  state.isLoading = true;
};

const loadingFailed = (
  state: BenefitsQuestionnaireState,
  action: PayloadAction<string>
) => {
  state.isLoading = false;
  state.error = action.payload;
};

const benefitsQuestionnaire = createSlice({
  name: "benefitsQuestionnaire",
  initialState,
  reducers: {
    getBenefitsQuestionnaireStart: startLoading,
    getBenefitsQuestionnaireSuccess(
      state,
      { payload }: PayloadAction<BenefitsQuestionnaireResult>
    ) {
      const { benefitsQuestionnaires } = payload;
      state.isLoading = false;
      state.error = null;
      benefitsQuestionnaires.forEach((item) => {
        state.benefitsQuestionnaireByID[item.BenefitsQuestionnaireID!] = item;
      });
      state.benefitsQuestionnaireList = benefitsQuestionnaires.map(
        (item) => item.BenefitsQuestionnaireID!
      );
    },
    getBenefitsQuestionnaireFailure: loadingFailed,
  },
});

export const {
  getBenefitsQuestionnaireStart,
  getBenefitsQuestionnaireSuccess,
  getBenefitsQuestionnaireFailure,
} = benefitsQuestionnaire.actions;

export default benefitsQuestionnaire.reducer;

export const fetchBenefitsQuestionnaire =
  (accessToken: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getBenefitsQuestionnaireStart());
      const result = await getAllBenefitsQuestionnaire(accessToken);
      dispatch(getBenefitsQuestionnaireSuccess(result));
    } catch (err: any) {
      dispatch(getBenefitsQuestionnaireFailure(err.toString()));
    }
  };
