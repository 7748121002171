import { useFormik } from "formik";
import { Box, Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useTypedTranslation } from "utils/customHooks";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import {
  addStandardStatement,
  updStandardStatement,
} from "./StandardStatementSlice";
import { StandardStatementValidationSchema } from "./StandardStatementValidation";
import { RootState } from "app/rootReducer";
import { IppRichTextEditor } from "components/RichTextEditor/IppRichTextEditor";
import { IppCheckbox } from "components/IppCheckbox";
import { IppFormDivider } from "components/IppFormDivider";

interface StandardStatementFormProps {
  editOpen: boolean;
  setEditOpen: (value: boolean) => void;
  setEditID: (value: number | undefined) => void;
  standardStatementID?: number;
}

export const StandardStatementForm = (props: StandardStatementFormProps) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen"]);

  const { editOpen, setEditOpen, setEditID, standardStatementID } = props;

  const {
    ft_eng_InternalNotes,
    ft_eng_Thread,
    ft_com_InternalNotes,
    ft_com_RegulatoryNotes,
    ft_ben_RND,
    ft_ben_SpendReport,
  } = useSelector((state: RootState) => state.client);

  const { clientModuleList, clientModulesById } = useSelector(
    (state: RootState) => state.clientModules
  );

  const clientModules = clientModuleList
    .map((id) => clientModulesById[id])
    .map((module) => module.ModuleID);

  const clientHasBenefits = clientModules.includes(1);

  const clientHasCommitments = clientModules.includes(2);

  const clientHasStakeholder = clientModules.includes(3);

  const { standardStatementsById, standardStatementList } = useSelector(
    (state: RootState) => state.standardStatement
  );

  const standardStatement = standardStatementID
    ? standardStatementList
        .map((id) => standardStatementsById[id])
        .find(
          (statement) => statement.StandardStatementID === standardStatementID
        )
    : undefined;

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        if (standardStatementID) {
          dispatch(updStandardStatement(accessToken, values));
        } else {
          dispatch(addStandardStatement(accessToken, values));
        }
        setEditOpen(false);
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const initialValues = standardStatement || {
    StandardStatement: "",
    InteractionDetails: false,
    InteractionInternalNotes: false,
    InteractionActionDetails: false,
    CommitmentDetails: false,
    CommitmentInternalNotes: false,
    CommitmentRegulatoryNotes: false,
    CommitmentLegalText: false,
    CommitmentActionDetails: false,
    InitiativeDetails: false,
    PaymentPeriodNotes: false,
    InteractionThreadSummary: false,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: StandardStatementValidationSchema(),
    onSubmit: onSub,
    enableReinitialize: true,
  });

  const handleEditClose = () => {
    setEditOpen(false);
    if (standardStatementID) setEditID(undefined);
    formik.resetForm();
  };

  const editForm = (
    <Dialog open={editOpen} onClose={handleEditClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {!standardStatementID
          ? t("strGen:prompts.add.addnewtitle", {
              fieldname: t("objPlt:objects.standardstatement.name"),
            })
          : t("strGen:prompts.edit.edittitle", {
              fieldname: t("objPlt:objects.standardstatement.name"),
            })}
      </DialogTitle>
      <DialogContent>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <IppRichTextEditor
                id="StandardStatement"
                label={t("objPlt:objects.standardstatement.fields.statement")}
                value={formik.values.StandardStatement}
                touchedExpression={formik.touched.StandardStatement}
                errorsExpression={formik.errors.StandardStatement}
                isEditing={true}
                setFieldValue={formik.setFieldValue}
                height={150}
              />
            </Grid>
            <IppFormDivider
              title={t("objPlt:objects.standardstatement.recordtypes")}
            />
            {clientHasStakeholder && (
              <>
                <Grid item xs={4}>
                  <IppCheckbox
                    id="InteractionDetails"
                    label={t(
                      "objPlt:objects.standardstatement.fields.interactiondetails"
                    )}
                    value={formik.values.InteractionDetails}
                    onChangeFunction={formik.handleChange}
                    isEditing={true}
                  />
                </Grid>
                {ft_eng_InternalNotes && (
                  <Grid item xs={4}>
                    <IppCheckbox
                      id="InteractionInternalNotes"
                      label={t(
                        "objPlt:objects.standardstatement.fields.interactioninternalnotes"
                      )}
                      value={formik.values.InteractionInternalNotes}
                      onChangeFunction={formik.handleChange}
                      isEditing={true}
                    />
                  </Grid>
                )}
                <Grid item xs={4}>
                  <IppCheckbox
                    id="InteractionActionDetails"
                    label={t(
                      "objPlt:objects.standardstatement.fields.interactionactiondetails"
                    )}
                    value={formik.values.InteractionActionDetails}
                    onChangeFunction={formik.handleChange}
                    isEditing={true}
                  />
                </Grid>
                {ft_eng_Thread && (
                  <Grid item xs={4}>
                    <IppCheckbox
                      id="InteractionThreadSummary"
                      label={t(
                        "objPlt:objects.standardstatement.fields.interactionthreadsummary"
                      )}
                      value={formik.values.InteractionThreadSummary}
                      onChangeFunction={formik.handleChange}
                      isEditing={true}
                    />
                  </Grid>
                )}
              </>
            )}
            {clientHasCommitments && (
              <>
                <Grid item xs={4}>
                  <IppCheckbox
                    id="CommitmentDetails"
                    label={t(
                      "objPlt:objects.standardstatement.fields.commitmentdetails"
                    )}
                    value={formik.values.CommitmentDetails}
                    onChangeFunction={formik.handleChange}
                    isEditing={true}
                  />
                </Grid>
                {ft_com_InternalNotes && (
                  <Grid item xs={4}>
                    <IppCheckbox
                      id="CommitmentInternalNotes"
                      label={t(
                        "objPlt:objects.standardstatement.fields.commitmentinternalnotes"
                      )}
                      value={formik.values.CommitmentInternalNotes}
                      onChangeFunction={formik.handleChange}
                      isEditing={true}
                    />
                  </Grid>
                )}
                {ft_com_RegulatoryNotes && (
                  <Grid item xs={4}>
                    <IppCheckbox
                      id="CommitmentRegulatoryNotes"
                      label={t(
                        "objPlt:objects.standardstatement.fields.commitmentregulatorynotes"
                      )}
                      value={formik.values.CommitmentRegulatoryNotes}
                      onChangeFunction={formik.handleChange}
                      isEditing={true}
                    />
                  </Grid>
                )}
                <Grid item xs={4}>
                  <IppCheckbox
                    id="CommitmentLegalText"
                    label={t(
                      "objPlt:objects.standardstatement.fields.commitmentlegaltext"
                    )}
                    value={formik.values.CommitmentLegalText}
                    onChangeFunction={formik.handleChange}
                    isEditing={true}
                  />
                </Grid>
                <Grid item xs={4}>
                  <IppCheckbox
                    id="CommitmentActionDetails"
                    label={t(
                      "objPlt:objects.standardstatement.fields.commitmentactiondetails"
                    )}
                    value={formik.values.CommitmentActionDetails}
                    onChangeFunction={formik.handleChange}
                    isEditing={true}
                  />
                </Grid>
              </>
            )}
            {clientHasBenefits && (
              <>
                {ft_ben_RND && (
                  <Grid item xs={4}>
                    <IppCheckbox
                      id="InitiativeDetails"
                      label={t(
                        "objPlt:objects.standardstatement.fields.intiativedetails"
                      )}
                      value={formik.values.InitiativeDetails}
                      onChangeFunction={formik.handleChange}
                      isEditing={true}
                    />
                  </Grid>
                )}
                {ft_ben_SpendReport && (
                  <Grid item xs={4}>
                    <IppCheckbox
                      id="PaymentPeriodNotes"
                      label={t(
                        "objPlt:objects.standardstatement.fields.spendreportnotes"
                      )}
                      value={formik.values.PaymentPeriodNotes}
                      onChangeFunction={formik.handleChange}
                      isEditing={true}
                    />
                  </Grid>
                )}
              </>
            )}
            <Grid item xs={12}>
              <IppFormButtons
                isEditing={true}
                setIsEditing={null}
                isAdding={!standardStatementID}
                showCancel={true}
                resetFunction={handleEditClose}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );

  return <Box>{editForm}</Box>;
};
