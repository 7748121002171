import axiosClient from "api/axiosClient";

export interface StandardStatement {
  StandardStatementID: number;
  ClientID: number;
  StandardStatement: string;
  InteractionDetails: boolean;
  InteractionInternalNotes: boolean;
  InteractionActionDetails: boolean;
  CommitmentDetails: boolean;
  CommitmentInternalNotes: boolean;
  CommitmentRegulatoryNotes: boolean;
  CommitmentLegalText: boolean;
  CommitmentActionDetails: boolean;
  InitiativeDetails: boolean;
  PaymentPeriodNotes: boolean;
  InteractionThreadSummary: boolean;
}

export type NewStandardStatement = Omit<
  StandardStatement,
  "StandardStatementID" | "ClientID"
>;

export type UpdateStandardStatement = Partial<StandardStatement>;

export interface StandardStatementsResult {
  standardStatements: StandardStatement[];
}

export async function getStandardStatements(
  accessToken: String
): Promise<StandardStatementsResult> {
  const url = `/standardStatement`;

  try {
    const { data } = await axiosClient.get<StandardStatementsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createStandardStatement(
  accessToken: String,
  newStandardStatement: NewStandardStatement
): Promise<StandardStatement> {
  const url = `/standardStatement`;

  try {
    const { data } = await axiosClient.post<StandardStatement>(
      url,
      newStandardStatement,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateStandardStatement(
  accessToken: String,
  updatedStandardStatement: UpdateStandardStatement
): Promise<StandardStatement> {
  const standardStatementID = updatedStandardStatement.StandardStatementID;

  const url = `/standardStatement/${standardStatementID}`;

  try {
    const { data } = await axiosClient.post<StandardStatement>(
      url,
      updatedStandardStatement,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteStandardStatement(
  accessToken: String,
  standardStatementID: number
): Promise<string> {
  const url = `/standardStatement/${standardStatementID}`;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
