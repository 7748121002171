import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CommitmentGroup,
  CommitmentGroupResult,
  getCommitmentGroups,
  getCommitmentGroupsByCommitment,
  deleteCommitmentGroup,
  getCommitmentGroupsByGroupID,
} from "api/commitments/commitmentGroupAPI";
import { AppThunk } from "app/store";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { SnackBarConstants } from "utils/customHooks";

interface CommitmentGroupState {
  commitmentGroupsById: Record<number, CommitmentGroup>;
  commitmentGroupList: number[];
  isLoading: boolean;
  error: string | null;
}

const CommitmentGroupInitialState: CommitmentGroupState = {
  commitmentGroupsById: {},
  commitmentGroupList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: CommitmentGroupState) {
  state.isLoading = true;
}

function loadingFailed(
  state: CommitmentGroupState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const commitmentGroups = createSlice({
  name: "commitmentGroups",
  initialState: CommitmentGroupInitialState,
  reducers: {
    getCommitmentGroupsStart: startLoading,
    getCommitmentGroupsSuccess(
      state,
      { payload }: PayloadAction<CommitmentGroupResult>
    ) {
      const { commitmentGroups } = payload;
      state.isLoading = false;
      state.error = null;

      commitmentGroups.forEach((commitmentGroup) => {
        state.commitmentGroupsById[commitmentGroup.CommitmentGroupID] =
          commitmentGroup;
      });

      state.commitmentGroupList = commitmentGroups.map(
        (commitmentGroup) => commitmentGroup.CommitmentGroupID
      );
    },
    getCommitmentGroupsFailure: loadingFailed,
    createCommitmentGroupStart: startLoading,
    createCommitmentGroupSuccess(
      state,
      { payload }: PayloadAction<CommitmentGroup>
    ) {
      const { CommitmentGroupID } = payload;

      state.commitmentGroupsById[CommitmentGroupID] = payload;
      state.commitmentGroupList.push(CommitmentGroupID);

      state.isLoading = false;
      state.error = null;
    },
    deleteCommitmentGroupSuccess(state, { payload }: PayloadAction<number>) {
      const CommitmentGroupID = payload;

      delete state.commitmentGroupsById[CommitmentGroupID];
      state.commitmentGroupList = state.commitmentGroupList.filter(
        (item) => item !== CommitmentGroupID
      );

      state.isLoading = false;
      state.error = null;
    },
    createCommitmentGroupFailure: loadingFailed,
  },
});

export const {
  getCommitmentGroupsStart,
  getCommitmentGroupsSuccess,
  getCommitmentGroupsFailure,
  createCommitmentGroupStart,
  createCommitmentGroupSuccess,
  deleteCommitmentGroupSuccess,
  createCommitmentGroupFailure,
} = commitmentGroups.actions;

export default commitmentGroups.reducer;

export const fetchCommitmentGroups =
  (accessToken: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCommitmentGroupsStart());
      const commitmentGroups = await getCommitmentGroups(accessToken);
      dispatch(getCommitmentGroupsSuccess(commitmentGroups));
    } catch (err: any) {
      dispatch(getCommitmentGroupsFailure(err.toString()));
    }
  };

export const fetchCommitmentGroupsByCommitment =
  (accessToken: string, commitmentID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCommitmentGroupsStart());
      const commitmentGroups = await getCommitmentGroupsByCommitment(
        accessToken,
        commitmentID
      );
      dispatch(getCommitmentGroupsSuccess(commitmentGroups));
    } catch (err: any) {
      dispatch(getCommitmentGroupsFailure(err.toString()));
    }
  };

export const fetchCommitmentGroupsByGroup =
  (accessToken: string, groupID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getCommitmentGroupsStart());
      const commitmentGroups = await getCommitmentGroupsByGroupID(
        accessToken,
        groupID
      );
      dispatch(getCommitmentGroupsSuccess(commitmentGroups));
    } catch (err: any) {
      dispatch(getCommitmentGroupsFailure(err.toString()));
    }
  };

export const delCommitmentGroup =
  (
    accessToken: string,
    commitmentGroupID: number,
    snackbarConstants: SnackBarConstants
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createCommitmentGroupStart());
      await deleteCommitmentGroup(accessToken, commitmentGroupID);
      dispatch(deleteCommitmentGroupSuccess(commitmentGroupID));
      dispatch(openSnackBar(snackbarConstants.DELETE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createCommitmentGroupFailure(err.toString()));
      dispatch(openSnackBar(snackbarConstants.FAILED, "error"));
    }
  };
