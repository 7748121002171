import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { addGrievance, updGrievance } from "./GrievanceSlice";
import { Box, Grid, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { IppTextField } from "components/IppTextField";
import { IppAutocomplete } from "components/IppAutocomplete";
import { ConvertDateOffset, GetJSDate } from "../../../utils/DateFunctions";
import { IppTokenAutocomplete } from "components/IppTokenAutoComplete";
import { fetchStakeholderGroups } from "../../platform/groups/StakeholderGroupSlice";
import { fetchGrievanceStatuses } from "features/datalists/GrievanceStatusSlice";
import { fetchUsers } from "features/users/UsersSlice";
import { Contact } from "../../../api/stakeholder/contactAPI";
import { fetchContacts } from "../../platform/contacts/ContactSlice";
import { IppContactAutoComplete } from "components/IppContactAutoComplete";
import { fetchIssues } from "../issue/IssueSlice";
import { selectUserRolesByUserAccountID } from "features/roles/UserRoleSlice";
import { IppDatePicker } from "components/IppDatePicker";
import { IppPersonResponsible } from "components/IppPersonResponsible";
import {
  useRoleChecks,
  useSnackBarConstants,
  useTypedTranslation,
} from "utils/customHooks";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { ModuleRoles } from "utils/types/General.types";

interface grievanceChildFormProps {
  closeAction: any; // action to close dialog
  setDidSaveInventory: any;
  parentTitle: string; // name of field the form is called from
  parentValue: any; // if called from child grid, init parent value
  grievance?: any; // existing interaction to be edited
}

export const GrievanceChildForm = (props: grievanceChildFormProps) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);
  const snackbarConstants = useSnackBarConstants();

  const {
    closeAction,
    setDidSaveInventory,
    parentTitle,
    parentValue,
    grievance,
  } = props;

  const validationSchema = yup.object({
    GrievanceName: yup
      .string()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.grievance.name"),
        })
      )
      .max(
        250,
        t("strGen:validation.max", {
          fieldname: t("objStk:objects.grievance.name"),
          count: 250,
        })
      ),
    ReportedDate: yup
      .date()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.grievance.fields.datereported"),
        })
      )
      .max(
        new Date(),
        t("strGen:validation.date.nofuturedate", {
          fieldname: t("objStk:objects.grievance.fields.datereported"),
        })
      )
      .typeError(t("strGen:validation.date.improperformat"))
      .nullable(),
    ResolutionDate: yup
      .date()
      .nullable()
      .typeError(t("strGen:validation.date.improperformat"))
      .max(
        new Date(),
        t("strGen:validation.date.nofuturedate", {
          fieldname: t("objStk:objects.grievance.fields.dateresolved"),
        })
      )
      .when("GrievanceStatusID", {
        is: 4,
        then: (validationSchema) =>
          validationSchema.required(
            t("strGen:validation.requiredifcompleted", {
              fieldname: t("objStk:objects.grievance.fields.dateresolved"),
              fieldname2: t("objStk:objects.grievance.fields.status"),
            })
          ),
      })
      .min(
        yup.ref("ReportedDate"),
        t("strGen:validation.date.notearlierthan", {
          fieldname: t("objStk:objects.grievance.fields.dateresolved"),
          fieldname2: t("objStk:objects.grievance.fields.datereported"),
        })
      ),
    GrievanceStatusID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.grievance.fields.status"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.grievance.fields.status"),
        })
      ),
    ProjectID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.grievance.fields.project"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.grievance.fields.project"),
        })
      ),
    PersonResponsible: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.grievance.fields.personresponsible"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objStk:objects.grievance.fields.personresponsible"),
        })
      ),
    Reference: yup
      .string()
      .trim()
      .nullable()
      .max(
        50,
        t("strGen:validation.max", {
          fieldname: t("objStk:objects.grievance.fields.reference"),
          count: 50,
        })
      ),
    Accomodation: yup.string().trim().nullable(),
    InterestAddressed: yup.string().trim().nullable(),
    ResidualConcerns: yup.string().trim().nullable(),
  });

  const checkStatus = () => {
    // can't have a resolution date unless completed
    if (
      formik.values.GrievanceStatusID === 4 &&
      !formik.values.ResolutionDate
    ) {
      return false;
    }
    return true;
  };

  const checkDate = () => {
    // can't have a resolution date unless completed
    if (!formik.values.ResolutionDate) {
      return true;
    } else if (formik.values.ResolutionDate <= formik.values.ReportedDate) {
      return false;
    }
    return true;
  };

  const {
    clientId,
    isLoading: clientIsLoading,
    ft_eng_BCER,
  } = useSelector((state: RootState) => state.client);

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  let itemData: any;
  if (grievance) {
    itemData = grievance;
  } else {
    itemData = {
      GrievanceName: "",
      Details: "",
      ReportedDate: GetJSDate(new Date()),
      ResolutionDate: null,
      ProjectID: -1,
      GrievanceStatusID: -1,
      PersonResponsible: currentProfile.UserAccountID,
      PersonResponsibleName:
        currentProfile.FirstName + " " + currentProfile.Surname,
      Reference: "",
      Accomodation: "",
      InterestAddressed: "",
      ResidualConcerns: "",
    };
  }

  const [isEditing, setIsEditing] = useState(true);
  const [listGroups, setListGroups] = useState<Array<string>>([]);
  const [listIssues, setListIssues] = useState<Array<string>>([]);
  const [listInteractions, setListInteractions] = useState<Array<number>>([]);
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
  const [canSave, setCanSave] = useState(false);

  const {
    grievanceContactList,
    grievanceContactsById,
    isLoading: grievanceContactIsLoading,
    error: grievanceContactError,
  } = useSelector((state: RootState) => state.grievanceContacts);

  const grievanceContacts = grievanceContactList.map(
    (id) => grievanceContactsById[id]
  );

  const {
    grievanceGroupList,
    grievanceGroupsById,
    isLoading: grievanceGroupIsLoading,
  } = useSelector((state: RootState) => state.grievanceGroups);

  const grievanceGroupsUnfiltered = grievanceGroupList.map(
    (id) => grievanceGroupsById[id]
  );

  const grievanceGroups = grievanceGroupsUnfiltered.filter(
    (items) => items.GrievanceID === itemData.GrievanceID
  );

  const {
    grievanceInteractionList,
    grievanceInteractionsById,
    isLoading: grievanceInteractionIsLoading,
  } = useSelector((state: RootState) => state.grievanceInteractions);

  const grievanceInteractionsUnfiltered = grievanceInteractionList.map(
    (id) => grievanceInteractionsById[id]
  );

  const grievanceInteractions = grievanceInteractionsUnfiltered.filter(
    (items) => items.GrievanceID === itemData.GrievanceID
  );

  const gi = grievanceInteractions.map((p) => {
    let n = Object.assign({}, p);
    n.InteractionDate = ConvertDateOffset(p.InteractionDate);
    return n;
  });

  const {
    grievanceIssueList,
    grievanceIssuesById,
    isLoading: grievanceIssueIsLoading,
    error: grievanceIssueError,
  } = useSelector((state: RootState) => state.grievanceIssues);

  const grievanceIssuesUnfiltered = grievanceIssueList.map(
    (id) => grievanceIssuesById[id]
  );

  const grievanceIssues = grievanceIssuesUnfiltered.filter(
    (items) => items.GrievanceID === itemData.GrievanceID
  );

  const {
    contactList,
    contactsById,
    isLoading: contactIsLoading,
    error: contactError,
  } = useSelector((state: RootState) => state.contacts);

  const contacts = contactList.map((id) => contactsById[id]);

  const {
    stakeholderGroupList,
    stakeholderGroupsById,
    isLoading: groupIsLoading,
    error: groupError,
  } = useSelector((state: RootState) => state.stakeholderGroups);

  const stakeholderGroups = stakeholderGroupList.map(
    (id) => stakeholderGroupsById[id]
  );

  const {
    issueList,
    issuesById,
    isLoading: issueIsLoading,
    error: issueError,
  } = useSelector((state: RootState) => state.issues);

  const issues = issueList.map((id) => issuesById[id]);

  const {
    grievanceStatusList,
    grievanceStatusesById,
    isLoading: statusIsLoading,
    error: statusError,
  } = useSelector((state: RootState) => state.grievanceStatus);

  const grievanceStatuses = grievanceStatusList.map(
    (id) => grievanceStatusesById[id]
  );

  const { getEngagementsPowerUserRoles } = useRoleChecks();

  const engagementPowerUserRoles = getEngagementsPowerUserRoles();

  // list of project IDs available in this Module
  const accessibleProjectIDs = engagementPowerUserRoles
    .filter((role) => role.UserAccountID === currentProfile.UserAccountID)
    .map((role) => role.ProjectID);

  const {
    projectList,
    projectsById,
    isLoading: projectIsLoading,
    error: projectError,
  } = useSelector((state: RootState) => state.projects);

  // if admin, show all projects
  // if non-admin, only show projects included in accessibleProjectIds
  const projects = currentProfile.IsClientAdmin
    ? projectList.map((id) => projectsById[id])
    : projectList
        .map((id) => projectsById[id])
        .filter((project) => accessibleProjectIDs.includes(project.ProjectID));

  const {
    userList,
    usersById,
    error: userError,
    isLoading: userIsLoading,
  } = useSelector((state: RootState) => state.users);

  // Filter for active users only
  const allUsers = userList.map((id) => usersById[id]);
  let users = allUsers.filter((item) => item.IsActive === true);

  const { interactionList, interactionsById, subInteractionisLoading } =
    useSelector((state: RootState) => state.interactions);

  const interactions = interactionList.map((id) => interactionsById[id]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchGrievanceStatuses(accessToken));
        dispatch(fetchUsers(accessToken, clientId));

        if (parentTitle !== t("objStk:objects.issue.name")) {
          dispatch(fetchIssues(accessToken));
        }
        if (parentTitle !== t("objPlt:objects.contact.name")) {
          dispatch(fetchContacts(accessToken));
        }
        if (parentTitle !== t("objPlt:objects.group.name")) {
          dispatch(fetchStakeholderGroups(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, itemData.GrievanceID, dispatch, getAccessTokenSilently]);

  // initialize values of array for parent item
  useEffect(() => {
    switch (parentTitle) {
      case t("objPlt:objects.group.name"):
        setListGroups([parentValue]);
        break;

      case t("objPlt:objects.contact.name"):
        setSelectedContacts([parentValue]);
        break;

      case t("objStk:objects.issue.name"):
        setListIssues([parentValue]);
        break;

      case t("objStk:objects.interaction.name"):
        setListInteractions([parentValue]);
        break;

      default:
        break;
    }
    return () => {
      // cleanup
    };
  }, []);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        dispatch(
          addGrievance(
            accessToken,
            values,
            selectedContacts,
            listGroups,
            listIssues,
            listInteractions,
            { status: false, basePath: null },
            snackbarConstants
          )
        );
        setDidSaveInventory(true);
        closeAction();
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  if (grievance) {
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          dispatch(
            updGrievance(
              accessToken,
              values.GrievanceID,
              values,
              null,
              null,
              null,
              { status: false, basePath: null },
              snackbarConstants
            )
          );
          closeAction();
        } catch (e) {
          console.error(e);
        }
      })();
    };
  }

  const formik = useFormik({
    initialValues: itemData,
    validationSchema: validationSchema,
    onSubmit: submitFunc,
  });

  let detailForm = (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        {grievance && isEditing && (
          <Grid item xs={12}>
            <Typography variant="h6">
              {t("strGen:prompts.edit.edittitle", {
                fieldname: t("objStk:objects.grievance.name"),
              })}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <IppTextField
            id="GrievanceName"
            required
            label={t("objStk:objects.grievance.name")}
            value={formik.values.GrievanceName}
            onChangeFunction={formik.handleChange}
            touchedExpression={formik.touched.GrievanceName}
            errorsExpression={formik.errors.GrievanceName}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        <Grid item xs={6}>
          <IppDatePicker
            id="ReportedDate"
            required
            label={t("objStk:objects.grievance.fields.datereported")}
            value={ConvertDateOffset(formik.values.ReportedDate)}
            onChangeFunction={(newValue: any) => {
              formik.setFieldValue("ReportedDate", GetJSDate(newValue), true);
              formik.setFieldTouched("ReportedDate", true, false);
            }}
            errorsExpression={formik.errors.ReportedDate}
            touchedExpression={formik.touched.ReportedDate}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="caption">
              <b>{t("objStk:objects.grievance.datereportednote")}</b>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <IppDatePicker
            id="ResolutionDate"
            label={t("objStk:objects.grievance.fields.dateresolved")}
            required={false}
            value={ConvertDateOffset(formik.values.ResolutionDate)}
            onChangeFunction={(newValue: any) => {
              formik.setFieldValue("ResolutionDate", GetJSDate(newValue), true);
              formik.setFieldTouched("ResolutionDate", true, false);
            }}
            errorsExpression={formik.errors.ResolutionDate}
            touchedExpression={formik.touched.ResolutionDate}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        <Grid item xs={6}>
          <IppAutocomplete
            id="GrievanceStatusID"
            required
            options={grievanceStatuses}
            value={grievanceStatuses.find((obj) => {
              return obj.GrievanceStatusID === formik.values.GrievanceStatusID;
            })}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                formik.setFieldValue(
                  "GrievanceStatusID",
                  newValue.GrievanceStatusID
                );
              } else {
                formik.setFieldValue("GrievanceStatusID", -1);
              }
            }}
            label={t("objStk:objects.grievance.fields.status")}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            optionLabelFunction={(option: any) => option.GrievanceStatusText}
            errorFunction={formik.errors.GrievanceStatusID}
            touchedFunction={formik.touched.GrievanceStatusID}
            textValueFunction={formik.values.GrievanceStatusText}
          />
        </Grid>
        <Grid item xs={6}>
          <IppAutocomplete
            id="ProjectID"
            required
            options={projects}
            value={projects.find((obj) => {
              return obj.ProjectID === formik.values.ProjectID;
            })}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                formik.setFieldValue("ProjectID", newValue.ProjectID);
              } else {
                formik.setFieldValue("ProjectID", -1);
              }
            }}
            label={t("objStk:objects.grievance.fields.project")}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            optionLabelFunction={(option: any) => option.ProjectName}
            errorFunction={formik.errors.ProjectID}
            touchedFunction={formik.touched.ProjectID}
            textValueFunction={formik.values.ProjectName}
          />
        </Grid>
        <Grid item xs={6}>
          <IppPersonResponsible
            id="PersonResponsible"
            required
            value={allUsers.find((obj) => {
              return obj.UserAccountID === formik.values.PersonResponsible;
            })}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                formik.setFieldValue(
                  "PersonResponsible",
                  newValue.UserAccountID
                );
              } else {
                formik.setFieldValue("PersonResponsible", null);
              }
            }}
            label={t("objStk:objects.grievance.fields.personresponsible")}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            optionLabelFunction={(option: any) =>
              option.FirstName + " " + option.Surname
            }
            errorFunction={formik.errors.PersonResponsible}
            touchedFunction={formik.touched.PersonResponsible}
            textValueFunction={formik.values.PersonResponsibleName}
            projectID={formik.values.ProjectID}
          />
        </Grid>
        <Grid item xs={6}>
          <IppTextField
            id="Reference"
            label={t("objStk:objects.grievance.fields.reference")}
            value={formik.values.Reference}
            required={false}
            onChangeFunction={formik.handleChange}
            touchedExpression={formik.touched.Reference}
            errorsExpression={formik.errors.Reference}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            toolTip={t("strGen:tooltips.grievance.reference")}
          />
        </Grid>
        {ft_eng_BCER && (
          <Grid item xs={6}>
            <IppTextField
              id="Accomodation"
              label={t("objStk:objects.grievance.fields.accomodation")}
              value={formik.values.Accomodation}
              multiLine={true}
              required={false}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.Accomodation}
              errorsExpression={formik.errors.Accomodation}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
        )}
        {ft_eng_BCER && (
          <Grid item xs={6}>
            <IppTextField
              id="InterestAddressed"
              label={t("objStk:objects.grievance.fields.interestaddressed")}
              value={formik.values.InterestAddressed}
              multiLine={true}
              required={false}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.InterestAddressed}
              errorsExpression={formik.errors.InterestAddressed}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
        )}
        {ft_eng_BCER && (
          <Grid item xs={6}>
            <IppTextField
              id="ResidualConcerns"
              label={t("objStk:objects.grievance.fields.residualconcerns")}
              value={formik.values.ResidualConcerns}
              multiLine={true}
              required={false}
              onChangeFunction={formik.handleChange}
              touchedExpression={formik.touched.ResidualConcerns}
              errorsExpression={formik.errors.ResidualConcerns}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Grid>
        )}
        {!grievance && (
          <>
            <Grid item xs={12}>
              <IppTokenAutocomplete
                id="GroupList"
                label={t("objStk:objects.grievance.fields.groupsinvolved", {
                  count: 2,
                })}
                options={stakeholderGroups.map((option) => option.GroupName)}
                selectedValues={listGroups}
                setSelectedValues={setListGroups}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </Grid>
            <Grid item xs={12}>
              <IppContactAutoComplete
                options={contacts}
                selectedContacts={selectedContacts}
                setSelectedContacts={setSelectedContacts}
              />
            </Grid>
            <Grid item xs={12}>
              <IppTokenAutocomplete
                id="IssueList"
                label={t("objStk:objects.grievance.fields.relatedissues", {
                  count: 2,
                })}
                options={issues.map((option) => option.IssueName)}
                selectedValues={listIssues}
                setSelectedValues={setListIssues}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <IppFormButtons
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            isAdding={!grievance}
            showCancel={true}
            submitDisabled={canSave}
            resetFunction={() => {
              closeAction();
            }}
          />
        </Grid>
      </Grid>
    </form>
  );

  return <div id="grievance-form">{detailForm}</div>;
};
