import { Company } from "api/companyAPI";
import { RootState } from "app/rootReducer";
import { useSelector } from "react-redux";

export const useCompanyListByRole = (companyList: Company[]) => {
  const { currentProfile } = useSelector((state: RootState) => state.profile);
  const { IsClient, CompanyID } = currentProfile;

  if (IsClient) return companyList;

  return companyList.filter((company) => company.CompanyID === CompanyID);
};
