import { Box, Grid, Popover, Tooltip } from "@mui/material";
import { useRef, useState } from "react";
import { IppButton } from "./Buttons/IppButton";
import { IppMenuButton } from "./Buttons/IppMenuButton";

type ButtonColor =
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning";

export interface ButtonProps {
  onClick: () => void;
  startIcon: JSX.Element;
  disabled: boolean;
  color?: ButtonColor;
  text: string;
  gridSize?: number;
  tooltip?: string;
}

export type LinkButtonProps = Omit<ButtonProps, "onClick"> & {
  linkPath: string;
};

export type ButtonsArrayProps = ButtonProps | LinkButtonProps;

interface IppPopoverMenuProps {
  tooltipMessage: string;
  buttons: ButtonsArrayProps[];
  width?: string;
}

const isLinkButton = (button: ButtonsArrayProps): button is LinkButtonProps => {
  return (button as LinkButtonProps).linkPath !== undefined;
};

const formatButtonProps = (
  buttons: ButtonsArrayProps[]
): ButtonsArrayProps[] => {
  return buttons.map((button) => {
    return {
      ...button,
      color: button.color || "secondary",
      gridSize: button.gridSize || 12,
      tooltip: button.tooltip || "",
    };
  });
};

export const IppPopoverMenu = (props: IppPopoverMenuProps) => {
  const { tooltipMessage, buttons, width } = props;
  const buttonBoxRef = useRef<HTMLDivElement | null>(null);

  const formattedButtons = formatButtonProps(buttons);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleToggle = () => {
    setAnchorEl(anchorEl ? null : buttonBoxRef.current);
  };

  // Append handleToggle to the provided button onClick
  const handleButtonClick = (buttonOnClick?: () => void) => {
    return () => {
      handleToggle();
      if (buttonOnClick) {
        buttonOnClick();
      }
    };
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Box ref={buttonBoxRef} display="inline-block">
        <IppMenuButton
          isOpenButton={!open}
          onClick={handleToggle}
          tooltip={!open ? tooltipMessage : undefined}
          sx={{ zIndex: 10 }} // zIndex to ensure button is above popover so that the pointer event is present
        />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPopover-paper": { width: width, marginTop: 1 },
          zIndex: 9,
        }}
      >
        <Grid container padding={1} spacing={1} justifyContent="space-between">
          {formattedButtons.map((button) => {
            if (isLinkButton(button)) {
              return (
                <Grid item xs={button.gridSize || 12}>
                  <a
                    href={button.linkPath}
                    // following line removed to avoid blank tab in Edge
                    // target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecorationLine: "none", color: "inherit" }}
                    download
                  >
                    <Tooltip title={button.tooltip}>
                      <IppButton
                        startIcon={button.startIcon}
                        color={button.color}
                        disabled={button.disabled}
                        fullWidth
                        onClick={handleToggle}
                      >
                        {button.text}
                      </IppButton>
                    </Tooltip>
                  </a>
                </Grid>
              );
            } else {
              return (
                <Grid item xs={button.gridSize}>
                  <Tooltip title={button.tooltip}>
                    <IppButton
                      onClick={handleButtonClick(button.onClick)}
                      startIcon={button.startIcon}
                      disabled={button.disabled}
                      color={button.color}
                      fullWidth
                    >
                      {button.text}
                    </IppButton>
                  </Tooltip>
                </Grid>
              );
            }
          })}
        </Grid>
      </Popover>
    </>
  );
};

IppPopoverMenu.defaultProps = {
  width: "150px",
};
