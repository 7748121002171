import axiosClient from "../axiosClient";

export interface BenefitsQuestionnaire {
  BenefitsQuestionnaireID?: number;
  AccessKey: string;
  TermsAccepted: boolean;
  ProponentName: string;
  ProponentAddress: string;
  ProponentCity: string;
  ProponentProvinceState: string;
  ProponentCountry: string;
  WorkScopeName: string;
  WorkScopeAddress: string;
  WorkScopeCity: string;
  WorkScopeProvinceState: string;
  WorkScopeCountry: string;
  ReferenceNumber?: number;
  ExpectedAnticipatedWorkLocation: string;
  ExpectedGoodsServices: string;
  ExistingGoodsServices: string;
  PercentNLandOC?: number;
  ProcurementStrategy: string;
  Policies: string;
  ResearchDevelopment: string;
  TechTransfer: string;
  ContractManager: string;
  ExistingAnticipatedWorkLocation: string;
  ExistingSubconSupName: string;
  ExistingSubconSupLocation: string;
}

export interface BenefitsQuestionnaireResult {
  benefitsQuestionnaires: BenefitsQuestionnaire[];
}

export async function getAllBenefitsQuestionnaire(
  accessToken: string
): Promise<BenefitsQuestionnaireResult> {
  const url = `/benefitsQuestionnaire`;

  try {
    const { data } = await axiosClient.get<BenefitsQuestionnaireResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    console.log("API Response:", data); // <-- Debugging Step

    return data;
  } catch (err: any) {
    throw err;
  }
}
