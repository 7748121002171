export const ShowActiveCompanies = <
  T extends {
    ShowAsActive: boolean;
    IsClient: boolean;
    CompanyName: string;
  }
>(
  companies: T[],
  alwaysReturnClient?: boolean
): T[] => {
  return companies
    .filter(
      (company) =>
        company.ShowAsActive || (alwaysReturnClient && company.IsClient)
    )
    .sort((a, b) => a.CompanyName.localeCompare(b.CompanyName)); // Sort alphabetically by CompanyName
};
