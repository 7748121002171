import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Grid, IconButton, Paper, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router";
import { ArrowLeft, ArrowRight, Save } from "@mui/icons-material";
import { IppDisplayField } from "components/IppDisplayField";
import { IppSkeleton } from "components/IppSkeleton";
import { IppTextField } from "components/IppTextField";
import { IppAutocomplete } from "components/IppAutocomplete";
import { fetchCompanies, mrgCompany } from "./CompaniesSlice";
import { CompanyValidationSchema } from "./CompanyValidation";
import { IppCheckbox } from "components/IppCheckbox";
import { fetchCategories } from "features/datalists/CategorySlice";
import { fetchIndigenousGroupsByClient } from "features/datalists/IndigenousGroupSlice";
import { fetchCompanyStructures } from "features/datalists/CompanyStructureSlice";
import { Company } from "api/companyAPI";
import { IppLocationAutoCompleteMerge } from "components/IppLocationAutoCompleteMerge";
import { fetchAllTiers } from "../tier/TierSlice";
import { useTypedTranslation } from "utils/customHooks";
import { fetchRegionsByRecordType } from "../regions/RegionSlice";
import { fetchClientActivityLocations } from "features/platform/admin/clientActivityLocations/clientActivityLocationSlice";
import { IppSubmitButton } from "components/Buttons/IppSubmitButton";
import { useGetBasePath } from "../../../utils/hooks/useGetBasePath";

interface CompanyMergeProps {
  originalID: string;
  newID: string;
}

const PREFIX = "CompanyMerge";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    // minWidth: 650,
    maxWidth: 1150,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const CompanyMergePage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objBen", "objPlt", "strGen"]);
  const [validationOK, setValidationOK] = useState(true);

  //Correct Re-directs for if a user is in SSHE or Benefits:
  const basePath = useGetBasePath();

  const { originalID, newID } = useParams<CompanyMergeProps>();
  const originalID_int = parseInt(originalID, 10);
  const newID_int = parseInt(newID, 10);
  if (typeof originalID_int != "number" && typeof newID_int != "number") {
    setValidationOK(false);
  }

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const {
    companiesById,
    companyList,
    isLoading: companyIsLoading,
    error: companyError,
  } = useSelector((state: RootState) => state.companies);

  const companies = companyList.map((companyID) => companiesById[companyID]);

  const {
    indigenousGroupList,
    indigenousGroupsById,
    error: indigenousGroupError,
    isLoading: indigenousGroupIsLoading,
  } = useSelector((state: RootState) => state.indigenousGroups);

  const indigenousGroups = indigenousGroupList.map(
    (IndigenousGroupID) => indigenousGroupsById[IndigenousGroupID]
  );

  const {
    companyStructureList,
    companyStructuresById,
    error: structureError,
    isLoading: structureIsLoading,
  } = useSelector((state: RootState) => state.companyStructures);

  const companyStructures = companyStructureList.map(
    (id) => companyStructuresById[id]
  );

  const {
    clientCompanyStructuresById,
    clientCompanyStructureList,
    isLoading: isLoadingClientCompanyStructures,
    error: clientCompanyStructuresError,
  } = useSelector((state: RootState) => state.clientCompanyStructures);
  const clientCompanyStructures = clientCompanyStructureList.map(
    (id) => clientCompanyStructuresById[id]
  );

  const {
    categoriesById,
    categoryList,
    error: categoryError,
    isLoading: categoryIsLoading,
  } = useSelector((state: RootState) => state.categories);

  const categories = categoryList.map(
    (CategoryID) => categoriesById[CategoryID]
  );

  const {
    clientId,
    ft_ben_Tier,
    ft_ben_Region,
    ft_ben_BusinessCategory,
    ft_ben_ActivityLocation,
    isLoading: clientIsLoading,
  } = useSelector((state: RootState) => state.client);

  const { tierList, tiersById, tierIsLoading } = useSelector(
    (state: RootState) => state.tier
  );

  const tiers = tierList.map((id) => tiersById[id]);

  const { regionList, regionsById, regionIsLoading } = useSelector(
    (state: RootState) => state.region
  );

  const regions = ft_ben_Region ? regionList.map((id) => regionsById[id]) : [];
  const hasRegionTypes = !!(ft_ben_Region && regions.length > 0);

  const { clientActivityLocationList, clientActivityLocationsById } =
    useSelector((state: RootState) => state.clientActivityLocations);

  const activityLocations =
    ft_ben_ActivityLocation && currentProfile.IsClient
      ? clientActivityLocationList.map((id) => clientActivityLocationsById[id])
      : [];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        if (companies.length === 0) {
          dispatch(fetchCompanies(accessToken, clientId));
          dispatch(fetchCategories(accessToken));
          dispatch(fetchIndigenousGroupsByClient(accessToken));
          dispatch(fetchCompanyStructures(accessToken));
        }
        if (ft_ben_Tier) {
          dispatch(fetchAllTiers(accessToken));
        }
        if (ft_ben_Region) {
          dispatch(fetchRegionsByRecordType(accessToken, "Company"));
        }
        if (ft_ben_ActivityLocation && currentProfile.IsClient) {
          dispatch(fetchClientActivityLocations(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, companies.length, dispatch, getAccessTokenSilently]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        //console.log("MERGED vals", values);
        dispatch(
          mrgCompany(accessToken, originalID_int, values, newID_int, basePath)
        );
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const oldCompany: Company = companiesById[Number(originalID_int)];
  const dupeCompany: Company = companiesById[Number(newID_int)];
  const mergeCompany: { [key: string]: any } = { ...oldCompany };

  for (const [key, value] of Object.entries(mergeCompany)) {
    if (!value || value < 0) {
      mergeCompany[key] = dupeCompany[key as keyof Company];
    }
  }

  const strictMergeCompany: Company = mergeCompany as Company;

  const formik = useFormik({
    initialValues: strictMergeCompany,
    validationSchema: CompanyValidationSchema({
      isChildSchema: false,
      companies: companies,
      compData: strictMergeCompany,
      isAdding: false,
      tierEnabled: ft_ben_Tier,
      regionEnabled: hasRegionTypes,
      activityLocationEnabled:
        ft_ben_ActivityLocation && currentProfile.IsClient,
      oldCompVendorID: oldCompany?.VendorID,
      dupeCompVendorID: dupeCompany?.VendorID,
    }),
    onSubmit: onSub,
  });

  useEffect(() => {
    if (formik.values) {
      // Reset values for Company Structure and Indigenous Group if Indigenous becomes false
      if (formik.values.Indigenous === false) {
        formik.setFieldValue("CompanyStructureID", 1);
        formik.setFieldValue("IndigenousGroupID", null);
      }
    }
  }, [formik.values]);

  let formView =
    companyIsLoading ||
    indigenousGroupIsLoading ||
    isLoadingClientCompanyStructures ||
    !oldCompany ||
    !formik.values ? (
      <IppSkeleton height={400} />
    ) : (
      <form onSubmit={formik.handleSubmit}>
        <Paper elevation={6} className={classes.boxSpace}>
          <Box padding={1} sx={{ borderRadius: 1 }}>
            <Grid container spacing={0}>
              <Grid item xs={12} container justifyContent="flex-end">
                <IppSubmitButton buttonText={t("strGen:buttons.save")} />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Original Company
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={8}>
                    <Typography variant="h6" gutterBottom>
                      Merged Values
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Duplicate
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/*  grid block */}
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label="Name"
                      value={oldCompany.CompanyName}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "CompanyName",
                          oldCompany.CompanyName
                        )
                      }
                    >
                      <ArrowRight />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <IppTextField
                  id="CompanyName"
                  label="Name*"
                  required={false}
                  value={formik.values.CompanyName}
                  onChangeFunction={formik.handleChange}
                  touchedExpression={formik.touched.CompanyName}
                  errorsExpression={formik.errors.CompanyName}
                  isEditing={true}
                  setIsEditing={null}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "CompanyName",
                          dupeCompany.CompanyName
                        )
                      }
                    >
                      <ArrowLeft />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label="Name"
                      value={dupeCompany.CompanyName}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label="Vendor ID"
                      value={oldCompany.VendorID}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue("VendorID", oldCompany.VendorID)
                      }
                    >
                      <ArrowRight />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <IppTextField
                  id="VendorID"
                  label="Vendor ID"
                  required={false}
                  value={formik.values.VendorID}
                  onChangeFunction={formik.handleChange}
                  touchedExpression={formik.touched.VendorID}
                  errorsExpression={formik.errors.VendorID}
                  isEditing={true}
                  setIsEditing={null}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue("VendorID", dupeCompany.VendorID)
                      }
                    >
                      <ArrowLeft />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label="Vendor ID"
                      value={dupeCompany.VendorID}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {ft_ben_Tier && (
                <>
                  <Grid item xs={4}>
                    <Grid container spacing={0}>
                      <Grid item xs={10}>
                        <IppDisplayField
                          label={t("objBen:objects.tiers.name")}
                          value={oldCompany.TierName}
                          isEditing={false}
                          setIsEditing={null}
                          showEdit={false}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() =>
                            formik.setFieldValue("TierID", oldCompany.TierID)
                          }
                        >
                          <ArrowRight />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <IppAutocomplete
                      id="TierID"
                      options={tiers}
                      value={tiers.find((obj) => {
                        return obj.TierID === formik.values.TierID;
                      })}
                      onChangeFunction={(event: ChangeEvent, newValue: any) => {
                        if (newValue) {
                          formik.setFieldValue("TierID", newValue.TierID);
                        } else {
                          formik.setFieldValue("TierID", null);
                        }
                      }}
                      label={t("objBen:objects.tiers.name")}
                      required={true}
                      isEditing={true}
                      setIsEditing={null}
                      optionLabelFunction={(option: any) => option.TierName}
                      touchedFunction={formik.touched.TierID}
                      errorFunction={formik.errors.TierID}
                      textValueFunction={formik.values.TierName}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={0}>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() =>
                            formik.setFieldValue("TierID", dupeCompany.TierID)
                          }
                        >
                          <ArrowLeft />
                        </IconButton>
                      </Grid>
                      <Grid item xs={10}>
                        <IppDisplayField
                          label={t("objBen:objects.tiers.name")}
                          value={dupeCompany.TierName}
                          isEditing={false}
                          setIsEditing={null}
                          showEdit={false}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}

              <IppLocationAutoCompleteMerge
                formik={formik}
                oldValues={oldCompany}
                dupeValues={dupeCompany}
                townCityOptions={{ show: true, required: true }}
                provinceStateOptions={{ show: true, required: true }}
                countryOptions={{ show: true, required: true }}
              />
              {hasRegionTypes && (
                <>
                  <Grid item xs={4}>
                    <Grid container spacing={0}>
                      <Grid item xs={10}>
                        <IppDisplayField
                          label={t("objBen:objects.company.fields.region.name")}
                          value={oldCompany.RegionName}
                          isEditing={false}
                          setIsEditing={null}
                          showEdit={false}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() =>
                            formik.setFieldValue(
                              "RegionID",
                              oldCompany.RegionID
                            )
                          }
                        >
                          <ArrowRight />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <IppAutocomplete
                      id="RegionID"
                      options={regions}
                      value={regions.find((obj) => {
                        return obj.RegionID === formik.values.RegionID;
                      })}
                      onChangeFunction={(event: ChangeEvent, newValue: any) => {
                        if (newValue) {
                          formik.setFieldValue("RegionID", newValue.RegionID);
                        } else {
                          formik.setFieldValue("RegionID", null);
                        }
                      }}
                      label={t("objBen:objects.company.fields.region.name")}
                      required={true}
                      isEditing={true}
                      setIsEditing={null}
                      optionLabelFunction={(option: any) => option.RegionName}
                      touchedFunction={formik.touched.RegionID}
                      errorFunction={formik.errors.RegionID}
                      textValueFunction={formik.values.RegionName}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={0}>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() =>
                            formik.setFieldValue(
                              "RegionID",
                              dupeCompany.RegionID
                            )
                          }
                        >
                          <ArrowLeft />
                        </IconButton>
                      </Grid>
                      <Grid item xs={10}>
                        <IppDisplayField
                          label={t("objBen:objects.company.fields.region.name")}
                          value={dupeCompany.RegionName}
                          isEditing={false}
                          setIsEditing={null}
                          showEdit={false}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              {ft_ben_ActivityLocation && currentProfile.IsClient && (
                <>
                  <Grid item xs={4}>
                    <Grid container spacing={0}>
                      <Grid item xs={10}>
                        <IppDisplayField
                          label={t(
                            "objBen:objects.company.fields.activitylocation.name"
                          )}
                          value={oldCompany.ActivityLocationName}
                          isEditing={false}
                          setIsEditing={null}
                          showEdit={false}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() =>
                            formik.setFieldValue(
                              "ActivityLocationID",
                              oldCompany.ActivityLocationID
                            )
                          }
                        >
                          <ArrowRight />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <IppAutocomplete
                      id="ActivityLocationID"
                      options={activityLocations}
                      value={activityLocations.find((obj) => {
                        return (
                          obj.ActivityLocationID ===
                          formik.values.ActivityLocationID
                        );
                      })}
                      onChangeFunction={(event: ChangeEvent, newValue: any) => {
                        if (newValue) {
                          formik.setFieldValue(
                            "ActivityLocationID",
                            newValue.ActivityLocationID
                          );
                        } else {
                          formik.setFieldValue("ActivityLocationID", null);
                        }
                      }}
                      label={t(
                        "objBen:objects.company.fields.activitylocation.name"
                      )}
                      required={true}
                      isEditing={true}
                      setIsEditing={null}
                      optionLabelFunction={(option: any) =>
                        option.ActivityLocationName
                      }
                      touchedFunction={formik.touched.ActivityLocationID}
                      errorFunction={formik.errors.ActivityLocationID}
                      textValueFunction={formik.values.ActivityLocationName}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={0}>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() =>
                            formik.setFieldValue(
                              "ActivityLocationID",
                              dupeCompany.ActivityLocationID
                            )
                          }
                        >
                          <ArrowLeft />
                        </IconButton>
                      </Grid>
                      <Grid item xs={10}>
                        <IppDisplayField
                          label={t(
                            "objBen:objects.company.fields.activitylocation.name"
                          )}
                          value={dupeCompany.ActivityLocationName}
                          isEditing={false}
                          setIsEditing={null}
                          showEdit={false}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              {ft_ben_BusinessCategory && (
                <Grid item xs={4}>
                  <Grid container spacing={0}>
                    <Grid item xs={10}>
                      <IppDisplayField
                        label="Category of Business"
                        value={
                          !categoryIsLoading && oldCompany.CategoryID > 0
                            ? categoriesById[oldCompany.CategoryID].CategoryName
                            : ""
                        }
                        isEditing={false}
                        setIsEditing={null}
                        showEdit={false}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton
                        onClick={() =>
                          formik.setFieldValue(
                            "CategoryID",
                            oldCompany.CategoryID
                          )
                        }
                      >
                        <ArrowRight />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {ft_ben_BusinessCategory && (
                <Grid item xs={4}>
                  <IppAutocomplete
                    id="CategoryID"
                    options={categories}
                    value={
                      categories.find((obj) => {
                        return obj.CategoryID === formik.values.CategoryID;
                      }) || null
                    }
                    onChangeFunction={(event: ChangeEvent, newValue: any) => {
                      if (newValue) {
                        formik.setFieldValue("CategoryID", newValue.CategoryID);
                      } else {
                        formik.setFieldValue("CategoryID", null);
                      }
                    }}
                    label="Category of Business"
                    isEditing={true}
                    setIsEditing={null}
                    optionLabelFunction={(option: any) => option.CategoryName}
                    touchedFunction={formik.touched.CategoryID}
                    errorFunction={formik.errors.CategoryID}
                    textValueFunction={
                      !categoryIsLoading && formik.values.CategoryID > 0
                        ? categoriesById[formik.values.CategoryID].CategoryName
                        : ""
                    }
                  />
                </Grid>
              )}
              {ft_ben_BusinessCategory && (
                <Grid item xs={4}>
                  <Grid container spacing={0}>
                    <Grid item xs={2}>
                      <IconButton
                        onClick={() =>
                          formik.setFieldValue(
                            "CategoryID",
                            dupeCompany.CategoryID
                          )
                        }
                      >
                        <ArrowLeft />
                      </IconButton>
                    </Grid>
                    <Grid item xs={10}>
                      <IppDisplayField
                        label="Category of Business"
                        value={
                          !categoryIsLoading && dupeCompany.CategoryID > 0
                            ? categoriesById[dupeCompany.CategoryID]
                                .CategoryName
                            : ""
                        }
                        isEditing={false}
                        setIsEditing={null}
                        showEdit={false}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label={t("objBen:objects.company.fields.internalreference")}
                      value={oldCompany.Reference}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue("Reference", oldCompany.Reference)
                      }
                    >
                      <ArrowRight />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <IppTextField
                  id="Reference"
                  label={t("objBen:objects.company.fields.internalreference")}
                  value={formik.values.Reference}
                  onChangeFunction={formik.handleChange}
                  touchedExpression={formik.touched.Reference}
                  errorsExpression={formik.errors.Reference}
                  isEditing={true}
                  setIsEditing={null}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue("Reference", dupeCompany.Reference)
                      }
                    >
                      <ArrowLeft />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label={t("objBen:objects.company.fields.internalreference")}
                      value={dupeCompany.Reference}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label="Notes"
                      value={oldCompany.CompanyNotes}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "CompanyNotes",
                          oldCompany.CompanyNotes
                        )
                      }
                    >
                      <ArrowRight />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <IppTextField
                  id="CompanyNotes"
                  label="Notes"
                  required={false}
                  value={formik.values.CompanyNotes}
                  onChangeFunction={formik.handleChange}
                  touchedExpression={formik.touched.CompanyNotes}
                  errorsExpression={formik.errors.CompanyNotes}
                  isEditing={true}
                  setIsEditing={null}
                  multiLine
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "CompanyNotes",
                          dupeCompany.CompanyNotes
                        )
                      }
                    >
                      <ArrowLeft />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label="Notes"
                      value={dupeCompany.CompanyNotes}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <IppCheckbox
                      id="Indigenous"
                      label={t("objPlt:platformwide.indigenous.name")}
                      value={oldCompany.Indigenous}
                      onChangeFunction={formik.handleChange}
                      isEditing={false}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "Indigenous",
                          oldCompany.Indigenous
                        )
                      }
                    >
                      <ArrowRight />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <IppCheckbox
                  id="Indigenous"
                  label={t("objPlt:platformwide.indigenous.name")}
                  value={formik.values.Indigenous}
                  onChangeFunction={formik.handleChange}
                  isEditing={true}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "Indigenous",
                          dupeCompany.Indigenous
                        )
                      }
                    >
                      <ArrowLeft />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <IppCheckbox
                      id="Indigenous"
                      label={t("objPlt:platformwide.indigenous.name")}
                      value={dupeCompany.Indigenous}
                      onChangeFunction={formik.handleChange}
                      isEditing={false}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {formik.values.Indigenous === true && (
                <>
                  <Grid item xs={4}>
                    <Grid container spacing={0}>
                      <Grid item xs={10}>
                        <IppDisplayField
                          label={t(
                            "objPlt:platformwide.indigenous.indigenousgroup"
                          )}
                          value={
                            oldCompany.IndigenousGroupID > 0
                              ? indigenousGroupsById[
                                  oldCompany.IndigenousGroupID
                                ].IndigenousGroupName
                              : ""
                          }
                          isEditing={false}
                          setIsEditing={null}
                          showEdit={false}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() =>
                            formik.setFieldValue(
                              "IndigenousGroupID",
                              oldCompany.IndigenousGroupID
                            )
                          }
                        >
                          <ArrowRight />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <IppAutocomplete
                      id="IndigenousGroupID"
                      label={t(
                        "objPlt:platformwide.indigenous.indigenousgroup"
                      )}
                      options={indigenousGroups}
                      value={
                        indigenousGroups.find((value) => {
                          return (
                            value.IndigenousGroupID ===
                            formik.values.IndigenousGroupID
                          );
                        }) || null
                      }
                      onChangeFunction={(event: ChangeEvent, newValue: any) => {
                        if (newValue) {
                          formik.setFieldValue(
                            "IndigenousGroupID",
                            newValue.IndigenousGroupID
                          );
                        } else {
                          formik.setFieldValue("IndigenousGroupID", null);
                        }
                      }}
                      touchedFunction={formik.touched.IndigenousGroupID}
                      errorFunction={formik.errors.IndigenousGroupID}
                      isEditing={true}
                      setIsEditing={null}
                      optionLabelFunction={(option: any) =>
                        option.IndigenousGroupName
                      }
                      textValueFunction={
                        !indigenousGroupIsLoading &&
                        formik.values.IndigenousGroupID > 0 &&
                        indigenousGroupsById[formik.values.IndigenousGroupID]
                          ? indigenousGroupsById[
                              formik.values.IndigenousGroupID
                            ].IndigenousGroupName
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={0}>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() =>
                            formik.setFieldValue(
                              "IndigenousGroupID",
                              dupeCompany.IndigenousGroupID
                            )
                          }
                        >
                          <ArrowLeft />
                        </IconButton>
                      </Grid>
                      <Grid item xs={10}>
                        <IppDisplayField
                          label={t(
                            "objPlt:platformwide.indigenous.indigenousgroup"
                          )}
                          value={
                            dupeCompany.IndigenousGroupID > 0
                              ? indigenousGroupsById[
                                  dupeCompany.IndigenousGroupID
                                ].IndigenousGroupName
                              : ""
                          }
                          isEditing={false}
                          setIsEditing={null}
                          showEdit={false}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={4}>
                    <Grid container spacing={0}>
                      <Grid item xs={10}>
                        <IppDisplayField
                          label={t(
                            "objBen:objects.company.fields.ownershipstructure"
                          )}
                          value={
                            !structureIsLoading &&
                            oldCompany.CompanyStructureID > 0 &&
                            companyStructuresById[oldCompany.CompanyStructureID]
                              ? companyStructuresById[
                                  oldCompany.CompanyStructureID
                                ].CompanyStructureName
                              : ""
                          }
                          isEditing={false}
                          setIsEditing={null}
                          showEdit={false}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() =>
                            formik.setFieldValue(
                              "CompanyStructureID",
                              oldCompany.CompanyStructureID
                            )
                          }
                        >
                          <ArrowRight />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <IppAutocomplete
                      id="CompanyStructureID"
                      options={clientCompanyStructures}
                      value={companyStructures.find((obj) => {
                        return (
                          obj.CompanyStructureID ===
                          formik.values.CompanyStructureID
                        );
                      })}
                      onChangeFunction={(event: ChangeEvent, newValue: any) => {
                        if (newValue) {
                          formik.setFieldValue(
                            "CompanyStructureID",
                            newValue.CompanyStructureID
                          );
                        } else {
                          formik.setFieldValue("CompanyStructureID", 1);
                        }
                      }}
                      label={t(
                        "objBen:objects.company.fields.ownershipstructure"
                      )}
                      required={true}
                      isEditing={true}
                      setIsEditing={null}
                      optionLabelFunction={(option: any) =>
                        option.CompanyStructureName
                      }
                      touchedFunction={formik.touched.CompanyStructureID}
                      errorFunction={formik.errors.CompanyStructureID}
                      textValueFunction={
                        !structureIsLoading &&
                        formik.values.CompanyStructureID > 0 &&
                        companyStructuresById[formik.values.CompanyStructureID]
                          ? companyStructuresById[
                              formik.values.CompanyStructureID
                            ].CompanyStructureName
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={0}>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() =>
                            formik.setFieldValue(
                              "CompanyStructureID",
                              dupeCompany.CompanyStructureID
                            )
                          }
                        >
                          <ArrowLeft />
                        </IconButton>
                      </Grid>
                      <Grid item xs={10}>
                        <IppDisplayField
                          label={t(
                            "objBen:objects.company.fields.ownershipstructure"
                          )}
                          value={
                            !structureIsLoading &&
                            dupeCompany.CompanyStructureID > 0 &&
                            companyStructuresById[
                              dupeCompany.CompanyStructureID
                            ]
                              ? companyStructuresById[
                                  dupeCompany.CompanyStructureID
                                ].CompanyStructureName
                              : ""
                          }
                          isEditing={false}
                          setIsEditing={null}
                          showEdit={false}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <IppCheckbox
                      id="ShowAsActive"
                      label="Show as Active"
                      value={oldCompany.ShowAsActive}
                      onChangeFunction={formik.handleChange}
                      isEditing={false}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "ShowAsActive",
                          oldCompany.ShowAsActive
                        )
                      }
                    >
                      <ArrowRight />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <IppCheckbox
                  id="ShowAsActive"
                  label="Show as Active"
                  value={formik.values.ShowAsActive}
                  onChangeFunction={formik.handleChange}
                  isEditing={true}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "ShowAsActive",
                          dupeCompany.ShowAsActive
                        )
                      }
                    >
                      <ArrowLeft />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <IppCheckbox
                      id="ShowAsActive"
                      label="Show as Active"
                      value={dupeCompany.ShowAsActive}
                      onChangeFunction={formik.handleChange}
                      isEditing={false}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <IppCheckbox
                      id="WomanOwned"
                      label="Woman-Owned business"
                      value={oldCompany.WomanOwned}
                      onChangeFunction={formik.handleChange}
                      isEditing={false}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "WomanOwned",
                          oldCompany.WomanOwned
                        )
                      }
                    >
                      <ArrowRight />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <IppCheckbox
                  id="WomanOwned"
                  label="Woman-Owned business"
                  value={formik.values.WomanOwned}
                  onChangeFunction={formik.handleChange}
                  isEditing={true}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "WomanOwned",
                          dupeCompany.WomanOwned
                        )
                      }
                    >
                      <ArrowLeft />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <IppCheckbox
                      id="WomanOwned"
                      label="Woman-Owned business"
                      value={dupeCompany.WomanOwned}
                      onChangeFunction={formik.handleChange}
                      isEditing={false}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <IppCheckbox
                      id="VeteranOwned"
                      label={t(
                        "objBen:objects.diversityindicators.fields.veteranowned"
                      )}
                      value={oldCompany.VeteranOwned}
                      onChangeFunction={formik.handleChange}
                      isEditing={false}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "VeteranOwned",
                          oldCompany.VeteranOwned
                        )
                      }
                    >
                      <ArrowRight />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <IppCheckbox
                  id="VeteranOwned"
                  label={t(
                    "objBen:objects.diversityindicators.fields.veteranowned"
                  )}
                  value={formik.values.VeteranOwned}
                  onChangeFunction={formik.handleChange}
                  isEditing={true}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "VeteranOwned",
                          dupeCompany.VeteranOwned
                        )
                      }
                    >
                      <ArrowLeft />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <IppCheckbox
                      id="VeteranOwned"
                      label={t(
                        "objBen:objects.diversityindicators.fields.veteranowned"
                      )}
                      value={dupeCompany.VeteranOwned}
                      onChangeFunction={formik.handleChange}
                      isEditing={false}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <IppCheckbox
                      id="NorthernResident"
                      label={t(
                        "objBen:objects.diversityindicators.fields.northernresidentcompany"
                      )}
                      value={oldCompany.NorthernResident}
                      onChangeFunction={formik.handleChange}
                      isEditing={false}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "NorthernResident",
                          oldCompany.NorthernResident
                        )
                      }
                    >
                      <ArrowRight />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <IppCheckbox
                  id="NorthernResident"
                  label={t(
                    "objBen:objects.diversityindicators.fields.northernresidentcompany"
                  )}
                  value={formik.values.NorthernResident}
                  onChangeFunction={formik.handleChange}
                  isEditing={true}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "NorthernResident",
                          dupeCompany.NorthernResident
                        )
                      }
                    >
                      <ArrowLeft />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <IppCheckbox
                      id="NorthernResident"
                      label={t(
                        "objBen:objects.diversityindicators.fields.northernresidentcompany"
                      )}
                      value={dupeCompany.NorthernResident}
                      onChangeFunction={formik.handleChange}
                      isEditing={false}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <IppCheckbox
                      id="Disability"
                      label="Owned by person(s) with Disability"
                      value={oldCompany.Disability}
                      onChangeFunction={formik.handleChange}
                      isEditing={false}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "Disability",
                          oldCompany.Disability
                        )
                      }
                    >
                      <ArrowRight />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <IppCheckbox
                  id="Disability"
                  label="Owned by person(s) with Disability"
                  value={formik.values.Disability}
                  onChangeFunction={formik.handleChange}
                  isEditing={true}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "Disability",
                          dupeCompany.Disability
                        )
                      }
                    >
                      <ArrowLeft />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <IppCheckbox
                      id="Disability"
                      label="Owned by person(s) with Disability"
                      value={dupeCompany.Disability}
                      onChangeFunction={formik.handleChange}
                      isEditing={false}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <IppCheckbox
                      id="VisibleMinority"
                      label="Owned by member(s) of Visible Minority"
                      value={oldCompany.VisibleMinority}
                      onChangeFunction={formik.handleChange}
                      isEditing={false}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "VisibleMinority",
                          oldCompany.VisibleMinority
                        )
                      }
                    >
                      <ArrowRight />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <IppCheckbox
                  id="VisibleMinority"
                  label="Owned by member(s) of Visible Minority"
                  value={formik.values.VisibleMinority}
                  onChangeFunction={formik.handleChange}
                  isEditing={true}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "VisibleMinority",
                          dupeCompany.VisibleMinority
                        )
                      }
                    >
                      <ArrowLeft />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <IppCheckbox
                      id="VisibleMinority"
                      label="Owned by member(s) of Visible Minority"
                      value={dupeCompany.VisibleMinority}
                      onChangeFunction={formik.handleChange}
                      isEditing={false}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end">
              <IppSubmitButton buttonText={t("strGen:buttons.save")} />
            </Grid>
          </Box>
        </Paper>
      </form>
    );

  return (
    <Root>
      <Box display="flex" justifyContent="center">
        <Grid container className={classes.editForm} spacing={1}>
          <Grid item xs={12}>
            <Paper elevation={6} className={classes.boxSpace}>
              <Box padding={1} sx={{ borderRadius: 1 }}>
                <Typography variant="h5" gutterBottom>
                  {t("strGen:pages.merge.mergefull", {
                    fieldname: t("objBen:objects.company.name", {
                      count: 2,
                    }),
                  })}
                </Typography>
                <Typography variant="body1">
                  {t("strGen:pages.merge.info", {
                    fieldname: t("objBen:objects.company.name", {
                      count: 2,
                    }),
                    fieldname2: t("objBen:objects.company.name"),
                  })}
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            {formView}
          </Grid>
        </Grid>
      </Box>
    </Root>
  );
};
