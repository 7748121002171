import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { addPayment, updPayment, delPayment } from "./PaymentSlice";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppFormHeader } from "components/IppFormHeader";
import { IppAutocomplete } from "components/IppAutocomplete";
import { fetchCompanies } from "features/benefits/companies/CompaniesSlice";
import { IppStaticTextfield } from "components/IppStaticTextfield";
import { fetchProjects } from "features/project/ProjectSlice";
import LoadingIndicator from "components/LoadingIndicator";
import { fetchProjectComponentsByProject } from "features/project/ProjectComponentSlice";
import SmallLoadingIndicator from "components/SmallLoadingIndicator";
import { IppAttachmentInventory } from "components/IppAttachmentInventory";
import { Prompt } from "react-router";
import { IppDisplayField } from "components/IppDisplayField";
import { IppDatePicker } from "components/IppDatePicker";
import {
  ConvertDateOffset,
  ConvertToJSDate,
  GetJSDate,
  LastDayInPreviousMonth,
} from "utils/DateFunctions";
import { IsMedSmall } from "utils/mediaQueries";
import { UserWriteAccess } from "utils/userAccess";
import { Contract } from "api/contractAPI";
import { fetchContracts } from "features/contract/ContractSlice";
import { PaymentValidation } from "./PaymentValidation";
import { useTypedTranslation } from "utils/customHooks";
import { IppTabPanel } from "components/IppTabPanel";
import { countAttachments } from "api/attachmentsAPI";
import { IppCurrencyField } from "components/IppCurrencyField";
import { fetchPaymentPeriods } from "../paymentPeriods/PaymentPeriodSlice";
import { IppFormDivider } from "components/IppFormDivider";
import { IppCurrencyDisplay } from "components/IppCurrencyDisplayField";
import { Company } from "api/companyAPI";
import { CompanyOptionsRender } from "utils/renderFunctions";
import { ShowActiveCompanies } from "../BenefitsUtils";
import { useRoleChecks } from "utils/customHooks";

const PREFIX = "PaymentForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    // minWidth: 650,
    maxWidth: 1150,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const PaymentForm = (props: any) => {
  const paymentData = props.payment || {};
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objBen", "objSlr", "objPlt", "strGen"]);
  const customBP = IsMedSmall();
  const [projectID, setProjectID] = useState(paymentData.ProjectID || 0);
  const [companyID, setCompanyID] = useState(paymentData.CompanyID || 0);
  const [isEditing, _setIsEditing] = useState(
    paymentData.PaymentID ? false : true
  );
  const [isAdding, setIsAdding] = useState(
    paymentData.PaymentID ? false : true
  );
  const [filteredContracts, setFilteredContracts] = useState([] as Contract[]);
  const [activeTab, setActiveTab] = useState(0);
  // used to display attachments count
  const [attachCount, setAttachCount] = useState({ Count: 0 } as any);
  const showEdit = UserWriteAccess("Benefits", paymentData.ProjectID);
  const { currentProfile, currentUserRoleList, currentUserRolesById } =
    useSelector((state: RootState) => state.profile);

  const userRoles = currentUserRoleList.map((id) => currentUserRolesById[id]);

  const { getBenefitsUserRoles } = useRoleChecks();

  const benPowerUserRoles = getBenefitsUserRoles();
  const accessibleProjectIDs = benPowerUserRoles.map((role) => role.ProjectID);
  const powerUserHasAccess = accessibleProjectIDs.some(
    (projectId) => projectId === paymentData.ProjectID
  );

  const setAllowCompanyUserEdit = () => {
    // determines editing for company user
    if (currentProfile.IsClientAdmin) {
      return true;
    } else if (paymentData.WorkflowStageID === 1) {
      return true;
    } else if (paymentData.WorkflowStageID === 2 && currentProfile.IsClient) {
      return true;
    } else if (powerUserHasAccess) {
      return true;
    }

    return false;
  };

  const allowCompanyUserEdit = setAllowCompanyUserEdit();

  const setIsEditing = (edits: Boolean) => {
    _setIsEditing(edits && allowCompanyUserEdit); // prevents non client users from doing any edits on this page
  };

  const { isLoading: clientIsLoading, ft_ben_Contract } = useSelector(
    (state: RootState) => state.client
  );

  const { isLoading: paymentIsLoading } = useSelector(
    (state: RootState) => state.payments
  );

  const {
    paymentPeriodsById,
    paymentPeriodList,
    isLoading: payPeriodIsLoading,
  } = useSelector((state: RootState) => state.paymentPeriods);

  const paymentPeriods = paymentPeriodList.map((id) => paymentPeriodsById[id]);

  const paymentPeriod = paymentPeriods.find(
    (period) => period.PaymentPeriodID === paymentData.PaymentPeriodID
  );

  const {
    companiesById,
    companyList,
    isLoading: companyIsLoading,
  } = useSelector((state: RootState) => state.companies);

  const companies = companyList.map((CompanyID) => companiesById[CompanyID]);
  const clientCompany = companies.find((obj) => obj.IsClient === true);

  const {
    projectList,
    projectsById,
    isLoading: projectIsLoading,
  } = useSelector((state: RootState) => state.projects);

  const projects = projectList
    .map((id) => projectsById[id])
    .filter((proj) => {
      if (currentProfile.IsClientAdmin) {
        return true;
      } else {
        return userRoles.some((role) => {
          return role.ProjectID === proj.ProjectID && role.ModuleID === 1;
        });
      }
    });

  const {
    projectComponentsById,
    projectComponentList,
    isLoading: projectComponentIsLoading,
  } = useSelector((state: RootState) => state.projectComponents);

  const projectcomponents = projectComponentList.map(
    (ProjectComponentID) => projectComponentsById[ProjectComponentID]
  );

  const {
    clientCurrencyList,
    clientCurrenciesById,
    isLoading: clientCurrencyIsLoading,
  } = useSelector((state: RootState) => state.clientCurrencies);

  const clientCurrency = clientCurrencyList.map(
    (id) => clientCurrenciesById[id]
  );

  const {
    contractList,
    contractsById,
    isLoading: contractIsLoading,
  } = useSelector((state: RootState) => state.contracts);

  //Full list of Contracts User can access on API, not filtered
  const contractOptions = contractList.map((id) => contractsById[id]);

  const { attachmentsById, attachmentList } = useSelector(
    (state: RootState) => state.attachments
  );

  const attachments = attachmentList.map((id) => attachmentsById[id]);

  // useEffect for when project changes
  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        dispatch(fetchProjectComponentsByProject(accessToken, projectID));

        //Filter full list of Contracts to generate options list after Proj and Comp set
        if (ft_ben_Contract && projectID && formik.values.CompanyID) {
          const contractsFiltered = contractOptions.filter(
            (contractItem: Contract) =>
              contractItem.ProjectID === projectID &&
              contractItem.CompanyID === formik.values.CompanyID
          );
          setFilteredContracts(contractsFiltered);
        } else {
          setFilteredContracts([]);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [projectID, companyID, dispatch, getAccessTokenSilently]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchCompanies(accessToken, 1));
        dispatch(fetchProjects(accessToken));
        if (ft_ben_Contract) {
          dispatch(fetchContracts(accessToken));
        }
        if (paymentData.PaymentID) {
          if (paymentData.PaymentPeriodID && !paymentPeriod) {
            dispatch(fetchPaymentPeriods(accessToken));
          }
          const count = await countAttachments(
            accessToken,
            "Payments",
            paymentData.PaymentID
          );
          setAttachCount(count.attachments ? count.attachments : 0);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  // used for attachment count
  useEffect(() => {
    (async () => {
      try {
        if (attachments.length > 0 && !isAdding) {
          const attach = attachments.filter((item) => {
            return (
              item.RecordID === paymentData.PaymentID &&
              item.RecordType === "Payment"
            );
          });
          if (attach && attach.length > 0)
            setAttachCount({ Count: attach.length });
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [attachmentList]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        // add project id to object, for back-end validation
        values.ProjectID = projectID;
        dispatch(addPayment(accessToken, values, false));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const handleDelete = () => {
    // function to delete current Payment entry
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(
          delPayment(
            accessToken,
            paymentData.PaymentID,
            paymentData.PaymentPeriodID,
            paymentData.PaymentPeriodID ? true : false
          )
        );
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const handleIsBtnDisabled = () => {
    if (currentProfile.IsClient) {
      return false;
    } else if (paymentData.WorkflowStageID === 1) {
      return false;
    } else return true;
  };

  const handleTabSelect = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };

  let submitFunc = onSub;

  if (!isAdding) {
    //Update case
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });
          // prevents non client users from updating
          allowCompanyUserEdit &&
            dispatch(updPayment(accessToken, values.PaymentID, values, false));
        } catch (e) {
          console.error(e);
        }
      })();
    };
  } else {
    //set defaults for create case which can only be Top Level Payment from this form
    paymentData.ProjectID = -1;
    paymentData.PaymentPeriodID = null;
    paymentData.CompanyID = -1;
    paymentData.CompanyName = "";
    paymentData.PaidByCompanyID = clientCompany?.CompanyID || -1;
    paymentData.PaymentDate = LastDayInPreviousMonth();
    paymentData.PaymentAmount = 0;
    paymentData.ProjectComponentID = null;
    if (ft_ben_Contract) {
      paymentData.ContractID = -1;
    }
  }

  useEffect(() => {
    if (!paymentData.PaymentID) {
      if (
        clientCompany &&
        formik.values.PaidByCompanyID !== clientCompany.CompanyID
      ) {
        formik.setFieldValue("PaidByCompanyID", clientCompany.CompanyID);
      }
    }
  }, [clientCompany]);

  const formik = useFormik({
    initialValues: paymentData,
    validationSchema: PaymentValidation(paymentPeriod),
    onSubmit: submitFunc,
  });

  // when editing, get project id
  if (!isAdding && projectID === 0) {
    if (!projectComponentIsLoading) {
      let pc = projectComponentsById[paymentData.ProjectComponentID];

      if (pc) {
        setProjectID(pc.ProjectID);
      }
    }
  }

  let viewForm = (
    <Box display="flex" justifyContent="center">
      <Grid container className={classes.editForm} spacing={1}>
        <Grid item xs={12}>
          <Paper className={classes.boxSpace}>
            <Grid container spacing={1}>
              <IppFormHeader
                title={
                  formik.values.PaymentPeriodID
                    ? t("objBen:objects.payment.name")
                    : t("objBen:objects.payment.directspend")
                }
                isEditing={isEditing}
                isAdding={isAdding}
                // returnPath="/benefits/payments"
                showReturn={currentProfile.IsClient ? true : false}
                showReturnToInv={false}
              />
              <Grid item xs={12}>
                <IppDisplayField
                  label={"ID"}
                  value={
                    formik.values.PaymentPeriodSequenceID
                      ? `SR-${formik.values.PaymentPeriodSequenceID}-S-${formik.values.SequenceID}`
                      : `DS - ${formik.values.SequenceID}`
                  }
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={false}
                />
              </Grid>

              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objBen:objects.payment.fields.paidto")}
                  value={formik.values.CompanyName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  isWebLink={
                    currentProfile.IsClient && formik.values.CompanyName
                      ? true
                      : false
                  }
                  route={`${window.location.href.substring(
                    0,
                    window.location.href.lastIndexOf("payments")
                  )}companies/${formik.values.CompanyID}`}
                  openInNewTab={false}
                  showEdit={showEdit}
                  tooltip={t("strGen:buttons.tooltip.clicktoview", {
                    objectname: t("objBen:objects.company.name"),
                  })}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objBen:objects.payment.fields.paidby")}
                  value={formik.values.PaidByCompanyName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  isWebLink={formik.values.PaidByCompanyName ? true : false}
                  route={`${window.location.href.substring(
                    0,
                    window.location.href.lastIndexOf("payments")
                  )}companies/${formik.values.PaidByCompanyID}`}
                  openInNewTab={false}
                  showEdit={showEdit}
                  tooltip={t("strGen:buttons.tooltip.clicktoview", {
                    objectname: t("objBen:objects.company.name"),
                  })}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objPlt:objects.project.name")}
                  value={formik.values.ProjectName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objPlt:objects.projectcomponent.fullname")}
                  value={formik.values.ProjectComponentName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              {ft_ben_Contract && (
                <Grid item xs={6}>
                  <IppDisplayField
                    label={t("objBen:objects.contract.name")}
                    value={formik.values.ContractTitle}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={showEdit}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objBen:objects.payment.fields.pmtdate")}
                  value={ConvertToJSDate(formik.values.PaymentDate)}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppCurrencyDisplay
                  label={t("objBen:objects.payment.fields.pmtamount")}
                  value={formik.values.PaymentAmount}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={showEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objBen:objects.payment.fields.currency")}
                  value={clientCurrency[0]?.CurrencyName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={false}
                />
              </Grid>

              <Grid item xs={12}>
                <IppFormButtons
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  isAdding={isAdding}
                  resetFunction={() => formik.resetForm()}
                  showDelete={allowCompanyUserEdit && showEdit}
                  deleteFunction={handleDelete}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );

  let editForm = clientIsLoading ? (
    <LoadingIndicator />
  ) : (
    <form noValidate onSubmit={formik.handleSubmit}>
      {!formik.isSubmitting && (
        <Prompt
          when={formik.dirty}
          message={t("strGen:prompts.unsavedchanges")}
        />
      )}

      <Grid container spacing={1}>
        <IppFormHeader
          title={t("objBen:objects.payment.directspend")}
          isEditing={isEditing}
          isAdding={isAdding}
          returnPath="/benefits/payments"
        />
        {isAdding && (
          <Grid item xs={12} marginBottom={2}>
            <IppFormDivider title="Note:" isBold={true} />
            <Typography>
              {`This is a record for a Direct Spend paid by ${
                clientCompany?.CompanyName || "the Client Company"
              } to a Contractor or Supplier. This Direct Spend will not be associated with Spend Reporting Periods.`}
            </Typography>
          </Grid>
        )}

        <Grid item xs={6}>
          <IppDatePicker
            id="PaymentDate"
            label={t("objBen:objects.payment.fields.pmtdate")}
            required={true}
            value={ConvertDateOffset(formik.values.PaymentDate)}
            disableFuture={true}
            onChangeFunction={(newValue: any) => {
              formik.setFieldValue("PaymentDate", GetJSDate(newValue), true);
              formik.setFieldTouched("PaymentDate", true, false);
            }}
            errorsExpression={formik.errors.PaymentDate}
            touchedExpression={formik.touched.PaymentDate}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            monthOnly={false}
          />
        </Grid>

        <Grid item xs={6}>
          <IppAutocomplete
            id="CompanyID"
            options={ShowActiveCompanies(companies)}
            value={companies.find((obj) => {
              return obj.CompanyID === formik.values.CompanyID;
            })}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                formik.setFieldValue("CompanyID", newValue.CompanyID);
                setCompanyID(newValue.CompanyID);
                formik.setFieldValue("ContractID", null);
              } else {
                formik.setFieldValue("CompanyID", -1);
                formik.setFieldValue("ContractID", null);
              }
            }}
            label={t("objBen:objects.payment.fields.paidto")}
            required={true}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            optionLabelFunction={(option: any) => option.CompanyName}
            renderOption={(props: any, option: Company) => {
              return CompanyOptionsRender(props, option);
            }}
            textValueFunction={formik.values.CompanyName}
            errorFunction={formik.errors.CompanyID}
            touchedFunction={formik.touched.CompanyID}
          />
        </Grid>
        <Grid item xs={6}>
          <IppAutocomplete
            id="ProjectID"
            options={projects}
            value={projects.find((obj) => {
              return obj.ProjectID === projectID;
            })}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                setProjectID(newValue.ProjectID);
                formik.setFieldValue("ProjectID", newValue.ProjectID);
                formik.setFieldValue("ProjectComponentID", -1);
                formik.setFieldValue("ContractID", null);
              } else {
                formik.setFieldValue("ProjectID", -1);
                formik.setFieldValue("ContractID", null);
              }
            }}
            label={t("objPlt:objects.project.name")}
            required={true}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            optionLabelFunction={(option: any) => option.ProjectName}
            textValueFunction={
              !projectIsLoading && projectID > 0
                ? projectsById[projectID]?.ProjectName
                : ""
            }
            errorFunction={formik.errors.ProjectID}
            touchedFunction={formik.touched.ProjectID}
            disabled={paymentPeriod ? true : false}
          />
        </Grid>
        <Grid item xs={6}>
          {projectComponentIsLoading ? (
            <SmallLoadingIndicator />
          ) : (
            <IppAutocomplete
              id="ProjectComponentID"
              options={projectID > 0 ? projectcomponents : []}
              value={projectcomponents.find((obj) => {
                return (
                  obj.ProjectComponentID === formik.values.ProjectComponentID
                );
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue(
                    "ProjectComponentID",
                    newValue.ProjectComponentID
                  );
                } else {
                  formik.setFieldValue("ProjectComponentID", -1);
                }
              }}
              label={t("objPlt:objects.projectcomponent.fullname")}
              required={true}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.ProjectComponentName}
              textValueFunction={
                projectID > 0 ? formik.values.ProjectComponentName : ""
              }
              errorFunction={formik.errors.ProjectComponentID}
              touchedFunction={formik.touched.ProjectComponentID}
              noOptionsText={t("strGen:components.nooptionsforobj", {
                objectname: t("objPlt:objects.project.name"),
              })}
              disabled={paymentPeriod ? true : false}
            />
          )}
        </Grid>
        {ft_ben_Contract && (
          <Grid item xs={6}>
            <IppAutocomplete
              id="ContractID"
              options={projectID > 0 ? filteredContracts : []}
              value={
                contractOptions.find((obj) => {
                  return obj.ContractID === formik.values.ContractID;
                }) || ""
              }
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("ContractID", newValue.ContractID);
                } else {
                  formik.setFieldValue("ContractID", null);
                }
              }}
              label={t("objBen:objects.contract.name")}
              noOptionsText={t("strGen:components.nooptionsforobj", {
                objectname: `${t("objBen:objects.company.name")} ${t(
                  "objPlt:objects.project.name"
                )}`,
              })}
              disabled={
                paymentPeriod
                  ? true
                  : !(
                      formik.values.CompanyID > 0 && formik.values.ProjectID > 0
                    )
              }
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.ContractTitle || ""}
              errorFunction={formik.errors.ContractID}
              touchedFunction={formik.touched.ContractID}
              textValueFunction={
                formik.values.ContractID > 0 ? formik.values.ContractTitle : ""
              }
              autoPopulate={false}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <IppCurrencyField
            id="PaymentAmount"
            label={t("objBen:objects.payment.fields.pmtamount")}
            required
            value={formik.values.PaymentAmount}
            onChangeFunction={(newValue) =>
              formik.setFieldValue("PaymentAmount", newValue)
            }
            touchedExpression={formik.touched.PaymentAmount}
            errorsExpression={formik.errors.PaymentAmount}
            isEditing={isEditing}
          />
        </Grid>
        <Grid item xs={6}>
          <IppStaticTextfield
            id="CurrencyType"
            label={t("objBen:objects.payment.fields.currency")}
            value={clientCurrency[0]?.CurrencyName}
          />
        </Grid>
        <Grid item xs={12}>
          <IppFormButtons
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            isAdding={isAdding}
            resetFunction={() => formik.resetForm()}
            showDelete={true}
            deleteFunction={handleDelete}
          />
        </Grid>
      </Grid>
    </form>
  );

  let pageForm =
    paymentIsLoading ||
    clientIsLoading ||
    companyIsLoading ||
    projectIsLoading ||
    clientCurrencyIsLoading ||
    (paymentPeriod && payPeriodIsLoading) ||
    (ft_ben_Contract && contractIsLoading) ? (
      <LoadingIndicator />
    ) : isAdding ? (
      <Box display="flex" justifyContent="center">
        <Grid container className={classes.editForm} spacing={1}>
          <Grid item xs={12}>
            <Paper className={classes.boxSpace}>{editForm}</Paper>
          </Grid>
        </Grid>
      </Box>
    ) : (
      <Grid container spacing={1}>
        <Grid item xs={customBP ? 12 : 5}>
          {isEditing ? (
            <Dialog open={isEditing} fullWidth maxWidth="lg">
              <DialogContent>{editForm}</DialogContent>
            </Dialog>
          ) : (
            <div>{viewForm}</div>
          )}
        </Grid>
        <Grid item xs={customBP ? 12 : 7}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={activeTab}
                      onChange={handleTabSelect}
                      variant="scrollable"
                      scrollButtons
                      allowScrollButtonsMobile
                    >
                      <Tab
                        value={0}
                        label={`${t("objBen:objects.payment.attachments")} (${
                          attachCount.Count
                        })`}
                      />
                    </Tabs>
                  </Box>
                  <IppTabPanel value={activeTab} index={0}>
                    <Box>
                      <IppAttachmentInventory
                        recordType="Payments"
                        itemID={paymentData.PaymentID}
                        companyID={currentProfile.CompanyID}
                        projectID={projectID}
                        moduleID={1}
                        disabled={handleIsBtnDisabled()}
                        objectName={t("objBen:objects.payment.name")}
                        title={t("objBen:objects.payment.attachments")}
                        categoryRecordType="Payment"
                      />
                    </Box>
                  </IppTabPanel>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );

  return <Root>{pageForm}</Root>;
};
